import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {  registerRestaurant } from "../../services/auth.services";
import { Link } from "react-router-dom";
import { globalLoader, handleServerValidations } from "../../../../utils/commonfunction";
import {
  SWAL_SETTINGS,
  PHONE_NO_LENGTH,
  TEXTAREA_MAX_LENGTH,
  cuisineOptions,
} from "../../../../utils/Constants";
import { useTranslation } from "react-i18next";
import CustomError from "../../../../utils/customError";
import { useValidationSchemas } from "../../validator/validation";
import logo from "../../../../assets/restaurant/images/logo.svg";
import PhoneInput from "../../../../utils/PhoneInput";
import { TagPicker, Tooltip, Whisper } from "rsuite";
import Login from "./login";
import { useSelector } from "react-redux";


const RestaurantRegister = () => {
  const { t } = useTranslation();
  const { restaurantAddValidationSchema } = useValidationSchemas();
  const navigate = useNavigate();
  const [previewimage, setPreviewImage] = useState("");
  const [enableButton , setEnableButton] = useState(false);


  const myGlobalData = useSelector((state) => state.globalData?.data);
  const [getGlobalData, setGlobalData] = useState({});

  useEffect(() => {
      if (myGlobalData) {
          const settingsData = JSON.parse(myGlobalData);
          setGlobalData(settingsData);
      }
  }, [myGlobalData]);






  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      restaurant_name: "",
      email: "",
      address: "",
      phone_number: "",
      profile_image: "",
      cuisine_type: [],
    },
    validateOnBlur: false,
    validationSchema: restaurantAddValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // globalLoader(true);
      // setSubmitted(true);
     
      let formData = new FormData();
      formData.append("restaurant_name", values.restaurant_name);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("phone_number", values.phone_number);
      formData.append("restaurant_logo", values.profile_image);
      formData.append("cuisine_type", JSON.stringify(values.cuisine_type));
      setEnableButton(true);
      registerRestaurant(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            resetForm();
            setEnableButton(false);
            navigate(`/restaurant/login`);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            setEnableButton(false);
            // setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
          setEnableButton(false);
        });
    },
  });

  return (
    getGlobalData.signup_option?  (
    <div className="register-wrap">
      <div className="register-wrap-in">
        <div className="login-logo">
          <img src={logo} alt="" />
        </div>
        <div className="login-form-wrap">
          <div className="login-form-header">
            <h4>{t("register_text")}</h4>
            <p>{t("register_para_text")}</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="login-form">
              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="restaurant_name" className="form-label">
                    {t("label_restaurant_name")}
                    <span>*</span>
                  </label>
                  <input
                    type="restaurant_name"
                    className="form-control"
                    id="restaurant_name"
                    name="restaurant_name"
                    placeholder={t("placeholder_restaurant_name")}
                    value={formik?.values?.restaurant_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="restaurant_name" form={formik} />
                  </span>
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("label_email")}
                    <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder={t("placeholder_email_address")}
                    value={formik?.values?.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="email" form={formik} />
                  </span>
                </div>

                <div className="col-6 mb-3">
                  <label htmlFor="company_address" className="form-label">
                    {t("label_address")}:<span className="requirestar">*</span>{" "}
                  </label>
                  <textarea
                    name="address"
                    id="company_address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_address}
                    className="form-control"
                    placeholder={t("company_address_placeholder")}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="address" form={formik} />
                  </span>
                </div>

                <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="phone_number">
                    {t("label_phone_number")}:<span className="requirestar">*</span>{" "}
                  </label>
                  <PhoneInput
                    name="phone_number"
                    id="phone_number"
                    onChange={(e) => {
                      formik.setFieldValue("phone_number", e?.phone_number);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_number}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                  </span>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="cuisine_type" className="text-left d-flex form-label">
                    {t("label_cuisine_type")}:<span className="requirestar">*</span>{" "}
                  </label>
                  <div className="">
                    <TagPicker
                      className="cp"
                      style={{display:'block'}}
                      data={cuisineOptions}
                      value={formik?.values?.cuisine_type}
                      onChange={(selectedValues) => {
                        formik.setFieldValue("cuisine_type", selectedValues);
                      }}
                      renderValue={(value, items) => {
                        const visibleOptions = 6;
                        const displayItems = items.slice(0, visibleOptions); // Show only the first 2 items
                        const hiddenCount = items.length - displayItems.length;
                        const removeTag = (i) => {
                          let allValues = [...formik?.values?.cuisine_type];
                          allValues.splice(i, 1);
                          formik.setFieldValue("cuisine_type", allValues);
                        };
                        return (
                          <>
                            {displayItems.map((item, i) => {
                              return (
                                <span className="rs-tag-text" key={i}>
                                  <div role="option" title="Indian" className="rs-tag rs-tag-md rs-tag-default rs-tag-closable">
                                    <span className="rs-tag-text">{item?.label}</span>
                                    <button
                                      type="button"
                                      className="rs-tag-icon-close rs-btn-close"
                                      aria-label="Remove"
                                      onClick={() => {
                                        removeTag(i);
                                      }}
                                    >
                                      <svg
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        focusable="false"
                                        className="rs-icon"
                                        aria-label="close"
                                        data-category="application"
                                      >
                                        <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </span>
                              );
                            })}
                            {items.length > visibleOptions ? <span> {hiddenCount} more ...</span> : null}
                          </>
                        );
                      }}
                      cleanable={false}
                    />
                  </div>
                  <span className="text-danger d-flex text-left">
                    <CustomError name="cuisine_type" form={formik} />
                  </span>
                </div>

                <div className="col-lg-6 text-center form-group">
                  <label htmlFor="profile_image" className="text-left d-flex ">
                    <span className="form-label">{t("label_logo")}:{" "}</span>
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{t("image_support_tooltip")}</Tooltip>}>
                      <span className="field-more-info mt-1 ms-1 cp">?</span>
                    </Whisper>
                  </label>
                  <input
                    className="form-control imgInput"
                    id="profile_image"
                    name="profile_image"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      formik.setFieldValue("profile_image", event.target.files[0] || "");
                      event.target.files.length === 1 ? setPreviewImage(URL.createObjectURL(event.target.files[0])) : setPreviewImage("");
                    }}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="profile_image" form={formik} />
                  </span>

                  {previewimage ? (
                    <ul className="question-image-preview questions-ul">
                      <li className="pr_img_box">
                        <img src={previewimage} style={{ height: "100px" }} alt={"Profile_Img"} />
                        <div className="img_options">
                          <button
                            type="button"
                            className="text-danger"
                            onClick={() => {
                              // console.log(previewResImage , previewimage)
                              setPreviewImage("");
                              formik.setFieldValue("profile_image", null);
                              document.getElementById("profile_image").value = "";
                            }}
                          >
                            <i className="ri-delete-bin-6-fill"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button className="btn btn-primary w-100 mt-4 justify-content-center" disabled={enableButton}>{t("label_register")}</button>
              <div className="login-cta-bottom text-center mt-3">
                <p>
                  {t("already_have_account_text")}?
                  <Link className="link-text ms-1" to="/restaurant/login">
                    {t("link_login")}
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>) : <Login/>
  );
};

export default RestaurantRegister;
