import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  cashRegister:null, 
  cashRegisterDetail : null
};

const cashierSlice = createSlice({
  name: 'cashierDetails',
  initialState,
  reducers: {
    setCashierDetail(state, action) {
      state.data = action.payload;
    },
    setCashRegister(state, action) {
      state.cashRegister = action.payload;
    }, 
    setCashierRegisterDetail(state, action) {
      state.cashRegisterDetail = action.payload;
    },
  }
});

export const { setCashierDetail,setCashRegister, setCashierRegisterDetail } = cashierSlice.actions;
export default cashierSlice.reducer;