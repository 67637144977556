import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login } from "../../services/auth.services";
import { Link } from "react-router-dom";
import {
    getSessionKey,
    handleNavigation,
    handleServerValidations,
    removeSessionKey,
    setLocalKey,
    setSessionKey,
} from "../../../../utils/commonfunction";
import { SWAL_SETTINGS, COOKIES_EXPIRATION, ROLE, REST_LOGIN_ERROR_TYPES } from "../../../../utils/Constants";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import CustomError from "../../../../utils/customError";
import { useValidationSchemas } from "../../validator/validation";
import logo from '../../../../assets/restaurant/images/logo.svg';
import { useSelector } from "react-redux";


const Login = () => {
    const { t } = useTranslation()
    const { loginValidationSchema } = useValidationSchemas();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const myGlobalData = useSelector((state) => state.globalData?.data);
    const [getGlobalData, setGlobalData] = useState({});

    useEffect(() => {
        if (myGlobalData) {
            const settingsData = JSON.parse(myGlobalData);
            setGlobalData(settingsData);
        }
    }, [myGlobalData]);

    /** Remember last logged-in user >>>> */
    useEffect(() => {
        const storedUsername = Cookies.get("rest_email");
        const storeUserpass = Cookies.get("rest_password");
        if (storedUsername) {
            formik.setFieldValue("email", storedUsername);
        }
        if (storeUserpass) {
            formik.setFieldValue("password", storeUserpass);
        }
    }, []);
    /** Remember last logged-in user <<<< */

    const handleshow = () => {
        setShow(!show);
    };



    function handleRememberMe(e) {
        if (e.target.checked) {
            setSessionKey("remember-me-rest", "true");
        } else {
            removeSessionKey("remember-me-rest");
            Cookies.remove("rest_email");
            Cookies.remove("rest_password");
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();

            formData.append("email", values.email);
            formData.append("password", values.password)


            login(formData)
                .then((response) => {
                    if (response.success) {
                        if (getSessionKey("remember-me-rest") === "true") {
                            Cookies.set("rest_email", response?.data?.email, {
                                expires: COOKIES_EXPIRATION,
                            });
                            Cookies.set("rest_password", values.password, {
                                expires: COOKIES_EXPIRATION,
                            });
                        } else {
                            Cookies.remove("rest_email");
                            Cookies.remove("rest_password");
                        }
                        setLocalKey(ROLE.RESTAURANT, JSON.stringify(response.data));
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        handleNavigation({
                            successURL: "/restaurant/dashboard",
                            role: ROLE.RESTAURANT
                        });
                    } else {
                        let message = handleServerValidations(response);
                        /** Handling different types of server errors */
                        const supportUrlConditions = [REST_LOGIN_ERROR_TYPES.ACCOUNT_IS_BLOCKED, REST_LOGIN_ERROR_TYPES.SUBSCRIPTION_EXPIRED, REST_LOGIN_ERROR_TYPES.ACCOUNT_NOT_ENROLLED, REST_LOGIN_ERROR_TYPES.PASSWORD_NOT_SET_YET];
                        if (supportUrlConditions.indexOf(response.error_type) !== -1) {
                            message += `<a class="d-block mt-2 no-decoration link-text" href="/support/${response.error_type}">${t('btn_text_contact_us')}</a>`;
                        }
                        Swal.fire({
                            icon: "error",
                            html: message,
                            ...SWAL_SETTINGS
                        });
                    }
                })
                .catch((error) => {
                    console.log("error===>", error)
                })
        }
    });



    return (
        <div className="login-wrap">
            <div className="login-wrap-in">
                <div className="login-logo">

                    <img src={logo} alt="" />

                </div>
                <div className="login-form-wrap">
                    <div className="login-form-header">
                        <h4>{t('login_text')}</h4>
                        <p>{t('login_para_text')}</p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-form">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{t('label_email_address')}<span>*</span></label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder={t('placeholder_email_address')}
                                    value={formik?.values?.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                />
                                <span className="text-danger d-flex text-left">
                                    <CustomError name="email" form={formik} />
                                </span>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">{t('label_password')}<span>*</span></label>
                                <div className="input-group">
                                    <input
                                        type={!show ? "text" : "password"}
                                        className="form-control border-end-0"
                                        id="password"
                                        placeholder={t('placeholder_password')}
                                        value={formik?.values?.password}
                                        name="password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span
                                        className="input-group-text cp restaurant-password-toggle"
                                        onClick={handleshow}
                                    >
                                        {!show === true ? (
                                            <i className="ti ti-eye"></i>
                                        ) : (
                                            <i className="ti ti-eye-off"></i>
                                        )}
                                    </span>
                                </div>

                                <span className="text-danger d-flex text-left">
                                    <CustomError name="password" form={formik} />
                                </span>

                            </div>
                            <div className="login-action-check d-flex justify-content-between align-items-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="rest-remember-me"
                                        value={"true"}
                                        defaultChecked={
                                            (Cookies.get("rest_email"),
                                                Cookies.get("rest_password"))
                                        }
                                        onChange={handleRememberMe}

                                    />
                                    <label className="form-check-label" htmlFor="rest-remember-me">
                                        {t("remember_me")}
                                    </label>

                                </div>
                                {/* <a href="#" className="link-text">Forgot password?</a> */}
                                <Link className="link-text" to="/restaurant/forget-password">
                                    {t("forgot_password")}?
                                </Link>

                            </div>
                            <button className="btn btn-primary w-100 mt-4 justify-content-center">{t('link_login')}</button>
                          {getGlobalData.signup_option&& 
                           <div className="login-cta-bottom text-center mt-3">

                                <p>{t("dont_have_account_text")}
                                    <Link className="link-text ms-1" to="/restaurant/register">
                                        {t('label_register')}
                                    </Link>
                                </p>
                            </div>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login