import React from 'react'
import { SORT_A, SORT_D } from '../../../utils/Constants'

export default function Sorting({ sort, handleSort, column, columnName, defaultSorting, breakName = false }) {
  return (
    <>
      {
        breakName === 'true' ?
          <div className="d-flex align-items-center">
            <span>{columnName}
              <>
                <i onClick={(e) => handleSort(e, column)} className={`cp ti ti-arrow-narrow-up ${sort.column === column && sort.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                <i onClick={(e) => handleSort(e, column)} className={`cp ti ti-arrow-narrow-down ${sort.column === column && sort.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
              </>

            </span>
          </div> :
          <>
            <span>{columnName}
              <>
                <i onClick={(e) => handleSort(e, column)} className={`cp ti ti-arrow-narrow-up ${sort.column === column && sort.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                <i onClick={(e) => handleSort(e, column)} className={`cp ti ti-arrow-narrow-down ${sort.column === column && sort.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
              </>

            </span>
          </>
      }
    </>
  )
}
