import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/abc-analysis";
const options = {role: ROLE.RESTAURANT};




export const getProductBasisReport = async (values) => {
  return await axiosInstance(options).post(`${path}/product_basis`, values);
}

export const getCategoryBasisReport = async (values) => {
  return await axiosInstance(options).post(`${path}/Category_basis`, values);
}

export const getDayBasisReport = async (values) => {
  return await axiosInstance(options).post(`${path}/day_basis`, values);
}

