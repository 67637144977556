import React, { useEffect, useState } from "react";
import { editServiceCharge } from "../../../services/serviceCharge.service";
import { getRestaurantSettings } from "../../../services/profile.services";
import { LOADER_TIMEOUT_TIME, SERVICE_CHARGED_CONDITION, SWAL_SETTINGS } from "../../../../../utils/Constants";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { handleServerValidations } from "../../../../../utils/commonfunction";
import { useValidationSchemas } from "../../../validator/validation";
import CustomError from "../../../../../utils/customError";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function ServiceCharge() {
  const [Loader, setLoader] = useState(false);
  const [ChargeSettings, setChargeSettings] = useState({});
  const { serviceChargeValidationSchema } = useValidationSchemas();
  const {t} = useTranslation();
  const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getRestaurantSettings()
      .then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        setChargeSettings(response && response?.data ? response?.data : {});
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log("Error===>", error);
      });
  }, []);

  const formServiceCharge = useFormik({
    initialValues: {
      service_charge: ChargeSettings.service_charge || 0,
      service_charge_applicable_condition: ChargeSettings.service_charge_applicable_condition,
      is_service_charge_enabled: ChargeSettings.is_service_charge_enabled,
    },
    enableReinitialize: true,
    validationSchema : serviceChargeValidationSchema,
    onSubmit: (values) => {
      setAddSubmissionLoader(true);
      const formData = new FormData();
      formData.append("service_charge", values.service_charge);
      formData.append("service_charge_applicable_condition", values.service_charge_applicable_condition);
      formData.append("is_service_charge_enabled", values.is_service_charge_enabled);
      editServiceCharge(formData)
        .then((response) => {
          setAddSubmissionLoader(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          setAddSubmissionLoader(false);
          console.error("Error updating charges", error);
        });
    },
  });

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t('service_charge')}</h4>
        </div>
        {
            Loader?(<div className="service-charges-box">
                <div className="card"> 
                <Skeleton className="m-2" height={50} count={2} />
                </div>
                 
            </div>): (
                 <div className="service-charges-box">
                 <form onSubmit={formServiceCharge.handleSubmit}>
                   <div className="card">
                     <div className="card-body">
                       <div className="d-flex gap-3 justify-content-between">
                         <div className="w-75 d-flex gap-3">
                           <div className="form-group w-100 e_none">
                             <input type="text" className="form-control" placeholder={t('service_charges')} readOnly />
                           </div>
                           <div className="form-group">
                             <div class="input-group">
                               <input
                                 type="text"
                                 class="form-control no_spring_input"
                                 placeholder={t('cost_price')}
                                 aria-label="Username"
                                 aria-describedby="basic-addon1"
                                 name="service_charge"
                                 value={formServiceCharge?.values?.service_charge}
                                 onChange={formServiceCharge.handleChange}
                                 onBlur={formServiceCharge.handleBlur}
                               />
       
                               <span class="input-group-text" id="basic-addon1">
                                 %
                               </span>
                               <CustomError className='text-danger' name="service_charge" form={formServiceCharge} />
                             </div>
                           </div>
                         </div>
                         <div class="form-check form-switch">
                           <input
                             class="form-check-input cp"
                             type="checkbox"
                             name="is_service_charge_enabled"
                             value={formServiceCharge?.values?.is_service_charge_enabled}
                             onChange={formServiceCharge.handleChange}
                             onBlur={formServiceCharge.handleBlur}
                             checked={formServiceCharge?.values?.is_service_charge_enabled}
                           />
                         </div>
                       </div>
                       <hr></hr>
                       <div className="d-inline-flex gap-5">
                         <div className="form-check">
                           <input
                             className="form-check-input cp"
                             type="radio"
                             id="always"
                             name="service_charge_applicable_condition"
                             value={SERVICE_CHARGED_CONDITION.ALWAYS}
                             onChange={formServiceCharge.handleChange}
                             onBlur={formServiceCharge.handleBlur}
                             checked={formServiceCharge.values.service_charge_applicable_condition === SERVICE_CHARGED_CONDITION.ALWAYS}
                           />
                           <label className="form-check-label cp" htmlFor="always">
                            {t('label_always')}
                           </label>
                         </div>
                         <div className="form-check">
                           <input
                             className="form-check-input cp"
                             type="radio"
                             id="checkout"
                             name="service_charge_applicable_condition"
                             value={SERVICE_CHARGED_CONDITION.AT_CHECKOUT}
                             onChange={formServiceCharge.handleChange}
                             onBlur={formServiceCharge.handleBlur}
                             checked={formServiceCharge.values.service_charge_applicable_condition === SERVICE_CHARGED_CONDITION.AT_CHECKOUT}
                           />
                           <label className="form-check-label cp" htmlFor="checkout">
                             {t('checkout_text')}
                           </label>
                         </div>
                       </div>
                     </div>
                   </div>
                   <button type="submit" className={"btn btn-primary mt-4"+(addSubmissionLoader?" btn-loader":"")}>
                     {t('btn_update')}
                   </button>
                 </form>
               </div>
            )
        }
       
      </div>
    </main>
  );
}

export default ServiceCharge;
