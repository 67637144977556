import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { daySales, daySalesReport } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, REPORT_TAX_TYPES, SORT_D, SORT_A } from '../../../../../utils/Constants';
import { showPrice, getDatesOfMonth, getTotalSalesData, formateDateWithMonthForGraph, writeMyExcel } from '../../../../../utils/commonfunction';
import ReactApexChart from 'react-apexcharts';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { DatePicker } from 'rsuite';
import moment from 'moment';
import RecordNotFound from '../../../shared/recordNotFound';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import { globalLoader } from "../../../../../utils/commonfunction";
import Sorting from '../../../shared/sorting';

export default function SalesReportStatusByDay() {

  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [saleData, setSaleData] = useState(null);
  const [dailySaleReport, setDailySaleReport] = useState(null);
  const [dailySaleTotalReport, setDailySaleTotalReport] = useState(null);
  const chartRef = useRef(null);
  const [reportDate, setReportDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState('');
  const [taxType, setTaxType] = useState(REPORT_TAX_TYPES[0].value);
  const componentRef = useRef();
  const [sorting, setSorting] = useState({ column: "date", order: SORT_D });
  const [Type, setType] = useState("day");
  const [defaultSorting, setDefaultSorting] = useState(true);

  const today = new Date();
  
  // Dynamically format the date based on the Type
  const initialSelectedDate =
    Type === "day"
      ? today.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
      : today.toLocaleDateString('en-GB', {
          month: 'short',
          year: 'numeric',
        });
  const lowerButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const getTodaySales = (obj) => {
    setLoader(true);
    daySales(obj).then(response => {
      setSaleData(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }


  const getTodaySalesReport = (obj) => {
    setLoader(true);
    daySalesReport(obj).then(response => {
      setDailySaleReport(response?.data?.dailyData || []);
      setDailySaleTotalReport(response?.data?.totalValues || null)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }

  useEffect(() => {
    let obj = {
      date: new Date(reportDate),
      paymentMethod: paymentMethod ? paymentMethod : undefined,
      taxType: taxType ? taxType : undefined
    }
    let obj2 = { ...obj }
    if (Object.keys(sorting).length > 0) {
      obj2 = {
        ...obj2,
        'sort': JSON.stringify(sorting)
      }
    }
    getTodaySales(obj);
    getTodaySalesReport(obj2);
  }, [selectedDate, paymentMethod, taxType, sorting]);

  const chartId = 'my-area-chart';
  const options = {
    chart: {
      id: chartId,
      height: 280,
      type: 'area',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#f28c40', '#008000'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        gradientToColors: ['#f28c40'],
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 100, 90],

        // opacityFrom: 0.7,
        // opacityTo: 0.9,
        // stops: [0, 90, 100],
      }
    },
    xaxis: {
      categories: getDatesOfMonth(reportDate)
    },
    yaxis: {
      labels: {
        formatter: (value) => `¥ ${value?.toFixed(2)}`, // Format the y-axis labels with the Euro symbol
      },
    },

    tooltip: {
      y: {
        formatter: function (value) {
          return `(¥ ${value?.toFixed(2)})`; // Adds brackets around the value
        }
      },
      x: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          const dates = getDatesOfMonth(reportDate)
          const [month, day] = dates[value - 1].split('/');
          const date = new Date(2024, month - 1, day);
          const monthName = date.toLocaleString('en-US', { month: 'long' });

          return `${monthName} ${day}`
        }
      },
      followCursor: true,
      intersect: false,
      // custom: function({series, seriesIndex, dataPointIndex, w}) {
      //   const {colors} = w.config;
      //   const {initialSeries} = w.globals;
      //   let tooltipHTML = `
      //   <div className="graph-tooltip-container p-3">
      //     <div className="row">   
      //     <div className="col-12 mb-2">
      //       <h6 className="text-white fs-5">February 11</h6>
      //     </div>     
      //   `;
      //   series.forEach((item, i)=>{
      //     tooltipHTML += `<div className="col-12 mb-3">
      //     <div className="d-flex align-items-center justify-content-start">
      //       <span className="tooltip-series-color-box me-3" style="background-color:${colors[i]};"></span>
      //       <span className="d-inline-block">${initialSeries[i].name} (¥ ${item[dataPointIndex]})</span>
      //     </div>
      //     </div>`;
      //   });
      //   tooltipHTML+=`
      //     <div className="tooltip-pointer">sss</div>
      //   `;
      //   tooltipHTML+=`</div></div>`;

      //   return tooltipHTML;
      // }
    },
    legend: {
      position: 'top'
    },
    grid: {
      show: true,
      borderColor: '#EEE',
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    markers: {
      size: 3,
      colors: ['#fff'],
      strokeColors: ['#f28c40', '#008000'],
      strokeWidth: 1,
      fillOpacity: 1,
      hover: {
        size: undefined,
        sizeOffset: 0
      }
    },
    stroke: {
      curve: 'smooth', // Curve type for the line (optional)
      width: 2 // Width of the line itself
    }

  };

  const series = [
    {
      name: t('totalProfit'),
      data: getTotalSalesData(reportDate, saleData, 'profit')
    },
    {
      name: t('nav_link_sales'),
      data: getTotalSalesData(reportDate, saleData)
    }
  ];


  const handleDownload = () => {
    globalLoader(true);
    downloadPdf();
  }


  const downloadPdf = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        const canvas = await html2canvas(html, { scale: 2 });
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let yOffset = 10;
        let pageHeight = 297;

        if (imgHeight > pageHeight) {
          pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
          pdf.addPage();
          yOffset = 0;
        } else {
          pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
        }
        pdf.save('daily_sale_report.xlsx');
        globalLoader(false);
      }
    },
  });



  const handleExportToExcel = () => {
    // Prepare the data for export
    const headers = [
      t('date'),
      t('totalTransactions'),
      t('totalSales'),
      t('totalCostPrice'),
      t('totalProfit'),
      t('profitRate'), // Will show percentage
      t('totalCash'),
      t('totalCredit'),
      t('totalOther'),
      t('totalCustomers'),
      t('men'),
      t('women'),
      t('orderPerCustomer')
    ];
  
    const rows = dailySaleReport.map((item) => [
      formateDateWithMonthForGraph(item?.date),
      item?.totalTransactions,
      `¥${showPrice(item?.totalSales)}`,
      `¥${showPrice(item?.totalCostPrice)}`,
      `¥${showPrice(item?.totalProfit)}`,
      item.profitRate ? `${item.profitRate.toFixed(2)}%` : '0%',
      `¥${showPrice(item?.totalCash)}`,
      `¥${showPrice(item?.totalCredit)}`,
      `¥${showPrice(item?.totalOther)}`,
      item?.totalCustomers,
      item?.men,
      item?.women,
      `¥${showPrice(item?.orderPerCustomer)}`
    ]);
  
    // Add total row at the end
    if (dailySaleTotalReport) {
      rows.push([
        t('total_label'),
        dailySaleTotalReport?.totalTransactions,
        `¥${showPrice(dailySaleTotalReport?.totalSales)}`,
        `¥${showPrice(dailySaleTotalReport?.totalCostPrice)}`,
        `¥${showPrice(dailySaleTotalReport?.totalProfit)}`,
        dailySaleTotalReport.profitRate
          ? `${dailySaleTotalReport.profitRate.toFixed(2)}%`
          : '0%',
        `¥${showPrice(dailySaleTotalReport?.totalCash)}`,
        `¥${showPrice(dailySaleTotalReport?.totalCredit)}`,
        `¥${showPrice(dailySaleTotalReport?.totalOther)}`,
        dailySaleTotalReport?.totalCustomers,
        dailySaleTotalReport?.men || 0,
        dailySaleTotalReport?.women || 0,
        `¥${showPrice(dailySaleTotalReport?.orderPerCustomer)}`
      ]);
    }
  
    writeMyExcel([headers, ...rows], "daily_sale_report.xlsx");
  };
  



  // const handleSort = (key) => {
  //   let direction = SORT_A;
  //   if (sortConfig.column === key && sortConfig.order === SORT_A) {
  //     direction = SORT_D;
  //   }
  //   setSortConfig({ column: key, order: direction });
  // };


  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (sorting.column === column && sorting.order === SORT_A) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  }



  const handleUpperButtonClick = () => {
    if (lowerButtonRef.current) {
      if (isDatePickerOpen) {
        // Close the DatePicker
        lowerButtonRef.current.close(); // Close the DatePicker programmatically
      } else {
        // Open the DatePicker
        lowerButtonRef.current.open(); // Open the DatePicker programmatically
      }
      setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
    }
  };
  

  const handleDateChange = (value) => {
    setReportDate(value);
    const formattedDate = value.toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric',
    });
    setSelectedDate(formattedDate); // Update the displayed value
    setIsDatePickerOpen(false); // Close the DatePicker after selection
  };

  const handleToggle = (open) => {
    setIsDatePickerOpen(open);
  };


 

  // Recalculate the selected date whenever Type or reportDate changes
  useEffect(() => {
    const formattedDate = reportDate.toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric',
    });
    setSelectedDate(formattedDate);
  }, [Type, reportDate]);

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false); // Close the DatePicker and change the icon state
  };


  return (
    <main id='main-content'>
      <div className='inner-container'>
        <div className='d-flex tabs-with-etc justify-content-between align-items-center mb-4'>
          <ul className="nav nav-pills gap-3">
            <li className="nav-item">
              <Link className="nav-link active" to={'/restaurant/sales'}>{t('byDay')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/restaurant/sales/sales-report-status-month'}>{t('byMonth')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/restaurant/sales/sales-report-status-week'}>{t('byDayOfWeek')}</Link>
            </li>
          </ul>
          <div className='tabls-dowpdown'>
            <select className="form-control cp" name='payment_method_type' value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
              <option value="">{t('choosePaymentMethod')}</option>
              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
            </select>
          </div>
        </div>
        <div className='card'>
          <div className='card-header border-0'>
            <div className='d-flex justify-content-between align-items-center'>

              {/* <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_date1")} placement="autoVertical"
                shouldDisableDate={(currentDate) => currentDate && currentDate > moment().endOf('day')} format="MMMM yyyy" cleanable={false} editable={false} name="reservation_date" onChange={(e) => { setReportDate(e) }} value={reportDate} /> */}


              <div style={{ position: 'relative', display: 'inline-block' }}>
                {/* Upper Button */}
                <button className="btn btn-date" onClick={handleUpperButtonClick}>
                  <i className="ti ti-calendar" style={{ color: "#757575" }}></i> <span style={{ color: "#757575" }}>{selectedDate}</span>{' '}
                  <i className={`ti ${isDatePickerOpen ? 'ti-chevron-up' : 'ti-chevron-down'}`} style={{ color: "#757575" }}></i>
                </button>

                {/* Hidden DatePicker */}
                <div
                  style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                >
                  <DatePicker
                    ref={lowerButtonRef}
                    oneTap={true}
                    classPrefix="restaurant"
                    placement="bottomStart" // Opens directly beneath the button
                    container={() => document.body} // Prevents clipping by parent containers
                    format="MMMM yyyy"
                    cleanable={false}
                    editable={false}
                    value={reportDate}
                    onChange={handleDateChange}
                    onToggle={handleToggle} // Handle open/close state
                    shouldDisableDate={(currentDate) => currentDate && currentDate > moment().endOf('day')}
                    onClose={handleDatePickerClose}
                  />
                </div>
              </div>

              {/* <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i className='ti ti-chevron-down'></i></button> */}
              <div className='card-head-group d-flex align-items-center gap-2'>

                <select className="form-control cp" name='payment_method_type' value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                  {REPORT_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                </select>


                <button className='btn btn-black w-100' onClick={handleExportToExcel} disabled={!dailySaleReport || dailySaleReport.length === 0}><i className='ti ti-download'></i>  {t('label_download')}</button>

                {/* <ReactToPrint trigger={() =>   <button className='btn btn-black w-100'><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
                  } content={() => componentRef.current} /> */}

                {dailySaleReport && dailySaleReport.length ?
                  <ReactToPrint trigger={() =>
                    <button className='btn btn-black w-100'><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
                  } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}" /> :
                  <button className='btn btn-black w-100' disabled><i className='ti ti-printer'></i>  {t('downloadPreview')}</button>
                }
              </div>
            </div>
          </div>
          <div className='card-body position-relative' >
         {isDatePickerOpen &&<div className="chart-overlay"></div> } 
            <div className="chart-container" style={{ position: 'relative' }}>
              <ReactApexChart ref={chartRef} options={options} series={series} type="area" height={350} className="dayReport" />
            </div>
          </div>
        </div>
        <div className='card mt-4'>
          <div className='card-body' >

            {dailySaleReport && dailySaleReport.length ?
              <div className='table-responsive' ref={componentRef}>
                <table className='table table-printable'>

                  <thead>
                    <tr>
                      <th>
                        <Sorting sort={sorting} handleSort={handleSort} column="date" columnName={t('date')} defaultSorting={defaultSorting} />
                      </th>
                      <th>
                        <Sorting sort={sorting} handleSort={handleSort} column="totalTransactions" columnName={t('totalTransactions')} defaultSorting={defaultSorting} breakName="true" />

                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalSales" columnName={t('totalSales')} defaultSorting={defaultSorting} breakName="true" />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalCostPrice" columnName={t('totalCostPrice')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalProfit" columnName={t('totalProfit')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="profitRate" columnName={t('profitRate')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalCash" columnName={t('totalCash')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalCredit" columnName={t('totalCredit')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalOther" columnName={t('totalOther')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="totalCustomers" columnName={t('totalCustomers')} defaultSorting={defaultSorting} breakName="true" />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="men" columnName={t('men')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="women" columnName={t('women')} defaultSorting={defaultSorting} />
                      </th>
                      <th >
                        <Sorting sort={sorting} handleSort={handleSort} column="orderPerCustomer" columnName={t('orderPerCustomer')} defaultSorting={defaultSorting} breakName="true" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dailySaleReport && dailySaleReport.length ? dailySaleReport.map((item, index) => {
                        return (
                          <tr key={index} >
                            {/* <td>5/1 (Wed)</td> */}
                            <td className='table-date'>{formateDateWithMonthForGraph(item?.date)}</td>
                            <td><span className="text-nowrap">{item?.totalTransactions}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalSales)}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalCostPrice)}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalProfit)}</span></td>
                            <td><span className="text-nowrap">{item.profitRate ? item.profitRate.toFixed(2) : 0}</span>%</td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalCash)}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalCredit)}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.totalOther)}</span></td>
                            <td><span className="text-nowrap">{item?.totalCustomers}</span></td>
                            <td><span className="text-nowrap">{item?.men}</span></td>
                            <td><span className="text-nowrap">{item?.women}</span></td>
                            <td><span className="text-nowrap">¥ {showPrice(item?.orderPerCustomer)}</span></td>
                          </tr>
                        )
                      }) : ""
                    }
                    {dailySaleTotalReport && (
                      <tr className='total-tr' id="totalSale">
                        <td>{t('total_label')}</td>
                        <td><span className="text-nowrap">{dailySaleTotalReport?.totalTransactions}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalSales)}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalCostPrice)}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalProfit)}</span></td>
                        <td><span className="text-nowrap">{dailySaleTotalReport.profitRate ? dailySaleTotalReport.profitRate.toFixed(2) : 0}</span>%</td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalCash)}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalCredit)}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.totalOther)}</span></td>
                        <td><span className="text-nowrap">{dailySaleTotalReport?.totalCustomers}</span></td>
                        <td><span className="text-nowrap">{dailySaleTotalReport?.men || 0}</span></td>
                        <td><span className="text-nowrap">{dailySaleTotalReport?.women || 0}</span></td>
                        <td><span className="text-nowrap">¥ {showPrice(dailySaleTotalReport?.orderPerCustomer)}</span></td>
                      </tr>)}
                  </tbody>
                </table>
              </div> : <RecordNotFound heading='lbl_record_not_found' />}
          </div>
        </div>
      </div>
    </main>
  )
}
