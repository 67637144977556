import React, { useEffect, useState } from "react";
import success from "../../../assets/restaurant/images/success.png";
import { detail } from "../services/order.service";
import { getSessionKey } from "../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
import { ORDER_PLATFORM } from "../../../utils/Constants";

export default function OrderSuccess() {
  const queryParams = new URLSearchParams(window.location.search);
  const order_id = queryParams.get("order_id");
  const [orderDetail, setOrderDetail] = useState(null);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const base = queryParams.get("base");
  const { t } = useTranslation();

  useEffect(() => {
    detail({ o_id: order_id }, { token: token })
      .then((response) => {
        setOrderDetail(response?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [order_id]);
  return (
    <>
      <div className="qr-body-wrap">
        <div className="order-success-wrap">
          <article>
            <figure>
              <img src={success} />
            </figure>
            <figcaption>
              <span className="label_order_id">
                {t("label_order_no")} #{orderDetail?.order_id}
              </span>
              <h4>{base === ORDER_PLATFORM.WEB ? t("label_thanks_visiting") : t('order_placed')}</h4>
              {base === ORDER_PLATFORM.WEB && <p>{t("label_go_cashier")}</p>}
            </figcaption>
            {base !== ORDER_PLATFORM.WEB && (
              <>
                <div className=" p-3 d-flex flex-column justify-content-center align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      const appData = { action: "go-to", data: "HomePage" };
                      window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
                    }}
                    style={{ width:'198px'}}
                  >
                    {t('back_home')}
                  </button>
               
                  <button
                    className="btn  btn-primary bg-white mt-2"
                    style={{ border: "1px solid #f8821b", color: "#f8821b", padding: "10px", width:'198px' }}
                    onClick={() => {
                      const appData = { action: "reprint", data: {} };
                      window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
                    }}
                  >
                   {t('btn_reprint')}
                  </button>
                </div>
              </>
            )}
          </article>
        </div>
      </div>
    </>
  );
}
