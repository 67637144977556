import html2canvas from "html2canvas";
import { addThreeColToPrint, format2LineHeading, format2LineShortColHeading, formateDate, showPrice, TrimText } from "./commonfunction";


async function convertHtmlToImage(htmlString) {
  return new Promise((resolve, reject) => {
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    tempContainer.style.top = "-9999px";
    tempContainer.style.width = "280px"
   
    tempContainer.innerHTML = htmlString; // Inject the HTML string
    document.body.appendChild(tempContainer);

    html2canvas(tempContainer, { scale: 2 }).then((canvas) => {
      document.body.removeChild(tempContainer); // Cleanup
      resolve(canvas);
    }).catch(reject);
  });
}

// Helper function to load an image into a canvas
async function loadImageToCanvas(imageSrc) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageSrc;
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      const qrCanvas = document.createElement("canvas");
      const qrCtx = qrCanvas.getContext("2d");
      qrCanvas.width = img.width;
      qrCanvas.height = img.height;
      qrCtx.drawImage(img, 0, 0, img.width, img.height);
      resolve(qrCanvas);
    };
  });
}




export const addKitchenPrint = (printer , response ,t , selectedLanguage='en')=>{
  let total_quantity = 0;
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true);
  printer.addText(`${t('label_table_print')}:${response.data.tableDetails.table_number} <${ response.data.number_of_persons} ${t('label_persons')}>`+ '\n');
  printer.addTextStyle(false, false, false);
  // printer.addFeedLine(1);
  printer.addText(formateDate(response.data.createdAt , "YYYY-MM-DD" , true , false)+ '\n');
  printer.addFeedLine(1);
  printer.addTextStyle(false, false, true); // (Reverse, Underline, Bold)
  printer.addText(formatLine(t('item_name_label'), t('label_quantity'), t('label_price'), true));
  printer.addTextStyle(false, false, false); 
  printer.addText("-----------------------------------------------\n");
  printer.addTextAlign(printer.ALIGN_LEFT);

  function formatLine(item, qty, price , isHeading = false) {
      const itemWidth = 25;  // Adjust width for Item Name
      const qtyWidth = 5;   // Adjust width for Quantity
      const priceWidth = 15; // Adjust width for Price
      const priceWithYen = isHeading===false ?`¥${price}`: `${price}`; 
      const itemName = isHeading===false? `[] ${item}` : `${item}`
      return `${itemName.padEnd(itemWidth)}${qty.toString().padStart(qtyWidth)}${priceWithYen.toString().padStart(priceWidth)}\n`;
  }
  
  printer.addTextAlign(printer.ALIGN_LEFT);

 
  
  response.data.ordered_items.forEach(element => {
    total_quantity += Number(element.quantity)
      printer.addText(formatLine(TrimText(selectedLanguage==='en'?element.item_name:element.item_name_ja, 12), element.quantity, element.price));
      if(element.spice_level_needed){
        printer.addText(`   ${t('spice_level_text')} : ${t(`label_${element.spice_level}`)}\n`);
      }
      if(element.addons.length){
          element.addons.forEach((addon)=>{
              printer.addText(`   ${selectedLanguage==='en'?addon.item_name:addon.item_name_ja}\n`);
              if(addon.spice_level_needed){
                printer.addText(`   ${t('spice_level_text')} : ${t(`label_${addon.spice_level}`)}\n`);
              }
          })
      }
  });


  printer.addText("-----------------------------------------------\n");
  function format2Line(label, value , isPrice =false) {
      const labelWidth = 38;  // Adjust for proper alignment
      const valueWidth = 7;  // Space for numbers (right-aligned)
      let newValue= isPrice ? `¥${value}` : `${value}`
      return `${label.padEnd(labelWidth)}${newValue.toString().padStart(valueWidth)}\n`;
  }
  
  printer.addText(formatLine(`${t('label_sub_total')}(${t('item_tax_option_exclusive')}):`,total_quantity, `¥${response.data.subtotal}`, true));
  printer.addText(format2Line(`${t('tax_label')}:`, response.data.tax_price, true));
  printer.addText(format2Line(`${t('service_charge')}:`, response.data.service_charge, true));
  printer.addText(format2Line(`${t('total_label')}:`, response.data.grand_total, true));
  response.data.base==="web"&&printer.addText("-----------------------------------------------\n");
  response.data.base==="web"&&printer.addText(`${t('label_order_by_qr')}\n`);
  printer.addCut(window.epson.ePOSDevice.CUT_FEED);
  printer.send();
};

export const addKitchenFoodPrint = (printer , response , t, selectedLanguage='en')=>{
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true);
  printer.addText(`${t('label_table_print')}:${response.data.tableDetails.table_number} <${ response.data.number_of_persons} ${t('label_persons')}>`+ '\n');
  printer.addTextStyle(false, false, false);
  printer.addText(formateDate(response.data.createdAt, "YYYY-MM-DD" , true , false)+ '\n');
  printer.addText("-----------------------------------------------\n");
  printer.addTextAlign(printer.ALIGN_LEFT);
  // printer.addText(format2LineFood(`${t('number_of_person_text')}`, response.data.number_of_persons , true));
  printer.addTextAlign(printer.ALIGN_LEFT);

  response.data.ordered_items.forEach(element => {
    if(element.item_variation==="food"){
      printer.addTextSize(1 ,2);
      printer.addText(format2LineFood(TrimText(selectedLanguage==='en'?element.item_name:element.item_name_ja, 12), element.quantity , true));
      printer.addTextSize(1 ,1);
    
      if(element.spice_level_needed){
          printer.addText(` ${t('spice_level_text')} : ${t(`label_${element.spice_level}`)}\n`);
      }
      if(element.addons.length){
          element.addons.forEach((addon)=>{
            printer.addTextStyle(false, false, true);
              printer.addText(` ${selectedLanguage==='en'?addon.item_name:addon.item_name_ja}\n`);
              printer.addTextStyle(false, false, false);
              if(addon.spice_level_needed){
                  printer.addText(` ${t('spice_level_text')} : ${t(`label_${addon.spice_level}`)}\n`);
              }
          })
      }
    
    
    }
   
  });

  function format2LineFood(label, value , isHeading=false) {
      const labelWidth = 38;  // Adjust for proper alignment
      const valueWidth = 10;  // Space for numbers (right-aligned)
      let newLabel= isHeading ? `${label}` : `[] ${label}`
      return `${newLabel.padEnd(labelWidth)}${value.toString().padStart(valueWidth)}\n`;
  }
  response.data.base==="web"&&printer.addText("-----------------------------------------------\n");
  response.data.base==="web"&&printer.addText(`${t('label_order_by_qr')}\n`);
  printer.addFeedLine(1);
  
 
 
  

  printer.addCut(window.epson.ePOSDevice.CUT_FEED);
  printer.send();
};

export const addKitchenDrinkPrint = (printer , response , t, selectedLanguage='en')=>{

  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true);
  printer.addText(`${t('label_table_print')}:${response.data.tableDetails.table_number} <${ response.data.number_of_persons} ${t('label_persons')}>`+'\n');
  printer.addTextStyle(false, false, false);
  printer.addText(formateDate(response.data.createdAt, "YYYY-MM-DD" , true , false)+ '\n');
  printer.addText("-----------------------------------------------\n");
  printer.addTextAlign(printer.ALIGN_LEFT);
  // printer.addText(format2LineFood(`${t('number_of_person_text')}`, response.data.number_of_persons , true));
  printer.addTextAlign(printer.ALIGN_LEFT);
  
  response.data.ordered_items.forEach(element => {
    if(element.item_variation==="drink"){
      printer.addTextSize(1 ,2);
      printer.addText(format2LineFood(TrimText(selectedLanguage==='en'?element.item_name:element.item_name_ja, 12), element.quantity , true));
      printer.addTextSize(1 ,1);
    
      if(element.spice_level_needed){
          printer.addText(` ${t('spice_level_text')} : ${t(`label_${element.spice_level}`)}\n`);
      }
      if(element.addons.length){
          element.addons.forEach((addon)=>{
            printer.addTextStyle(false, false, true);
              printer.addText(` ${selectedLanguage==='en'?addon.item_name:addon.item_name_ja}\n`);
              printer.addTextStyle(false, false, false);
              if(addon.spice_level_needed){
                  printer.addText(` ${t('spice_level_text')} : ${t(`label_${addon.spice_level}`)}\n`);
              }
          })
      }
    
    
    }
   
  });

  function format2LineFood(label, value , isHeading=false) {
      const labelWidth = 38;  // Adjust for proper alignment
      const valueWidth = 10;  // Space for numbers (right-aligned)
      let newLabel= isHeading ? `${label}` : `[] ${label}`
      return `${newLabel.padEnd(labelWidth)}${value.toString().padStart(valueWidth)}\n`;
  }
  response.data.base==="web"&&printer.addText("-----------------------------------------------\n");
  response.data.base==="web"&&printer.addText(`${t('label_order_by_qr')}\n`);
  printer.addFeedLine(1);
  
 
 
  

  printer.addCut(window.epson.ePOSDevice.CUT_FEED);
  printer.send();
 
};

export const billingReceiptPrint = async (printer, response ,t, selectedLanguage='en') => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  let htmlCanvas, htmlctx, htmlCanvas1, htmlctx1, htmlCanvas2, htmlctx2, qrCanvas, qrCtx;

  // Convert HTML to Image only if data exists 
  if (response?.receiptData?.header_text) {
    htmlCanvas = await convertHtmlToImage(response.receiptData.header_text);
    htmlctx = htmlCanvas.getContext("2d");
  }

  if (response?.receiptData?.qr_code_msg) {
    htmlCanvas1 = await convertHtmlToImage(response.receiptData.qr_code_msg);
    htmlctx1 = htmlCanvas1.getContext("2d");
  }

  if (response?.receiptData?.registered_number) {
    htmlCanvas2 = await convertHtmlToImage(response.receiptData.registered_number);
    htmlctx2 = htmlCanvas2.getContext("2d");
  }

  const imageSrc = response?.receiptData?.header_image;
  const qrImageSrc = response?.receiptData?.qr_code_image;

  // Convert QR Code Image if available
  if (qrImageSrc) {
    qrCanvas = await loadImageToCanvas(qrImageSrc);
    qrCtx = qrCanvas.getContext("2d");
  }

  if (imageSrc) {
    const img = new Image();
    img.src = imageSrc;
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      const size = 150;
      canvas.width = size;
      canvas.height = size;

      // Create circular clipping path
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      ctx.closePath();
      ctx.clip();

      // Draw the image inside the circular area
      ctx.drawImage(img, 0, 0, size, size);

      // 🔹 Add Image to Printer if present
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addImage(
        ctx, 0, 0, canvas.width, canvas.height,
        window.epson.ePOSDevice.COLOR_1,
        window.epson.ePOSDevice.MODE_MONO
      );

      addReceiptContent();
    };
  } 
  else {
    addReceiptContent();
  }

  function addReceiptContent() {
    printer.addTextAlign(printer.ALIGN_CENTER);

    // 🔹 Add HTML content images if present
    if (htmlCanvas) {
      printer.addImage(
        htmlctx, 0, 0, htmlCanvas.width, htmlCanvas.height,
        window.epson.ePOSDevice.COLOR_1,
        window.epson.ePOSDevice.MODE_MONO
      );
    }

    printer.addText(formateDate(new Date(), "YYYY-MM-DD" , true , false) + '\n');
    printer.addTextAlign(printer.ALIGN_LEFT);

    response.orderDetail.ordered_items.forEach((item) => {
      printer.addText(addThreeColToPrint(TrimText(selectedLanguage==='en'?item.item_name:item.item_name_ja, 15), `¥${item.price}*${item.quantity}`, `¥${item.price * item.quantity}`, true));
    });

    printer.addTextStyle(false, false, true);
    printer.addText(format2LineHeading(t('label_item_total_tax'), `¥${response.subtotal}`, true));
    printer.addTextStyle(false, false, false);
    printer.addText(`${t('label_indication_text')}`);
    printer.addFeedLine(1);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextStyle(false, false, true);
    printer.addText(format2LineHeading(t('label_sub_total_exclusive_tax'), `¥${response.subtotal}`, true));
    printer.addTextStyle(false, false, false);
    printer.addText(format2LineHeading(t('tax_label'), `¥${response.tax_price}`, true));
    printer.addText(format2LineHeading(t('service_charge'), `¥${response.service_charge}`, true));
    printer.addText(format2LineHeading(t('label_coupon_discount'), `¥${response.coupon_code_amount}`, true));
    printer.addText(format2LineHeading(t('label_gift_card'), `¥${response.gift_card_amount}`, true));
    printer.addTextStyle(false, false, true);
    printer.addText(format2LineHeading(t('label_total_capitalize'), `¥${response.amount_after_discount}`, true));
    printer.addTextStyle(false, false, false);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`(8% ${t('tax_on_text')} ¥${response.total_tax_8})\n`);
    printer.addText(`(10%${t('tax_on_text')} ¥${response.total_tax_10})\n`);
    printer.addText(format2LineHeading(t('payment'), `¥${response.amount_after_discount}`, true));
    printer.addTextAlign(printer.ALIGN_CENTER);

    if (htmlCanvas2) {
      printer.addImage(
        htmlctx2, 0, 0, htmlCanvas2.width, htmlCanvas2.height,
        window.epson.ePOSDevice.COLOR_1,
        window.epson.ePOSDevice.MODE_MONO
      );
    }

    printer.addTextStyle(false, false, true);
    printer.addText(`${response.receiptData.footer_title}\n`);
    printer.addTextStyle(false, false, false);

    if (response?.receiptData?.phone_number) {
      printer.addText(`${t('tel')}: ${response.receiptData.phone_number}\n`);
    }

    if (response?.receiptData?.address) {
      printer.addText(`${t('list_heading_address')}: ${response.receiptData.address}\n`);
    }

    printer.addFeedLine(1);

    // 🔹 Add QR Code Image if available
    if (qrCanvas) {
      printer.addImage(
        qrCtx, 0, 0, qrCanvas.width, qrCanvas.height,
        window.epson.ePOSDevice.COLOR_1,
        window.epson.ePOSDevice.MODE_MONO
      );
    }

    // 🔹 Add HTML content for QR Code message if available
    if (htmlCanvas1) {
      printer.addImage(
        htmlctx1, 0, 0, htmlCanvas1.width, htmlCanvas1.height,
        window.epson.ePOSDevice.COLOR_1,
        window.epson.ePOSDevice.MODE_MONO
      );
    }

    printer.addCut(window.epson.ePOSDevice.CUT_FEED);
    printer.send();
  }
};



export const  midDayReportPrint = async (printer , response ,t, selectedLanguage='en')=>{

  const {userData , categoryData , transactionDetails , transactionDataPaymentMethodWise , CardList , OtherPaymentList } = response

// tax exclusive part start from here
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true); 
  printer.addText( `${response.userData.restaurant_name}\n`);
  printer.addTextStyle(false, false, false); 
  printer.addText(formateDate(new Date(), "YYYY-MM-DD" , true , false)+ '\n');
  printer.addText(`${t('label_sales_inspection')}\n`)
  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addFeedLine(1);
  printer.addTextStyle(false, false, true); 
  printer.addText(addThreeColToPrint(t('label_category') , t('label_quantity') , t('labeL_price_jpy') , true));
  printer.addTextStyle(false, false, false);
  response.categoryData.forEach((category)=>{
    printer.addText(addThreeColToPrint(`${category.category_name}` , `${category.total_quantity}` , `¥${category.total_amount}` , true));
  });
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_number_of_inspection'),   response.categoryData.length , true));
  printer.addText(format2LineHeading(t('number_of_transactions'),   (transactionDetails && transactionDetails.total_transactions) ? transactionDetails.total_transactions : 0 , true));
  printer.addText(format2LineHeading(t('label_number_of_sets'),   (transactionDetails && transactionDetails.total_transactions) ? transactionDetails.total_transactions : 0 , true));
  printer.addText(format2LineHeading(t('label_product_total'),   `¥${(transactionDetails && transactionDetails.subtotal) ? showPrice(transactionDetails.subtotal) : 0}` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_total_sales_exclusive'),   `¥${(transactionDetails && transactionDetails.subtotal) ? showPrice(transactionDetails.subtotal) : 0}` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_product_total_amount'),   `¥${(transactionDetails && transactionDetails.subtotal) ? showPrice(transactionDetails.subtotal) : 0}` , true));
  printer.addText(format2LineHeading(t('service_charges'),   `¥${transactionDetails ? showPrice(transactionDetails.service_charge || 0) : 0}` , true));
  printer.addText(format2LineHeading(t('label_late_night_charge'),   `¥0` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_discount'),   `-¥${transactionDetails ? showPrice(transactionDetails.total_coupon_code_amount || 0) : 0}` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_cash_discount'),   `-¥0` , true));
  printer.addText(format2LineHeading(t('label_rounding_discount'),   `-¥0` , true));
  printer.addText(format2LineHeading(t('label_coupon_discount'),   `-¥${transactionDetails ? showPrice(transactionDetails.total_coupon_code_amount || 0) : 0}` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('total_label'),   `¥${transactionDetails 
    ? showPrice((transactionDetails.subtotal || 0) + 
                (transactionDetails.service_charge || 0) - 
                (transactionDetails.total_coupon_code_amount || 0)) 
    : 0 }` , true));
  printer.addText(addThreeColToPrint(`` , `(8% ${t('tax_label')})` , `¥${transactionDetails ? showPrice(transactionDetails.total_tax_8 || 0) : 0 }` , true));
  printer.addText(addThreeColToPrint(`` , `(10%${t('tax_label')})` , `¥${transactionDetails ? showPrice(transactionDetails.total_tax_10 || 0) : 0 }` , true));
  printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('label_error'),   `-¥0` , true));
  printer.addText(format2LineHeading(t('label_error_adjustment'),   `¥${transactionDetails 
    ? showPrice(
        (transactionDetails.subtotal || 0) + 
        (transactionDetails.service_charge || 0) - 
        (transactionDetails.total_coupon_code_amount || 0)
    ) 
    : 0}` , true));
    printer.addText("-----------------------------------------------\n");
  printer.addText(format2LineHeading(t('number_of_customers'),   `${transactionDetails ? (transactionDetails.number_of_persons || 0) : 0}` , true));
  printer.addText(format2LineHeading(t('label_average_spent'),   `¥${transactionDetails && transactionDetails.number_of_persons > 0 
    ? showPrice(
        ((transactionDetails.subtotal || 0) + 
        (transactionDetails.service_charge || 0) - 
        (transactionDetails.total_coupon_code_amount || 0)) / 
        transactionDetails.number_of_persons
      ) 
    : 0 }` , true));
    printer.addText("-----------------------------------------------\n");
  printer.addText(addThreeColToPrint(t('label_gift_card') , `${ transactionDetails ? (transactionDetails.gift_card_count || 0) : 0 }` , `¥${transactionDetails ? showPrice(transactionDetails.gift_card_amount || 0) : 0}` , true));
  printer.addText(format2LineHeading(t('labeL_amount_with_gift_card'),   `¥${transactionDetails ?showPrice(
    (transactionDetails.subtotal || 0) + 
    (transactionDetails.service_charge || 0) - 
    (transactionDetails.total_coupon_code_amount || 0) - (transactionDetails.gift_card_amount || 0)
)   : 0}` , true));

printer.addText("-----------------------------------------------\n");



printer.addText(`${t('label_payment_tax_exclusive')}\n`);



printer.addText("-----------------------------------------------\n");

printer.addText(addThreeColToPrint(t('table_label_cash_sales') , `${transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length ? (transactionDataPaymentMethodWise[0].transaction_count || 0) : 0 }` , `¥${transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length  ? showPrice(transactionDataPaymentMethodWise[0].total_subtotal || 0) : 0 }` , true));


printer.addText(addThreeColToPrint(t('label_card_sales') , `${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[1]) 
  ? (transactionDataPaymentMethodWise[1].transaction_count || 0) 
  : 0 }` , `¥${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[1]) 
    ? showPrice(transactionDataPaymentMethodWise[1].total_subtotal || 0) 
    : 0 }` , true));

CardList.forEach((card)=>{
  printer.addText(addThreeColToPrint(`  ${card.payment_type}` , `${card.transaction_count || 0}` , `¥${showPrice(card.total_subtotal || 0)}` , true));
});


printer.addText(addThreeColToPrint(t('table_label_other_sales') , `${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[2]) 
  ? (transactionDataPaymentMethodWise[2].transaction_count || 0) 
  : 0 }` , `¥${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[2]) 
    ? showPrice(transactionDataPaymentMethodWise[2].total_subtotal || 0) 
    : 0 }` , true));


OtherPaymentList.forEach((card)=>{
  printer.addText(addThreeColToPrint(`  ${card.payment_type}` , `${card.transaction_count || 0}` , `¥${showPrice(card.total_subtotal || 0)}` , true));
});

printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('total_label'),   `¥${transactionDetails ? showPrice((transactionDetails.subtotal || 0) ) : 0 }` , true));


// FROM HERE TAX INCLUSIVE PART 

printer.addText("-----------------------------------------------\n");


printer.addText(format2LineHeading(t('label_total_sales_inclusive'),   `¥${transactionDetails ? showPrice((transactionDetails.amount_after_discount || 0) ) : 0}` , true));


printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('table_title_total_sales'),   `¥${transactionDetails ? showPrice((transactionDetails.subtotal || 0)+ (transactionDetails.tax_price||0)) : 0}` , true));
printer.addText(format2LineHeading(t('service_charges'),   `¥${transactionDetails ? showPrice(transactionDetails.service_charge || 0) : 0}` , true));
printer.addText(format2LineHeading(t('label_late_night_charge'),   `¥0` , true));

printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('label_discount'),   `-¥${transactionDetails ? showPrice(transactionDetails.total_coupon_code_amount || 0) : 0}` , true));

printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('label_cash_discount'),   `-¥0` , true));
printer.addText(format2LineHeading(t('label_rounding_discount'),   `-¥0` , true));
printer.addText(format2LineHeading(t('label_coupon_discount'),   `-¥${transactionDetails ? showPrice(transactionDetails.total_coupon_code_amount || 0) : 0}` , true));

printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('total_label'),   `¥${transactionDetails 
  ? showPrice((transactionDetails.subtotal || 0) + 
              (transactionDetails.service_charge || 0)+ (transactionDetails.tax_price || 0) - 
              (transactionDetails.total_coupon_code_amount || 0)) 
  : 0}` , true));
printer.addText(addThreeColToPrint(`` , `(8% ${t('tax_label')})` , `¥${transactionDetails ? showPrice(transactionDetails.total_tax_8 || 0) : 0 }` , true));
printer.addText(addThreeColToPrint(`` , `(10% ${t('tax_label')})` , `¥${transactionDetails ? showPrice(transactionDetails.total_tax_10 || 0) : 0  }` , true));
printer.addText("-----------------------------------------------\n");
printer.addText(format2LineHeading(t('label_tax_amount'),   `¥${transactionDetails 
  ? showPrice((transactionDetails.tax_price || 0)  )
  : 0 }` , true));

printer.addText(format2LineHeading(t('label_total_sales_exclusive'),   `¥${transactionDetails ? showPrice((transactionDetails.subtotal || 0) ) : 0}` , true));

printer.addText("-----------------------------------------------\n");

printer.addText(format2LineHeading(t('number_of_customers'),   `${transactionDetails ? (transactionDetails.number_of_persons || 0) : 0}` , true));
printer.addText(format2LineHeading(t('label_average_spent'),   `¥${transactionDetails && transactionDetails.number_of_persons > 0 
  ? showPrice(
      ((transactionDetails.subtotal || 0) + 
      (transactionDetails.service_charge || 0) - 
      (transactionDetails.total_coupon_code_amount || 0)+(transactionDetails.tax_price || 0)) / 
      transactionDetails.number_of_persons
    ) 
  : 0 }` , true));
  printer.addText("-----------------------------------------------\n");
printer.addText(addThreeColToPrint(t('label_gift_card') , `${transactionDetails ? (transactionDetails.gift_card_count || 0) : 0 }` , `¥${transactionDetails ? showPrice(transactionDetails.gift_card_amount || 0) : 0}` , true));
printer.addText(format2LineHeading(t('labeL_amount_with_gift_card'),   `¥${transactionDetails ?showPrice(
  (transactionDetails.subtotal || 0) + 
  (transactionDetails.service_charge || 0)+(transactionDetails.tax_price || 0) - 
  (transactionDetails.total_coupon_code_amount || 0) - (transactionDetails.gift_card_amount || 0)
)   : 0 }` , true));



printer.addText("-----------------------------------------------\n");



printer.addText(`${t('label_payment_tax_inclusive')}\n`);



printer.addText("-----------------------------------------------\n");


printer.addText(addThreeColToPrint(t('table_label_cash_sales') , `${transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length ? (transactionDataPaymentMethodWise[0].transaction_count || 0) : 0 }` , `¥${transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length  ? showPrice(transactionDataPaymentMethodWise[0].total_great_grand_total || 0) : 0}` , true));


printer.addText(addThreeColToPrint(t('label_card_sales') , `${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[1]) 
  ? (transactionDataPaymentMethodWise[1].transaction_count || 0) 
  : 0 }` , `¥${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[1]) 
    ? showPrice(transactionDataPaymentMethodWise[1].total_great_grand_total || 0) 
    : 0  }` , true));

CardList.forEach((card)=>{
  printer.addText(addThreeColToPrint(`  ${card.payment_type}` , `${card.transaction_count || 0}` , `¥${showPrice(card.total_great_grand_total || 0)}` , true));
});


printer.addText(addThreeColToPrint(t('table_label_other_sales'), `${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[2]) 
  ? (transactionDataPaymentMethodWise[2].transaction_count || 0) 
  : 0 }` , `¥${(transactionDataPaymentMethodWise && transactionDataPaymentMethodWise.length > 1 && transactionDataPaymentMethodWise[2]) 
    ? showPrice(transactionDataPaymentMethodWise[2].total_great_grand_total || 0) 
    : 0 }` , true));


OtherPaymentList.forEach((card)=>{
  printer.addText(addThreeColToPrint(`  ${card.payment_type}` , `${card.transaction_count || 0}` , `¥${showPrice(card.total_great_grand_total || 0)}` , true));
});

printer.addText("-----------------------------------------------\n");


printer.addText(format2LineHeading(t('total_label'),   `¥${transactionDetails 
  ? showPrice((transactionDetails.subtotal || 0) + 
              (transactionDetails.service_charge || 0)+ (transactionDetails.tax_price || 0) - 
              (transactionDetails.total_coupon_code_amount || 0)) 
  : 0}` , true));


  printer.addText("-----------------------------------------------\n");


  printer.addTextAlign(printer.ALIGN_RIGHT);

  printer.addText(formateDate(new Date(), "YYYY-MM-DD" , true , false)+ '\n');

  printer.addCut(window.epson.ePOSDevice.CUT_FEED);
  printer.send();
}; 


export const royushuPrint = async (printer, response, t, selectedLanguage = 'en') => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  let htmlCanvas, htmlctx;

  // Convert HTML to Image only if data exists 
  if (response?.receiptData?.registered_number) {
    htmlCanvas = await convertHtmlToImage(response.receiptData.registered_number);
    htmlctx = htmlCanvas?.getContext("2d");

    // Ensure canvas has valid dimensions
    if (htmlCanvas && htmlCanvas.width > 0 && htmlCanvas.height > 0) {
      canvas.width = htmlCanvas.width;
      canvas.height = htmlCanvas.height;
      ctx.drawImage(htmlCanvas, 0, 0);
    }
  }

  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true);
  printer.addTextSize(1 ,2);
  printer.addText(`${response.restaurant_data.restaurant_name}\n`);
  printer.addTextSize(1 ,1);
  printer.addTextStyle(false, false, false);
  printer.addText(`${response.restaurant_data.address}\n`);
  printer.addTextStyle(false, false, true);
  printer.addFeedLine(1);
  printer.addTextSize(1 ,2);
  printer.addText(`${t('label_invoice')}\n`);
  printer.addTextSize(1 ,1);
  printer.addTextStyle(false, false, false);
  printer.addText(formateDate(new Date(), "YYYY-MM-DD", true, false) + '\n');
  printer.addTextAlign(printer.ALIGN_LEFT);

  if (response.orderDetail?.customerDetails?.customer_name) {
    printer.addText(format2LineShortColHeading(t('customer_name'), TrimText(response.orderDetail.customerDetails.customer_name, 12), true));
  }

  if (response.orderDetail?.customerDetails?.customer_phone_number) {
    printer.addText(format2LineShortColHeading(t('label_phone_number'), `${response.orderDetail.customerDetails.customer_phone_number}`, true));
  }

  printer.addText("-----------------------------------------------\n");
  printer.addTextAlign(printer.ALIGN_RIGHT);
  printer.addTextStyle(false, false, true);
  printer.addText(`¥${response.amount_after_discount}\n`);
  printer.addTextStyle(false, false, false);
  printer.addText("-----------------------------------------------\n");
  printer.addText(`(${t('tax_included_label')} ¥${response.tax_price})\n`);
  printer.addText(`(${t('service_charges')} ¥${response.service_charge})\n`);
  printer.addText(`(${t('label_coupon_discount')} ¥${response.coupon_code_amount})\n`);
  printer.addText(`(${t('label_gift_card')} ¥${response.gift_card_amount})\n`);
  printer.addText(`(8% ${t('tax_label')}(${t('tax_included_label')}) ¥${response.total_tax_8})\n`);
  printer.addText(`(10% ${t('tax_label')}(${t('tax_included_label')}) ¥${response.total_tax_10})\n`);
  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addText("-----------------------------------------------\n");
  printer.addText(`${t('label_received_amount_text')}\n`);
  printer.addText("-----------------------------------------------\n");
  response.orderDetail.ordered_items.forEach((item) => {
    printer.addText(format2LineHeading(TrimText(selectedLanguage === 'en' ? item.item_name : item.item_name_ja, 15), `¥${item.price}*${item.quantity}`, true));
  });

  printer.addText("-----------------------------------------------\n");
  printer.addText(`${t('label_indication_text')}\n`);
  printer.addText("-----------------------------------------------\n");
  printer.addText(`${t('label_visiting_text')}\n`);

  // Ensure the canvas has valid content before printing
  if (htmlCanvas && htmlctx) {
    printer.addImage(
      htmlctx,
      0, 0, htmlCanvas.width, htmlCanvas.height,
      window.epson.ePOSDevice.COLOR_1,
      window.epson.ePOSDevice.MODE_MONO
    );
  }

  printer.addCut(window.epson.ePOSDevice.CUT_FEED);
  printer.send();
};




