import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import { FILE_SLUG, SWAL_SETTINGS } from '../../../../../utils/Constants';
import Swal from 'sweetalert2';
import { getSampleFiles, handleImports } from '../../../services/bulkImport.service';
import * as Yup from 'yup';

function BulkImport() {
  const [updateStatus, setUpdateStatus] = useState(false);
  const { t } = useTranslation();
  const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
  const fileInput = useRef();
  const errorsModalShowBtn = useRef();
  const [errorsList, setErrorsList] = useState([]);

  const validationSchema = Yup.object({
    file: Yup.mixed()
      .required("err_file_is_required")
      .test("fileFormat", "err_invalid_file_formate_excel", (value) => {
        return value && value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }),
  });
  const formSampleFiles = useFormik({
    initialValues: {
      slug: FILE_SLUG.CATEGORY,
      file: null
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setAddSubmissionLoader(true);
      const formData = new FormData();
      formData.append('slug', values.slug);
      formData.append('file', values.file);
      handleImports(formData).then((response) => {
        setAddSubmissionLoader(false);
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS
          });
          formSampleFiles.resetForm();
          fileInput.current.value = "";
        } else {
          if (response.fileValidations) {
            Swal.fire({
              icon: "error",
              text: response.message,
              ...SWAL_SETTINGS
            });
            errorsModalShowBtn?.current?.click();
            setErrorsList(response.error);
            formSampleFiles.setFieldValue('file', null);
            fileInput.current.value = "";
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS
            });
          }
        }
      }).catch((err) => {
        console.error(err);
        setAddSubmissionLoader(false);
      });
    }
  });


  const downloadFileData = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = ''; // Optional: specify a filename if needed
    link.target = '_blank'; // Open in a new tab if necessary
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle file change
  const handleFileChange = (event) => {
    formSampleFiles.setFieldValue('file', event.currentTarget.files[0]);
  };

  // Function to download sample files
  const downloadFile = async (e) => {
    e.preventDefault();
    setAddSubmissionLoader(true);
    try {
      const response = await getSampleFiles({ slug: formSampleFiles.values.slug });
      setAddSubmissionLoader(false);
      if (response.success) {
        setUpdateStatus(!updateStatus);
        Swal.fire({
          icon: 'success',
          text: response.message,
          ...SWAL_SETTINGS,
        });

        downloadFileData(response.data.file);
      } else {
        Swal.fire({
          icon: 'error',
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    } catch (error) {
      setAddSubmissionLoader(false);
      console.error('Error downloading file:', error);
    }
  };



  return (
    <main id='main-content'>
      <div className='inner-container'>
        <div className='page-header d-flex align-items-center'>
          <h4 className='heading-title-text'>{t("heading_bulk_import")}</h4>
        </div>
        <div className='dashboard-content-wraper'>
          <div className='row'>
            <div className='col-md-12 col-lg-10 col-sm-12'>
              <div className='card'>
                <form onSubmit={formSampleFiles.handleSubmit}>
                  <div className='card-body'>
                    {/* Radio Button Group */}
                    <div className='mb-3'>
                      <label className='form-label'>{t("input_label_choose_upload_type")}</label>
                      <div className='d-flex gap-5'>
                        <div className='form-check'>
                          <input
                            className='form-check-input cp'
                            type='radio'
                            name='slug'
                            id='category'
                            value={FILE_SLUG.CATEGORY}
                            checked={formSampleFiles.values.slug === FILE_SLUG.CATEGORY}
                            onChange={formSampleFiles.handleChange}
                          />
                          <label htmlFor='category' className='form-check-label cp'>
                            {t("input_label_category")}
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input cp'
                            type='radio'
                            name='slug'
                            id='menuItems'
                            value={FILE_SLUG.MENU_ITEMS}
                            checked={formSampleFiles.values.slug === FILE_SLUG.MENU_ITEMS}
                            onChange={formSampleFiles.handleChange}
                          />
                          <label htmlFor='menuItems' className='form-check-label cp'>
                            {t("input_label_menu_items")}
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input cp'
                            type='radio'
                            name='slug'
                            id='options'
                            value={FILE_SLUG.OPTIONS}
                            checked={formSampleFiles.values.slug === FILE_SLUG.OPTIONS}
                            onChange={formSampleFiles.handleChange}
                          />
                          <label htmlFor='options' className='form-check-label cp'>
                            {t("input_label_options")}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* File Upload */}
                    <div className='csv-box'>
                      <h4>{t("label_import_excel_file")}</h4>
                      <div className='csv-input-ui d-flex align-items-center'>
                        <input
                          type='file'
                          name='file'
                          onChange={handleFileChange}
                          accept='.xlsx'
                          ref={fileInput}
                          className='form-control form-control-lg bg-transparent border-0 me-5 text-capitalize'
                        />
                        <button
                          className='btn btn-primary px-5'
                          type='submit'
                          disabled={addSubmissionLoader}
                        >
                          {t("btn_submit")}
                        </button>
                      </div>
                      {formSampleFiles.touched.file && formSampleFiles.errors.file ? (
                        <div className="text-danger">{t(formSampleFiles.errors.file)}</div>
                      ) : null}
                      <p className='mt-3 mb-0'>
                        {t("label_download_sample")}{' '}
                        <a href='/' className='text-decoration-none link-text' onClick={downloadFile}>
                          {t("sample_file_demo_import")}
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#errorsModal" ref={errorsModalShowBtn}></button>
      <div className="modal fade" id="errorsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="errorsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="errorsModalLabel">{t("heading_errors")}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("s_no")}</th>
                      <th>{t("heading_param_name")}</th>
                      <th>{t("heading_error_description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorsList.map((error, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{error?.param}</td>
                          <td>{error?.message}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between w-100 align-items-center">
                <div>
                  <p className='text-muted fs-14 mb-0'>{t('sample_file_errors_description')}</p>
                </div>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("button_text_understood")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default BulkImport;