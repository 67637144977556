
import React, { useEffect, useRef, useState } from 'react'
import trash from '../../../../../assets/restaurant/images/trash.svg';
import { useTranslation } from 'react-i18next';
import { ACTIVE, COUPON_CATEGORY_TYPES, COUPON_CODE_MAX_LENGTH, COUPON_MAX_FLAT_DISCOUNT, COUPON_TYPES, DEACTIVE, LOADER_TIMEOUT_TIME, SORT_A, SORT_D, SWAL_SETTINGS } from '../../../../../utils/Constants';
import Swal from 'sweetalert2';
import { DT, formateDate, formatePickerDate, globalLoader, handleOffcanvasScroll, handleServerValidations, TrimText, useDebouncing } from '../../../../../utils/commonfunction';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import RecordNotFound from '../../../shared/recordNotFound';
import { DatePicker, Drawer } from "rsuite";
import CustomPagination from "../../../../admin/common/custompagination";
import isBefore from "date-fns/isBefore";
import { couponList, add, deleteCoupon, edit, detail, changeStatus } from "../../../services/coupon.service";
import calendarIcon from '../../../../../assets/restaurant/images/calenderLogo.png'

const CustomBase64Caret = () => (
    <img
        src={calendarIcon}
        alt="Custom Caret"
        style={{ width: 20, height: 20 }}
    />
);

export default function Coupon() {
    const params = useParams();
    const { t } = useTranslation();
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState('');
    const [refreshList, setRefreshList] = useState(false);
    const [loader, setLoader] = useState(true);
    const [List, setList] = useState([]);
    const [sortConfig, setSortConfig] = useState({ column: "createdAt", order: SORT_D });
    const [page, setPage] = useState(1);
    const [offset, setOffsetPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemPerPage] = useState(10);
    const [couponDetail, setCouponDetail] = useState({});
    const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
    const [editSubmissionLoader, setEditSubmissionLoader] = useState(false);
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const navigate = useNavigate();


    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    /** Add record form */
    const addForm = useFormik({
        initialValues: {
            coupon_code: "",
            coupon_category: "",
            discount_type: COUPON_TYPES.FLAT,
            discount: "",
            expire_date: "",
        },
        validationSchema: Yup.object().shape({
            coupon_code: Yup.string().trim().required(t("validation_coupon_code_required")).max(COUPON_CODE_MAX_LENGTH, DT(t("validation_coupon_code_max_length"), [COUPON_CODE_MAX_LENGTH])),
            coupon_category: Yup.string().trim().required(t("validation_coupon_category_required")),
            discount_type: Yup.string().trim().required(t("validation_discount_type_required")),
            discount: Yup.number().required(t("validation_discount_value_required"))
                .min(1, t("number_err_msg"))
                .test("percentage_limit", t("validation_discount_percentage_limit"), function (value) {
                    const { discount_type } = this.parent;
                    if (discount_type === "percentage") {
                        if (value > 100) {
                            return this.createError({
                                message: t("validation_discount_percentage_limit"), // Message for percentage
                            });
                        }
                        return true;
                    }else{
                        if (value > COUPON_MAX_FLAT_DISCOUNT) {
                            return this.createError({
                                message: DT(t("validation_max_discount_is"), [COUPON_MAX_FLAT_DISCOUNT]), // Message for percentage
                            });
                        }
                        return true;
                    }
                }),
            expire_date: Yup.string().trim().required(t("validation_expire_date_required")),
        }),
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            setAddSubmissionLoader(true);
            add(values).then((response) => {
                setAddSubmissionLoader(false);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    resetForm();
                    setOpenWithHeader(false);
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                setAddSubmissionLoader(false);
                console.error("Error updating profile", error);
            });
        },
    });
    /** Edit record form */
    const editForm = useFormik({
        initialValues: {
            coupon_code: "",
            coupon_category: "",
            discount_type: "",
            discount: "",
            expire_date: "",
        },
        validationSchema: Yup.object().shape({
            coupon_code: Yup.string().trim().required(t("validation_coupon_code_required")).max(COUPON_CODE_MAX_LENGTH, DT(t("validation_coupon_code_max_length"), [COUPON_CODE_MAX_LENGTH])),
            coupon_category: Yup.string().trim().required(t("validation_coupon_category_required")),
            discount_type: Yup.string().trim().required(t("validation_discount_type_required")),
            discount: Yup.number().required(t("validation_discount_value_required"))
                .min(1, t("number_err_msg"))
                .test("percentage_limit", t("validation_discount_percentage_limit"), function (value) {
                    const { discount_type } = this.parent;
                    if (discount_type === "percentage") {
                        if (value > 100) {
                            return this.createError({
                                message: t("validation_discount_percentage_limit"), // Message for percentage
                            });
                        }
                        return true;
                    }else{
                        if (value > COUPON_MAX_FLAT_DISCOUNT) {
                            return this.createError({
                                message: DT(t("validation_max_discount_is"), [COUPON_MAX_FLAT_DISCOUNT]), // Message for percentage
                            });
                        }
                        return true;
                    }
                }),
            expire_date: Yup.string().trim().required(t("validation_expire_date_required")),
        }),
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            let data = {
                o_id: couponDetail?._id,
                ...values
            }
            setEditSubmissionLoader(true);
            edit(data).then((response) => {
                setEditSubmissionLoader(false);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    resetForm();
                    setOpenWithHeaderEdit(false)
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                setEditSubmissionLoader(false);
                console.error("Error updating profile", error);
            });
        },
    });
    const prepareGlobalSearch = (value) => {
        setGlobalSearch(value);
    };
    const searchDebounce = useDebouncing(prepareGlobalSearch);

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setSelectedStatus(value);
        handleFilterStatusChange(e);
    };
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };


    const handleFilterStatusChange = (e) => {
        if (e.target.value) {
            prepareSearch("status", Number(e.target.value));
        } else {
            prepareSearch("status", "");
        }
    };


    useEffect(() => {
        setLoader(true);
        const formData = new FormData();
        if (Object.keys(search).length) {
            formData.append("search", JSON.stringify(search));
        }
        // if (dateValue) {
        //   formData.append("date_search", JSON.stringify({ date: dateValue }));
        // }
        formData.append("global_search", globalSearch);
        // Determine the page number
        let page = params.page;
        setPage(page);
        formData.append("page", page);
        formData.append("per_page", itemPerPage);
        if (sortConfig) {
            formData.append("sort", JSON.stringify(sortConfig));
        }

        couponList(formData)
            .then((data) => {
                if (data && data.data) {
                    setList(data.data.list || []);
                    setPage(data.data.page);
                    setOffsetPage(data.data.offset);
                    setTotalRecords(data.data.total_records || 0);
                    if(data.data.list.length==0 && page>1){
                        setPage(page-1);
                        navigate(`/restaurant/settings/coupon/${page-1}` );
                    }
                } else {
                    setList([]);
                    setOffsetPage(0);
                    setTotalRecords(0);
                }
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIMEOUT_TIME);
            })
            .catch((error) => {
                console.log("error ====> ", error);
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIMEOUT_TIME);
            });
    }, [search, refreshList, globalSearch, params.page, sortConfig]);


    const handleSort = (key) => {
        let direction = SORT_A;
        if (sortConfig.column === key && sortConfig.order === SORT_A) {
            direction = SORT_D;
        }
        setSortConfig({ column: key, order: direction });
    };

    /** Handling the delete functionality */
    const deleteRecord = (record) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: 'swal-restaurant',
                confirmButton: 'btn btn-danger col',
                cancelButton: 'btn btn-secondary col'
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                deleteCoupon({ o_id: record._id }).then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                }).catch(() => {
                    globalLoader(false);
                });
            }
        });
    };

    const getCouponDetail = (item) => {
        detail({ o_id: item._id })
            .then((response) => {
                editForm.setValues({
                    coupon_id: response?.data?.coupon_id || "",
                    coupon_code: response?.data?.coupon_code || "",
                    coupon_category: response?.data?.coupon_category || "",
                    expire_date: response?.data?.expire_date || "",
                    discount_type: response?.data?.discount_type || "",
                    discount: response?.data?.discount || "",
                });
                setCouponDetail(response?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const changeStatusToggle = (item) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: item.status ? t("coupon_deactive_status_text") : t("coupon_active_status_text"),
            // imageUrl: trash,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: item.status ? t("btn_inactivate") : t("btn_activate"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: "swal-restaurant",
                confirmButton: "btn btn-danger col",
                cancelButton: "btn btn-secondary col",
            },
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                changeStatus({ o_id: item._id, status: item.status ? DEACTIVE : ACTIVE })
                    .then((response) => {
                        globalLoader(false);
                        if (response.success) {
                            Swal.fire({
                                icon: "success",
                                text: response.message,
                                ...SWAL_SETTINGS,
                            });
                            setRefreshList(!refreshList);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                    })
                    .catch((error) => {
                        globalLoader(false);
                        console.log("deleteError");
                    });
            } else {
                // setSortableCategories(List);
            }
        });
    };


    return (
        <main id='main-content'>
            <div className='inner-container'>
                <div className='page-header d-flex align-items-center'>
                    <h4 className='heading-title-text'>{t('label_coupon_settings')} ({List.length})</h4>
                </div>
                <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                    <div className='search-box'>
                        <input type='text' className='form-control search-input' placeholder={t("input_placeholder_search")} onInput={(e) => {
                            searchDebounce(e.target.value);
                        }} />
                    </div>
                    <div className='card-head-group d-flex align-items-center gap-2'>
                        <div className="tabls-dowpdown">
                            <select
                                className="form-control cp"
                                value={selectedStatus} // Bind state value
                                onChange={handleSelectChange}
                            >
                                <option disabled="" value="">
                                    {t("label_select_status")}
                                </option>
                                <option value="1"> {t("btn_active")}</option>
                                <option value="0">{t("btn_inactive")}</option>
                            </select>
                        </div>
                        <button className='btn btn-primary' onClick={() => { setOpenWithHeader(true); }}><i className='ti ti-plus'></i> {t('label_create_coupon')}</button>
                    </div>
                </div>
                <div className="dashboard-content-wraper">
                    <div className="category-list-wrap">
                        {loader ? (
                            <div className="w-100">
                                <Skeleton className="mb-2" height={50} count={5} />
                            </div>
                        ) : List?.length ? (
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{t("s_no")}</th>
                                                    <th>
                                                        {t("label_coupon_id")}
                                                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("coupon_id")}>
                                                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "coupon_id" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                                                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "coupon_id" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                                                        </span>
                                                    </th>
                                                    <th>
                                                        {t("label_coupon_code")}
                                                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("coupon_code")}>
                                                            <i
                                                                className={`ti ti-arrow-narrow-up ${sortConfig.column === "coupon_code" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`}
                                                                style={{ marginLeft: "5px" }}
                                                            ></i>
                                                            <i
                                                                className={`ti ti-arrow-narrow-down ${sortConfig.column === "coupon_code" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`}
                                                                style={{ marginLeft: "-8px" }}
                                                            />
                                                        </span>
                                                    </th>

                                                    <th>
                                                        {t("label_coupon_category")}
                                                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("coupon_category")}>
                                                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "coupon_category" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                                                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "coupon_category" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                                                        </span>
                                                    </th>
                                                    <th>
                                                        {t("label_type_of_discount")}
                                                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("discount_type")}>
                                                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "discount_type" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                                                            <i
                                                                className={`ti ti-arrow-narrow-down ${sortConfig.column === "discount_type" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`}
                                                                style={{ marginLeft: "-8px" }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th>
                                                        {t("label_amount_of_discount")}
                                                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("discount")}>
                                                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "discount" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                                                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "discount" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                                                        </span>
                                                    </th>
                                                    <th>{t("list_heading_status")}</th>
                                                    <th style={{ width: "150px" }}>{t("label_action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {List.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{offset + index + 1}</td>
                                                            <td>{TrimText(item?.coupon_id, 15)}</td>
                                                            <td>{TrimText(item?.coupon_code, 15) || t("label_not_entered")}</td>
                                                            <td className='text-capitalize'>{item?.coupon_category || t("label_not_entered")}</td>
                                                            <td className='text-capitalize'>{item?.discount_type}</td>
                                                            <td>{item?.discount_type === COUPON_TYPES.PERCENTAGE ? `${item?.discount} %` : `¥ ${item?.discount}`}</td>
                                                            <td>
                                                                <div className="mai-card-right">
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input cp" type="checkbox" checked={item?.status} onChange={(e) => changeStatusToggle(item)} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="d-flex">
                                                                <div className="dropdown ms-2">
                                                                    <button className="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="ti ti-dots-vertical"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <li>
                                                                            <a
                                                                                className="dropdown-item cp"
                                                                                onClick={() => {
                                                                                    getCouponDetail(item);
                                                                                }}
                                                                                data-bs-toggle="offcanvas"
                                                                                data-bs-target="#view-staff"
                                                                            >
                                                                                <i className="ti ti-eye"></i>
                                                                                {t("btn_view")}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dropdown-item cp"
                                                                                onClick={() => {
                                                                                    getCouponDetail(item);
                                                                                    setOpenWithHeaderEdit(true);
                                                                                }}
                                                                            >
                                                                                <i className="ti ti-edit"></i>
                                                                                {t("btn_edit")}
                                                                            </a>
                                                                        </li>

                                                                        <li>
                                                                            <a
                                                                                className="dropdown-item cp"
                                                                                onClick={() => {
                                                                                    deleteRecord(item);
                                                                                }}
                                                                            >
                                                                                <i className="ti ti-trash"></i>
                                                                                {t("btn_delete")}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table-status-info d-flex justify-content-between align-items-center">
                                        <span>
                                            {t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}
                                        </span>
                                        <nav aria-label="Page navigation example" title={page}>
                                            {totalRecords && totalRecords > 0 ? (
                                                <CustomPagination
                                                    datalength={totalRecords}
                                                    itemperpage={itemPerPage}
                                                    currentPage={page}
                                                    custompath="page"
                                                    setPage={setPage}
                                                    pageRoute={[{ name: "coupons", path: "/restaurant/settings/coupon" }]}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <RecordNotFound heading="no_coupons_found" />
                        )}
                    </div>
                </div>
            </div>

            {/* Add form  */}
            <Drawer open={openWithHeader}>
                <Drawer.Header closeButton={false}>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <h5 className="mb-0">{t("label_create_new_coupon")}</h5>
                        <button
                            className="btn-icon"
                            onClick={() => {
                                setOpenWithHeader(false);
                                addForm.resetForm();
                            }}
                        >
                            <i className="ri-close-fill"></i>
                        </button>
                    </div>
                </Drawer.Header>
                <Drawer.Body
                    onScroll={(e) => {
                        handleOffcanvasScroll(e, "editNewEmail", "rsuite");
                    }}
                >
                    <form onSubmit={addForm.handleSubmit} noValidate={true}>
                        <div className="mb-3">
                            <label className="form-label">{t("label_coupon_code")}<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_coupon_code")}
                                name="coupon_code"
                                value={addForm.values.coupon_code}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.coupon_code && addForm.errors.coupon_code ? (
                                <div className="text-danger">{t(addForm.errors.coupon_code)}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t("label_coupon_category")}<span>*</span></label>
                            <select className="form-control cp" name='coupon_category' value={addForm?.values?.coupon_category} onChange={addForm.handleChange}>
                                <option value={""}>{t("option_select_coupon_category")}</option>
                                {COUPON_CATEGORY_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                            </select>
                            {addForm.touched.coupon_category && addForm.errors.coupon_category ? (
                                <div className="text-danger">{t(addForm.errors.coupon_category)}</div>
                            ) : null}
                        </div>

                        <div className='mb-3'>
                            <label className="form-label">{t("label_expire_date")}<span>*</span></label>
                            <DatePicker
                                oneTap={true}
                                classPrefix="restaurant"
                                placeholder={t("placeholder_choose_date")}
                                placement="autoVertical"
                                format="dd/MM/yyyy"
                                cleanable={false}
                                editable={false}
                                name="expire_date"
                                caretAs={CustomBase64Caret}
                                shouldDisableDate={(date) => isBefore(date, new Date(yesterday))}
                                onChange={(e) => {
                                    addForm.setFieldTouched("expire_date", true);
                                    addForm.setFieldValue("expire_date", e);
                                }}
                                value={addForm.values.expire_date?new Date(addForm.values.expire_date):null}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.expire_date && addForm.errors.expire_date ? (
                                <div className="text-danger">{t(addForm.errors.expire_date)}</div>
                            ) : null}
                        </div>
                        {
                            console.log("addForm.values", addForm.values)
                        }

                        <div className="mb-3">
                            <label className="form-label">
                                {t("label_type_of_discount")}
                                <span>*</span>
                            </label>
                            <div className="d-flex gap-5">
                                <div className="form-check">
                                    <input
                                        className="form-check-input cp"
                                        name="discount_type"
                                        type="radio"
                                        id={COUPON_TYPES.FLAT}
                                        value={COUPON_TYPES.FLAT}
                                        onChange={addForm.handleChange}
                                        checked={addForm?.values?.discount_type === COUPON_TYPES.FLAT}
                                    />
                                    <label className="form-check-label cp" htmlFor={COUPON_TYPES.FLAT}>
                                        {t("label_coupon_type_flat")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input cp"
                                        name="discount_type"
                                        type="radio"
                                        id={COUPON_TYPES.PERCENTAGE}
                                        value={COUPON_TYPES.PERCENTAGE}
                                        onChange={addForm.handleChange}
                                        checked={addForm?.values?.discount_type === COUPON_TYPES.PERCENTAGE}
                                    />
                                    <label className="form-check-label cp" htmlFor={COUPON_TYPES.PERCENTAGE}>
                                        {t("label_coupon_type_percentage")}
                                    </label>
                                </div>
                            </div>
                            {addForm.touched.discount_type && addForm.errors.discount_type ? (
                                <div className="text-danger">{t(addForm.errors.discount_type)}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t("label_amount_of_discount")}<span>*</span></label>
                            <div className='input-group'>
                                {
                                    addForm?.values?.discount_type === COUPON_TYPES.FLAT && 
                                    <span class="input-group-text" id="basic-addon1">
                            ¥
                               </span>
                                }
                            
                            <input
                                type="number"
                                className="form-control no_spring_input"
                                placeholder={t("placeholder_enter_discount")}
                                name="discount"
                                value={addForm.values.discount}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                               {
                                addForm?.values?.discount_type === COUPON_TYPES.PERCENTAGE &&
                                <span class="input-group-text" id="basic-addon1">
                                 %
                               </span>
                               } 

                            </div>
                           
                            {addForm.touched.discount && addForm.errors.discount ? (
                                <div className="text-danger">{t(addForm.errors.discount)}</div>
                            ) : null}
                        </div>
                        <div className="button-form">
                            <button type="submit" className={"btn btn-primary w-100" + (addSubmissionLoader ? " btn-loader" : "")}>
                                {t("btn_submit")}
                            </button>
                        </div>
                    </form>
                </Drawer.Body>
            </Drawer>

            {/* Edit form  */}

            <Drawer open={openWithHeaderEdit}>
                <Drawer.Header closeButton={false}>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <h5 className="mb-0">{t("heading_edit_coupon")}</h5>
                        <button
                            className="btn-icon"
                            onClick={() => {
                                setOpenWithHeaderEdit(false);
                                editForm.resetForm();
                            }}
                        >
                            <i className="ri-close-fill"></i>
                        </button>
                    </div>
                </Drawer.Header>
                <Drawer.Body
                    onScroll={(e) => {
                        handleOffcanvasScroll(e, "editEmail", "rsuite");
                    }}
                >
                    <form onSubmit={editForm.handleSubmit} noValidate={true}>
                        <div className="mb-3">
                            <label className="form-label">{t("label_coupon_code")}<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_coupon_code")}
                                name="coupon_code"
                                value={editForm.values.coupon_code}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.coupon_code && editForm.errors.coupon_code ? (
                                <div className="text-danger">{t(editForm.errors.coupon_code)}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t("label_coupon_category")}<span>*</span></label>
                            <select className="form-control cp" name='coupon_category' value={editForm.values.coupon_category} onChange={editForm.handleChange}>
                                <option value={""}>{t("option_select_coupon_category")}</option>
                                {COUPON_CATEGORY_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                            </select>
                            {editForm.touched.coupon_category && editForm.errors.coupon_category ? (
                                <div className="text-danger">{t(editForm.errors.coupon_category)}</div>
                            ) : null}
                        </div>

                        <div className='mb-3'>
                            <label className="form-label">{t("label_expire_date")}<span>*</span></label>
                            <DatePicker
                                oneTap={true}
                                classPrefix="restaurant"
                                placeholder={t("placeholder_choose_date")}
                                placement="autoVertical"
                                format="dd/MM/yyyy"
                                cleanable={false}
                                editable={false}
                                name="expire_date"
                                caretAs={CustomBase64Caret}
                                shouldDisableDate={(date) => isBefore(date, new Date(yesterday))}
                                onChange={(e) => {
                                    editForm.setFieldTouched("expire_date", true);
                                    editForm.setFieldValue("expire_date", e);
                                }}
                                value={new Date(editForm.values.expire_date)}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.expire_date && editForm.errors.expire_date ? (
                                <div className="text-danger">{t(editForm.errors.expire_date)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_type_of_discount")}<span>*</span></label>
                            <div className="d-flex gap-5">
                                <div className="form-check">
                                    <input
                                        className="form-check-input cp"
                                        name="coupon_type"
                                        type="radio"
                                        id={COUPON_TYPES.FLAT}
                                        value={COUPON_TYPES.FLAT}
                                        onChange={() => editForm.setFieldValue("discount_type", COUPON_TYPES.FLAT)}
                                        checked={editForm.values.discount_type === COUPON_TYPES.FLAT}
                                    />
                                    <label className="form-check-label cp" htmlFor={COUPON_TYPES.FLAT}>
                                        {t("label_coupon_type_flat")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input cp"
                                        name="coupon_type"
                                        type="radio"
                                        id={COUPON_TYPES.PERCENTAGE}
                                        value={COUPON_TYPES.PERCENTAGE}
                                        onChange={() => editForm.setFieldValue("discount_type", COUPON_TYPES.PERCENTAGE)}
                                        checked={editForm.values.discount_type === COUPON_TYPES.PERCENTAGE}
                                    />
                                    <label className="form-check-label cp" htmlFor={COUPON_TYPES.PERCENTAGE}>
                                        {t("label_coupon_type_percentage")}
                                    </label>
                                </div>
                            </div>
                            {editForm.touched.coupon_type && editForm.errors.coupon_type ? (
                                <div className="text-danger">{t(editForm.errors.coupon_type)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_amount_of_discount")}<span>*</span></label>
                            <div className='input-group'>
                                {
                                    editForm?.values?.discount_type === COUPON_TYPES.FLAT && 
                                    <span class="input-group-text" id="basic-addon1">
                            ¥
                               </span>
                                }
                            
                            <input
                                type="number"
                                className="form-control no_spring_input"
                                placeholder={t("placeholder_enter_discount")}
                                name="discount"
                                value={editForm.values.discount}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                             {
                                editForm?.values?.discount_type === COUPON_TYPES.PERCENTAGE &&
                                <span class="input-group-text" id="basic-addon1">
                                 %
                               </span>
                               } 

                            </div>
                            {editForm.touched.discount && editForm.errors.discount ? (
                                <div className="text-danger">{t(editForm.errors.discount)}</div>
                            ) : null}
                        </div>
                        <div className="button-form">
                            <button type="submit" className={"btn btn-primary w-100" + (editSubmissionLoader ? " btn-loader" : "")}>
                                {t("btn_update")}
                            </button>
                        </div>
                    </form>
                </Drawer.Body>
            </Drawer>

            <div className="offcanvas offcanvas-end right-modal-w" id="view-staff" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        {t("coupon_details")}
                    </h5>
                    <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close">
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="view-staff-info">
                        <div className="row gap-4">
                            <div className="col-md-12">
                                <h4>{t("label_coupon_id")}</h4>
                                <p>{couponDetail?.coupon_id ? couponDetail.coupon_id : t("label_not_entered")}</p>
                            </div>

                            <div className="col-md-12">
                                <h4>{t("label_coupon_code")}</h4>
                                <p>{couponDetail?.coupon_code ? couponDetail.coupon_code : t("label_not_entered")}</p>
                            </div>
                            <div className="col-md-12">
                                <h4>{t("label_coupon_category")}</h4>
                                <p className='text-capitalize'>{couponDetail?.coupon_category ? couponDetail.coupon_category : t("label_not_entered")}</p>
                            </div>
                            <div className="col-md-12">
                                <h4>{t("label_type_of_discount")}</h4>
                                <p className='text-capitalize'>{couponDetail?.discount_type ? couponDetail.discount_type : t("label_not_entered")}</p>
                            </div>

                            <div className="col-md-12">
                                <h4>{t("label_amount_of_discount")}</h4>
                                <p>{couponDetail?.discount ? couponDetail.discount_type===COUPON_TYPES.FLAT?`¥${couponDetail.discount}` : `${couponDetail.discount} %`  : t("label_not_entered")}</p>
                            </div>


                            <div className="col-md-12">
                                <h4>{t("label_expire_date")}</h4>
                                <p>
                                    {formateDate(couponDetail.expire_date)}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}