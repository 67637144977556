import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Outlet, useLocation} from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantSettings } from "../services/table.services";
import { addRestData } from '../../../redux/slices/restSettings';
import { SOCKET_CLIENT } from "../../../utils/socket";
import { PRINTER_NAMES, SOCKET_EVENTS } from "../../../utils/Constants";
import { connectToPrinter, getDate, getPrinter, TrimText } from "../../../utils/commonfunction";
import { addKitchenDrinkPrint, addKitchenFoodPrint, addKitchenPrint } from "../../../utils/printerfunction";
import { useTranslation } from "react-i18next";

const CashierLayout = () => {
    let printerConnection2 = null;
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const [restaurantDetailChanged, setRestaurantDetailChanged] = useState(false);
    const {t , i18n} = useTranslation();
    // console.log(i18n.language)
    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/cashier/css/style.css"),
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);


    /** Joining user to socket for realtime notifications <<<<< */
    // useMemo(() => {
    //     SOCKET_CLIENT.on('GET-DATA', async (response) => {
    //         if (response) {
    //             if (response?.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
    //                 console.log("socket event listened")
    //                 setRestaurantDetailChanged(!restaurantDetailChanged)
    //             }
    //             else if(response?.event === SOCKET_EVENTS.ORDER_PLACED){
    //                 const printer = await getPrinter(PRINTER_NAMES.KITCHEN_PRINTER);
    //                 if(printer){
    //                     // console.log(response , "data for kitchen printer")
    //                     addKitchenPrint(printer , response , t , i18n.language);
    //                     if (response.data.ordered_items.some(item => item.item_variation === "food")) {
    //                     addKitchenFoodPrint(printer , response , t , i18n.language);
    //                 }
    //                     if (response.data.ordered_items.some(item => item.item_variation === "drink")) {
    //                         addKitchenDrinkPrint(printer,response , t , i18n.language);
    //                     }
                       
    //                 }
    //             }
    //         }
    //     });
    // }, [restaurantDetailChanged]);

    // useMemo(() => {
    //     getRestaurantSettings().then((globalDetails) => {
    //         dispatch(addRestData(globalDetails?.data));
    //     });
    // }, [restaurantDetailChanged]);
    useMemo(() => {
        getRestaurantSettings().then((globalDetails) => {
            dispatch(addRestData(globalDetails?.data));
            setRestaurantDetailChanged(false)
        });
    }, [restaurantDetailChanged]);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div>
                    <Header />
                    <Outlet />
                </div>
            )}
        </>
    );
};
export default CashierLayout;