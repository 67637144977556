import React, { useEffect, useMemo, useState } from "react";
import CustomError from "../../../utils/customError";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "../../../utils/PhoneInput";
import {
  CONSUMPTION_TAX_RATES,
  CUSTOMER_TAGS,
  DISPLAY_METHOD_TAX_OPTIONS,
  ITEM_TYPES,
  ORDER_PLATFORM,
  ORDER_TYPES,
  PHONE_NO_LENGTH,
  SERVICE_CHARGED_CONDITION,
  SPICE_LEVELS,
  SWAL_SETTINGS,
} from "../../../utils/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSessionKey,
  getSubTotalQRApp,
  getTranslatedKey,
  getUniqueID,
  handleServerValidations,
  isSameArray,
  pluralText,
  showPrice,
} from "../../../utils/commonfunction";
import Swal from "sweetalert2";
import trash from "../../../assets/restaurant/images/trash.svg";
import { useTranslation } from "react-i18next";
import { createOrder, detail } from "../services/order.service";
import { getRestaurantSettings } from "../../restaurant/services/profile.services";
import { menuItemDetail } from "../services/menu.service";
import { SetCartLength } from "../../../redux/slices/qrcodeDetails";

export default function ReviewOrder() {
  const queryParams = new URLSearchParams(window.location.search);
  const restaurant_id = queryParams.get("restaurant_id");
  const table_id = queryParams.get("table_id");
  const order_type = queryParams.get("order_type");
  const tableDetails = useSelector((state) => {
    return state.QrOrderToken.tableDetails;
  });
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [numberOfPersonOptions, setNumberOfPersonOptions] = useState([]);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const order_id = queryParams.get("order_id")
    ? queryParams.get("order_id")
    : null;
  const [orderDetail, setOrderDetail] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(
    location?.state?.customerDetails || null
  );
  const ChargeSettings = useSelector((state) => {
    return state.QrOrderToken.ChargeSettings;
  });
  const [pricing, setPricing] = useState({
    subtotal: 0,
    tax_price: 0,
    grand_total: 0,
    total_tax_8: 0,
    total_tax_10: 0,
    service_charge: 0,
    grand_cost_price:0
  });
  // const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [currentItem, setCurrentItem] = useState({});
  const dispatch = useDispatch();
  const base = queryParams.get("base");
  const staff_id = queryParams.get('staff_id'); 
  const [isSubmitted , setIsSubmitted]= useState(false)

  useEffect(() => {
    if (tableDetails && tableDetails?.capacity) {
      let i = 1;
      const numberOfPersonOptionsTemp = [];
      for (i; i <= tableDetails?.capacity; i++) {
        numberOfPersonOptionsTemp.push(i);
      }
      setNumberOfPersonOptions(numberOfPersonOptionsTemp);
    }
  }, [tableDetails]);

  useEffect(() => {
    if (order_id) {
      detail({ o_id: order_id }, { token: token })
        .then((response) => {
          setOrderDetail(response?.data);
          let customerData = {
            customer_name: response?.data?.customer_name || "",
            customer_phone_number:
              response?.data?.customerDetails?.customer_phone_number || "",
            number_of_persons: response?.data?.number_of_persons || "",
            customer_note: "",
            tag : response?.data?.tag
          };
          setCustomerDetails(customerData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [order_id]);

  const addForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      ordered_items: [...(location?.state?.selectedItems || [])],
      customer_name: customerDetails?.customer_name || "",
      customer_phone_number: customerDetails?.customer_phone_number || "",
      number_of_persons: customerDetails?.number_of_persons || "",
      customer_note: customerDetails?.customer_note || "",
      parent_id: order_id || "",
      tag : customerDetails?.tag ||null , 
      base : ""
    },
    validationSchema: Yup.object({
      ordered_items: Yup.array().min(1, t("err_item_required")),
      number_of_persons: Yup.number().required(t("err_no_of_person")),
      customer_phone_number: Yup.string()
        .test(
          "phone_digit_error",
          "label_phone_number_digits_error",
          (value) => {
            const tempLength =
              value && value.split(" ") && value.split(" ")[1]
                ? value.split(" ").length
                : 0;
            if (tempLength > 1) {
              const phoneNumber = (value?.split(" "))[1];
              return phoneNumber && /^\d+$/.test(phoneNumber);
            } else {
              return true;
            }
          }
        )
        .test(
          "phone_min_length",
          "label_phone_number_min_length_error",
          (value) => {
            const tempLength =
              value && value.split(" ") && value.split(" ")[1]
                ? value.split(" ").length
                : 0;
            if (tempLength > 1) {
              const phoneNumber = (value?.split(" "))[1];
              return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
            } else {
              return true;
            }
          }
        )
        .test(
          "phone_max_length",
          "label_phone_number_max_length_error",
          (value) => {
            const tempLength =
              value && value.split(" ") && value.split(" ")[1]
                ? value.split(" ").length
                : 0;
            if (tempLength > 1) {
              const phoneNumber = (value?.split(" "))[1];
              return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = {
        ...values,
        restaurant_id,
        table_id,
        type: order_id ? ORDER_TYPES.SUB_ORDER : ORDER_TYPES.BASE_ORDER,
        tag: values.tag,
        ...(getSubTotalQRApp(values?.ordered_items, ChargeSettings) || {}),
        parent_id: order_id || "",
        base : base , 
        staff_id : base===ORDER_PLATFORM.APP ? staff_id : null
      };
      setIsSubmitted(true);
      createOrder(formData, { token: token })
        .then((response) => {
          setIsSubmitted(false);
          if (response.success) {
            if(base===ORDER_PLATFORM.WEB){
              Swal.fire({
                icon: "success",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
            else{
              const appData = { action: 'show-success', data: handleServerValidations(response) };
              window.ReactNativeWebView?.postMessage(JSON.stringify(appData));

            }
     
            resetForm();
            dispatch(SetCartLength(0));
            const mySearchParams = new URLSearchParams(window.location.search);
            mySearchParams.set(
              "order_id",
              response?.data?.orderData?.parent_id
                ? response?.data?.orderData?.parent_id
                : response?.data?.orderData?._id
            );
            if(base===ORDER_PLATFORM.WEB){
              navigate({
                pathname: "/qr-order/place",
                search: mySearchParams.toString(),
              });
            }
            else{
              navigate({
                pathname: "/qr-order/success",
                search: mySearchParams.toString(),
              });
            }
           
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((err) => {
          setIsSubmitted(false);
          console.error(err);
        });
    },
  });

  useEffect(() => {
    setPricing(
      getSubTotalQRApp(addForm?.values?.ordered_items, ChargeSettings, {
        handleTaxSettings: true,
      })
    );
  }, [addForm?.values?.ordered_items, ChargeSettings]);

  const deleteRecord = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = [...addForm.values?.ordered_items];
        const index = data?.findIndex((record) => record.temp_item_id === item?.temp_item_id);
        if (index !== -1) {
          data.splice(index, 1);
          addForm.setFieldValue("ordered_items", data);
        }
      }
    });
  };

  const handleAsterisk = (item) => {
    let result = null;
    if (
      item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT &&
      ChargeSettings?.icon_display_eligible_for_reduced_tax_rate
    ) {
      result = <span className="fw-bold">*</span>;
    }
    return result;
  };



  const ifItemExistWithSameSpice = (modifiedItems, currItem) => {
    if (modifiedItems.length == 0) {
      return [];
    }
    else {
      let result = []
      modifiedItems.forEach((item, index) => {
        const itemIndex = index;
        let spiceLevelMatch;
        if (item._id === currItem._id) {
          const existingAddons = modifiedItems[index].updatedAddOns;
          spiceLevelMatch = currItem.addons.every((addon) => existingAddons.some((existingAddon) => {
            return existingAddon.spice_level === addon.spice_level
          }));
          if (spiceLevelMatch) {
            item.addons.forEach((addon) => {
              const addOnIndex = modifiedItems[itemIndex]?.updatedAddOns.findIndex(
                (a) => a.addon_id === addon.addon_id && a.spice_level === addon.spice_level);
              const existingAddon = modifiedItems[itemIndex]?.updatedAddOns[addOnIndex];
              existingAddon["count"] += 1;
            });
          }
        }
      })
    }

  }

//   const getItemInQuantity = (allItems) => {
//     const modifiedItems = [];
//     allItems.forEach((item) => {
//         if (item.type === "set_of_items") {
//             const comboAddons = item.addons.map(a => `${a.item_id}-${a.spice_level}`);
//             const existingIndex = modifiedItems.findIndex(record => record._id === item._id && isSameArray(comboAddons, record.addonArray));

//             if (existingIndex !== -1) {
//                 modifiedItems[existingIndex].quantity += 1; // Increment quantity
//             } else {
//                 modifiedItems.push({
//                     ...item,
//                     quantity: 1,
//                     addonArray: comboAddons // Store the addon identifiers for comparison
//                 });
//             }
//         } else if (item.type === "item") {
//             const existingIndex = modifiedItems.findIndex(
//                 record => record._id === item._id && record.spice_level === item.spice_level
//             );
//             if (existingIndex === -1) {
//                 modifiedItems.push({
//                     ...item,
//                     quantity: 1,
//                 });
//             } else {
//                 modifiedItems[existingIndex].quantity += 1; // Increment quantity
//             }
//         }
//     });
//     return modifiedItems;
// };



  const handleChangeQuantity = (item, type, quantity) => {
    let allItems = [...addForm?.values?.ordered_items];
    if (type === "increase") {
      if(item.type ===ITEM_TYPES.ITEM && !item.spice_level_needed){
        const index = allItems.findIndex(record=>record?.temp_item_id === item?.temp_item_id);
        allItems[index].quantity += 1;
      }else{
        if (item.spice_level_needed || item.addons.length > 0) {
          fetchMenuItemDetail(item?._id).then(()=>{
            let elem = document.getElementById("increaseQuantity");
            elem.click();
          });
        } else {
          allItems.push(item);
        }
      }
    } else {
      const index = allItems.findLastIndex((record) => record?.temp_item_id === item?.temp_item_id);
      if (index >= 0) {
        if (quantity > 1) {
          allItems[index].quantity -= 1;
        }else{
          allItems.splice(index, 1);
        }
      }
    }
    addForm.setFieldValue("ordered_items", allItems);
  };

  const fetchMenuItemDetail = (_id, formType="add", defaultValues={}) => {
    return new Promise((resolve, reject) => {
      menuItemDetail({ o_id: _id }, { token: token })
        .then((response) => {
          setCurrentItem(response?.data);
          if (response?.data.type === ITEM_TYPES.ITEM) {
            let updatedItem = {};
            if(formType === "add"){
              updatedItem = {
                form_type:formType,
                ...response.data,
                spice_level: response?.data?.spice_level_needed
                  ? SPICE_LEVELS.LOW
                  : null,
                spice_level_needed: response?.data?.spice_level_needed || false,
              };
            }else{
              updatedItem = {...defaultValues,form_type:formType,};
            }
            formik.setValues(updatedItem);
            setSelectedItem(updatedItem);
          } else {
            let data = {};
            if(formType === "add"){
              data = {
                form_type:formType,
                ...response?.data,
                addons: response?.data?.addons?.map((addon) => {
                  return {
                    item_id: "",
                    item_name: "",
                    item_name_ja: "",
                    spice_level: null,
                    spice_level_needed: false,
                    addon_id: addon?._id,
                    addon_name: addon.addon_name,
                    items: addon.items,
                  };
                }),
              };
            }else{
              data = {...defaultValues,form_type:formType};
            }
            setSelectedItem(data);
            formik.setValues(data);
          }
          setTimeout(() => {
            resolve(response);
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  const formik = useFormik({
    initialValues: {
      spice_level: selectedItem?.spice_level || null,
      addons:
        selectedItem?.addons?.map((addon) => ({
          item_id: addon.item_id,
          spice_level: addon.spice_level || null,
        })) || [],
    },
    onSubmit: (values, { resetForm }) => {
      function getAddonArray(item) {
        return item.addons.map(a => `${a.item_id}-${a.spice_level}`);
      }
      let allItems = [...addForm.values?.ordered_items];
      if (values.form_type === "add") {
        if (selectedItem.type === ITEM_TYPES.ITEM) {
          const index = allItems.findIndex(record => record._id === selectedItem._id && record.spice_level === values.spice_level);
          if (index !== -1) {
            allItems[index].quantity += 1;
          } else {
            allItems.push({
              ...values,
              quantity: 1,
              temp_item_id: getUniqueID('selected-item-')
            });
          }
        } else {
          let index = -1;
          const item = { ...values };
          const comboAddons = item.addons.map(a => `${a.item_id}-${a.spice_level}`);
          index = allItems.findIndex(record => record._id === item._id && isSameArray(comboAddons, getAddonArray(record)));
          if (index !== -1) {
            allItems[index].quantity += 1;
          } else {
            allItems.push({
              ...values,
              quantity: 1,
              temp_item_id: getUniqueID('selected-item-')
            });
          }
        }
      }else{
        let index = allItems.findIndex(record=>record.temp_item_id === values.temp_item_id);
        if(selectedItem.type === ITEM_TYPES.ITEM){
          let similarIndex = allItems.findIndex(record=>record.temp_item_id !== values.temp_item_id && record._id === values._id && record.spice_level === values.spice_level);
          if(similarIndex === -1){
            allItems[index] = values;
          }else{
            allItems[similarIndex].quantity += values.quantity;
            allItems.splice(index, 1);
          }
        }else{
          // let similarIndex = allItems.findIndex(record=>record.temp_item_id !== values.temp_item_id && record.spice_level === values.spice_level);
          let similarIndex = -1;
          const item = { ...values };
          const comboAddons = item.addons.map(a => `${a.item_id}-${a.spice_level}`);
          similarIndex = allItems.findIndex(record => record.temp_item_id !== values.temp_item_id && record._id === item._id && isSameArray(comboAddons, getAddonArray(record)));
          if(similarIndex === -1){
            allItems[index] = values;
          }else{
            allItems[similarIndex].quantity += values.quantity;
            allItems.splice(index, 1);
          }
        }
      }


      addForm.setFieldValue("ordered_items", allItems);
      resetForm();
    },
  });

  useEffect(()=>{
    let result = 0;
    addForm?.values?.ordered_items.forEach((record)=>{
      result += record?.quantity;
    });
    dispatch(SetCartLength(result));
  },[addForm?.values?.ordered_items]);

  return (
    <>
      {/* <div className='mobile-container'> */}
      <form className="form-flex-layout" onSubmit={addForm.handleSubmit}>
        {/* <div className='mobile-container-wraper'> */}
        <div className="qr-body-wrap">
          <div className="p-3">
            <div className="form-review-order">
              <div className="mb-3">
                <label className="form-label">
                  {t("number_of_person_text")}
                  <span>*</span>
                </label>
                <select
                  className="form-control form-control-placeholder cp"
                  name="number_of_persons"
                  value={addForm?.values?.number_of_persons}
                  onChange={(e) => {
                    addForm.setFieldValue(
                      "number_of_persons",
                      e.target.value ? Number(e.target.value) : ""
                    );
                  }}
                >
                  <option value={""}>{t("input_select_text")}</option>
                  {numberOfPersonOptions?.map((option, index) => {
                    return (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <CustomError
                  className="text-danger"
                  name="number_of_persons"
                  form={addForm}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("full_name_label")}</label>
                <input
                  type="text"
                  className="form-control form-control-placeholder"
                  placeholder={t("placeholder_enter_addon_option_name")}
                  name="customer_name"
                  value={addForm?.values?.customer_name}
                  onChange={addForm.handleChange}
                // disabled={order_id ? true : false}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("label_phone_number")}</label>
                {/* <input type='text' className='form-control' placeholder='Enter your phone number' name="phone_number" value={addForm?.values?.phone_number} onChange={addForm.handleChange} /> */}
                <PhoneInput
                  // disabled={order_id ? true : false}
                  placeholder={t("placeholder_phone_number")}
                  value={addForm?.values?.customer_phone_number}
                  name={"phone_number"}
                  onChange={(e) => {
                    addForm.setFieldValue(
                      "customer_phone_number",
                      e?.phone_number
                    );
                  }}
                  className="form-control form-control-placeholder"
                  onBlur={addForm.handleBlur}
                />
                <CustomError
                  name="customer_phone_number"
                  form={addForm}
                  className="text-danger"
                  shortCodes={{
                    PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min,
                    PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max,
                  }}
                />
              </div>
              {
                base===ORDER_PLATFORM.APP &&
                <div className="mb-3">
                <label className="form-label">
                  {t("label_customer_tag")} 
                </label>
                <select
                  className="form-control form-control-placeholder cp"
                  name="tag"
                  value={addForm?.values?.tag}
                  onChange={addForm.handleChange}
                >
                  <option value={""}>{t("input_select_text")}</option>
                  <option value={CUSTOMER_TAGS.VIP}>{t("tag_vip")}</option>
                  <option value={CUSTOMER_TAGS.REGULAR}>{t("tag_regular")}</option>
                  
                </select>
              </div>
              }
            </div>
            {addForm?.values?.ordered_items?.length ? (
              <div className="items-edit-delete">
                <h4>{t("label_items")} </h4>
                <div className="d-flex gap-2 flex-column">
                  {addForm?.values?.ordered_items?.map(
                    (item, index) => {
                      return (
                        <div className="card card-2" key={index}>
                          <div className="p-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="">
                                <span className="d-block">
                                  {getTranslatedKey("item_name", item)}{" "}
                                  {handleAsterisk(item)}{" "}
                                  {item?.quantity > 1 ? (
                                    <strong>{`(x${item?.quantity})`}</strong>
                                  ) : null}
                                  <br />
                                  {item?.spice_level ? (
                                    <span className="fs-12">
                                      {t("spice_level_text")} -{" "}
                                      <strong>
                                        {t("label_" + item?.spice_level)}
                                      </strong>
                                    </span>
                                  ) : null}
                                </span>

                                {/* <span className="d-block"> {getTranslatedKey('item_name',item)} {handleAsterisk(item)}</span> */}
                                {item?.type === ITEM_TYPES.SET_OF_ITEMS ? (
                                  <div className="w-100 pt-2">
                                    {item?.addons?.map(
                                      (addon, addonIndex) => {
                                        return (
                                          <div
                                            className={
                                              "border p-2 rounded-2 mb-2" +
                                              (addonIndex + 1 <
                                                item?.addons?.length
                                                ? " me-1"
                                                : "")
                                            }
                                            key={addonIndex}
                                          >
                                            <span className="d-block fs-12">
                                              {getTranslatedKey(
                                                "item_name",
                                                addon
                                              )}
                                              {addon?.count > 1 ? (
                                                <strong>{`(x${addon?.count})`}</strong>
                                              ) : null}
                                            </span>
                                            {addon?.spice_level ? (
                                              <span className="d-block fs-12">
                                                {t("spice_level_text")} -{" "}
                                                <strong>
                                                  {t(
                                                    "label_" +
                                                    addon?.spice_level
                                                  )}
                                                </strong>
                                              </span>
                                            ) : null}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}
                                <small>
                                  
                                  ¥ {ChargeSettings?.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?showPrice(item?.price * item?.quantity):showPrice(item?.total_price * item?.quantity)}
                                </small>
                              </div>
                              <div>
                                <div className="border border-1 rounded-1 mb-2 quantity-box d-flex justify-content-between">
                                  <button
                                    className="btn btn-sm py-1 px-2 h-auto"
                                    type="button"
                                    onClick={() => {
                                      handleChangeQuantity(
                                        item,
                                        "decrease",
                                        item?.quantity
                                      );
                                    }}
                                  >
                                    <i className="ti ti-minus"></i>
                                  </button>
                                  <span className="d-flex align-items-center">
                                    {item?.quantity}
                                  </span>
                                  <button
                                    className="btn btn-sm py-1 px-2 h-auto"
                                    type="button"
                                    onClick={() => {
                                      handleChangeQuantity(
                                        item,
                                        "increase",
                                        item?.quantity
                                      );
                                    }}
                                  >
                                    <i className="ti ti-plus"></i>
                                  </button>
                                </div>
                                <div className="d-flex gap-2">
                                  <button
                                    type="button"
                                    disabled={item?.type===ITEM_TYPES.ITEM && item?.spice_level_needed===false}
                                    onClick={() => {
                                      fetchMenuItemDetail(item?._id, "edit", item).then(()=>{
                                        let elem = document.getElementById("increaseQuantity");
                                        elem.click();
                                      });
                                    }}
                                    className="btn btn-icon btn-sm h-auto py-2 no-color-disabled"
                                  >
                                    <i className="ti ti-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      deleteRecord(item);
                                    }}
                                    className="btn btn-icon btn-danger  btn-sm h-auto py-2"
                                  >
                                    <i className="ti ti-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              <div className="items-edit-delete">
                <h4>{t("no_order_text")}</h4>
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={() => {
                    const searchParams = location.search;
                    navigate(`/qr-order${searchParams}`);
                  }}
                >
                  {t("select_item_label")}
                </button>
                <CustomError
                  className="text-danger"
                  name="ordered_items"
                  form={addForm}
                />
              </div>
            )}
            <div className="mb-3 mt-4">
              <label className="form-label">{t("requirement_text")}</label>
              <textarea
                style={{ height: "120px" }}
                placeholder={t("write_a_note")}
                className="form-control form-control-placeholder"
                name="customer_note"
                value={addForm?.values?.customer_note}
                onChange={addForm.handleChange}
              ></textarea>
            </div>
            {/* Showing the pricing breakdown for selected items */}
            {addForm?.values?.ordered_items?.length ? (
              <div className="info-order-review">
                <div className="card card-2">
                  <div className="p-2">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            {t("sub_total_label")}(
                            {addForm?.values?.ordered_items?.length}{" "}
                            {pluralText(
                              addForm?.values?.ordered_items?.length,
                              t("label_item"),
                              t("label_items")
                            )}
                            )
                            {/* {ChargeSettings?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED && (
                        <span>
                           ({t('tax_included_label')})</span>
                      )} */}
                          </td>
                          <td className="text-end">
                            ¥ {showPrice(pricing?.subtotal)
                            }
                          </td>
                        </tr>
                        
                        {pricing?.tax_price ? (
                          <tr>
                            <td>{t("total_tax_label")}</td>
                            <td className="text-end">
                              ¥ {showPrice(pricing?.tax_price)}
                            </td>
                          </tr>
                        ) : null}
                        {pricing?.service_charge ? (
                          <tr>
                            <td>{t("service_charge")}</td>
                            <td className="text-end">
                              ¥ {showPrice(pricing?.service_charge)}
                            </td>
                          </tr>
                        ) : null}

                        <tr>
                          <td colSpan={2} className="text-end">
                          
                              <span className="d-block no-wrap">
                                (8% {t("tax_on_text")} ¥{" "}
                                {showPrice(pricing?.total_tax_8)})
                              </span>
                           
                           
                              <span className="d-block no-wrap">
                                (10% {t("tax_on_text")} ¥{" "}
                                {showPrice(pricing?.total_tax_10)})
                              </span>
                            
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{t("grand_total_text")}</h4>
                          </td>
                          <td className="text-end">
                            <h4>¥ {Number(showPrice(pricing?.grand_total))}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="cart-bottom p-3">
          <button className="btn btn-primary w-100" type="submit" disabled={isSubmitted}>
            {t("place_order_label")}
          </button>
        </div>
        {/* </div> */}
      </form>

      <button
        className="btn btn-dark w-100 mt-2 d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        id="increaseQuantity"
      ></button>
      {/* // </div> */}

      <div
        className="modal fade qr-modal"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              {selectedItem?.type === ITEM_TYPES.ITEM ? (
                <>
                  {selectedItem?.spice_level_needed ? (
                    <>
                      <div className="p-2">
                        <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                          <h4>
                            {t("label_spice_level_for")}{" "}
                            {getTranslatedKey(
                              selectedItem.item_name,
                              selectedItem
                            )}
                          </h4>
                        </div>
                        <div className="veg-tags">
                          <ul className="spice-gap">
                            {Object.entries(SPICE_LEVELS).map(
                              ([key, value]) => (
                                <li key={key}>
                                  <div className="check-input-tick">
                                    <input
                                      type="radio"
                                      className="cp"
                                      name="spice_level"
                                      id={`spice_level_${value}_${selectedItem?._id}`}
                                      value={value}
                                      checked={
                                        formik.values.spice_level === value
                                      }
                                      onChange={formik.handleChange}
                                    />
                                    <label
                                      htmlFor={`spice_level_${value}_${selectedItem?._id}`}
                                      className="cp"
                                    >
                                      <i className="ti ti-circle-check"></i>{" "}
                                      {t(`label_${value}`)}
                                    </label>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex gap-3 mt-4 mb-2">
                        <button
                          className="btn btn-gry w-100"
                          data-bs-dismiss="modal" type="button"
                        >
                          {t("false_label_no")}
                        </button>
                        <button
                          className="btn btn-primary w-100"
                          onClick={formik.handleSubmit}
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          {t("btn_update")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <h4 className="mb-3">{t("label_choose_option")}</h4>
                  )}
                </>
              ) : (
                <>
                  <h4 className="mb-3">{t("label_choose_option")}</h4>
                  <>
                    <div className="">
                      {currentItem?.addons?.map((addon, index) => {
                        return (
                          <div key={index}>
                            <div className="row-veg-card">
                              <div className="head-veg-card align-items-center justify-content-between mb-3">
                                <h4>{getTranslatedKey("addon_name", addon)}</h4>
                                {/* {addon?.addon_name}  */}
                                <div className="form-group">
                                  <select
                                    className="form-control cp"
                                    onBlur={formik.handleBlur}
                                    name={`addons[${index}].item_id`}
                                    value={
                                      formik?.values?.addons[index]?.item_id
                                    }
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      let selectedItemData = addon?.items.filter(
                                        (item) => item?._id === e?.target?.value
                                      )[0];
                                      if (selectedItemData) {
                                        formik.setFieldValue(
                                          `addons[${index}].item_name_ja`,
                                          selectedItemData?.item_name_ja
                                        );
                                        formik.setFieldValue(
                                          `addons[${index}].item_name`,
                                          selectedItemData?.item_name
                                        );
                                        formik.setFieldValue(
                                          `addons[${index}].spice_level_needed`,
                                          selectedItemData?.spice_level_needed
                                        );
                                        if (
                                          selectedItemData?.spice_level_needed
                                        ) {
                                          if (
                                            formik?.values?.addons[index]
                                              ?.spice_level
                                          ) {
                                            /** Already selected by user */
                                          } else {
                                            formik.setFieldValue(
                                              `addons[${index}].spice_level`,
                                              SPICE_LEVELS.LOW
                                            );
                                          }
                                        } else {
                                          formik.setFieldValue(
                                            `addons[${index}].spice_level`,
                                            null
                                          );
                                        }
                                      }
                                      setTimeout(() => {
                                        formik.handleBlur(e);
                                      }, 100);
                                    }}
                                  >
                                    <option value={""}>
                                      {t("input_select_text")}
                                    </option>
                                    {addon?.items?.map((option, opIndex) => {
                                      return (
                                        <option
                                          key={opIndex}
                                          value={option?._id}
                                        >
                                          {getTranslatedKey(
                                            "item_name",
                                            option
                                          )}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {formik?.touched &&
                                    formik?.touched.addons &&
                                    formik?.touched.addons[index]?.item_id &&
                                    !formik?.values?.addons[index]?.item_id ? (
                                    <span className="validation-error text-danger fs-14">
                                      {t("select_item_text")}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              {(function () {
                                let selectedItemData = addon?.items.filter(
                                  (item) =>
                                    item?._id ===
                                    formik?.values?.addons[index]?.item_id
                                )[0];
                                if (
                                  selectedItemData &&
                                  selectedItemData?.spice_level_needed
                                ) {
                                  return (
                                    <>
                                      {/* <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                                      <h4>{t("label_spice_level_for")} for {selectedItem.item_name}</h4>
                                    </div> */}
                                      <div className="veg-tags">
                                        <ul className="spice-gap">
                                          <li>
                                            <div className="check-input-tick">
                                              <input
                                                type="radio"
                                                className="cp"
                                                onChange={formik.handleChange}
                                                name={`addons[${index}].spice_level`}
                                                id={
                                                  "spice_level_low" + addon?._id
                                                }
                                                checked={
                                                  formik?.values?.addons[index]
                                                    ?.spice_level ===
                                                  SPICE_LEVELS.LOW
                                                }
                                                value={SPICE_LEVELS.LOW}
                                              />
                                              <label
                                                htmlFor={
                                                  "spice_level_low" + addon?._id
                                                }
                                                className="cp"
                                              >
                                                <i className="ti ti-circle-check"></i>{" "}
                                                {t("label_low")}
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="check-input-tick">
                                              <input
                                                type="radio"
                                                className="cp"
                                                onChange={formik.handleChange}
                                                name={`addons[${index}].spice_level`}
                                                id={
                                                  "spice_level_medium" +
                                                  addon?._id
                                                }
                                                checked={
                                                  formik?.values?.addons[index]
                                                    ?.spice_level ===
                                                  SPICE_LEVELS.MEDIUM
                                                }
                                                value={SPICE_LEVELS.MEDIUM}
                                              />
                                              <label
                                                htmlFor={
                                                  "spice_level_medium" +
                                                  addon?._id
                                                }
                                                className="cp"
                                              >
                                                <i className="ti ti-circle-check"></i>{" "}
                                                {t("label_medium")}
                                              </label>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="check-input-tick">
                                              <input
                                                type="radio"
                                                className="cp"
                                                onChange={formik.handleChange}
                                                name={`addons[${index}].spice_level`}
                                                id={
                                                  "spice_level_high" +
                                                  addon?._id
                                                }
                                                checked={
                                                  formik?.values?.addons[index]
                                                    ?.spice_level ===
                                                  SPICE_LEVELS.HIGHT
                                                }
                                                value={SPICE_LEVELS.HIGHT}
                                              />
                                              <label
                                                htmlFor={
                                                  "spice_level_high" +
                                                  addon?._id
                                                }
                                                className="cp"
                                              >
                                                <i className="ti ti-circle-check"></i>{" "}
                                                {t("label_high")}
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </>
                                  );
                                }
                                return null;
                              })()}
                            </div>
                            {index + 1 < currentItem?.addons.length ? (
                              <hr className="my-3" />
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </>
                  <div className="d-flex gap-3 mt-4 mb-2">
                    <button
                      className="btn btn-gry w-100"
                      data-bs-dismiss="modal"
                      onClick={formik.resetForm}
                      type="button"
                    >
                      {t("false_label_no")}
                    </button>
                    <button
                      className="btn btn-primary w-100"
                      onClick={formik.handleSubmit}
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      {t("btn_update")}
                    </button>
                  </div>
                  {/* <button
              className="btn btn-primary w-100 mt-4"
              type="button"
              onClick={formik.handleSubmit}
              data-bs-dismiss="modal"
            >
              {t("btn_update")}
            </button> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}