import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getDayBasisReport } from "../../../services/abc-analysis.service";
import { useTranslation } from "react-i18next";
import logo from "../../../../../assets/restaurant/images/calenderLogo.png";
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, SORT_A, SORT_D } from "../../../../../utils/Constants";
import { DatePicker } from "rsuite";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import RecordNotFound from "../../../shared/recordNotFound";
import Skeleton from "react-loading-skeleton";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { globalLoader, TrimText, writeMyExcel } from "../../../../../utils/commonfunction";
import html2canvas from "html2canvas";

export default function AbcAnalysisDayBasis() {
  const [List, setList] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [Type, setType] = useState("quantity");
  const [payment_method, setPaymentMethod] = useState("");
  const { t } = useTranslation();
  const [langChanged, setLangChanged] = useState(false);
  const [Loader, setLoader] = useState(true);
  const [sortConfig, setSortConfig] = useState({ column: "Mon", order: SORT_D });
  const componentRef = useRef();


  const today = new Date();

  // Dynamically format the date based on the Type
  const initialSelectedDate = today.toLocaleDateString('en-GB', {
    month: 'short',
    year: 'numeric',
  });
  const lowerButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);


  const handleUpperButtonClick = () => {
    if (lowerButtonRef.current) {
      if (isDatePickerOpen) {
        // Close the DatePicker
        lowerButtonRef.current.close(); // Close the DatePicker programmatically
      } else {
        // Open the DatePicker
        lowerButtonRef.current.open(); // Open the DatePicker programmatically
      }
      setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
    }
  };


  const handleDateChange = (value) => {
    setTodayDate(value);
    const formattedDate = value.toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric',
    });
    setSelectedDate(formattedDate); // Update the displayed value
    setIsDatePickerOpen(false); // Close the DatePicker after selection
  };

  const handleToggle = (open) => {
    setIsDatePickerOpen(open);
  };






  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false); // Close the DatePicker and change the icon state
  };


  const CustomBase64Caret = () => <img src={logo} alt="Custom Caret" style={{ width: 20, height: 20 }} />;

  useEffect(() => {
    setLoader(true);
    const data = {
      payment_method: payment_method,
      Type: Type
    };
    if (sortConfig) {
      data.sort = JSON.stringify(sortConfig);
    }
    data.month = todayDate.toISOString().slice(0, 7);
    getDayBasisReport(data)
      .then((response) => {
        setList(response.data);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log(response.data);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [payment_method, todayDate, langChanged, sortConfig]);

  useMemo(() => {
    document.addEventListener("language_change", function () {
      setLangChanged(!langChanged);
    });
  });


  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };



  const handleDownload = () => {
    globalLoader(true);
    downloadPdf();
  }

  const downloadPdf = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        const canvas = await html2canvas(html, { scale: 2 });
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 200;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let yOffset = 10;
        let pageHeight = 297;

        if (imgHeight > pageHeight) {
          pdf.addImage(imgData, 'PNG', 10, yOffset, imgWidth, imgHeight);
          pdf.addPage();
          yOffset = 0;
        } else {
          pdf.addImage(imgData, 'PNG', 10, yOffset, imgWidth, imgHeight);
        }
        pdf.save('weekly_sales_report.pdf');
        globalLoader(false);
      }
    },
  });

  const handleExportToExcel = () => {
    // Prepare the data for export
    const headers = [
      t('label_product_name'),
      t('label_monday'),
      t('label_tuesday'),
      t('label_wednesday'),
      t('label_thursday'),
      t('label_friday'),
      t('label_saturday'),
      t('label_sunday'),
      t('label_total'),
    ];


    const rows = List.map((item) => {
      // Calculate total sum for the row
      const totalSum = item.data.reduce((acc, curr) => acc + (Type === "quantity" ? curr.total_quantity : curr.total_amount), 0);

      // Prepare the row data
      const row = [
        TrimText(item.item_name, 25), // Product Name
        ...Array.from({ length: 7 }, (_, dayIndex) => {
          // Adjust index for Sunday start
          const adjustedIndex = (dayIndex +1) % 7;
          return Type === "quantity"
            ? item.data[adjustedIndex]?.total_quantity || 0
            : `¥${(item.data[adjustedIndex]?.total_amount || 0).toFixed(2)}`;
        }),
        Type === "quantity" ? totalSum : `¥${Number(totalSum).toFixed(2)}`, // Total
      ];

      return row;
    });

    // Add total row if List exists
    if (List && List.length > 0) {
      const overallTotals = Array.from({ length: 7 }, (_, dayIndex) => {
        return List.reduce((acc, item) => {
          const adjustedIndex = dayIndex % 7;
          return acc + (Type === "quantity"
            ? item.data[adjustedIndex]?.total_quantity || 0
            : item.data[adjustedIndex]?.total_amount || 0);
        }, 0);
      });

      // Add the overall total row

    }

    console.log("rows", rows)
    // Write to Excel
    writeMyExcel([headers, ...rows], "weekly_sales_report.xlsx");
  };



  return (
    <main id="main-content">
      <div className="menu-level-3">
        <ul className="nav nav-pills gap-3">
          <li className="nav-item">
            <Link className="nav-link" to={"/restaurant/sales/abc-analysis"}>
              {t("label_product_basis")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/restaurant/sales/abc-analysis-category-basis"}>
              {t("label_category_basis")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to={"/restaurant/sales/abc-analysis-day-basis"}>
              {t("label_day_basis")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="inner-container">
        <div className="d-flex tabs-with-etc justify-content-between align-items-center mb-4">
          <ul className="nav nav-pills gap-3">
            <li
              className="nav-item"
              onClick={() => {
                setType("quantity");
              }}
            >
              <Link className={Type === "quantity" ? "nav-link active" : "nav-link"}>{t("label_quantity")}</Link>
            </li>
            <li
              onClick={() => {
                setType("sale_amount");
              }}
            >
              <Link className={Type === "sale_amount" ? "nav-link active" : "nav-link"}>{t("label_amount_sale")}</Link>
            </li>
          </ul>
          <div className="tabls-dowpdown">
            <select className="form-control cp" onChange={(e) => setPaymentMethod(e.target.value)}>
              <option value="">
                {t("text_choose_payment_method")}
              </option>
              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {t(`${item.label}`)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">


          <div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Upper Button */}
            <button className="btn btn-date" onClick={handleUpperButtonClick}>
              <i className="ti ti-calendar" style={{ color: "#757575" }}></i> <span style={{ color: "#757575" }}>{selectedDate}</span>{' '}
              <i className={`ti ${isDatePickerOpen ? 'ti-chevron-up' : 'ti-chevron-down'}`} style={{ color: "#757575" }}></i>
            </button>

            {/* Hidden DatePicker */}
            <div
              style={{
                visibility: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <DatePicker
                ref={lowerButtonRef}
                oneTap={true}
                classPrefix="restaurant"
                placement="bottomStart" // Opens directly beneath the button
                container={() => document.body} // Prevents clipping by parent containers
                format={"MMM yyyy"}
                cleanable={false}
                editable={false}
                value={todayDate}
                onChange={handleDateChange}
                onToggle={handleToggle} // Handle open/close state
                disabledDate={(date) => date > new Date()}
                onClose={handleDatePickerClose}
              />
            </div>
          </div>



          {/* <button className="">
            <DatePicker
              oneTap={true}
              classPrefix="restaurant"
              placement="autoVertical"
              cleanable={false}
              editable={false}
              value={todayDate}
              format="MMM yyyy"
              caretAs={CustomBase64Caret}
              onChange={(value) => {
                setTodayDate(value);
              }}
              disabledDate={(date) => date > new Date()}
            />
          </button> */}
          <div className="card-head-group d-flex align-items-center gap-2">
            <button
              className="btn btn-black"
              onClick={() => {
                List?.length && handleExportToExcel();
              }}
              disabled={!List?.length}
            >
              <i className="ti ti-download"></i> {t("label_download")}
            </button>
            {/* <button
              className="btn btn-black"
              onClick={() => {
                List?.length && printPDF();
              }}
            >
              <i className="ti ti-printer"></i> {t("label_print")}
            </button> */}
            <ReactToPrint trigger={() =>
              <button className='btn btn-black w-100' disabled={!List?.length}><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
            } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}" />
          </div>
        </div>
        {Loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ) : (
          <div className="card">

            <div className="card-body" ref={componentRef}>
              {List && List.length ? (
                <div className="table-responsive">
                  <table className="table table-printable total-last-td">
                    <thead>
                      <tr>
                        <th>{t("label_product_name")}</th>
                        <th>
                          {t("label_monday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Mon")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Mon" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Mon" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_tuesday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Tue")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Tue" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Tue" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_wednesday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Wed")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Wed" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Wed" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_thursday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Thu")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Thu" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Thu" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_friday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Fri")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Fri" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Fri" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_saturday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Sat")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Sat" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Sat" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>
                          {t("label_sunday")}
                          <span style={{ cursor: "pointer" }} onClick={() => handleSort("Sun")}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Sun" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Sun" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                        </th>
                        <th>{t("total_label")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List && List.length > 0
                        ? List.map((item, index) => {
                          // Calculate the sum of all quantities or amounts
                          const totalSum = item.data.reduce((acc, curr) => acc + (Type === "quantity" ? curr.total_quantity : curr.total_amount), 0);

                          return (
                            <tr key={index}>
                              <td>{TrimText(item?.item_name, 25)}</td>
                              {item.data.map((dataItem, i) => {
                                // Use (i + 1) % 7 to shift index and wrap around for Sunday
                                const adjustedIndex = (i + 1) % 7;
                                return (
                                  <td key={i}>
                                    {Type === "quantity"
                                      ? item.data[adjustedIndex].total_quantity
                                      : `¥ ${item.data[adjustedIndex].total_amount.toFixed(2)}`}
                                  </td>
                                );
                              })}
                              <td>{Type === "quantity" ? totalSum : `¥ ${Number(totalSum).toFixed(2)}`}</td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                  </table>
                </div>
              ) : (
                <RecordNotFound heading="lbl_record_not_found" />
              )}
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
