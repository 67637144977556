import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Calendar } from "rsuite";
import { DateRangePicker, DatePicker } from "rsuite";
import { revenueReport } from "../../../services/tableOrder.service";
import { capitalizeFirstLetter, formateDateWithMonthForGraph, getDateInFormat, showPrice, TrimText, writeMyExcel } from "../../../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import CustomError from "../../../../../utils/customError";

function DownloadReport() {

  const {t} = useTranslation();




  const containerRef = useRef(null);

  const customContainer = () => {
    return document.getElementById("custom-container");
  };

  const revenueReportForm = useFormik({
    initialValues: { created: [], type: "sales" },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().trim()
        .required("type_required")
    }),
    onSubmit: (values , {resetForm}) => {
      const formData = new FormData();
      formData.append("created", values.created);
      formData.append("type", values.type);
      revenueReport(formData)
        .then((response) => {
          // console.log(response.data);
          if(values.type==="sales"){
         
            handleSalesExportToExcel(response?.data?.dailyData || [] ,response?.data?.totalValues ||[] );
          }
          else{
            handleExportToExcel(response?.data?.transactions ? response?.data?.transactions : []);
          }
          // resetForm();

        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const handleDateChange = (value) => {
    revenueReportForm.setFieldValue("created" , value) 
  

  };




  const handleSalesExportToExcel = (reportData=[] , totalData=[]) => {
    // Prepare the data for export
    const headers = [
      t('date'),
      t('totalTransactions'),
      t('totalSales'),
      t('totalCostPrice'),
      t('totalProfit'),
      t('profitRate'), // Will show percentage
      t('totalCash'),
      t('totalCredit'),
      t('totalOther'),
      t('totalCustomers'),
      t('men'),
      t('women'),
      t('orderPerCustomer')
    ];
  
    const rows = reportData.map((item) => [
      formateDateWithMonthForGraph(item?.date),
      item?.totalTransactions,
      `¥${showPrice(item?.totalSales)}`,
      `¥${showPrice(item?.totalCostPrice)}`,
      `¥${showPrice(item?.totalProfit)}`,
      item.profitRate ? `${item.profitRate.toFixed(2)}%` : '0%',
      `¥${showPrice(item?.totalCash)}`,
      `¥${showPrice(item?.totalCredit)}`,
      `¥${showPrice(item?.totalOther)}`,
      item?.totalCustomers,
      item?.men,
      item?.women,
      `¥${showPrice(item?.orderPerCustomer)}`
    ]);
  
    // Add total row at the end
    if (totalData) {
      rows.push([
        t('total_label'),
        totalData?.totalTransactions,
        `¥${showPrice(totalData?.totalSales)}`,
        `¥${showPrice(totalData?.totalCostPrice)}`,
        `¥${showPrice(totalData?.totalProfit)}`,
        totalData.profitRate
          ? `${totalData.profitRate.toFixed(2)}%`
          : '0%',
        `¥${showPrice(totalData?.totalCash)}`,
        `¥${showPrice(totalData?.totalCredit)}`,
        `¥${showPrice(totalData?.totalOther)}`,
        totalData?.totalCustomers,
        totalData?.men || 0,
        totalData?.women || 0,
        `¥${showPrice(totalData?.orderPerCustomer)}`
      ]);
    }
  
    writeMyExcel([headers, ...rows], "daily_sale_report.xlsx");
  };


  const handleExportToExcel = (data) => {
    // Prepare the data for export
    const headers = [
      t("date_time_label"),
      t("label_slip_number"),
      t("label_table_no"),
      t("title_amount"),
      t("label_choose_payment_method"),
      t("label_cashier_manager"),
      t("label_no_of_people"),
      t('label_type'),
      t('label_status'),
      t("lable_comments"),
   
    ];

    const rows = data.map((item) => [
      getDateInFormat(item?.createdAt),
      String(item?.slip_number)?.padStart(4, "0") || "",
      TrimText(item?.tableDetail?.table_number, 20),
      `¥${showPrice(item?.amount_after_discount || 0, 2)}`,
      capitalizeFirstLetter(item?.payment_method),
      item?.cashierDetail?.name,
      item?.number_of_persons,
      item.type==="restaurant_order"?t('label_sales'): t('label_cancelled') ,
      item.is_edited===true?item.type === "restaurant_order" ? t("label_edited") : t("label_cancelled"): t("label_success") ,
      item.comment && item.comment.length > 0 ? item?.comment : t("label_success"),
    ]);
   
    writeMyExcel([headers, ...rows], "transaction_history_report.xlsx");
  };

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t('label_download_report')}</h4>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <div className="card">
            <div className="card-body">
              <form onSubmit={revenueReportForm.handleSubmit}>
                <div className="calendar-box d-flex ">
                  <DateRangePicker
                    open={true}
                    showHeader={false}
                    locale={{
                      sunday: 'Sun',
                      monday: 'Mon',
                      tuesday: 'Tue',
                      wednesday: 'Wed',
                      thursday: 'Thu',
                      friday: 'Fri',
                      saturday: 'Sat',
                      monthFormat: 'MMMM yyyy',
                    }}
                    ranges={[]}
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      margin: "0 20px",
                      width: "500px",
                    }}
                    cleanable={false}
                    editable={false}
                    disabledDate={(date) => date > new Date()}
                    defaultCalendarValue={[new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()]}
                    container={() => containerRef.current}
                    // onChange={(value) => {
                    //   console.log("changed" , value);
                    //   handleDateChange(value)
                    // }}
                    onChange={handleDateChange}
                    renderTitle={(date) => {
                      return <span>{date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>;
                    }}
                   
                  />
      
                  <div id="custom-container" ref={containerRef}></div>
                </div>
                <div className="radio-btns-list d-flex flex-column gap-3 py-3">
                  <div class="form-check">
                    <input
                      className="form-check-input cp"
                      type="radio"
                      id="sales"
                      value="sales"
                      name="type"
                      checked={revenueReportForm.values.type === "sales"}
                      onChange={revenueReportForm.handleChange}
                      onBlur={revenueReportForm.handleBlur}
                    />
                    <label class="form-check-label cp" for="sales">
                      {t('label_daily_sales_basis')}
                    </label>
                  
                  </div>
                  <div class="form-check">
                    <input
                      className="form-check-input cp"
                      type="radio"
                      id="transaction"
                      value="transaction"
                      name="type"
                      checked={revenueReportForm.values.type === "transaction"}
                      onChange={revenueReportForm.handleChange}
                      onBlur={revenueReportForm.handleBlur}
                    />
                    <label class="form-check-label cp" for="transaction">
                      {t('label_each_transaction')}
                    </label>
                  </div>
                  <CustomError  className="mx-2 text-danger" name="type" form={revenueReportForm} />
                  <div>
                    <button className="btn btn-primary" type="submit">
                      {t('label_export')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DownloadReport;
