import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant"
const options = {role: ROLE.RESTAURANT};

export const updateUserProfile = async (values , o_id) => {
    return await axiosInstance(options).put(`${path}/user-profile/edit`, values);
}

export const updateInitialPassword = async (values) => {
  return await axiosInstance(options).patch(`${path}/user-profile/change-initial-password`, values);
}

export const updateUserPassword = async (values) => {
  return await axiosInstance(options).patch(`${path}/user-profile/update-password`, values);
}

export const userProfileDetails = async () => {
    return await axiosInstance(options).get(`${path}/user-profile/details`);
}

export const updateRestaurantProfile = async (values) => {
  return await axiosInstance(options).put(`${path}/profile/edit`, values);
}

export const restaurantProfileDetails = async () => {
    return await axiosInstance(options).get(`${path}/profile/details`);
}

export const contractDetails = async () => {
    return await axiosInstance(options).get(`${path}/contract/details`);
}
//restaurant/settings/details
export const getRestaurantSettings = async (data={}) => {
  return await axiosInstance({...options, ...data}).get(`${path}/settings/details`)
}
///settings/update-email-settings
export const UpdateEmailNotification = async (values) => {
  return await axiosInstance(options).patch(`${path}/settings/update-email-settings`,values);
}

export const cashierPasswordUpdate = async (values)=>{
  return await axiosInstance(options).patch(`${path}/profile/cashier-password` , values)
}

export const setFiscalYearSetting = async (values)=>{
  return await axiosInstance(options).patch(`${path}/profile/fiscal-year` , values)
}

export const updateTaxSettings = async (values) => {
  return await axiosInstance(options).post(`${path}/settings/edit-tax-setting`,values);
}

export const updatePrinterSettings = async (values) => {
  return await axiosInstance(options).patch(`${path}/settings/update-printer-settings`, values);
};

export const TestPrinterConnection = async (values) => {
  return await axiosInstance(options).post(`${path}/settings/check-connection`,values);
}