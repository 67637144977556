import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import Swal from "sweetalert2";
import * as globalSettingsService from "../../../services/globalsetting.services";
import Breadcrums from "../../../common/breadcrumbs";
import {
  DT,
  handleServerValidations,
} from "../../../../../utils/commonfunction";
import { PHONE_NO_LENGTH, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Loader from "../../../common/loader";
import { URL_REGEX, amountRegex, MOBILE_NUMBER_REGEX } from "../../../../../utils/Constants";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import PhoneInput from "../../../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../../../utils/commonValidations";

const GlobalSettings = (props) => {
  const {t} = useTranslation();
  const [showdefault, setShowDefault] = useState({});
  // const [ setphone_numberNumber] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    { title: t("sidebar_link_global_settings"), url: "" },
  ];

  useEffect(() => {
    globalSettingsService
      .Details()
      .then((response) => {
        setShowDefault(response && response.data ? response?.data : []);
        // setphone_numberNumber(response?.data?.phone_number_no);
        // console.log("🚀 ~ .then ~ response:", response.data);
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
        setDataLoaded(true);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    socialMedia: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .required('validation_err_title_required')
          .max(20, 'validation_err_reached_maximum_length'),
        icon: Yup.string()
          .required('validation_err_icon_required')
          .max(50, 'validation_err_reached_maximum_length'),
        link: Yup.string()
          .required('validation_err_link_required')
          .max(50, 'validation_err_reached_maximum_length')
          .matches(URL_REGEX, 'invalid_web_url'),
      })
    ),
    AdminEmail: Yup.string()
      .trim()
      .required('label_email_error')
      .email('invalid_email'),
    phone_number: PHONE_VALIDATION,
    CopyRighttxt: Yup.string()
      .trim()
      .required('copyright_error'),
    Address: Yup.string()
      .trim()
      .required('label_location_error')
      .max(100, 'validation_err_reached_maximum_length'),
    web: Yup.string()
      .trim()
      .required('website_error')
      .matches(URL_REGEX, 'invalid_web_url'),
    title: Yup.string()
      .trim()
      .required('label_title_error')
      .max(50, 'label_title_length_error_50'),
    monthly_restaurant_enrollment_cost: Yup.string()
      .trim()
      .required('monthly_enrollment_error')
      .matches(amountRegex, 'label_invalid_amount_error'),
    yearly_restaurant_enrollment_cost: Yup.string()
      .trim()
      .required('yearly_enrollment_error')
      .matches(amountRegex, 'label_invalid_amount_error'),
  });



  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          validateOnChange={true}
          enableReinitialize
          initialValues={{
            Address: showdefault && showdefault.address ? showdefault.address : "",
            // smtp_username:
            //   showdefault && showdefault.smtp_username
            //     ? showdefault.smtp_username
            //     : "",
            AdminEmail: showdefault && showdefault.admin_email ? showdefault.admin_email : "",
            phone_number: showdefault && showdefault.phone_number ? showdefault.phone_number : "",
            CopyRighttxt: showdefault && showdefault.copy_right_text ? showdefault.copy_right_text : "",

            web: showdefault && showdefault.website ? showdefault.website : "",
            title: showdefault && showdefault.title ? showdefault.title : "",
            monthly_restaurant_enrollment_cost: showdefault && showdefault.monthly_restaurant_enrollment_cost ? showdefault.monthly_restaurant_enrollment_cost : "",
            yearly_restaurant_enrollment_cost: showdefault && showdefault.yearly_restaurant_enrollment_cost ? showdefault.yearly_restaurant_enrollment_cost : "",
            signup_option: showdefault && showdefault.signup_option ? showdefault.signup_option : false,
            socialMedia: showdefault && showdefault.social_media_details && showdefault?.social_media_details?.length ? showdefault.social_media_details : [{ title: "", link: "", icon: "" }],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitted(true);
            let formData = new FormData();
            formData.append("address", values.Address);
            formData.append("website", values.web);
            // formData.append("smtp_username", values.smtp_username);
            formData.append("admin_email", values.AdminEmail);
            formData.append("social_media_details", JSON.stringify(values.socialMedia));
            formData.append("phone_number", values.phone_number);
            formData.append("copy_right_text", values.CopyRighttxt);
            formData.append("title", values.title);
            formData.append("monthly_restaurant_enrollment_cost", values.monthly_restaurant_enrollment_cost);
            formData.append("yearly_restaurant_enrollment_cost", values.yearly_restaurant_enrollment_cost);
            formData.append("signup_option", values.signup_option);
            // console.log("formData--->",formData)
            globalSettingsService
              .Add(formData)
              .then((response) => {
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  setTimeout(() => {
                    setSubmitted(false);
                  }, 2000);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  setSubmitted(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setFieldTouched }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log("error", errors)} */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">{t("sidebar_link_global_settings")}</h6>
                      </div>
                      <div className="row row-sm">
                        {/* <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="smtp_username"
                            className="text-left d-flex"
                          >
                            SMTP {t("label_username")}:<span className="requirestar">*</span>
                          </label>
                          <input
                            name="smtp_username"
                            type="text"
                            id="smtp_username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.smtp_username}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.smtp_username &&
                              touched.smtp_username &&
                              errors.smtp_username}
                          </span>
                        </div> */}
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="AdminEmail" className="text-left d-flex">
                            {t("role_super_admin")} {t("label_email")}:<span className="requirestar">*</span>
                          </label>
                          <input
                            name="AdminEmail"
                            type="text"
                            id="AdminEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AdminEmail}
                            className="form-control"
                            placeholder={t("placeholder_email_address")}
                          />
                          <span className="text-danger d-flex text-left">{errors.AdminEmail && touched.AdminEmail && t(errors.AdminEmail)}</span>
                        </div>
                        {/* <div className="col-md-12 text-center form-group">
                          <label htmlFor="phone_number" className="text-left d-flex">
                            {t("label_phone_number_no")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className=" form-control d-flex">
                            <select
                              className="selectInput"
                              style={{
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginTop: 0,
                                border: 0,
                                width: 40,
                                backgroundColor: "white",
                              }}
                              value={values.countryCodePhone}
                              onChange={(event) => {
                                setFieldValue(
                                  "countryCodePhone",
                                  event.target.value
                                );
                                setFieldValue(
                                  "dialCodePhone",
                                  "+" +
                                    getCountryCallingCode(event.target.value)
                                );
                              }}
                            >
                              {getCountries().map((country) => (
                                <>
                                  <option key={country} value={country}>
                                    <ReactCountryFlag countryCode={country} />
                                    {showDropDown(country)}
                                  </option>
                                </>
                              ))}
                            </select>
                            <label
                              style={{ padding: 0, margin: 0, paddingTop: 2 }}
                            >
                              {values.dialCodePhone}
                            </label>
                            <input
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "white",
                              }}
                              className="input_phone_number"
                              name="phone_number"
                              id="phone_number"
                              onChange={(event) => {
                                setFieldValue(`phone_number`, event.target.value);
                              }}
                              value={values.phone_number}
                              onKeyDown={blockInvalidChar}
                            />
                          </div> */}
                        {/* <input
                          type="text"
                          name="phone_number"
                          id="phone_number"
                          className="form-control"
                          placeholder="Enter phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyDown={blockInvalidChar}
                        /> 
                          <span className="text-danger d-flex text-left">
                            {errors.phone_number &&
                              touched.phone_number &&
                              errors.phone_number}
                          </span>
                            </div>*/}
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="phone_number" className="text-left d-flex">
                            {t("label_contact_no")}:<span className="requirestar">*</span>
                          </label>
                          <PhoneInput
                            name="phone_number"
                            type="text"
                            id="phone_number"
                            onChange={(e) => {
                              setFieldValue("phone_number", e?.phone_number);
                            }}
                            // onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone_number}
                            className="form-control"
                            placeholder={t("web_inquiry_form_placeholder_contact_number")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.phone_number && touched.phone_number && DT(t(errors.phone_number), { PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max })}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="Address" className="text-left d-flex">
                            {t("list_heading_address")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <textarea
                            name="Address"
                            id="Address"
                            type="text"
                            style={{ minHeight: "100px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Address}
                            className="form-control"
                            placeholder={t("placeholder_address")}
                          />
                          <span className="text-danger d-flex text-left">{errors.Address && touched.Address && t(errors.Address)}</span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="web" className="text-left d-flex">
                            {t("label_website")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <input name="web" id="web" type="text" onChange={handleChange} onBlur={handleBlur} value={values.web} className="form-control" placeholder={t("website_placeholder")} />
                          <span className="text-danger d-flex text-left">{errors.web && touched.web && t(errors.web)}</span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="CopyRighttxt" className="text-left d-flex">
                            {t("label_copy_right_text")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="CopyRighttxt"
                            type="text"
                            id="CopyRighttxt"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.CopyRighttxt}
                            className="form-control"
                            placeholder={t("copy_right_text_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">{errors.CopyRighttxt && touched.CopyRighttxt && t(errors.CopyRighttxt)}</span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("title")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="title"
                            type="text"
                            id="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                            placeholder={t("page_title_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">{errors.title && touched.title && t(errors.title)}</span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="monthly_restaurant_enrollment_cost" className="text-left d-flex">
                            {t("monthly_enrollement_cost")}:<span className="requirestar">*</span>{" "}
                          </label>

                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">¥</span>
                            </div>
                            <input
                              name="monthly_restaurant_enrollment_cost"
                              type="text"
                              id="monthly_restaurant_enrollment_cost"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.monthly_restaurant_enrollment_cost}
                              className="form-control"
                              placeholder={t("monthly_cost_placeholder")}
                            />
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.monthly_restaurant_enrollment_cost && touched.monthly_restaurant_enrollment_cost && t(errors.monthly_restaurant_enrollment_cost)}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="yearly_restaurant_enrollment_cost" className="text-left d-flex">
                            {t("yearly_enrollement_cost")}:<span className="requirestar">*</span>{" "}
                          </label>

                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">¥</span>
                            </div>
                            <input
                              name="yearly_restaurant_enrollment_cost"
                              type="text"
                              id="yearly_restaurant_enrollment_cost"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.yearly_restaurant_enrollment_cost}
                              className="form-control"
                              placeholder={t("yearly_cost_placeholder")}
                            />
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.yearly_restaurant_enrollment_cost && touched.yearly_restaurant_enrollment_cost && t(errors.yearly_restaurant_enrollment_cost)}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label className="text-left d-flex">
                            {t("show_sign_up_page")} <span className="requirestar">*</span>
                          </label>
                          <div className="d-flex ">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="signup_option"
                                id="ShowSignUpPageYes"
                                value={values.signup_option}
                                onChange={() => setFieldValue("signup_option", true)}
                                onBlur={handleBlur}
                                checked={values.signup_option === true}
                              />
                              <label className="form-check-label" htmlFor="ShowSignUpPageYes">
                                {t("btn_yes")}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="signup_option"
                                id="ShowSignUpPageNo"
                                value={values.signup_option}
                                onChange={() => setFieldValue("signup_option", false)}
                                onBlur={handleBlur}
                                checked={values.signup_option === false}
                              />
                              <label className="form-check-label" htmlFor="ShowSignUpPageNo">
                                {t("btn_No")}
                              </label>
                            </div>
                          </div>
                         
                        </div>

                        {/* ---- */}

                        <label className="text-left d-flex">{t("label_social_media")}:</label>
                        <FieldArray
                          name="socialMedia"
                          render={(arrayHelper) => (
                            <div className="col-12 mb-4">
                              <div className="row">
                                {values?.socialMedia && values?.socialMedia?.length >= 0 ? (
                                  values?.socialMedia.map((item, index) => (
                                    <div className="col-12 " key={index}>
                                      <div className="w-100 p-2 bg-group">
                                        <div className="row">
                                          <div className="col-md-3 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="title"
                                              name={`socialMedia[${index}].title`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.title}
                                              placeholder={t("input_placeholder_title")}
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched?.socialMedia && touched?.socialMedia[index] && touched?.socialMedia[index].title) || submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                t(errors.socialMedia[index].title)}
                                            </span>
                                          </div>
                                          <div className="col-md-4 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="link"
                                              name={`socialMedia[${index}].link`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.link}
                                              placeholder={t("input_placeholder_link")}
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched.socialMedia && touched.socialMedia[index] && touched.socialMedia[index].link) || submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                t(errors.socialMedia[index].link)}
                                            </span>
                                          </div>
                                          <div className="col-md-3 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="icon"
                                              name={`socialMedia[${index}].icon`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.icon}
                                              placeholder={t("input_placeholder_icon")}
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched.socialMedia && touched.socialMedia[index] && touched.socialMedia[index].icon) || submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                t(errors.socialMedia[index].icon)}
                                            </span>
                                          </div>
                                          <div className="col-md-2">
                                            <div className="social-media-action">
                                              <button
                                                className="btn btn-danger "
                                                type="button"
                                                disabled={values?.socialMedia?.length === 1}
                                                onClick={() => arrayHelper.remove(index)} // remove a friend from the list
                                              >
                                                {t("link_remove")}
                                              </button>
                                              {index === values?.socialMedia?.length - 1 && (
                                                <button
                                                  className="btn btn-main-primary signbtn ms-2 "
                                                  type="button"
                                                  onClick={() =>
                                                    arrayHelper.insert(values?.socialMedia?.length, {
                                                      title: "",
                                                      link: "",
                                                      icon: "",
                                                    })
                                                  }
                                                >
                                                  {t("link_add")}
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <button className="btn btn-main-primary signbtn mr-2 ms-2 mb-3" type="button" onClick={() => arrayHelper.push({})}>
                                    Add a social media
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        />

                        {/* {hasPermission("/admin/cms/settings/submit") ? ( */}
                        <div className="">
                          <button
                            className="btn btn-main-primary signbtn mr-2"
                            type="submit"
                            disabled={submitted ? true : null}
                            onClick={() => {
                              setSubmitClicked(true);
                            }}
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            {t("btn_submit")}
                          </button>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default GlobalSettings;


