import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateNetworkSetting } from "../../../services/table.services";
import { addRestData } from "../../../../../redux/slices/restSettings";


export default function NetworkSetting() {
  const { t } = useTranslation();
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);
  const dispatch = useDispatch();
  const [networkSpeed , setNetworkSpeed] = useState("");


  // const updateSetting = (networkType) => {
  //   // console.log(networkType);
   
  //     checkSpeed(); 
   
  //   updateNetworkSetting({network_setting_wifi: networkType}).then(() => {
  //     const updatedData = { ...restSettingDetails, network_setting_wifi: networkType };
  //     dispatch(addRestData(updatedData));
  //   });
  // };



  const checkSpeed = async () => {
    const fileUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/configs/default/default_user_profile.png`; // ~100KB file
    const fileSizeInBits = 100 * 8 * 1024; // Approximate file size in bits

    const startTime = performance.now();
    try {
        const response = await fetch(fileUrl, { cache: "no-store" });
        await response.blob(); // Ensures full download
        const endTime = performance.now();

        const durationInSeconds = (endTime - startTime) / 1000;
        const speedMbps = (fileSizeInBits / durationInSeconds) / (1024 * 1024); // Convert to Mbps

        setNetworkSpeed(speedMbps.toFixed(2));
    } catch (error) {
        console.error("Speed test failed:", error);
        setNetworkSpeed("Error testing speed");
    }
};

useEffect(()=>{
  checkSpeed();
},[])

  return (
    <div>
      <div className="card-outer-header d-flex justify-content-between align-items-center">
        <h4 className="heading-title-text">{t("label_network_settings")}</h4>
      </div>

      <div className="dashboard-content-wraper mt-4">
        {/* LAN Option */}
        {/* <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">{t("label_lan")}</h6>
              <div className="form-check form-switch">
                <input
                  className="form-check-input cp"
                  type="checkbox"
                  // checked={restSettingDetails?.network_setting === "lan"}
                  // onChange={() => updateSetting("lan")}
                  disabled
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* WiFi Option */}
        <div className="card">
          <div className="card-body">
            {/* <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">{t("label_wifi")}</h6>
              <div className="form-check form-switch">
                <input
                  className="form-check-input cp"
                  type="checkbox"
                  checked={restSettingDetails?.network_setting_wifi === true}
                  onChange={() => updateSetting(!restSettingDetails?.network_setting_wifi)}
                  // checked= {true}
                />
              </div>
            </div> */}
            <div className="d-flex align-items-center">
              <h6 className="badge bg-success me-3 mb-0">{t('label_connected')}</h6>
              <span className="fs-18">
                {t('label_network_speed')} <strong>{networkSpeed} Mbps.</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
