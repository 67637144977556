import React, { useEffect, useMemo, useRef, useState } from 'react'
import { GlobalSettingsDetails } from './website/services/website.services';
import { useDispatch } from 'react-redux';
import { addData } from '../redux/slices/globalDetails';
import { Outlet, useNavigate } from 'react-router-dom';
import offlineImg from '../assets/website/images/offline.png'
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

export default function GlobalLayout() {
    const offlineModalOpen = useRef();
    const offlineModalClose = useRef();
    const navigate = useNavigate();
    window.navigate_ = navigate;
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(true);
    const {t}= useTranslation();
    useMemo(() => {
        GlobalSettingsDetails().then(async (globalDetails) => {
            dispatch(addData(JSON.stringify(globalDetails?.data)));
        });
    }, []);
    const isNetworkConnected = async () => {
        fetch('https://www.google.com/')
          .then(response => {
            if (response.ok) {
                setIsConnected(true);
                offlineModalClose.current?.click();
            }
          })
          .catch(error => {
            return false;
          });
      };

      const handleOnline = () => {
        offlineModalClose.current?.click();
      };
      const handleOffline = () => {
        offlineModalOpen.current?.click();
      };
      useEffect(() => {    
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
    
        return () => {
          window.removeEventListener("online", handleOnline);
          window.removeEventListener("offline", handleOffline);
        };
      }, []);
  return <>
  <Outlet/>

<button type="button" ref={offlineModalOpen} className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
 
</button>


<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content offline-modal-content">
    <button type="button" class="btn-close d-none" ref={offlineModalClose} data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body">
        <div className="w-100 d-flex justify-content-center py-5">
            <div className='text-center'>
                <img className='offline-modal-img' src={offlineImg} alt="Offline" />
                <h3 className='fs-22'>{t('label_no_internet')}</h3>
                <p>{t('label_check_internet_text')}</p>
                <button onClick={isNetworkConnected} className='btn btn-primary mt-2'>{t('btn_retry')}</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
}
