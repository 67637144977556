import React, { useEffect, useMemo, useRef, useState } from 'react';
import card1 from '../../../assets/cashier/images/card-img.svg';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import visa from '../../../assets/cashier/images/card.png';
import linepay from '../../../assets/cashier/images/line-pay.png';

// import HomeIcon from '../../../assets/cashier/images/home-img.svg';
// import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
// import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
// import AmericanCard from '../../../assets/cashier/images/american-card.png';
// import amex from '../../../assets/cashier/images/amex.png';
// import diners from '../../../assets/cashier/images/diners.png';
// import JcbCard from '../../../assets/cashier/images/jcb-card.png';
// import Mastercard from '../../../assets/cashier/images/mastercard.png';
// import aupay from '../../../assets/cashier/images/au-pay.png';
// import merupay from '../../../assets/cashier/images/meru-pay.png';
// import paypay from '../../../assets/cashier/images/pay-pay.png';
// import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
// import dbarai from '../../../assets/cashier/images/d-barai.png';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { detail, payByCash, PaymentMethodList } from '../services/table.services';
import { LOADER_TIMEOUT_TIME, NO_CARD_SELECTED, NO_OTHER_PAYMENT_SELECTED, PAYMENT_METHOD_TYPES, ROLE, SWAL_SETTINGS } from '../../../utils/Constants';
import RecordNotFound from '../../restaurant/shared/recordNotFound';
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { capitalizeFirstLetter, DT, getLocalKey, getSubTotalQRApp, handleServerValidations, showPrice, TrimText } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
// import { Modal } from 'bootstrap';

const keypadButtons = [
  '1', '2', '3',
  '4', '5', '6',
  '7', '8', '9',
  '0', '.', 'C'
];

export default function PaymentViaCash() {
  const [amountReceived, setAmountReceived] = useState(0);
  const [creditCardPaymentMethod, setCreditCardPaymentMethod] = useState([]);
  const [otherPaymentMethod, setOtherPaymentMethod] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const amountReceivedRef = useRef(null);
  const [tableDetail, setTableDetail] = useState({});
  const params = useParams();
  const [orderPriceDetail, setOrderPriceDetail] = useState(null);
  const restaurantData = getLocalKey(ROLE.CASHIER) ? JSON.parse(getLocalKey(ROLE.CASHIER)) : {};
  const cashierData = getLocalKey('cashierDetail') ? JSON.parse(getLocalKey('cashierDetail')) : {};
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [giftCardApplied, setGiftCardApplied] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedPaymentMethod , setSelectedPaymentMethod]= useState("");
  const [selectedPaymentMethodDetail , setSelectedPaymentMethodDetail] = useState(null);
  const closeModalFirst = useRef();
  const closeModal = useRef();
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);
  const  payByOther = useRef();
  const  payByCard = useRef();


  console.log(restSettingDetails)


  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        setTableDetail(response?.data);
        if (response?.data?.is_available === false) {
          const combinedOrderedItems = [...response?.data?.activeOrder?.ordered_items];
          response?.data?.activeOrder?.subOrders.forEach(subOrder => {
            combinedOrderedItems.push(...subOrder.ordered_items);
          });
          if (response?.data?.activeOrder?.gift_card_applied) {
            setGiftCardApplied(true);
          }
          if (response?.data?.activeOrder?.coupon_code_applied) {
            setCouponCodeApplied(true);
          }
          handelOrderCalculations(response?.data?.activeOrder);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }


  const handelOrderCalculations = (orderDetail, coupon_code_applied) => {
    let data = {
      subtotal: 0,
      tax_price: 0,
      grand_total: 0,
      total_tax_8: 0,
      total_tax_10: 0,
      service_charge: 0,
      great_grand_total: 0,
      amount_after_discount: 0,
      coupon_code_applied: coupon_code_applied,
      coupon_discount: orderDetail?.coupon_discount,
      coupon_type: orderDetail?.coupon_type , 
      coupon_code_amount : orderDetail.coupon_code_amount, 
      gift_card_amount : orderDetail.gift_card_amount
    };
    const subOrders = orderDetail?.subOrders;
    // console.log(orderDetail , "check")
    if (orderDetail) {
      data.subtotal += orderDetail?.subtotal;
      data.tax_price += orderDetail?.tax_price;
      // data.total_tax_8 += orderDetail?.total_tax_8;
      // data.total_tax_10 += orderDetail?.total_tax_10;
      orderDetail.ordered_items.forEach((item) => {
        if (item.consumption_tax_rate === 8) {
          data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
        } else if (item.consumption_tax_rate === 10) {
          data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
        }
      })
      data.grand_total += orderDetail?.grand_total;
      data.service_charge += orderDetail?.service_charge;
    }
    if (subOrders && subOrders.length) {
      subOrders.forEach((order) => {
        if (order) {
          console.log(order)
          data.subtotal += order?.subtotal;
          data.tax_price += order?.tax_price;
          // data.total_tax_8 += order?.total_tax_8;
          // data.total_tax_10 += order?.total_tax_10;
          data.grand_total += order?.grand_total;
          data.service_charge += order?.service_charge;

          

          order.ordered_items.forEach((item) => {
            if (item.consumption_tax_rate === 8) {
              data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
            } else if (item.consumption_tax_rate === 10) {
              data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
            }
          })


        }
      });
    }
      data.great_grand_total = data.subtotal + data.service_charge + data.tax_price;
      data.amount_after_discount = data.great_grand_total;
    if (orderDetail?.gift_card_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.gift_card_amount

    }

    if (orderDetail?.coupon_code_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.coupon_code_amount;
    }

    setOrderPriceDetail(data);
  };

  useEffect(() => {
    if (params.tableId) {
      getTableDetail(params.tableId);
    }
  }, [params.tableId]);

  const handleKeypadClick = (value) => {
    console.log(value, "button value");
  
    if (value === 'C') {
      setAmountReceived(0);
    } else if (value === '.' && String(amountReceived).includes('.')) {
      // Prevent multiple decimal points
      return;
    } else {
      setAmountReceived((prev) => {
        const newValue = prev === 0 ? String(value) : String(prev) + value;
        return newValue;
      });
    }
  };
  const keyboardHandleMouseDown = (event) => {
    event?.target?.classList?.add('active');
  };
  const keyboardHandleMouseUp = (event) => {
    event?.target?.classList?.remove('active');
  };


  useEffect(() => {
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.CREDIT_CARD }).then((response) => {
      setCreditCardPaymentMethod(response.data.list);
    }).catch((err) => {
      console.error(err)
    })
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.QR_PAYMENTS }).then((response) => {
      setOtherPaymentMethod(response.data.list);
    }).catch((err) => {
      console.error(err)
    })
  }, []);

  useEffect(() => {
    const element = amountReceivedRef.current;
    if (element) {
      const adjustFontSize = () => {
        element.classList.remove(
          'font-size-30px',
          'font-size-25px',
          'font-size-20px',
          'font-size-15px',
          'font-size-12px'
        );
        if (element.scrollWidth > 200) {
          element.classList.add('font-size-12px');
        } else if (element.scrollWidth > 150) {
          element.classList.add('font-size-15px');
        } else if (element.scrollWidth > 100) {
          element.classList.add('font-size-20px');
        } else if (element.scrollWidth > 50) {
          element.classList.add('font-size-25px');
        } else {
          element.classList.add('font-size-30px');
        }
      };

      adjustFontSize();
      window.addEventListener('resize', adjustFontSize);
      return () => {
        window.removeEventListener('resize', adjustFontSize);
      };
    }
  }, [amountReceived]);



  const handlePayment = (item) => {
  
  
    let newAmountReceived = 0;
  
    if (item && Object.keys(item).length > 0) {
      setSelectedPaymentMethod(item._id);
    
      newAmountReceived = (giftCardApplied || couponCodeApplied)
       ? showPrice(orderPriceDetail?.amount_after_discount)
       : showPrice(orderPriceDetail?.great_grand_total);
  
     setAmountReceived(newAmountReceived); // Asynchronous update
  
     closeModalFirst.current.click();
     closeModal.current.click();

     console.log("inside if , item",item)
    }

    else{
      newAmountReceived = amountReceived;
    }

    if(Number(newAmountReceived)===0){
      if(giftCardApplied || couponCodeApplied){
        newAmountReceived =orderPriceDetail.amount_after_discount 
      }
      else{
        newAmountReceived = orderPriceDetail.great_grand_total
      }
      
    }

  
  if (
    Number(newAmountReceived) < Number(showPrice(
      (giftCardApplied || couponCodeApplied) 
        ? orderPriceDetail.amount_after_discount 
        : orderPriceDetail.great_grand_total
    ))
  )  {
      Swal.fire({
        icon: "error",
        text: DT(
          t("msg_amount_not_equal_total"),
          [
            showPrice(newAmountReceived),
            ((!giftCardApplied && !couponCodeApplied)
              ? showPrice(orderPriceDetail.great_grand_total)
              : showPrice(orderPriceDetail.amount_after_discount))
          ]
        ),
        ...SWAL_SETTINGS,
      });
      return;
    }
  
  
    else{
      let obj = {
        subtotal: orderPriceDetail?.subtotal,
        tax_price: orderPriceDetail?.tax_price,
        total_tax_8: orderPriceDetail?.total_tax_8,
        total_tax_10: orderPriceDetail?.total_tax_10,
        grand_total: orderPriceDetail?.grand_total,
        great_grand_total: orderPriceDetail?.great_grand_total,
        service_charge: orderPriceDetail?.service_charge,
        received_amount: newAmountReceived, // Use the local variable
        table_id: params?.tableId,
        cashier_id: cashierData?._id,
        order_id: tableDetail?.activeOrder?._id,
        giftCardApplied: giftCardApplied,
        couponCodeApplied: couponCodeApplied,
        amount_after_discount: giftCardApplied || couponCodeApplied
          ? orderPriceDetail?.amount_after_discount
          : orderPriceDetail?.great_grand_total,
        gift_card_amount: orderPriceDetail?.gift_card_amount,
        coupon_code_amount: orderPriceDetail?.coupon_code_amount,
        coupon_code: tableDetail?.activeOrder?.coupon_code,
      };
  
       if (item?.payment_method_type === "credit_card") {
          obj.payment_method = "card";
          obj.method_name = item.payment_type;
          obj.payment_method_id = item._id;
        
        }
        if (item?.payment_method_type === "qr_payments") {
          obj.payment_method = "other";
          obj.method_name = item.payment_type;
          obj.payment_method_id = item._id;
        
        }
        setLoader(true);
        payByCash(obj)
          .then((response) => {
            if (response.success) {
              setTimeout(() => {
                setLoader(false);
                navigate(`/cashier/payment-success/${tableDetail?.activeOrder?._id}`);
              }, LOADER_TIMEOUT_TIME);
            } else {
              setTimeout(() => {
                setLoader(false);
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }, LOADER_TIMEOUT_TIME);
            }
          })
          .catch((error) => {
            setLoader(false);
            console.error("error--->", error);
          });
      }
  
    };
    
  
  /** Attaching the hardware keyboard with virtual keyboard */
  useEffect(()=>{
    function handleKeyEvents(event){
      if(window.location.pathname?.includes("/cashier/payment-via-cash")){
        const allNumbers = ["0","1","2","3","4","5","6","7","8","9"];
        if(allNumbers.indexOf(event.key)>=0){
          const keyboardElement = document.getElementById(`keyboard-key-${event.key}`);
          if(keyboardElement){
            if(event.type === "keyup"){
              keyboardElement.click();
              keyboardElement.classList.remove("active");
            }else{
              keyboardElement.classList.add("active");
            }
          }
        }else if(event.key === "Backspace" || event.key === "Delete"){
          const keyboardElement = document.getElementById(`keyboard-key-C`);
          if(keyboardElement){
            if(event.type === "keyup"){
              keyboardElement.click();
              keyboardElement.classList.remove("active");
            }else{
              keyboardElement.classList.add("active");
            }
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyEvents);
    window.addEventListener('keyup', handleKeyEvents);
    return () => {
      window.removeEventListener('keydown', handleKeyEvents);
      window.removeEventListener('keyup', handleKeyEvents);
    };
  },[]);


  const openOtherPaymentModal = () => {
// console.log(amountReceived ,orderPriceDetail.great_grand_total ,orderPriceDetail.amount_after_discount )
let newAmount = 0;
newAmount = amountReceived

if(Number(amountReceived)===0){

  if(giftCardApplied || couponCodeApplied){
    newAmount = Number(orderPriceDetail.amount_after_discount);
    setAmountReceived(Number(orderPriceDetail.amount_after_discount) )
  }
  else{
    setAmountReceived( Number(orderPriceDetail.great_grand_total))
    newAmount =  Number(orderPriceDetail.great_grand_total)
  }
  
}

if (
  (!giftCardApplied && !couponCodeApplied && Number(newAmount) !== Number(showPrice(orderPriceDetail.great_grand_total))) ||
  (giftCardApplied && couponCodeApplied && Number(newAmount) !== Number(showPrice(orderPriceDetail.amount_after_discount)))
)  
    {
      Swal.fire({
        icon: "error",
        text:t("msg_amount_not_equal"),
        ...SWAL_SETTINGS,
      });
      return;
    }
    else{
     payByOther.current.click();
    }
  
   
  };

  const openCardModal = () => {
    let newAmount = 0;
    newAmount = amountReceived
    
    if(Number(amountReceived)===0){
    
      if(giftCardApplied || couponCodeApplied){
        newAmount = Number(orderPriceDetail.amount_after_discount);
        setAmountReceived(Number(orderPriceDetail.amount_after_discount) )
      }
      else{
        setAmountReceived( Number(orderPriceDetail.great_grand_total))
        newAmount =  Number(orderPriceDetail.great_grand_total)
      }
      
    }

    if (
      (!giftCardApplied && !couponCodeApplied && Number(newAmount) !== Number(showPrice(orderPriceDetail.great_grand_total))) ||
      (giftCardApplied && couponCodeApplied && Number(newAmount) !== Number(showPrice(orderPriceDetail.amount_after_discount)))
    )  
        {
          Swal.fire({
            icon: "error",
            text: t("msg_amount_not_equal"),
            ...SWAL_SETTINGS,
          });
          return;
        }

        else{

          payByCard.current.click();

        }
  
  
  };


  return (
    <div className="dashboard-cashier-wrap">
      {loader && (
        <div className="loader-box">
          <div className="loader-in">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stop-color="#FFFFFF"></stop>
                <stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop>
                <stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop>
                <stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop>
                <stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop>
              </radialGradient>
              <circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
                <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform>
              </circle>
              <circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle>
            </svg>
            <span>{t("label_waiting_payment")} </span>
          </div>
        </div>
      )}
      <div className="cashier-head text-center">
        <h4>{t("payment_label")}</h4>
        <div className="btns-head-in-left" onClick={() => navigate("/cashier/dashboard")}>
          <button className="btn btn-icon">
            <i className="ti ti-arrow-left"></i>
          </button>
        </div>
      </div>
      <div className="payment-wraper">
        <div className="payment-wraper-in">
          <div className="paymnet-typing shadow-box">
            <span>{t("label_total")}</span>
            <h4>¥ {giftCardApplied || couponCodeApplied ? showPrice(orderPriceDetail?.amount_after_discount) : showPrice(orderPriceDetail?.great_grand_total)}</h4>
          </div>
          <div className="payment-keypad-box shadow-box">
            <div className="amount-received-box d-flex align-items-center justify-content-between">
              <h4>{t("label_amt_recieved")}</h4>
              <h5 ref={amountReceivedRef}>¥ {amountReceived}</h5>
            </div>
            <div className="payment-keypad">
              <div className="row">
                {keypadButtons.map((button, index) => (
                  <div className="col-md-4" key={index}>
                    <button
                      disabled={String(amountReceived).length >= 13 && button !== "C"}
                      className="cashier-key-button"
                      id={`keyboard-key-${button}`}
                      onClick={() => handleKeypadClick(button)}
                      onMouseDown={keyboardHandleMouseDown}
                      onMouseUp={keyboardHandleMouseUp}
                    >
                      {button}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="keypad-action-btn-left gap-3">
            {restSettingDetails?.credit_card_payments_acceptance && (
              <button className="keypad-action-btns" onClick={()=>openOtherPaymentModal()}>
                <img src={card1} />
                <span>{t("label_pay_via_other")}</span>
              </button>
            )}
            <span className='d-none' data-bs-toggle="modal" data-bs-target="#pay-with-other" ref={payByOther}></span>
            {restSettingDetails?.qr_code_payments_acceptance && (
              <button className="keypad-action-btns mt-5" onClick={()=>openCardModal()}>
                <img src={card2} />
                <span>{t("label_pay_via_card")}</span>
              </button>
            )}
              <span className='d-none' data-bs-toggle="modal" data-bs-target="#pay-card-modal" ref={payByCard}></span>
          </div>
          <div className="keypad-action-btn-right gap-3">
            <button className="keypad-action-btns" onClick={() => handlePayment(null)}>
              <img src={PayIcon} />
              <span>{t("label_pay_via_cash")}</span>
            </button>
          </div>

          <div className="modal fade modal-cashier" id="pay-card-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setSelectedPaymentMethod("");
                    }}
                  >
                    <i className="ti ti-arrow-left"></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    {t("label_choose_credit_card")}
                  </h1>
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeModalFirst}
                    onClick={() => {
                      setSelectedPaymentMethod("");
                    }}
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="payment-radio">
                    {creditCardPaymentMethod.length ? (
                      <div className="row">
                        {creditCardPaymentMethod.map((item, index) => (
                          <div
                            className="col-md-3"
                            key={index}
                            onClick={() => {
                              handlePayment(item);
                            }}
                          >
                            <div className="payment-radio-card">
                              <input type="radio" id={item.payment_type} name={item.payment_type} checked={selectedPaymentMethod === item?._id} />
                              <img src={item?.method_image} alt="card" />
                              <span>{TrimText(capitalizeFirstLetter(item.payment_type),7)}</span>
                            </div>
                          </div>
                        ))}

                        <div
                          className="col-md-3"
                          onClick={() => {
                            handlePayment(NO_CARD_SELECTED);
                          }}
                        >
                          <div className="payment-radio-card">
                            <input type="radio" id="card_not_selected" name="card_not_selected" checked={selectedPaymentMethod === "card_not_selected"} />
                            <img src={visa} alt="card" />
                            <span>{TrimText(t('label_no_selection'),7)}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <RecordNotFound heading="no_staff_found" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal-cashier" id="pay-with-other" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setSelectedPaymentMethod("");
                    }}
                  >
                    <i className="ti ti-arrow-left"></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    {t("label_choose_payment_method")}
                  </h1>
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeModal}
                    onClick={() => {
                      setSelectedPaymentMethod("");
                    }}
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="payment-radio payment-method-radio">
                    <div className="payment-radio">
                      {otherPaymentMethod.length ? (
                        <div className="row">
                          {otherPaymentMethod.map((item, index) => (
                            <div
                              className="col-md-3"
                              key={index}
                              onClick={() => {
                                handlePayment(item);
                              }}
                            >
                              <div className="payment-radio-card">
                                <input type="radio" id={item.payment_type} name={item.payment_type} checked={selectedPaymentMethod === item?._id} />
                                <img src={item?.method_image} alt="card" />
                                <span>{TrimText(capitalizeFirstLetter(item.payment_type),7)}</span>
                              </div>
                            </div>
                          ))}
                          <div
                          className="col-md-3"
                          onClick={() => {
                            handlePayment(NO_OTHER_PAYMENT_SELECTED);
                          }}
                        >
                          <div className="payment-radio-card">
                            <input type="radio" id="other_payment_not_selected" name="other_payment_not_selected" checked={selectedPaymentMethod === "other_payment_not_selected"} />
                            <img src={visa} alt="card" />
                            <span>{TrimText(t('label_no_selection'),7)}</span>
                          </div>
                        </div>
                        </div>
                      ) : (
                        <RecordNotFound heading="no_staff_found" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
