import React, { useEffect, useState } from 'react' ; 
import { PaymentMethodList , addPaymentMethod , editPaymentMethod , deletePaymentMethod , paymentMethodDetails , changePaymentTypeStatus , changePaymentMethodStatus } from '../../../services/payment-method.services';
import RecordNotFound from '../../../shared/recordNotFound';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { LOADER_TIMEOUT_TIME,PAYMENT_METHOD_TYPES , SWAL_SETTINGS } from "../../../../../utils/Constants";
import { handleServerValidations } from "../../../../../utils/commonfunction";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import { Drawer, Tooltip, Whisper } from "rsuite";
import * as Yup from 'yup';
import CustomError from '../../../../../utils/customError';

function CreditCardPayment() {

    const [list , setList] = useState([]) ; 
    const [addDrawer , setAddDrawer] = useState(false);
    const [editDrawer , setEditDrawer] = useState(false);
    const [paymentType , setPaymentType] = useState({}) ; 
    const [paymentMethodStatus , setPaymentMethodStatus] = useState(false) ; 
    const [loader , setLoader] = useState(false);
    const{t}= useTranslation();
    const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
    const [editSubmissionLoader, setEditSubmissionLoader] = useState(false);
    const [previewimage, setPreviewImage] = useState("");
    

    useEffect(()=>{
        setLoader(true);
        PaymentMethodList({global_search : PAYMENT_METHOD_TYPES.CREDIT_CARD}).then((response)=>{
            setTimeout(()=>{setLoader(false)} , LOADER_TIMEOUT_TIME);
            setList(response.data.list);
            setPaymentMethodStatus(response?.data?.payment_acceptance?.credit_card_payments_acceptance);
        
        })
        .catch((err)=>{
        setTimeout(()=>{setLoader(false)} , LOADER_TIMEOUT_TIME);
        console.log(err)})
    } , [paymentType]) ; 

    const formAddPaymentType= useFormik({
        initialValues: { payment_type: "" , method_logo: ""},
        validationSchema: Yup.object({
            payment_type: Yup.string().trim()
              .required("payment_type_error"),
              method_logo: Yup.mixed()
              .nullable()
              .test(
                'fileType',
                'supported_file_error',
                value => !value || ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
              )
              .test(
                'fileSize',
                'image_max_size_error',
                value => !value || value.size <= 1.5 * 1024 * 1024
              ), 
          }),
          enableReinitialize: true,
        onSubmit: (values) => {
          setAddSubmissionLoader(true);
          const formData = new FormData();
          setPaymentType({payment_type: values.payment_type});
          formData.append("payment_method_type", PAYMENT_METHOD_TYPES.CREDIT_CARD);
          formData.append("payment_type", values.payment_type);
          formData.append("method_logo" , values.method_logo); 
          setPreviewImage("");
          addPaymentMethod(formData)
            .then((response) => {
              setAddSubmissionLoader(false);
              if (response.success) {
                formAddPaymentType.resetForm();
                setAddDrawer(false);
                setPaymentType({});
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              setAddSubmissionLoader(false);
              console.error("Error updating payment type", error);
            });
        },
      });

      const formEditPaymentType= useFormik({
        initialValues: { payment_type: "" , o_id : "" ,method_logo : "" },
        validationSchema: Yup.object({
            payment_type: Yup.string().trim()
              .required("payment_type_error"),
              method_logo: Yup.mixed()
              .nullable()
              .test(
                'fileType',
                'supported_file_error',
                value => !value || ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
              )
              .test(
                'fileSize',
                'image_max_size_error',
                value => !value || value.size <= 1.5 * 1024 * 1024
              ), 
          }),
          enableReinitialize: true,
        onSubmit: (values) => {
          const formData = new FormData();
          formData.append("payment_method_type", PAYMENT_METHOD_TYPES.CREDIT_CARD);
          formData.append("payment_type", values.payment_type);
          formData.append("o_id", values.o_id);
          formData.append("method_logo" , values.method_logo)
          setEditSubmissionLoader(true);
          setPreviewImage("");
          editPaymentMethod(formData)
            .then((response) => {
              setEditSubmissionLoader(false);
              if (response.success) {
                formEditPaymentType.resetForm();
                setEditDrawer(false);
                setPaymentType({});
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              setEditSubmissionLoader(false);
              console.error("Error updating payment type", error);
            });
        },
      });

      const handlePaymentEdit =  (o_id)=>{
            paymentMethodDetails(o_id).then((response)=>{
            setEditDrawer(true);
            formEditPaymentType.setFieldValue("payment_type" , response?.data?.payment_type);
            formEditPaymentType.setFieldValue("o_id" , response?.data?._id);
            setPreviewImage(response?.data?.method_image||"");
          
        }).catch((err)=>{console.log(err)})
      } ; 

      const handlePaymentDelete = (o_id) => {
        Swal.fire({
          title: t("msg_are_you_sure"),
          text: t("btn_delete_text"),
          imageUrl: trash,
          showCancelButton: true,
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d",
          confirmButtonText: t("btn_delete"),
          cancelButtonText: t("btn_cancel"),
          reverseButtons: true,
          customClass: {
            popup: "swal-restaurant",
            confirmButton: "btn btn-danger col",
            cancelButton: "btn btn-secondary col",
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            
            deletePaymentMethod({ o_id: o_id })
              .then((response) => {
                if (response.success) {
                  setPaymentType({});
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((error) => {
                console.error("Error deleting payment type", error);
                Swal.fire({
                  icon: "error",
                  text: "An error occurred while deleting the payment type.",
                  ...SWAL_SETTINGS,
                });
              });
          } else {
            // Swal.fire({
            //   icon: "info",
            //   text: t("delete_cancelled"),
            //   ...SWAL_SETTINGS,
            // });
          }
        }).catch((error) => {
          console.error("Error handling deletion confirmation", error);
        });
      };

      const handleChangeStatus = (item)=>{

         let dataForUpdate = {
            o_id : item._id , 
            status : item.status===1 ?0 : 1
        }
        changePaymentTypeStatus(dataForUpdate).then((response)=>{setPaymentType({});
        Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
    }).catch((err)=>{console.log(err)})


      }

      const handlePaymentMethodStatus = ()=>{
        paymentMethodStatus ? setPaymentMethodStatus(false) : setPaymentMethodStatus(true) ; 
        const data = {credit_card_payments_acceptance :paymentMethodStatus ?false : true }
        changePaymentMethodStatus(data).then((response)=>{

            setPaymentType({});
        Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });

        })
        .catch((err)=>{console.log(err)})
      }
      



  return (
    <main id='main-content'>
    <div className='inner-container'>
        <div className='page-header d-flex align-items-center'>
            <h4 className='heading-title-text'>{t('credit_card_payment')}</h4>
        </div>
        <div className='payment-method-ui'>
            <div className='card'>
                <div className='card-body'>
                  {loader ? <div>
                     <Skeleton height={60} count={5}/>

                  </div> :
                  <>
                  <div className='card-method-toggle d-flex justify-content-between'>
                      <div>
                          <h4>{t('accept_credit_card_heading')}</h4>
                          <p>{t('accept_credit_card_text')}</p>
                      </div>
                      <div className="form-check form-switch">
                          <input className="form-check-input cp" type="checkbox" checked={paymentMethodStatus} onChange={()=>{handlePaymentMethodStatus()}}/>
                      </div>
                  </div>
                  <div className='payment-method-list'>
                      <h4> {t('payment_type_label')}</h4>
                      <div className='payment-method-list-in'>
                          {list && list.length>0? list.map((item , index)=>{ return(<div className='payment-method-row d-flex' key={index}>
                              <div className="form-check">
                                  <input className="form-check-input cp" type="checkbox" id="opening_check" name="opening" onChange={()=>{handleChangeStatus(item)}} checked={item.status===1 ? true : false} disabled={!paymentMethodStatus}/>
                                  <label className="form-check-label cp" for="opening_check">{item.payment_type}</label>
                              </div>
                              <div className='payment-method-btns d-inline-flex gap-3'>
                                  <button className='btn btn-icon' onClick={()=>{handlePaymentEdit(item._id)}} disabled={!paymentMethodStatus}>
                                      <i className='ti ti-edit'></i>
                                  </button>
                                  <button className='btn btn-icon' onClick={()=>{handlePaymentDelete(item._id)}} disabled={!paymentMethodStatus}>
                                      <i className='ti ti-trash'></i>
                                  </button>
                              </div>
                          </div>)}) : <RecordNotFound heading='no_staff_found' /> }
                         
                      </div>
                      <button className='btn btn-primary mt-3' onClick={()=>{setAddDrawer(true)}} disabled={!paymentMethodStatus}>{t('add_more_btn')}</button>
                  </div>
                  </>
                  
                  }





                    
                </div>
            </div>
        </div>
    </div>

    {/* modal for add payment method  */}

    <Drawer open={addDrawer}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('add_payment_type')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setAddDrawer(false);
                formAddPaymentType.resetForm();
                setPreviewImage("")
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formAddPaymentType.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor='payment_type'> {t('payment_type_label')}</label>
              <input
                type="text"
                id = "payment_type"
                className="form-control"
                placeholder={t('payment_type_placeholder')}
                name="payment_type"
                value={formAddPaymentType?.values?.payment_type}
                onChange={formAddPaymentType.handleChange}
                onBlur={formAddPaymentType.handleBlur}
              />
              <CustomError  name="payment_type" form={formAddPaymentType} className="text-danger"/>
            </div>

            <div className="mb-3">
                  <label htmlFor="method_logo" className="text-left d-flex ">
                    <span className="form-label">{t("label_logo")}:{" "}</span>
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{t("image_support_tooltip")}</Tooltip>}>
                      <span className="field-more-info mt-1 ms-1 cp">?</span>
                    </Whisper>
                  </label>
                  <input
                    className="form-control imgInput"
                    id="method_logo"
                    name="method_logo"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      formAddPaymentType.setFieldValue("method_logo", event.target.files[0] || "");
                      event.target.files.length === 1 ? setPreviewImage(URL.createObjectURL(event.target.files[0])) : setPreviewImage("");
                    }}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="method_logo" form={formAddPaymentType} />
                  </span>

                  {previewimage ? (
                    <ul className="question-image-preview questions-ul">
                      <li className="pr_img_box">
                        <img src={previewimage} style={{ height: "100px" }} alt={"Profile_Img"} />
                        <div className="img_options">
                          <button
                            type="button"
                            className="text-danger"
                            onClick={() => {
                              // console.log(previewResImage , previewimage)
                              setPreviewImage("");
                              formAddPaymentType.setFieldValue("method_logo", null);
                              document.getElementById("method_logo").value = "";
                            }}
                          >
                            <i className="ri-delete-bin-6-fill"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>

            <div className="button-form">
              <button type="submit" className={"btn btn-primary w-100"+(addSubmissionLoader?" btn-loader":"")}>
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>


    {/* modal for edit payment method   */}

    <Drawer open={editDrawer}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('edit_payment_type')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setEditDrawer(false);
                formEditPaymentType.resetForm();
                setPreviewImage("");
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formEditPaymentType.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor='payment_type'> {t('payment_type_label')}</label>
              <input
                type="text"
                id = "payment_type"
                className="form-control"
                placeholder={t('payment_type_placeholder')}
                name="payment_type"
                value={formEditPaymentType?.values?.payment_type}
                onChange={formEditPaymentType.handleChange}
                onBlur={formEditPaymentType.handleBlur}
              />
               <CustomError  name="payment_type" form={formEditPaymentType} className="text-danger"/>
            </div>

            <div className="mb-3">
                  <label htmlFor="method_logo" className="text-left d-flex ">
                    <span className="form-label">{t("label_logo")}:{" "}</span>
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{t("image_support_tooltip")}</Tooltip>}>
                      <span className="field-more-info mt-1 ms-1 cp">?</span>
                    </Whisper>
                  </label>
                  <input
                    className="form-control imgInput"
                    id="method_logo"
                    name="method_logo"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      formEditPaymentType.setFieldValue("method_logo", event.target.files[0] || "");
                      event.target.files.length === 1 ? setPreviewImage(URL.createObjectURL(event.target.files[0])) : setPreviewImage("");
                    }}
                  />
                  <span className="text-danger d-flex text-left">
                    <CustomError name="method_logo" form={formEditPaymentType} />
                  </span>

                  {previewimage ? (
                    <ul className="question-image-preview questions-ul">
                      <li className="pr_img_box">
                        <img src={previewimage} style={{ height: "100px" }} alt={"Profile_Img"} />
                        <div className="img_options">
                          <button
                            type="button"
                            className="text-danger"
                            onClick={() => {
                              // console.log(previewResImage , previewimage)
                              setPreviewImage("");
                              formEditPaymentType.setFieldValue("method_logo", null);
                              document.getElementById("method_logo").value = "";
                            }}
                          >
                            <i className="ri-delete-bin-6-fill"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>

            <div className="button-form">
              <button type="submit" className={"btn btn-primary w-100"+(editSubmissionLoader?" btn-loader":"")}>
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>


</main>
  )
}

export default CreditCardPayment
