import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { userProfileDetails, updateUserProfile } from './../../../services/profile.services'
import CustomError from '../../../../../utils/customError'
import Swal from 'sweetalert2'
import { SWAL_SETTINGS, INPUT_MAX_25, PHONE_NO_LENGTH, TEXTAREA_MAX_LENGTH, LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants'
import { handleServerValidations, DT, globalLoader } from '../../../../../utils/commonfunction'
import * as Yup from 'yup'
import PhoneInput from '../../../../../utils/PhoneInput'
import Skeleton from 'react-loading-skeleton'

export default function RestMyProfile() {
    const { t } = useTranslation()
    const [defaultData, setDefaultData] = useState({});
    const fileSelectInput = useRef();
    const [loader, setLoader] = useState(true);
    const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);

    const validateSchema = Yup.object().shape({
        first_name: Yup.string().trim().max(INPUT_MAX_25, "max_length_error").required("label_first_name_error"),
        last_name: Yup.string().trim().max(INPUT_MAX_25, "max_length_error").required("label_last_name_error"),
        email: Yup.string()
            .trim()
            .email("label_email_invalid_format_error")
            .test('no-uppercase', "label_email_uppercase_error", function (value) {
                if (value) {
                    return !/[A-Z]/.test(value);
                }
                return true;
            })
            .required("label_email_required_error"),
        phone_number: Yup.string()
        .test('phone_required', 'label_phone_number_required_error', (value) => {
                const phoneNumber = (value.split(" "))[1];
                return phoneNumber && phoneNumber.length;
            }
          )
        .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
                const phoneNumber = (value.split(" "))[1];
                return phoneNumber && /^\d+$/.test(phoneNumber);
            }
          )
        .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
                const phoneNumber = (value.split(" "))[1];
                return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
            }
          )
        .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
                const phoneNumber = (value.split(" "))[1];
                return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
            }
          )
            ,
        address: Yup.string().max(TEXTAREA_MAX_LENGTH, DT("validation_max_input_characters", [TEXTAREA_MAX_LENGTH])).trim().required("label_location_error"),
        profile_image: Yup.string().required("label_profile_image_error"),
    })

    useEffect(() => {
        userProfileDetails().then((response) => {
            setTimeout(()=>{setLoader(false);} , LOADER_TIMEOUT_TIME) ;
            setDefaultData(response && response.data ? response?.data : {});
        }).catch((error) => {
            setTimeout(()=>{setLoader(false);} , LOADER_TIMEOUT_TIME) ;
            Swal.fire({
                icon: "error",
                text: handleServerValidations(error),
                ...SWAL_SETTINGS,
            });
        })
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: defaultData && defaultData.email || "",
            first_name: defaultData && defaultData.first_name || "",
            last_name: defaultData && defaultData.last_name || "",
            address: defaultData && defaultData.address || "",
            phone_number: defaultData && defaultData.phone_number || "",
            // phone_code: defaultData && defaultData.phone_code || "",
            profile_image: defaultData && defaultData.profile_image || "",
        },
        validationSchema: validateSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            // globalLoader(true);
            let formData = new FormData();
            formData.append("email", values.email);
            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("address", values.address);
            formData.append("phone_number", values.phone_number);
            formData.append("profile_image", values.profile_image);
            setAddSubmissionLoader(true);

            updateUserProfile(formData)
                .then((response) => {
                    setAddSubmissionLoader(false);
                    globalLoader(false)
                    setSubmitting(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    setAddSubmissionLoader(false);
                    globalLoader(false)
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(error),
                        ...SWAL_SETTINGS,
                    });
                    console.log("error===>", error)
                })
        }
    });

    const handleFileSelection = (event) => {
        if (event.target.files.length) {
            formik.setFieldValue("profile_image", event.target.files[0]);
        }
    };
    const handleFilePreview = (file) => {
        if (typeof file === "string") {
            return file;
        } else {
            return URL.createObjectURL(file);
        }
    };


    return (
        <main id='main-content'>
            <div className='inner-container'>
                <ul className="nav nav-pills gap-3 mb-3">
                    <li className="nav-item">
                        <Link className="nav-link active" to={'/restaurant/profile'}>{t("link_my_profile")}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={'/restaurant/profile/change-password'}>{t("change_password")}</Link>
                    </li>
                </ul>
                <div className='card'>
                    <div className='card-body'>
                        {loader ?
                            <div className='w-100'>
                                <Skeleton circle={true} style={{ width: "96px", height: "96px" }} />
                                <Skeleton className='mt-4' style={{ height: "30px" }} />
                                <Skeleton className='mt-4' style={{ height: "30px" }} />
                                <Skeleton className='mt-4' style={{ height: "30px" }} />
                            </div>
                            :
                            <form onSubmit={formik.handleSubmit}>
                                <div className='profile-photo-upload mb-3'>
                                    <div className='avatar avatar-xl'>
                                        <img alt='profile-img' src={handleFilePreview(formik?.values?.profile_image)} />
                                    </div>
                                    <button className='cp e_none' type='button' onClick={() => { fileSelectInput?.current?.click() }}>
                                        <input
                                            ref={fileSelectInput}
                                            id="profile_image"
                                            name="profile_image"
                                            accept="image/*"
                                            type='file'
                                            onChange={handleFileSelection}
                                        />
                                        <i className='ti ti-upload'></i>
                                    </button>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label htmlFor='first_name' className="form-label">{t("label_first_name")}<span>*</span></label>
                                            <input
                                                type="text"
                                                id='first_name'
                                                className="form-control"
                                                placeholder={t("placeholder_first_name")}
                                                name="first_name"
                                                value={formik.values.first_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.onBlur}
                                            />
                                            <CustomError name="first_name" form={formik} className="text-danger" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label htmlFor='last_name' className="form-label">{t("label_last_name")}<span>*</span></label>
                                            <input
                                                type="text"
                                                id='last_name'
                                                className="form-control"
                                                placeholder={t("placeholder_last_name")}
                                                name='last_name'
                                                value={formik.values.last_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <CustomError name="last_name" form={formik} className="text-danger" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label htmlFor='email' className="form-label">Email address <span>*</span></label>
                                            <input
                                                type="email"
                                                id='email'
                                                className="form-control"
                                                name='email'
                                                disabled
                                                value={formik.values.email}
                                            />
                                            <CustomError name="email" form={formik} className="text-danger" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label htmlFor='phone_number' className="form-label">{t("label_phone_number")}<span>*</span></label>
                                            <PhoneInput
                                                value={formik?.values?.phone_number}
                                                name={'phone_number'} onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }} onBlur={formik.handleBlur} />
                                            <CustomError name="phone_number" form={formik} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3 input-location">
                                            <label htmlFor='address' className="form-label">{t("label_restaurant_address")} <span>*</span></label>
                                            <input
                                                type="text"
                                                id='address'
                                                className="form-control"
                                                value={formik.values.address}
                                                name='address'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder={t("placeholder_address")}
                                            />
                                            <CustomError name="address" form={formik} className='text-danger' />

                                        </div>

                                    </div>
                                </div>
                                <div className='button-form'>
                                    <button className={"btn btn-primary "+(addSubmissionLoader?" btn-loader":"")}>{t("btn_update_profile")}</button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}
