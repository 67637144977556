import React, { useEffect, useState } from "react";
import { getRestaurantSettings, updateTaxSettings } from "../../../services/profile.services";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { DISPLAY_METHOD_TAX_OPTIONS, LOADER_TIMEOUT_TIME, SWAL_SETTINGS, TAX_ROUND_OFF_FRACTIONS } from "../../../../../utils/Constants";
import { handleServerValidations } from "../../../../../utils/commonfunction";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addRestData } from "../../../../../redux/slices/restSettings";

function Tax() {
  const [loader, setLoader] = useState(false);
  const [updateStatus , setUpdateStatus] = useState(false);
const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);



  const formTaxUpdate = useFormik({
    initialValues: {
      tax_when_register_product: "",
      display_method_product_price: "",
      icon_display_eligible_for_reduced_tax_rate: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setAddSubmissionLoader(true);
      const formData = new FormData();

      formData.append("tax_when_register_product", values.tax_when_register_product);
      formData.append("display_method_product_price", values.display_method_product_price);
      formData.append("icon_display_eligible_for_reduced_tax_rate", values.icon_display_eligible_for_reduced_tax_rate);
      updateTaxSettings(formData)
        .then((response) => {
          setAddSubmissionLoader(false);
          if (response.success) {
            setUpdateStatus(!updateStatus)
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          setAddSubmissionLoader(false);
          console.error("Error updating receipt", error);
        });
    },
  });

  useEffect(() => {
    setLoader(true);
    getRestaurantSettings()
      .then((response) => {
        setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        // console.log(response.data)
        dispatch(addRestData(response?.data));
        formTaxUpdate.setFieldValue("tax_when_register_product", response?.data?.tax_when_register_product);
        formTaxUpdate.setFieldValue("display_method_product_price", response?.data?.display_method_product_price);
        formTaxUpdate.setFieldValue("icon_display_eligible_for_reduced_tax_rate", String(response?.data?.icon_display_eligible_for_reduced_tax_rate));
      })
      .catch((err) => {
        setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        console.log(err);
      });
  }, [updateStatus]);
  return (
    <main id="main-content">
      <form onSubmit={formTaxUpdate.handleSubmit}>
        <div className="inner-container">
          <div className="page-header d-flex align-items-center">
            <h4 className="heading-title-text">{t('tax_label')} </h4>
          </div>
          {loader ? <div className='w-100'>
            <Skeleton className='mb-4' height={150} />
            <Skeleton className='mb-2' height={40} count={6} />
          </div> : <>
          <div className="tax-info-ui">
            <div className="tax-info-blk">
              <p>{t('round_off_text_heading')}</p>
              <div className="card">
                <div className="tax-info-row">
                  <div className="tax-info-blk-title">{t('registering_text')}</div>
                  <div className="tax-info-radio">
                    <div className="d-inline-flex gap-5">
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="truncate_product" name="tax_when_register_product"
                          value={TAX_ROUND_OFF_FRACTIONS.TRUNCATE}
                          onChange={(e) => formTaxUpdate.setFieldValue("tax_when_register_product", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.tax_when_register_product === TAX_ROUND_OFF_FRACTIONS.TRUNCATE}
                        />
                        <label className="form-check-label cp" htmlFor="truncate_product">
                          {t('truncate_label')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="round_up_product" name="tax_when_register_product"
                          value={TAX_ROUND_OFF_FRACTIONS.ROUND_UP}
                          onChange={(e) => formTaxUpdate.setFieldValue("tax_when_register_product", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.tax_when_register_product === TAX_ROUND_OFF_FRACTIONS.ROUND_UP} />
                        <label className="form-check-label cp" htmlFor="round_up_product">
                          {t('round_up_label')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="round_off_product" name="tax_when_register_product"
                          value={TAX_ROUND_OFF_FRACTIONS.ROUND_OFF}
                          onChange={(e) => formTaxUpdate.setFieldValue("tax_when_register_product", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.tax_when_register_product === TAX_ROUND_OFF_FRACTIONS.ROUND_OFF}
                        />
                        <label className="form-check-label cp" htmlFor="round_off_product">
                          {t('round_off_label')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tax-info-blk">
              <p>{t('display_method_label')}</p>
              <div className="card">
                <div className="tax-info-row">
                  <div className="tax-info-blk-title">{t('product_price_label')}</div>
                  <div className="tax-info-radio">
                    <div className="d-inline-flex gap-5">
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="tax_excluded" name="display_method_product_price"
                          value={DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED}
                          onChange={(e) => formTaxUpdate.setFieldValue("display_method_product_price", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED}
                        />
                        <label className="form-check-label cp" htmlFor="tax_excluded">
                          {t('tax_excluded_label')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="tax_included" name="display_method_product_price"
                          value={DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED}
                          onChange={(e) => formTaxUpdate.setFieldValue("display_method_product_price", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED}
                        />
                        <label className="form-check-label cp" htmlFor="tax_included">
                          {t('tax_included_label')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-2">{t('cash_registered_text')}</p>
            </div>
            <div className="tax-info-blk">
              <p>{t('icon_display_label')}</p>
              <div className="card">
                <div className="tax-info-row">
                  <div className="tax-info-blk-title">{t('eligible_text')}</div>
                  <div className="tax-info-radio">
                    <div className="d-inline-flex gap-5">
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="display" name="icon_display_eligible_for_reduced_tax_rate"
                          value={true}
                          onChange={(e) => formTaxUpdate.setFieldValue("icon_display_eligible_for_reduced_tax_rate", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.icon_display_eligible_for_reduced_tax_rate === "true"}
                        />
                        <label className="form-check-label cp" htmlFor="display">
                          {t('display_label')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input cp" type="radio" id="hidden" name="icon_display_eligible_for_reduced_tax_rate"
                          value={false}
                          onChange={(e) => formTaxUpdate.setFieldValue("icon_display_eligible_for_reduced_tax_rate", e.target.value)}
                          onBlur={formTaxUpdate.handleBlur}
                          checked={formTaxUpdate.values.icon_display_eligible_for_reduced_tax_rate === "false"}
                        />
                        <label className="form-check-label cp" htmlFor="hidden">
                          {t('hidden_label')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-2">{t('display_icon_text')}</p>
            </div>
          </div>
          <button type="submit" className={"btn btn-primary"+(addSubmissionLoader?" btn-loader":"")}>
            {t('btn_update')}
          </button>
          </>}
        </div>
      </form>
    </main>
  );
}

export default Tax;
