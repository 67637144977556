import React, { useEffect, useState } from 'react';
import icon1 from '../../../../../assets/restaurant/images/color-icon1.png';
import icon2 from '../../../../../assets/restaurant/images/color-icon2.png';
import icon3 from '../../../../../assets/restaurant/images/color-icon3.png';
import { list, todaySales } from '../../../services/tableOrder.service';
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { showPrice, TrimText } from '../../../../../utils/commonfunction';
import { useTranslation } from 'react-i18next';
export default function RestDashboard() {

  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [List, setList] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const data = [
    '#067c18', '#4e9107', '#c9a70e', '#119e07', '#09246d', '#7ead11',
    '#3d0070', '#91a009', '#1e0487', '#0b3a84', '#a3092b', '#892900',
    '#378e08', '#035566', '#059136', '#419b09', '#019b2d', '#519104',
    '#1d0275', '#270784', '#00967a', '#b517ea', '#00445b', '#0a4668',
    '#46037c', '#0a466b', '#04877e', '#930cc9', '#ca0de8', '#ed00b5',
    '#930b08', '#02545b', '#c000d6', '#007a74', '#0f5996', '#0b8221',
    '#910709', '#1e8909', '#517f07', '#0c317c', '#054a70', '#eabb12',
    '#d19112', '#5e7c03', '#910d1e', '#346d00', '#b910cc', '#3a0996',
    '#28006d', '#039b93', '#628205', '#d310c3', '#0b7277', '#041f66',
    '#01647c', '#c90c96', '#4d910e', '#09a02a', '#960616', '#02026b',
    '#02930f', '#bf0fbf', '#078e46', '#ccc20a', '#1a960f', '#d68306',
    '#8c2a09', '#0f967b', '#0e1393', '#d60aad', '#ce9e00', '#9e2c00',
    '#3c03a0', '#540877', '#2c0b7a', '#0e7507', '#c96902', '#549900',
    '#f4da16', '#068484', '#9e0b28', '#198c00', '#00045b', '#e800c9',
    '#bb0ece', '#a00934', '#029374', '#2f9b04', '#0d961d', '#d30c73',
    '#b21901', '#e80485', '#0c7f46', '#d1ab04', '#d606d2', '#6b8909',
    '#d60a77', '#c5cc00', '#016775', '#062863'
  ];


  const tableList = () => {
    setLoader(true);
    list().then(data => {
      setList(data && data.data && data.data.list ? data.data.list : []);
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }

  const getTodaySales = () => {
    setLoader(true);
    todaySales().then(response => {
      setSaleData(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }
  useEffect(() => {
    tableList();
    getTodaySales();
  }, []);


  // search, refreshList, globalSearch , langChanged
  return (
    <div className='home-dashboard'>
      <div className='container-fluid'>
        <div className='py-4'>
          <div className='dashboard-container'>
            <div className='dashboard-left'>
              <div className='dashboard-table-blk'>
                {List.map((item, index) => {
                  return (<div key={index} className='d-table-box'>
                    <div className='card'>
                      <div className='d-table-box-in'>
                        <div className='table-name-box'>
                          <h4>{t('label_table1')}</h4>
                          <h4>{TrimText(item?.table_number, 6)}</h4>
                        </div>
                        <h6 data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">¥ {item?.activeOrder ? showPrice(item?.activeOrder?.great_grand_total) : 0}</h6>
                      </div>
                    </div>
                  </div>)
                })}
              </div>
            </div>
            <div className='dashboard-right'>
              <div className='card'>
                <div className='card-body'>
                  <div className='amount-block'>
                    <div className='amount-row'>
                      <figcaption>
                        <span>{t('amount_of_sale')}</span>
                        <h4>￥{showPrice(saleData?.totalSales || 0)}</h4>
                        <p>￥{showPrice(saleData?.totalSalesExcludingTax || 0)} {t('excluding_tax')}</p>
                      </figcaption>
                      <figure>
                        <img src={icon1} alt="Icon 1" />
                      </figure>
                    </div>
                    <div className='amount-row'>
                      <figcaption>
                        <span>{t('settled')}</span>
                        <h4>￥{showPrice(saleData?.settledOrders?.totalAmount || 0)}</h4>
                        <p>￥{showPrice(saleData?.settledOrders?.totalAmountExcludingTax || 0)} {t('excluding_tax')}</p>
                      </figcaption>
                      <figure>
                        <img src={icon2} alt="Icon 2" />
                      </figure>
                    </div>
                    <div className='amount-row'>
                      <figcaption>
                        <span>{t('unpaid')}</span>
                        <h4>￥{showPrice(saleData?.unsettledOrders?.totalAmount || 0)}</h4>
                        <p>￥{showPrice(saleData?.unsettledOrders?.totalAmountExcludingTax || 0)} {t('excluding_tax')}</p>
                      </figcaption>
                      <figure>
                        <img src={icon3} alt="Icon 3" />
                      </figure>
                    </div>
                  </div>
                </div>
                <hr className='m-0'></hr>
                <div className='card-body'>
                  <div className='user-statics'>
                    <ul>
                      <li>
                        <h4>{t('number_of_customers')}</h4>
                        <h4>{saleData?.totalCustomers || 0}</h4>
                      </li>
                      <li>
                        <h4>{t('settled')}</h4>
                        <h4>{saleData?.settledCustomers || 0}</h4>
                      </li>
                      <li>
                        <h4>{t('unpaid')}</h4>
                        <h4>{saleData?.unsettledCustomers || 0}</h4>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h4>{t('number_of_transactions')}</h4>
                        <h4>{saleData?.totalTransactions || 0}</h4>
                      </li>
                      <li>
                        <h4>{t('settled')}</h4>
                        <h4>{saleData?.totalSettledTransactions || 0}</h4>
                      </li>
                      <li>
                        <h4>{t('unpaid')}</h4>
                        <h4>{saleData?.totalUnsettledTransactions || 0}</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
