import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { daySales } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, REPORT_TAX_TYPES } from '../../../../../utils/Constants';
import { showPrice, getDatesOfMonth, getTotalSalesData } from '../../../../../utils/commonfunction';
import ReactApexChart from 'react-apexcharts';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { DatePicker } from 'rsuite';

export default function SalesReportStatusByDay() {

  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [List, setList] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const chartRef = useRef(null);
  const [reportDate, setReportDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState('');
  const [taxType, setTaxType] = useState(REPORT_TAX_TYPES[0].value);

  const getTodaySales = (obj) => {
    setLoader(true);
    daySales(obj).then(response => {
      setSaleData(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }

  useEffect(() => {
    let obj = {
      date: reportDate,
      paymentMethod: paymentMethod ? paymentMethod : undefined,
      taxType: taxType ? taxType : undefined
    }
    getTodaySales(obj);
  }, [reportDate, paymentMethod, taxType]);

  const chartId = 'my-area-chart';
  const options = {
    chart: {
      id: chartId,
      height: 280,
      type: 'area',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#f28c40', '#008000'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        gradientToColors: ['#f3d4bc'],
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      }
    },
    xaxis: {
      categories: getDatesOfMonth(reportDate)
    },
    yaxis: {
      title: {
        text: 'Amount (¥)', // This is the label with the Euro symbol
      },
      labels: {
        formatter: (value) => `¥${value}`, // Format the y-axis labels with the Euro symbol
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `¥${value}`, // Format tooltips to include the Euro symbol
      },
    },
    legend: {
      position: 'top'
    },
    grid: {
      show: true,
      borderColor: '#EEE',
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    }


  };

  const series = [
    {
      name: 'Profit',
      data: getTotalSalesData(reportDate, saleData, 'profit')
    },
    {
      name: 'Sales',
      data: getTotalSalesData(reportDate, saleData)
    }
  ];

  // Function to download the chart
  const handleDownload = () => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.dataURI().then(({ imgURI }) => {
        const link = document.createElement('a');
        link.href = imgURI;
        link.download = 'chart-image.png';
        link.click();
      });
    }
  };


  const handleDownloadPreview = () => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.dataURI().then(({ imgURI }) => {
        console.log("Opening print preview");

        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          const printWindow = window.open('', '', 'height=600,width=800');

          printWindow.document.write('<html><head><title>Print Chart</title></head><body>');
          printWindow.document.write('<img src="' + img.src + '" width="100%" />');
          printWindow.document.write('</body></html>');
          printWindow.document.close();

          printWindow.print();
        };
      });
    }
  };



  return (
    <main id='main-content'>
      <div className='inner-container'>
        <div className='d-flex tabs-with-etc justify-content-between align-items-center mb-4'>
          <ul className="nav nav-pills gap-3">
            <li className="nav-item">
              <Link className="nav-link active" to={'/restaurant/sales'}>By day</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/restaurant/sales/sales-report-status-month'}>By month</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/restaurant/sales/sales-report-status-week'}>By day of the week</Link>
            </li>
          </ul>
          <div className='tabls-dowpdown'>
            <select className="form-control cp" name='payment_method_type' value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
              <option values="" disabled>Choose payment method type</option>
              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
            </select>
          </div>
        </div>
        <div className='card'>
          <div className='card-header border-0'>
            <div className='d-flex justify-content-between align-items-center'>

              <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_date")} placement="autoVertical"
                format="MMMM yyyy" cleanable={false} editable={false} name="reservation_date" onChange={(e) => { setReportDate(e) }} value={reportDate} />

              {/* <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i className='ti ti-chevron-down'></i></button> */}
              <div className='card-head-group d-flex align-items-center gap-2'>

                <select className="form-control cp" name='payment_method_type' value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                  {REPORT_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                </select>
                <button className='btn btn-black' onClick={handleDownload}><i className='ti ti-download'></i> Download</button>
                <button className='btn btn-black' onClick={handleDownloadPreview}><i className='ti ti-printer' ></i> Print</button>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className="chart-container" style={{ position: 'relative' }}>
              <ReactApexChart ref={chartRef} options={options} series={series} type="area" height={350} className="dayReport" />
            </div>
          </div>
        </div>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Number of transactions</th>
                    <th>Amount of sales</th>
                    <th>Cost price</th>
                    <th>Profit</th>
                    <th>Profit rate</th>
                    <th>Total Cash</th>
                    <th>Total Credit</th>
                    <th>Others</th>
                    <th>Number of customers</th>
                    <th>Men</th>
                    <th>Women</th>
                    <th>Order per customer</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    saleData && saleData.length ? saleData.map((item, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>5/1 (Wed)</td> */}
                          <td>{item?.date}</td>
                          <td>{item?.totalTransactions}</td>
                          <td>¥{showPrice(item?.totalSales)}</td>
                          <td>¥{showPrice(item?.totalCostPrice)}</td>
                          <td>¥{showPrice(item?.totalProfit)}</td>
                          <td>{item.profitRate ? item.profitRate.toFixed(2) : 0}%</td>
                          <td>¥{showPrice(item?.totalCash)}</td>
                          <td>¥{showPrice(item?.totalCredit)}</td>
                          <td>¥{showPrice(item?.totalOther)}</td>
                          <td>{item?.totalCustomers}</td>
                          <td>{item?.men}</td>
                          <td>{item?.women}</td>
                          <td>¥{showPrice(item?.orderPerCustomer)}</td>
                        </tr>
                      )
                    }) : ""
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
