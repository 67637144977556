import React, { useEffect, useMemo, useRef, useState } from 'react';
import card1 from '../../../assets/cashier/images/paymentsuccessicon.png';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import HomeIcon from '../../../assets/cashier/images/home-img.svg';
import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getPrinter, showPrice } from '../../../utils/commonfunction';
import ReactToPrint from 'react-to-print';
import { detail, receiptDetail, transactionDetail } from '../services/table.services';
import OrderReceipt from '../shared/orderReceipt';
import sucessIcon from '../../../assets/cashier/images/success.png';
import { PRINTER_NAMES } from '../../../utils/Constants';
import { billingReceiptPrint, royushuPrint } from '../../../utils/printerfunction';
import { useSelector } from 'react-redux';

export default function PaymentSuccess() {
  const { t , i18n} = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [orderTransactionDetail, setOrderTransactionDetail] = useState({});
  const componentRef = useRef();
  const reprintRef = useRef();
  const [langChanged, setLangChanged] = useState(false);
  const [printerDetails , setPrinterDetails] = useState({});
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);



  const getOrderDetail = (orderId , type='receipt') => {
    transactionDetail({ o_id: orderId , type : type})
      .then(async(response) => {
        setOrderTransactionDetail(response?.data);
        const printer = await getPrinter(PRINTER_NAMES.CASHIER_PRINTER);
        billingReceiptPrint(printer , response?.data , t , i18n.language);

      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    if (params.orderId) {
      getOrderDetail(params.orderId);
    }
    else{
      navigate("/cashier/dashboard")
    }
  }, [params.orderId,langChanged]);


  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });

  const printReceipt = async ()=>{
    if (params.orderId) {
      getOrderDetail(params.orderId);
    }
   
  }
  const printRoyushuReceipt = async ()=>{
    if (params.orderId) {
      getOrderDetail(params.orderId , 'royushu');
    }
  }

  return (
    <div className="dashboard-cashier-wrap">
      <div className="cashier-head text-center">
        <h4>{t("payment_label")}</h4>
        <div className="btns-head-in-left" onClick={() => navigate(-1)}>
          <button className="btn btn-icon">
            <i className="ti ti-arrow-left"></i>
          </button>
        </div>
      </div>

      <div className="payment-wraper">
        <div className="payment-wraper-in">
          {orderTransactionDetail.payment_method === "cash" && (
            <div className="paymnet-typing shadow-box">
              <span>{t("label_change_to_return")}</span>
              <h4>¥ {showPrice(orderTransactionDetail?.returned_amount)}</h4>
            </div>
          )}

          <div className="payment-keypad-box shadow-box">
            <div className="paymnet-success-msg">
              {orderTransactionDetail.payment_method === "cash" ? (
                <figure>
                  <img src={card1} />
                </figure>
              ) : (
                <img width="100px" src={sucessIcon} />
              )}

              {orderTransactionDetail.payment_method !== "cash" && (
                <div className="d-flex flex-column align-items-center ">
                  <span style={{ fontSize: "18px", color: "black" }}>Order no. # {orderTransactionDetail?.orderDetail?.order_id}</span>
                  <span className="m-2" style={{ fontSize: "50px", fontWeight: "bold", color: "black" }}>
                    ¥ {showPrice(orderTransactionDetail?.received_amount)}
                  </span>
                </div>
              )}
              <h4>{t("payment_success_msg")}</h4>

              {orderTransactionDetail.payment_method !== "cash" && (
                <div className="">
                  <button className="btn btn-primary" onClick={() => navigate("/cashier/dashboard")}>
                    Back to Home
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="keypad-action-btn-left gap-3">
           

            <button
              className="keypad-action-btns"
              onClick={() => {
                printRoyushuReceipt();
              }}
            >
              <img src={InvoiceIcon} />
              <span>{t("label_print_invoice")}</span>
            </button>

        
            <button
                  className="keypad-action-btns mt-5"
                  onClick={() => {
                    printReceipt();
                  }}
                >
                  <img src={ReprintIcon} />
                  <span>{t("label_reprint")}</span>
                </button>
          </div>
          <div className="keypad-action-btn-right gap-3">
            <button className="keypad-action-btns" onClick={() => navigate("/cashier/dashboard")}>
              <img src={HomeIcon} />
              <span>{t("nav_link_home")}</span>
            </button>
          </div>
        </div>
      </div>
      {/* style={{ display: 'none' }} */}
      <div style={{ display: "none" }}>
        <div ref={componentRef} className="container d-flex justify-content-center align-items-center vh-100">
          {/* <OrderReceipt orderId={params.orderId} ref={componentRef}/> */}
          <div dangerouslySetInnerHTML={{ __html: orderTransactionDetail?.html ? orderTransactionDetail?.html : "N/A" }}></div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div ref={reprintRef} className="container d-flex justify-content-center align-items-center vh-100">
          <div dangerouslySetInnerHTML={{ __html: orderTransactionDetail?.reprint_html ? orderTransactionDetail?.reprint_html : "N/A" }}></div>
        </div>
      </div>
    </div>
  );
}
