import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Outlet, useLocation} from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantSettings } from "../services/table.services";
import { addRestData } from '../../../redux/slices/restSettings';
import { SOCKET_CLIENT } from "../../../utils/socket";
import { SOCKET_EVENTS } from "../../../utils/Constants";

const CashierLayout = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const [restaurantDetailChanged, setRestaurantDetailChanged] = useState(false);
    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/cashier/css/style.css"),
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);


    /** Joining user to socket for realtime notifications <<<<< */
    useMemo(() => {
        SOCKET_CLIENT.on('GET-DATA', async (response) => {
            if (response) {
                if (response?.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
                    console.log("socket event listened")
                    setRestaurantDetailChanged(!restaurantDetailChanged)
                }
            }
        });
    }, [restaurantDetailChanged]);

    useMemo(() => {
        getRestaurantSettings().then((globalDetails) => {
            dispatch(addRestData(globalDetails?.data));
        });
    }, [restaurantDetailChanged]);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div>
                    <Header />
                    <Outlet />
                </div>
            )}
        </>
    );
};
export default CashierLayout;