import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../../services/auth.services";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import Swal from "sweetalert2";
import { globalLoader, handleServerValidations } from "../../../../utils/commonfunction";
// import { useTranslation } from "react-i18next";
import logo from '../../../../assets/restaurant/images/logo.svg';
import { useValidationSchemas } from "../../validator/validation";
import CustomError from "../../../../utils/customError";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ResetPassword = () => {
    // const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { resetPswValidationSchema } = useValidationSchemas()
    const [newshow, setNewShow] = useState("false");
    const [conformshow, setConformShow] = useState("false");
    const { t } = useTranslation()

    useEffect(() => {
        if (!state?.validate_string || state?.validate_string.length === 0) {
            navigate("/restaurant/login");
        }
    }, [state, navigate]);

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: resetPswValidationSchema,
        onSubmit: (values, { resetForm }) => {
            globalLoader(true);
            const formData = new FormData();
            formData.append("new_password", values.newPassword);
            formData.append("c_password", values.confirmPassword);
            formData.append("validate_string", state.validate_string);

            resetPassword(formData)
                .then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        navigate("/restaurant/login");
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    globalLoader(false);
                    console.log("error--->", error);
                });
        }
    });

    const handleshow = (event) => {
        if (event === "newpwd") {
            setNewShow(!newshow);
        }
        if (event === "confirmpwd") {
            setConformShow(!conformshow);
        }
    };

    return (
        <div className="login-wrap">
            <div className="login-wrap-in">
                <div className="login-logo">

                    <img src={logo} alt="Logo" />

                </div>
                <div className="login-form-wrap">
                    <div className="login-form-header">
                        <h4>{t('btn_reset_password')}</h4>
                        <p>{t('reset_password_text')}</p>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="login-form">
                        <div className="mb-3">
                            <label htmlFor="newPassword" className="form-label">{t('label_new_password')} <span>*</span></label>
                            <div className="input-group">
                                <input
                                    type={!newshow ? "text" : "password"}
                                    id="newPassword"
                                    name="newPassword"
                                    className="form-control border-end-0"
                                    // className={`form-control ${formik.errors.newPassword ? 'is-invalid' : ''}`}
                                    placeholder={t("placeholder_password1")}
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <span className="input-group-text cp restaurant-password-toggle" onClick={() => { handleshow("newpwd"); }}
                                >
                                    {!newshow === true ? (
                                        <i className="ti ti-eye"></i>
                                    ) : (
                                        <i className="ti ti-eye-off"></i>
                                    )}

                                </span>
                            </div>
                            <CustomError name="newPassword" form={formik} className="text-danger" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">{t('confirm_password_label')}<span>*</span></label>
                            <div className="input-group">
                                <input
                                    type={!conformshow ? "text" : "password"}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="form-control border-end-0"
                                    // className={`form-control ${formik.errors.confirmPassword ? 'is-invalid' : ''}`}
                                    placeholder="Re-enter password to confirm"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <span className="input-group-text cp restaurant-password-toggle" onClick={() => { handleshow("confirmpwd"); }}
                                >
                                    {!conformshow === true ? (
                                        <i className="ti ti-eye"></i>
                                    ) : (
                                        <i className="ti ti-eye-off"></i>
                                    )}
                                </span>
                            </div>
                            <CustomError name="confirmPassword" form={formik} className="text-danger" />
                        </div>
                        <button type="submit" className="btn btn-primary w-100 justify-content-center">
                            {t('btn_submit')}
                        </button>
                        <div className="login-cta-bottom text-center mt-3">
                            <p className="mb-2">
                                {t("did_you_remembered_your_password")}?{" "}
                                {t("label_try_to")} <Link className="" to="/restaurant/login">{t("link_login")}</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
