import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/sales-report";
const options = {role: ROLE.RESTAURANT};


export const list = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const todaySales = async (values) => {
    return await axiosInstance(options).get(`${path}/today-sales`, values);
}
  
export const daySales = async (values) => {
  return await axiosInstance(options).post(`${path}/day-sales`, values);
}

export const transactionHistory = async (values) => {
  return await axiosInstance(options).post(`${path}/transaction-history`, values);
}

export const daySalesReport = async (values) => {
  return await axiosInstance(options).post(`${path}/day-sales-report`, values);
}

export const monthlySales = async (values) => {
  return await axiosInstance(options).post(`${path}/monthly-sales`, values);
}

export const monthlySalesReport = async (values) => {
  return await axiosInstance(options).post(`${path}/monthly-sales-report`, values);
}

export const dayOfWeekSales = async (values) => {
  return await axiosInstance(options).post(`${path}/day-of-week-sales`, values);
}

export const dayOfWeekSalesReport = async (values) => {
  return await axiosInstance(options).post(`${path}/day-of-week-sales-report`, values);
}

export const transactionDetail = async (values) => {
  return await axiosInstance(options).get(`${path}/transaction-detail?o_id=${values.o_id}`);
}

export const deleteTransactionSlip = async (values) => {
  return await axiosInstance(options).put(`${path}/transaction-slip-delete`,values );
}