import React, { useEffect, useMemo, useRef, useState, useTransition } from "react";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { applyCouponCode, applyGiftCard, deleteMenuItem, detail, getKitchenPrintHtml, getRestaurantSettings, removeCouponCode, removeGiftCard, removeServiceCharge, updateMenuItem, updateServiceCharge } from "../../../services/table.services";
import RecordNotFound from "../../../../restaurant/shared/recordNotFound";
import { getSubTotalQRApp, getTranslatedKey, handleServerValidations, pluralText, roundFigurePrice, showPrice, TrimText } from "../../../../../utils/commonfunction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addRestData } from "../../../../../redux/slices/restSettings";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { CONSUMPTION_TAX_RATES, DISPLAY_METHOD_TAX_OPTIONS, SOCKET_EVENTS, SWAL_SETTINGS } from "../../../../../utils/Constants";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { SOCKET_CLIENT } from "../../../../../utils/socket";
import ReactToPrint from "react-to-print";
import CustomError from "../../../../../utils/customError";
import { useValidationSchemas } from "../../../validator/validation";
import { transactionDetail } from "../../../services/sales.service";

const CashierHomeLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tableId } = useParams();
  const [tableDetail, setTableDetail] = useState({});
  const [combinedOrderItems, setCombinedOrderItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const params = useParams();
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);
  const [orderPriceDetail, setOrderPriceDetail] = useState(null);
  const [giftCardApplied, setGiftCardApplied] = useState(false);
  const [serviceChargeApplied, setServiceChargeApplied] = useState(false);
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [kitchenPrintHtml, setKitchenPrintHtml] = useState("");
  const [kitchenPrintHtmlLoading, setKitchenPrintHtmlLoading] = useState(false);
  const [foodPrintHtmlLoading, setFoodPrintHtmlLoading] = useState(false);
  const [drinkPrintHtmlLoading, setDrinkPrintHtmlLoading] = useState(false);
  const printSlipRef = useRef();
  const printSlipTriggerRef = useRef();
  const [stateChanges, setStateChanges] = useState(false);
  const [isAdded , setIsAdded] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const closeEditModal = useRef();
  const [refresh, setRefresh] = useState(false);
  const { quantityValidationSchema } = useValidationSchemas();
  const [selectedItem , setSelectedItem] = useState({});
  const [searchParams] = useSearchParams();
  const orderType = searchParams.get("type");
  const [transactionData, setTransactionData] = useState({});
  const { transactionId } = useParams();





  const formik = useFormik({
    initialValues: {
      couponCode: "",
      gift_card_amount: "",
      isCouponChecked: false,
      isGiftCardChecked: false,
    },
    validationSchema: Yup.object({
      couponCode: Yup.string()
        .nullable() // Allows null or undefined
        .test("couponCode", t("error_coupon_required"), function (value) {
          const { isCouponChecked } = this.parent;
          return !isCouponChecked || (isCouponChecked && value && value.trim() !== '');
        }),
      gift_card_amount: Yup.number()
        .nullable() // Allows null or undefined
        .test("gift_card_amount", t("error_gift_card_required"), function (value) {
          const { isGiftCardChecked } = this.parent;
          return !isGiftCardChecked || (isGiftCardChecked && value != null);
        })
        .min(1, t("number_err_msg")),
    }),
    onSubmit: (values) => {
      console.log("Form Values:", values);
      navigate(`/cashier/payment-via-cash/${tableId}`);
    },
  });

  const serviceChargeFormik = useFormik({
    initialValues: {
      service_charge_percentage: 0,
      is_service_charge_percentage: false
    },
    validationSchema: Yup.object({
      service_charge_percentage: Yup.number()
        .required(t("error_service_charge_required"))
        .min(0, t("number_err_msg"))
        .max(100, t("max_number_err_msg"))
    }),
    onSubmit: (values) => {
      console.log("Form Values:", values);
      // navigate(`/cashier/payment-via-cash/${tableId}`);
    },
  });


  const getTableDetail = (tableId) => {
    console.log("this.ca;;ed")
    detail({ o_id: tableId })
      .then((response) => {
        setTableDetail(response?.data);
        // console.log(response.data)
        if (response?.data?.is_available === false) {
          const combinedOrderedItems = response?.data?.activeOrder?.ordered_items.map(item => ({
            ...item,
            order_id: response?.data?.activeOrder?._id, // Add `_id` as `order_id`
          }));
          
          response?.data?.activeOrder?.subOrders.forEach((subOrder) => {
            const order_id = subOrder._id; 
            const updatedItems = subOrder.ordered_items.map(item => ({
              ...item,
              order_id: order_id, 
            }));
            combinedOrderedItems.push(...updatedItems);
          });
          
          setCombinedOrderItems(combinedOrderedItems);
          const itemCounts = combinedOrderedItems.reduce((acc, item) => {
            // Create a unique key based on both item name and spice level
            const key = `${item.item_name}-${item.spice_level}`;

            // Initialize the accumulator for this key if it doesn't exist
            acc[key] = acc[key] || {
              quantity: 0,  // Start quantity at 0 to accumulate values
              consumption_tax_rate: item.consumption_tax_rate,
              price: item.price,
              tax_price: item.tax_price,
              total_price: item.total_price,
              item_name_ja: item.item_name_ja,
              spice_level: item.spice_level, // Include spice level in the result
            };

            // Add the item's quantity to the existing quantity for this key
            acc[key].quantity += item.quantity;

            return acc;
          }, {});


          // Convert the counts into an array of objects
          const result = Object.keys(itemCounts).map((key) => ({
            item_name: key.split('-')[0], // Extract the item name from the key
            spice_level: itemCounts[key].spice_level, // Extract spice level
            quantity: itemCounts[key].quantity,
            consumption_tax_rate: itemCounts[key].consumption_tax_rate,
            price: itemCounts[key].price,
            tax_price: itemCounts[key].tax_price,
            total_price: itemCounts[key].total_price,
            item_name_ja: itemCounts[key].item_name_ja,
          }));

          setOrderItems(combinedOrderedItems);

          handelOrderCalculations(response?.data?.activeOrder, response.data.coupon_code_applied);

          if (response?.data?.activeOrder?.gift_card_applied) {
            setGiftCardApplied(true);
            formik.setFieldValue('isGiftCardChecked', true);
            formik.setFieldValue('gift_card_amount', response?.data?.activeOrder?.gift_card_amount)
          }
          else {
            setGiftCardApplied(false);
            formik.setFieldValue('isGiftCardChecked', false);
            formik.setFieldValue('gift_card_amount', '')
          }

          if (response?.data?.activeOrder?.coupon_code_applied) {
            setCouponCodeApplied(true);
            formik.setFieldValue('isCouponChecked', true);
            formik.setFieldValue('couponCode', response?.data?.activeOrder?.coupon_code)
          }
          else {
            setCouponCodeApplied(false);
            formik.setFieldValue('isCouponChecked', false);
            formik.setFieldValue('couponCode', '')
          }
          if (response?.data?.activeOrder?.service_charge_percentage) {
            setServiceChargeApplied(true);
            serviceChargeFormik.setFieldValue("service_charge_percentage", response?.data?.activeOrder?.service_charge_percentage);
            serviceChargeFormik.setFieldValue("is_service_charge_percentage", true);
          } else {
            setServiceChargeApplied(false);
            serviceChargeFormik.setFieldValue("service_charge_percentage", "");
            serviceChargeFormik.setFieldValue("is_service_charge_percentage", false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelOrderCalculations = (orderDetail, coupon_code_applied) => {
    let data = {
      subtotal: 0,
      tax_price: 0,
      grand_total: 0,
      total_tax_8: 0,
      total_tax_10: 0,
      service_charge: 0,
      great_grand_total: 0,
      amount_after_discount: 0,
      coupon_code_applied: coupon_code_applied,
      coupon_discount: orderDetail?.coupon_discount,
      coupon_type: orderDetail?.coupon_type , 
      coupon_code_amount : orderDetail.coupon_code_amount
    };
    const subOrders = orderDetail?.subOrders;
    // console.log(orderDetail , "check")
    if (orderDetail) {
      data.subtotal += orderDetail?.subtotal;
      data.tax_price += orderDetail?.tax_price;
      // data.total_tax_8 += orderDetail?.total_tax_8;
      // data.total_tax_10 += orderDetail?.total_tax_10;
      orderDetail.ordered_items.forEach((item) => {
        if (item.consumption_tax_rate === 8) {
          data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
        } else if (item.consumption_tax_rate === 10) {
          data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
        }
      })
      data.grand_total += orderDetail?.grand_total;
      data.service_charge += orderDetail?.service_charge;
    }
    if (subOrders && subOrders.length) {
      subOrders.forEach((order) => {
        if (order) {
          console.log(order)
          data.subtotal += order?.subtotal;
          data.tax_price += order?.tax_price;
          // data.total_tax_8 += order?.total_tax_8;
          // data.total_tax_10 += order?.total_tax_10;
          data.grand_total += order?.grand_total;
          data.service_charge += order?.service_charge;

          

          order.ordered_items.forEach((item) => {
            if (item.consumption_tax_rate === 8) {
              data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
            } else if (item.consumption_tax_rate === 10) {
              data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
            }
          })


        }
      });
    }
      data.great_grand_total = data.subtotal + data.service_charge + data.tax_price;
      data.amount_after_discount = data.great_grand_total;
    if (orderDetail?.gift_card_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.gift_card_amount

    }

    if (orderDetail?.coupon_code_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.coupon_code_amount;
    }

    setOrderPriceDetail(data);
  };
// console.log(orderPriceDetail)
  useEffect(() => {
    if(orderType && orderType==='edit-sales' && params.tableId){
      getTransactionDetail(params.tableId);
    }
    if (!orderType && orderType!=='edit-sales' && params.tableId) {
      getTableDetail(params.tableId);
    }
  }, [params.tableId, stateChanges , isAdded ]);


  const getTransactionDetail = (o_id) => {
    transactionDetail({ o_id: o_id })
      .then((response) => {
        setTransactionData(response && response.data ? response.data : []);
        const combinedOrderedItems = response?.data?.orderDetail?.ordered_items.map(item => ({
          ...item,
          order_id: response?.data?.activeOrder?._id,
        }));

        response?.data?.orderDetail?.subOrders.forEach((subOrder) => {
          const order_id = subOrder._id;
          const updatedItems = subOrder.ordered_items.map(item => ({
            ...item,
            order_id: order_id,
          }));
          combinedOrderedItems.push(...updatedItems);
        });

        setCombinedOrderItems(combinedOrderedItems);
        const itemCounts = combinedOrderedItems.reduce((acc, item) => {
          const key = `${item.item_name}-${item.spice_level}`;
          acc[key] = acc[key] || {
            quantity: 0,
            consumption_tax_rate: item.consumption_tax_rate,
            price: item.price,
            tax_price: item.tax_price,
            total_price: item.total_price,
            item_name_ja: item.item_name_ja,
            spice_level: item.spice_level,
          };

          acc[key].quantity += item.quantity;

          return acc;
        }, {});

        setOrderItems(combinedOrderedItems);
        handelOrderCalculations(response?.data?.orderDetail, response.data.coupon_applied);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };


  const applyGiftCardAmount = () => {
    applyGiftCard({ order_id: tableDetail?.activeOrder?._id, gift_card_amount: formik?.values?.gift_card_amount })
      .then((response) => {
        if (response?.success) {
          setGiftCardApplied(true);
          getTableDetail(tableDetail?._id);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyServiceCharge = () => {
    // if(serviceChargeFormik?.values?.service_charge != orderPriceDetail?.service_charge){
    updateServiceCharge({ order_id: tableDetail?.activeOrder?._id, updated_service_charge: serviceChargeFormik?.values?.service_charge_percentage })
      .then((response) => {
        if (response?.success) {
          setStateChanges(!stateChanges);
          setServiceChargeApplied(true);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handelServiceChargePercentageChange = (val) => {
    if (!val && serviceChargeApplied) {
      Swal.fire({
        title: t("msg_are_you_sure"),
        text: t("msg_remove_service_charge"),
        imageUrl: trash,
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: t("btn_delete"),
        cancelButtonText: t("btn_cancel"),
        reverseButtons: true,
        customClass: {
          popup: "swal-restaurant",
          confirmButton: "btn btn-danger col",
          cancelButton: "btn btn-secondary col",
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          removeServiceChargePercentage();
          serviceChargeFormik.setFieldValue("is_service_charge_percentage", false);
          serviceChargeFormik.setFieldValue("service_charge_percentage", "")
        } else {
        }
      });
    } else {
      serviceChargeFormik.setFieldValue("is_service_charge_percentage", val);
    }
  };

  const removeServiceChargePercentage = () => {
    removeServiceCharge({ order_id: tableDetail?.activeOrder?._id })
      .then((response) => {
        if (response?.success) {
          setServiceChargeApplied(false);
          setStateChanges(!stateChanges);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handelGiftCardChange = (val) => {
    if (!val && giftCardApplied) {
      return removeGiftCardSwal();
      // formik.setFieldValue("gift_card_amount", ""); 
      // removeGiftCardAmount();
    }
    formik.setFieldValue("isGiftCardChecked", val);
  }

  const removeGiftCardSwal = () => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_remove_gift_card"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeGiftCardAmount();
        formik.setFieldValue("isGiftCardChecked", false);
        formik.setFieldValue("gift_card_amount", "")
      } else {
      }
    });
  };

  const removeGiftCardAmount = () => {
    removeGiftCard({ order_id: tableDetail?.activeOrder?._id })
      .then((response) => {
        if (response?.success) {
          setGiftCardApplied(false);
          setStateChanges(!stateChanges);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const applyCouponCodeAmount = () => {
    if (formik?.values?.couponCode && formik?.values?.couponCode.trim() !== '') {
      applyCouponCode({ order_id: tableDetail?.activeOrder?._id, coupon_code: formik?.values?.couponCode })
        .then((response) => {
          if (response?.success) {
            setCouponCodeApplied(true);
            getTableDetail(tableDetail?._id);
            Swal.fire({
              icon: "success",
              text: t(response.message),
              ...SWAL_SETTINGS,
            });
          }
          else {
            Swal.fire({
              icon: "error",
              text: t(response.message),
              ...SWAL_SETTINGS,
            });

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

  };

  const handelCouponCodeChange = (val) => {
    // console.log(val)
    if (!val && couponCodeApplied) {
      return removeCouponCodeSwal();
    }
    formik.setFieldValue("isCouponChecked", val);
  }

  const removeCouponCodeSwal = () => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_remove_coupon_code"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeCouponCodeAmount();
        formik.setFieldValue("isCouponChecked", false);
        formik.setFieldValue("couponCode", "")
      } else {
      }
    });
  };

  const removeCouponCodeAmount = () => {
    removeCouponCode({ order_id: tableDetail?.activeOrder?._id })
      .then((response) => {
        if (response?.success) {
          setCouponCodeApplied(false);
          setStateChanges(!stateChanges);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useMemo(() => {
 
    SOCKET_CLIENT.on('GET-DATA', (response) => {
      if (response.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
        if (params.tableId) {
          getTableDetail(params.tableId);
        }
      }
    });
  }, []);

  const handleAsterisk = (item) => {
    let result = null;
    if (
      item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT &&
      restSettingDetails?.icon_display_eligible_for_reduced_tax_rate
    ) {
      result = <p className="fw-bold">*</p>;
    }
    return result;
  };

  const handlePreview = (tableID , type) => {
    if(type==="all"){
      setKitchenPrintHtmlLoading(true);
    }
    if(type==="food"){
      setFoodPrintHtmlLoading(true)
    }
    if(type==="drink"){
      setDrinkPrintHtmlLoading(true);
    }
 
    getKitchenPrintHtml(tableID , type).then((response) => {
      setKitchenPrintHtml(response || "");
      if (printSlipTriggerRef.current) {
        setTimeout(() => {
          printSlipTriggerRef.current.click();
          setKitchenPrintHtmlLoading(false);
          setFoodPrintHtmlLoading(false);
          setDrinkPrintHtmlLoading(false);
        }, 100);
      }
    });
  };


  useEffect(() => {
    const handleItemAdded = () => {
      setIsAdded((prev) => !prev); 
    };

    document.addEventListener("item_added", handleItemAdded);

    
    return () => {
      document.removeEventListener("item_added", handleItemAdded);
    };
  }, []);


  

  const handleDeleteItem = (item)=>{
    // console.log(item , "delete");


    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("delete_menu_item_text"),
      imageUrl : trash ,
      showCancelButton: true,
      confirmButtonColor: "#f8821b",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("true_label_yes"),
      cancelButtonText: t("false_label_no"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result)=>{
       if(result.isConfirmed){
        const order_id = item.order_id
        delete item.order_id;
        let obj={
          order_id :orderType && orderType==='edit-sales' ? transactionData?.order_id: order_id , 
          item,
          type:orderType && orderType==='edit-sales' ?  orderType: 'edit-order',
          transaction_id:orderType && orderType==='edit-sales' ?  params.tableId: undefined
        }
         deleteMenuItem(obj).then((response)=>{
          setStateChanges(!stateChanges);
          const evt = new CustomEvent("item_deleted");
          document.dispatchEvent(evt);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
         }).catch((err)=>{
           console.log(err);
         })
       }
       else{

       }
    })
   }



  const editQuantityForm = useFormik({
    initialValues: {
      quantity: 0,
    },
    validationSchema: quantityValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // const data = {
      //  order_id : selectedItem?.order_id , 
      //  item : {
      //   temp_item_id : selectedItem?.temp_item_id , 
      //   _id : selectedItem?._id, 
      //   quantity : values?.quantity
      //  }
      // };

      const data={
        order_id :orderType && orderType==='edit-sales' ? transactionData?.order_id: selectedItem?.order_id  , 
        item:{
          ...selectedItem, // Spread all properties of `selectedItem`
          temp_item_id: selectedItem?.temp_item_id, // Overwrite `temp_item_id`
          _id: selectedItem?._id, // Overwrite `_id`
          quantity: values?.quantity // Overwrite `quantity`
        },
        type:orderType && orderType==='edit-sales' ?  orderType: 'edit-order',
        transaction_id:orderType && orderType==='edit-sales' ?  params.tableId: undefined
      }
      updateMenuItem(data)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            closeEditModal.current.click();
            resetForm();
            setIsAdded(!isAdded);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
          setSubmitting(false);
          resetForm();
          setRefresh(!refresh);
        })
        .catch((err) => {
          setSubmitting(false);
          resetForm();
          console.log("error ====> ", err);
          closeEditModal.current.click();
        });
    },
  });

  const handleEditItem = (item)=>{
    setSelectedItem(item);
    editQuantityForm.setFieldValue('quantity' , item?.quantity??0);
   };

  return (
    <>
      <div className="dashboard-cashier">
        <Outlet />
        <div className="dashboard-cashier-right">
          {!tableId ? (
            <div className="table-not-found">
              <RecordNotFound heading="no_table_selected" />
            </div>
          ) : (
            <div className="dashboard-cashier-right-in">
              <div className="menu-qty d-flex flex-column gap-2 p-3">
                <div className="category-list-grid">
                  {orderItems.length ? (
                    <>
                      {orderItems.map((item, index) => {
                        return (
                          <div className="menu-qty-row position-relative menu-actions-trigger" key={index}>
                            <div>
                              <h4 className="d-flex">
                                {TrimText(getTranslatedKey("item_name", item), 5)} {handleAsterisk(item)}
                              </h4>

                              {item.spice_level && orderType!=="edit-sales" && (
                                <p className="mb-0 fs-12">
                                  {t("spice_level_text")} - <strong className="text-capitalize">{item?.spice_level}</strong>
                                </p>
                              )}
                            </div>

                            <div className="menu-qty-right">
                              <span>{item?.quantity}</span>
                            </div>

                            <div>
                              <h4 className="mb-3">
                                ¥{" "}
                                {restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED
                                  ? showPrice(item?.price * item?.quantity)
                                  : showPrice(item?.total_price * item?.quantity)}
                              </h4>
                              <div className="menu-items-actions">
                                <span
                                  className="m-1.5"
                                  style={{ fontSize: "18px", fontWeight: "bolder", cursor: "pointer" }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#quantity-modal-id"
                                  onClick={() => {
                                    handleEditItem(item);
                                  }}
                                >
                                  <i className="ti ti-edit"></i>
                                </span>
                                <span
                                  className="m-1.5"
                                  style={{ fontSize: "18px", fontWeight: "bolder", cursor: "pointer" }}
                                  onClick={() => {
                                    if (orderItems.length > 1) {
                                      handleDeleteItem(item);
                                    }
                                    else{
                                      Swal.fire({
                                        icon: "error",
                                        text: "This is the last item in your order and cannot be deleted.",
                                        ...SWAL_SETTINGS,
                                      });
                                    }
                                  }}
                                >
                                  <i className="ti ti-trash"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <RecordNotFound heading="no_category_found_text" text="no_category_found_text_para" />
                  )}
                </div>
              </div>

              <div className="buttom-bill-info">
                <form onSubmit={formik.handleSubmit}>
                {!orderType && orderType !== 'edit-sales' && (
                  <div className="coupon-enter d-flex flex-column gap-3 p-3">
                    <div className="coupon-enter-in">
                      <div className="form-check">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          id="coupon_code"
                          checked={formik.values.isCouponChecked}
                          onChange={(e) => {
                            handelCouponCodeChange(e?.target?.checked)
                          }}
                        />
                        <label className="form-check-label cp" htmlFor="coupon_code">
                          {t("label_coupon_code")}
                        </label>
                      </div>
                      {formik.values.isCouponChecked && (
                        <div className="coupon-input">
                          <label className="form-label">{t("label_enter_code")}</label>
                          <div className="d-flex">
                            <input type="text" className="form-control no_spring_input pe-73px" placeholder={t("label_enter_coupon")} {...formik.getFieldProps("couponCode")} style={{
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }} />
                            <button type="button" className="btn btn-primary" disabled={!formik?.values?.couponCode} onClick={applyCouponCodeAmount} style={{
                              position: "static",
                              borderTopLeftRadius: "0",
                              borderBottomLeftRadius: "0",
                            }} >
                              {t("label_apply")}
                            </button>
                          </div>


                          {formik.touched.couponCode && formik.errors.couponCode ? <div className="text-danger">{formik.errors.couponCode}</div> : null}

                        </div>
                      )}
                    </div>
                    <div className="coupon-enter-in">
                      <div className="form-check">
                        <input
                          className="form-check-input cp "
                          type="checkbox"
                          id="gift_card"
                          checked={formik.values.isGiftCardChecked}
                          onChange={(e) => {
                            handelGiftCardChange(e?.target?.checked)
                          }}
                        />
                        <label className="form-check-label cp" htmlFor="gift_card">
                          {t("label_gift_card")}
                        </label>
                      </div>
                      {formik.values.isGiftCardChecked && (
                        <div className="coupon-input">
                          <label className="form-label">{t("enter_gift_card_amount")}</label>
                          <div className="input-group">
                            <span className="input-group-text" id="basic-addon1"> ¥ </span>
                            <input type="number" className="form-control no_spring_input pe-73px" placeholder={t("enter_gift_card_amount")} {...formik.getFieldProps("gift_card_amount")} />
                            <button type="button" className="btn btn-primary" disabled={!formik?.values?.gift_card_amount} onClick={applyGiftCardAmount} style={{ position: 'static' }}>
                              {t("label_apply")}
                            </button>
                          </div>

                          {formik.touched.gift_card_amount && formik.errors.gift_card_amount ? <div className="text-danger">{formik.errors.gift_card_amount}</div> : null}
                        </div>
                      )}
                    </div>
                    {/* <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-primary" >
                            {t("btn_kitchen_preview")}
                          </button> )}
                  content={() => printSlipRef.current}
                  pageStyle="@media print {.no-print {display: none;}}"
                /> */}
                    {/* <button type="button" className={"btn btn-primary" + (kitchenPrintHtmlLoading ? " btn-loader" : "")} onClick={() => { handlePreview(params.tableId , 'all') }} >
                      {t("btn_kitchen_preview")}
                    </button>
                    <button type="button" className={"btn btn-primary" + (foodPrintHtmlLoading ? " btn-loader" : "")} onClick={() => { handlePreview(params.tableId , 'food') }} >
                      {t("btn_food_preview")}
                    </button>
                    <button type="button" className={"btn btn-primary" + (drinkPrintHtmlLoading ? " btn-loader" : "")} onClick={() => { handlePreview(params.tableId , 'drink') }} >
                      {t("btn_drink_preview")}
                    </button> */}
                    {restSettingDetails?.service_charge_applicable_condition !== "always" && restSettingDetails?.is_service_charge_enabled ?
                      <div className="coupon-enter-in">
                        <div className="form-check">
                          <input
                            className="form-check-input cp "
                            type="checkbox"
                            id="service_charge_percentage"
                            checked={serviceChargeFormik.values.is_service_charge_percentage}
                            // checked={serviceChargeApplied}
                            onChange={(e) => {
                              handelServiceChargePercentageChange(e?.target?.checked)
                            }}
                          />
                          <label className="form-check-label cp" htmlFor="service_charge_percentage">
                            {t("service_charges")}
                          </label>
                        </div>
                        {serviceChargeFormik?.values?.is_service_charge_percentage &&
                          <div className="coupon-input">
                            <label className="form-label">{t("service_charges")} (%)</label>
                            <div className="input-group">
                              {/* <span className="input-group-text" id="basic-addon1"> ¥ </span> */}
                              <input type="number" className="form-control no_spring_input pe-73px" placeholder={t("enter_service_charge")} {...serviceChargeFormik.getFieldProps("service_charge_percentage")} />
                              <button type="button" className="btn btn-primary" disabled={(Object.keys(serviceChargeFormik?.errors).length) || serviceChargeFormik?.values?.service_charge_percentage === tableDetail?.activeOrder?.service_charge_percentage} onClick={applyServiceCharge} style={{ position: 'static' }}>
                                {t("label_apply")}
                              </button>
                            </div>
                            {serviceChargeFormik.touched.service_charge_percentage && serviceChargeFormik.errors.service_charge_percentage ? <div className="text-danger">{serviceChargeFormik.errors.service_charge_percentage}</div> : null}
                          </div>
                        }
                      </div> : ''
                    }
                  </div>
                )}
                  <div className="subtotal-info p-3">
                    <ul className=" d-flex flex-column gap-3">
                      <li>
                        <h4>
                          {t("sub_total_label")} ({orderItems?.length} {pluralText(orderItems?.length, t("label_item"), t("label_items"))})
                        </h4>
                        <h4>
                          ¥{" "}

                          {showPrice(orderPriceDetail?.subtotal)}
                        </h4>
                      </li>
                      {/* {restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED && (
                        <span style={{ fontSize: "12px", marginTop: "-12px", color: "#424242" , padding:"0px 5px" , fontWeight:"500"}}>
                           ( {t('tax_included_label')})</span>
                      )} */}

                      {orderPriceDetail?.service_charge ? (
                        <li>
                          <h4>{t("service_charge")}</h4>
                          <h4>¥ {showPrice(orderPriceDetail?.service_charge)}</h4>
                        </li>
                      ) : null}
                      <li>
                        <h4>{t("label_consumption_tax")}</h4>
                        <h4>¥ {showPrice(orderPriceDetail?.tax_price)}</h4>
                      </li>
                      {
                        orderPriceDetail?.coupon_code_applied || couponCodeApplied ? <li>
                          <h4>
                            {t("label_coupon_discount")}
                          </h4>
                          <h4>
                            {orderPriceDetail?.coupon_type === "percentage" ? <span>{showPrice(orderPriceDetail?.coupon_discount)}%</span> : <span>¥{" "}{showPrice(orderPriceDetail?.coupon_code_amount)}</span>}

                          </h4>
                        </li> : ""
                      }

                    </ul>

                    <p>
                      8% {t("label_take_out_tax")} ¥{showPrice(orderPriceDetail?.total_tax_8)}
                    </p>


                    <p>
                      10% {t("label_dine_in_tax")} ¥{showPrice(orderPriceDetail?.total_tax_10)}
                    </p>
                     {
                      orderPriceDetail?.total_tax_8 && orderPriceDetail?.total_tax_8>0 && restSettingDetails.icon_display_eligible_for_reduced_tax_rate ?   <p className="d-block no-wrap">★ {t('tax_on_other_text')} <br />{t('tax_rate_8')}</p> : ""
                     }
                   
                  </div>
                  {/* onClick={() => navigate(`/cashier/payment-via-cash/${tableId}`)} */}
                  {/* <button className="btn btn-primary d-flex justify-content-between w-100 btn-total" type="submit">
                    <span>{t("label_total_capitalize")}</span>
                    <span>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                  </button> */}
                  <button className="btn btn-primary d-flex justify-content-between w-100 btn-total" type="submit" disabled={orderType && orderType==='edit-sales'}>
                    <span>{t("label_total_capitalize")}</span>
                    <div className="text-end">
                      {giftCardApplied || couponCodeApplied ? (
                        <>
                          <span className="text-decoration-line-through" style={{ fontSize: 'medium' }}>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                          <span className="d-block"> ¥ {showPrice(orderPriceDetail?.amount_after_discount)}</span>
                        </>
                      ) : (
                        <span>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                      )}
                    </div>
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-none" >
        <ReactToPrint trigger={() =>
          <button className='keypad-action-btns' ref={printSlipTriggerRef}> </button>
        } content={() => printSlipRef.current} />
        <div className="w-100" ref={printSlipRef} dangerouslySetInnerHTML={{ __html: kitchenPrintHtml ? kitchenPrintHtml : "N/A" }}></div>
      </div>


      <div className="modal fade unique-modal-class" data-bs-backdrop="static" id="quantity-modal-id" tabIndex="-1" aria-labelledby="update-quantity-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header d-flex justify-content-between">
              <h1 className="modal-title fs-5 w-100" id="unique-modalLabel">
                Enter Menu Item Quantity
              </h1>
              <button
                type="button"
                className="btn btn-icon ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique-close-modal"
                ref={closeEditModal}
                onClick={() => {
                  editQuantityForm.resetForm();
                }}
              >
                <i className="ti ti-x"></i>
              </button>
            </div>

            <div className="modal-body p-2">
              <form onSubmit={editQuantityForm.handleSubmit}>
                <div className="input-group p-2">
                  <input
                    type="text"
                    placeholder="Enter Quantity"
                    className="form-control"
                    name="quantity"
                    value={editQuantityForm?.values?.quantity}
                    onChange={(e) => {
                      setIsDirty(false);
                      editQuantityForm.handleChange(e);
                    }}
                    onBlur={editQuantityForm.handleBlur}
                  />
                  <CustomError name="quantity" form={editQuantityForm} className="text-danger" />
                </div>

                <div className="d-flex justify-content-end m-2">
                  <button type="submit" className="btn btn-primary" style={{ width: "100px" }} disabled={isDirty}>
                    {t('btn_save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashierHomeLayout;
