import { createSlice } from "@reduxjs/toolkit";

export const QrCodeSettingsSlice = createSlice({
  name: "QrOrderToken",
  initialState: {
    qrPanelToken: "",
    tableDetails: "" , 
    ChargeSettings: {},
    CartLength: 0,
  },
  reducers: {
    addQrPanelToken: (state, action) => {
      state.qrPanelToken = action.payload;
    },
    addTableDetails: (state, action) => {
      state.tableDetails = action.payload;
    },
    ChargeSettingsDetail : (state, action) => {
      state.ChargeSettings = action.payload;
    },
    SetCartLength : (state, action) => {
      state.CartLength = action.payload;
    },
}
});

export const { addQrPanelToken, addTableDetails , ChargeSettingsDetail, SetCartLength } = QrCodeSettingsSlice.actions;
export default QrCodeSettingsSlice.reducer;
