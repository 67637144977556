import React, { useEffect, useMemo, useRef, useState } from "react";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { ReservationsList, add, cancelReservation, deleteReservation, edit, reservationDetails } from "../../../services/reservation.service";
import { list, detail, tableOptions } from "../../../services/table.service";
import { ACTIVE, DEACTIVE, LOADER_TIMEOUT_TIME, PHONE_NO_LENGTH, RESERVATION_STATUS, SOCKET_EVENTS, SORT_A, SORT_D, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
  DT,
  formateDate,
  formatePickerDate,
  get24HoursFormatedTime,
  getDate,
  getFormatedTime,
  getSessionKey,
  globalLoader,
  handleOffcanvasScroll,
  handleServerValidations,
  TrimText,
  useDebouncing,
} from "../../../../../utils/commonfunction";
import CustomError from "../../../../../utils/customError";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RecordNotFound from "../../../shared/recordNotFound";
import PhoneInput from "../../../../../utils/PhoneInput";
import { DatePicker, Drawer } from "rsuite";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../../../admin/common/custompagination";
import isBefore from "date-fns/isBefore";
import logo from '../../../../../assets/restaurant/images/calenderLogo.png'; 
import { SOCKET_CLIENT } from "../../../../../utils/socket";


const CustomBase64Caret = () => (
  <img 
    src={logo}
    alt="Custom Caret" 
    style={{ width: 20, height: 20 }} 
  />
);

export default function Reservations() {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [List, setList] = useState([]);
  const { addResevationValidationSchema } = useValidationSchemas();
  const [refreshList, setRefreshList] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
  const [prevGlobalSearch, setPrevGlobalSearch] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [loader, setLoader] = useState(true);
  const [reservationDetail, setReservationDetail] = useState({});
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage] = useState(10);
  const [employeeId, setEmployeeId] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [tableList, setTableList] = useState([]);
  const [tableDetail, setTableDetail] = useState([]);
  const [tableOptionsList, setTableOptionsList] = useState([]);
  const { table_id} = location.state || {};


  // State for search input and select dropdown
  const [searchValue, setSearchValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [numberOfPersonOptions, setNumberOfPersonOptions] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [sortConfig, setSortConfig] = useState({ column: "date", order: SORT_D });
  const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
  const [selectedTable, setSelectedTable] = useState(table_id||null );

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const prepareGlobalSearch = (value) => {
    setPrevGlobalSearch(globalSearch);
    setGlobalSearch(value);
  };
  const searchDebounce = useDebouncing(prepareGlobalSearch);

  const handleFilterStatusChange = (e) => {
    if (e.target.value) {
      prepareSearch("status", e.target.value);
    } else {
      prepareSearch("status", "");
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    searchDebounce(value);
  };

  // Handle select dropdown change
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedStatus(value);
    handleFilterStatusChange(e);
  };
  const handleTableChange = (e) => {
    const value = e.target.value;
    setSelectedTable(value);
  };
  const handleDateChange = (e) => {
    const value = e;
    setDateValue(value);
  };

  useEffect(() => {
    list()
      .then((data) => {
        if (data && data.data) {
          setTableList(data.data.list || []);
        } else {
          setTableList([]);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, []);

  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        const tableDetails = response?.data;
        setTableDetail(tableDetails);
        if (tableDetails && tableDetails?.capacity) {
          let i = 1;
          const numberOfPersonOptionsTemp = [];
          for (i; i <= tableDetails?.capacity; i++) {
            numberOfPersonOptionsTemp.push(i);
          }
          setNumberOfPersonOptions(numberOfPersonOptionsTemp);
          addForm.setFieldValue("table_number", tableDetails?.table_number);
          editForm.setFieldValue("table_number", tableDetails?.table_number);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    if (Object.keys(search).length) {
      formData.append("search", JSON.stringify(search));
    }
    if (selectedTable) {
      formData.append("table_id", selectedTable);
    }
    if (dateValue) {
      // console.log('check' , dateValue)
      formData.append("date_search", JSON.stringify({ date: dateValue }));
    }
    formData.append("global_search", globalSearch);
    // Determine the page number
    let page = params.page;
    if (globalSearch) {
      if (prevGlobalSearch !== globalSearch || !prevGlobalSearch) {
        page = 1; // Reset to the first page if globalSearch changes
      }
      setPage(page);
    }
    formData.append("page", page);
    formData.append("per_page", itemPerPage);
    if (sortConfig) {
      formData.append("sort", JSON.stringify(sortConfig));
    }

    ReservationsList(formData)
      .then((data) => {
        if (data && data.data) {
          setList(data.data.list || []);
          setPage(data.data.page);
          setOffsetPage(data.data.offset);
          setTotalRecords(data.data.total_records || 0);
        } else {
          setList([]);
          setOffsetPage(0);
          setTotalRecords(0);
        }
        setPrevGlobalSearch(globalSearch);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [search, refreshList, globalSearch, params.page, dateValue, sortConfig, selectedTable]);

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const checkReservationStatus = (item) => {
    if (!item) return null;
    const today = new Date();
    const endDate = new Date(item.end_time);
    if (endDate < today) {
      return RESERVATION_STATUS.find((status) => status.value === "passed")?.label;
    }
    const currentStatus = RESERVATION_STATUS.find((status) => status.value === item.status);
    return currentStatus ? currentStatus.label : null;
  };

  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };

  const addForm = useFormik({
    initialValues: {
      table_id: "",
      number_of_guest: "",
      customer_name: "",
      customer_email: "",
      customer_phone_number: "",
      reservation_date: "",
      start_time: "",
      table_number: "",
      end_time: "",
    },
    validationSchema: addResevationValidationSchema,
    validate: (values) => {
      const errors = {};
      if (values.table_id && tableDetail) {
        const capacity = tableDetail.capacity;
        if (values.number_of_guest > capacity) {
          errors.number_of_guest = DT(t("err_number_of_guest"), [capacity]);
        }
      }

      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("table_id", values.table_id);
      formData.append("number_of_guest", values.number_of_guest);
      formData.append("customer_name", values.customer_name);
      formData.append("customer_email", values.customer_email);
      formData.append("customer_phone_number", values.customer_phone_number);
      const reservationDate = new Date(values.reservation_date);
      const startTime = new Date(reservationDate);
      startTime.setHours(new Date(values.start_time).getHours());
      startTime.setMinutes(new Date(values.start_time).getMinutes());

      const endTime = new Date(reservationDate);
      endTime.setHours(new Date(values.end_time).getHours());
      endTime.setMinutes(new Date(values.end_time).getMinutes());

      formData.append("date", reservationDate);
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("table_number", values.table_number);
      setAddSubmissionLoader(true);
      add(formData)
        .then((response) => {
          setAddSubmissionLoader(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm();
            setOpenWithHeader(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          setAddSubmissionLoader(false);
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  const editForm = useFormik({
    initialValues: {
      reservation_id: "",
      table_id: "",
      number_of_guest: "",
      customer_name: "",
      customer_email: "",
      customer_phone_number: "",
      reservation_date: "",
      start_time: "",
      end_time: "",
      table_number: "",
    },
    validationSchema: addResevationValidationSchema,
    validate: (values) => {
      const errors = {};
      if (values.table_id && tableDetail) {
        const capacity = tableDetail.capacity;
        if (values.number_of_guest > capacity) {
          errors.number_of_guest = DT(t("err_number_of_guest"), [capacity]);
        }
      }

      return errors;
    },

    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("reservation_id", reservationDetail._id);
      formData.append("table_id", values.table_id);
      formData.append("number_of_guest", values.number_of_guest);
      formData.append("customer_name", values.customer_name);
      formData.append("customer_email", values.customer_email);
      formData.append("customer_phone_number", values.customer_phone_number);
      const reservationDate = new Date(values.reservation_date);
      const startTime = new Date(reservationDate);
      startTime.setHours(new Date(values.start_time).getHours());
      startTime.setMinutes(new Date(values.start_time).getMinutes());

      console.log("startTime", startTime);
      const endTime = new Date(reservationDate);
      endTime.setHours(new Date(values.end_time).getHours());
      endTime.setMinutes(new Date(values.end_time).getMinutes());

      formData.append("date", reservationDate);
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("table_number", values.table_number);
      edit(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm();
            setOpenWithHeaderEdit(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  const deleteRecord = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteReservation({ o_id: item._id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError");
          });
      } else {
        // setSortableCategories(List);
      }
    });
  };

  const reservationCancel = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_cancel_reservation_text"),
      // imageUrl: trash,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_cancel_reservation"),
      cancelButtonText: t("false_label_no"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        cancelReservation({ o_id: item._id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError");
          });
      } else {
        // setSortableCategories(List);
      }
    });
  };

  const getReservationDetail = (item) => {
    reservationDetails({ o_id: item._id })
      .then((response) => {
        editForm.setValues({
          reservation_id: response?.data?._id || "",
          table_id: response?.data?.tableDetails?._id || "",
          number_of_guest: response?.data?.number_of_guest || "",
          customer_name: response?.data?.customer_name || "",
          customer_email: response?.data?.customer_email || "",
          customer_phone_number: response?.data?.customer_phone_number || "",
          reservation_date: response?.data?.date || "",
          start_time: response?.data?.start_time || "",
          end_time: response?.data?.end_time || "",
          table_number: response?.data?.tableDetails?.table_number || "",
        });
        setReservationDetail(response?.data);
        getTableDetail(response?.data?.tableDetails?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    tableOptions(true).then((response) => {
      setTableOptionsList(response?.data || []);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    SOCKET_CLIENT.on("GET-DATA", (response) => {
      if (response.event === SOCKET_EVENTS.RESERVATION_ADDED) {
       setRefreshList(!refreshList);
      }
      if (response.event === SOCKET_EVENTS.RESERVATION_CANCELLED) {
        setRefreshList(!refreshList);
      }
    });
  }, []);

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">
            {t("label_reservations")} ({totalRecords})
          </h4>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <div className="search-box">
            <input type="text" value={searchValue} className="form-control search-input" placeholder={t("input_placeholder_search")} onChange={handleSearchChange} />
          </div>
          <div className="card-head-group d-flex align-items-center gap-2">
            <div className="tabls-dowpdown">
              <DatePicker
                oneTap={true}
                classPrefix="restaurant"
                placeholder="DD/MM/YYYY"
                placement="autoVertical"
                format="dd/MM/yyyy"
                cleanable={true}
                editable={false}
                // value={dateValue}
                onChange={handleDateChange}
                caretAs={CustomBase64Caret}
              />
            </div>
            <div className="tabls-dowpdown">
              <select className="form-control cp" value={selectedTable} onChange={handleTableChange}>
                <option value={""}>{t("option_select_table")}</option>
                {tableOptionsList.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {TrimText(item?.table_number, 15)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="tabls-dowpdown">
              <select className="form-control cp" value={selectedStatus} onChange={handleSelectChange}>
                <option disabled="" value="">
                  {t("label_select_status")}
                </option>

                {RESERVATION_STATUS.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {t(item.label)}
                    </option>
                  );
                })}
              </select>
            </div>

            <button
              className="btn btn-primary"
              onClick={() => {
                setOpenWithHeader(true);
              }}
            >
              <i className="ti ti-plus"></i> {t("label_make_reservation")}
            </button>
          </div>
        </div>

   
        <div className="dashboard-content-wraper">
          <div className="category-list-wrap">
            {loader ? (
              <div className="w-100">
                <Skeleton className="mb-2" height={50} count={5} />
              </div>
            ) : List?.length ? (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("s_no")}</th>
                          <th>
                            {t("label_table_number")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("table_number")}>
                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "table_number" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "table_number" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                            </span>
                          </th>
                          <th>
                            {t("label_guest_name")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("customer_name")}>
                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "customer_name" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "customer_name" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                            </span>
                          </th>
                          <th>
                            {t("contact_no")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("customer_phone_number")}>
                              <i
                                className={`ti ti-arrow-narrow-up ${sortConfig.column === "customer_phone_number" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`}
                                style={{ marginLeft: "5px" }}
                              ></i>
                              <i
                                className={`ti ti-arrow-narrow-down ${sortConfig.column === "customer_phone_number" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`}
                                style={{ marginLeft: "-8px" }}
                              />
                            </span>
                          </th>
                          {/* <th>{t("number_of_guests_text")}</th> */}
                          <th>
                            {t("number_of_guests_text")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("number_of_guest")}>
                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "number_of_guest" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                              <i
                                className={`ti ti-arrow-narrow-down ${sortConfig.column === "number_of_guest" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`}
                                style={{ marginLeft: "-8px" }}
                              />
                            </span>
                          </th>
                          <th>
                            {t("input_placeholder_date")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("date")}>
                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "date" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "date" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                            </span>
                          </th>

                          <th>
                            {t("label_time")}
                            <span style={{ cursor: "pointer" }} onClick={() => handleSort("start_time")}>
                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "start_time" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "start_time" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                            </span>
                          </th>
                          <th>{t("list_heading_status")} </th>
                          <th style={{ width: "150px" }}>{t("label_action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {List.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{offset + index + 1}</td>
                              <td>{TrimText(item?.tableDetails?.table_number, 15)}</td>
                              <td className="text-capitalize">{TrimText(item?.customer_name, 15) || t("label_not_entered")}</td>
                              <td>{item?.customer_phone_number || t("label_not_entered")}</td>
                              <td>{item?.number_of_guest}</td>
                              <td>{formateDate(item?.date)}</td>

                              <td>{get24HoursFormatedTime(item?.start_time)}</td>
                              <td>
                                <span
                                  className={`status-badge d-block ${checkReservationStatus(item) === "label_upcoming" ? "status-badge-success" : "status-badge-danger"}`}
                                  style={{ width: "max-content" }}
                                >
                                  {t(checkReservationStatus(item))}
                                </span>
                              </td>
                              <td className="d-flex">
                                <div className="dropdown ms-2">
                                  <button className="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="ti ti-dots-vertical"></i>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                      <a
                                        className="dropdown-item cp"
                                        onClick={() => {
                                          getReservationDetail(item);
                                        }}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#view-staff"
                                      >
                                        <i className="ti ti-eye"></i>
                                        {t("btn_view")}
                                      </a>
                                    </li>
                                    {checkReservationStatus(item) === "label_upcoming" && (
                                      <>
                                        <li>
                                          <a
                                            className="dropdown-item cp"
                                            onClick={() => {
                                              getReservationDetail(item);
                                              setOpenWithHeaderEdit(true);
                                            }}
                                          >
                                            <i className="ti ti-edit"></i>
                                            {t("btn_edit")}
                                          </a>
                                        </li>

                                        <li>
                                          <a
                                            className="dropdown-item cp"
                                            onClick={() => {
                                              reservationCancel(item);
                                            }}
                                          >
                                            <i className="ti ti-circle-x"></i>
                                            {t("btn_cancel")}
                                          </a>
                                        </li>
                                      </>
                                    )}

                                    <li>
                                      <a
                                        className="dropdown-item cp"
                                        onClick={() => {
                                          deleteRecord(item);
                                        }}
                                      >
                                        <i className="ti ti-trash"></i>
                                        {t("btn_delete")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-status-info d-flex justify-content-between align-items-center">
                    <span>
                      {t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}
                    </span>
                    <nav aria-label="Page navigation example" title={page}>
                      {totalRecords && totalRecords > 0 ? (
                        <CustomPagination
                          datalength={totalRecords}
                          itemperpage={itemPerPage}
                          currentPage={page}
                          custompath="page"
                          setPage={setPage}
                          pageRoute={[{ name: "reservations", path: "/restaurant/table-management/reservations/list" }]}
                        />
                      ) : (
                        ""
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            ) : (
              <RecordNotFound heading="no_reservations_found" />
            )}
          </div>
        </div>
        <div>
          {/* <button className='btn btn-success' data-bs-toggle="offcanvas" data-bs-target="#view-staff">view staff details</button>
                    <button className='btn btn-danger ms-2' data-bs-toggle="modal" data-bs-target="#shift-history">view staff details</button> */}
        </div>
      </div>

      {/* add item modal  */}
      {/* {console.log(addForm.errors)}  */}
      <Drawer open={openWithHeader}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("label_make_reservations")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeader(false);
                addForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editNewEmail", "rsuite");
          }}
        >
          <form onSubmit={addForm.handleSubmit} noValidate>
            {/* Select Table */}
            <div className="mb-3">
              <label className="form-label">
                {t("select_table_number")}
                <span>*</span>
              </label>
              <select
                className="form-control cp"
                name="table_id"
                value={addForm?.values?.table_id}
                onChange={(e) => {
                  addForm.handleChange(e);
                  getTableDetail(e?.target?.value);
                }}
                onBlur={addForm.handleBlur}
              >
                <option disabled value="">
                  {t("input_select_text")}
                </option>
                {tableList.map((item, index) => (
                  <option key={index} value={item._id}>
                    {TrimText(item.table_number, 20)}
                  </option>
                ))}
              </select>
              <CustomError name="table_id" form={addForm} className="text-danger" />
            </div>

            {/* Number of Guests */}
            <div className="mb-3">
              <label className="form-label">
                {capitalizeFirstLetterOfEachWord(t("number_of_guests_text"))}
                <span>*</span>
              </label>
              <input
                type="number"
                className="form-control no_spring_input"
                placeholder={t("placeholder_enter_number_of_guest")}
                name="number_of_guest"
                value={addForm?.values?.number_of_guest}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
                max={tableDetail?.capacity}
               
              />
              {/* <select
                                className="form-control form-control-placeholder cp"
                                name="number_of_guest"
                                value={addForm?.values?.number_of_guest}
                                onChange={(e) => {
                                    addForm.setFieldValue(
                                        "number_of_guest",
                                        e.target.value ? Number(e.target.value) : ""
                                    );
                                }}
                            >
                                <option value={""}>{t("input_select_text")}</option>
                                {numberOfPersonOptions?.map((option, index) => {
                                    return (
                                        <option value={option} key={index}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select> */}
              <CustomError name="number_of_guest" form={addForm} className="text-danger" />
            </div>

            {/* Guest Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_guest_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="customer_name"
                value={addForm?.values?.customer_name}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="customer_name" form={addForm} className="text-danger" />
            </div>

            {/* Guest Email */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_guest_email")}
                <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="customer_email"
                value={addForm?.values?.customer_email}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="customer_email" form={addForm} className="text-danger" />
            </div>

            {/* Phone Number */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_phone_number")}
                <span>*</span>
              </label>
              <PhoneInput
                value={addForm?.values?.customer_phone_number}
                name="customer_phone_number"
                onChange={(e) => addForm.setFieldValue("customer_phone_number", e?.phone_number)}
                placeholder={t("placeholder_phone_number")}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="customer_phone_number" form={addForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
            </div>

            {/* Reservation Date */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_date")}
                <span>*</span>
              </label>
              <DatePicker
                oneTap={true}
                classPrefix="restaurant"
                placeholder={t("placeholder_date")}
                placement="autoVertical"
                format="dd/MM/yyyy"
                cleanable={false}
                editable={false}
                name="reservation_date"
                shouldDisableDate={(date) => isBefore(date, new Date(yesterday))}
                onChange={(e) => {
                  addForm.setFieldTouched("reservation_date", true);
                  addForm.setFieldValue("reservation_date", e);
                }}
                value={new Date(addForm.values.reservation_date)}
                onBlur={addForm.handleBlur}
                caretAs={CustomBase64Caret}
              />
              <CustomError name="reservation_date" form={addForm} className="text-danger" />
            </div>

            {/* Start Time */}
            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <label className="form-label">
                    {t("start_time")}
                    <span>*</span>
                  </label>
                  <DatePicker
                    classPrefix="restaurant"
                    placement="autoVertical"
                    format="HH:mm"
                    cleanable={false}
                    editable={true}
                    name="start_time"
                    onChange={(e) => {
                      addForm.setFieldTouched("start_time", true);
                      addForm.setFieldValue("start_time", e);
                    }}
                    value={new Date(addForm.values.start_time)}
                    onBlur={addForm.handleBlur}
                  />
                  <CustomError name="start_time" form={addForm} className="text-danger" />
                </div>
                <div className="col">
                  <label className="form-label">
                    {t("end_time")}
                    <span>*</span>
                  </label>
                  <DatePicker
                    classPrefix="restaurant"
                    placement="autoVertical"
                    format="HH:mm"
                    cleanable={false}
                    editable={true}
                    name="end_time"
                    onChange={(e) => {
                      addForm.setFieldTouched("end_time", true);
                      addForm.setFieldValue("end_time", e);
                    }}
                    value={new Date(addForm.values.end_time)}
                    onBlur={addForm.handleBlur}
                  />
                  <CustomError name="end_time" form={addForm} className="text-danger" />
                </div>
              </div>
            </div>

            {/* End Time */}
            {/* <div className="mb-3">
                            <label className="form-label">
                                {t("end_time")}
                                <span>*</span>
                            </label>
                            <DatePicker classPrefix="restaurant" placement="autoVertical" format="HH:mm" cleanable={false} editable={true} name="end_time" onChange={(e) => {
                                addForm.setFieldTouched('end_time', true);
                                addForm.setFieldValue("end_time", e);
                            }} value={new Date(addForm.values.end_time)}
                                onBlur={addForm.handleBlur} />
                            <CustomError name="end_time" form={addForm} className="text-danger" />
                        </div> */}

            {/* Submit Button */}
            <div className="button-form">
              <button type="submit" className={"btn btn-primary w-100" + (addSubmissionLoader ? " btn-loader" : "")}>
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      <Drawer open={openWithHeaderEdit}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("heading_edit_reservation")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeaderEdit(false);
                editForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editEmail", "rsuite");
          }}
        >
          <form onSubmit={editForm.handleSubmit} noValidate>
            {/* Select Table */}
            <div className="mb-3">
              <label className="form-label">
                {t("select_table_number")}
                <span>*</span>
              </label>
              <select
                className="form-control cp"
                name="table_id"
                value={editForm?.values?.table_id}
                onChange={(e) => {
                  editForm.handleChange(e);
                  getTableDetail(e?.target?.value);
                }}
                onBlur={editForm.handleBlur}
              >
                <option disabled value="">
                  {t("input_select_text")}
                </option>
                {tableList.map((item, index) => (
                  <option key={index} value={item._id}>
                    {TrimText(item.table_number, 20)}
                  </option>
                ))}
              </select>
              <CustomError name="table_id" form={editForm} className="text-danger" />
            </div>

            {/* Number of Guests */}
            <div className="mb-3">
              <label className="form-label">
                {t("number_of_guests_text")}
                <span>*</span>
              </label>
              <input
                type="number"
                className="form-control no_spring_input"
                placeholder={t("placeholder_enter_number_of_guest")}
                name="number_of_guest"
                value={editForm?.values?.number_of_guest}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
                max={tableDetail?.capacity}
              />
              {/* <select
                                className="form-control form-control-placeholder cp"
                                name="number_of_guest"
                                value={editForm?.values?.number_of_guest}
                                onChange={(e) => {
                                    editForm.setFieldValue(
                                        "number_of_guest",
                                        e.target.value ? Number(e.target.value) : ""
                                    );
        
                                }}
                            >
                                <option value={""}>{t("input_select_text")}</option>
                                {numberOfPersonOptions?.map((option, index) => {
                                    return (
                                        <option value={option} key={index}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select> */}
              <CustomError name="number_of_guest" form={editForm} className="text-danger" />
            </div>

            {/* Guest Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_guest_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="customer_name"
                value={editForm?.values?.customer_name}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="customer_name" form={editForm} className="text-danger" />
            </div>

            {/* Guest Email */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_guest_email")}
                <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="customer_email"
                value={editForm?.values?.customer_email}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="customer_email" form={editForm} className="text-danger" />
            </div>

            {/* Phone Number */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_phone_number")}
                <span>*</span>
              </label>
              <PhoneInput
                value={editForm?.values?.customer_phone_number}
                name="customer_phone_number"
                onChange={(e) => editForm.setFieldValue("customer_phone_number", e?.phone_number)}
                placeholder={t("placeholder_phone_number")}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="customer_phone_number" form={editForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
            </div>

            {/* Reservation Date */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_date")}
                <span>*</span>
              </label>
              <DatePicker
                shouldDisableDate={(date) => isBefore(date, new Date(yesterday))}
                oneTap={true}
                classPrefix="restaurant"
                placeholder={t("placeholder_date")}
                placement="autoVertical"
                format="dd/MM/yyyy"
                cleanable={false}
                editable={false}
                name="reservation_date"
                onChange={(e) => {
                  editForm.setFieldTouched("reservation_date", true);
                  editForm.setFieldValue("reservation_date", e);
                }}
                value={new Date(editForm.values.reservation_date)}
                onBlur={editForm.handleBlur}
                caretAs={CustomBase64Caret}
              />
              <CustomError name="reservation_date" form={editForm} className="text-danger" />
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <label className="form-label">
                    {t("start_time")}
                    <span>*</span>
                  </label>
                  <DatePicker
                    classPrefix="restaurant"
                    placement="autoVertical"
                    format="HH:mm"
                    cleanable={false}
                    editable={true}
                    name="start_time"
                    onChange={(e) => {
                      editForm.setFieldTouched("start_time", true);
                      editForm.setFieldValue("start_time", e);
                    }}
                    value={new Date(editForm.values.start_time)}
                    onBlur={editForm.handleBlur}
                  />
                  <CustomError name="start_time" form={editForm} className="text-danger" />
                </div>
                <div className="col">
                  <label className="form-label">
                    {t("end_time")}
                    <span>*</span>
                  </label>
                  <DatePicker
                    classPrefix="restaurant"
                    placement="autoVertical"
                    format="HH:mm"
                    cleanable={false}
                    editable={true}
                    name="end_time"
                    onChange={(e) => {
                      editForm.setFieldTouched("end_time", true);
                      editForm.setFieldValue("end_time", e);
                    }}
                    value={new Date(editForm.values.end_time)}
                    onBlur={editForm.handleBlur}
                  />
                  <CustomError name="end_time" form={editForm} className="text-danger" />
                </div>
              </div>
            </div>
            {/* Address */}

            {/* Submit Button */}
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_update")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      <div className="offcanvas offcanvas-end right-modal-w" id="view-staff" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("reservation_details")}
          </h5>
          <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="view-staff-info">
            <div className="row gap-4">
              <div className="card-body">
                <div className="table-main-box">
                  <div className="table-name h-auto">{reservationDetail?.tableDetails?.table_number}</div>
                  <p>
                    {t("number_of_guests_text")} {reservationDetail?.number_of_guest}
                  </p>
                </div>
              </div>

              <div className="col-md-12">
                <h4>{t("label_guest_name")}</h4>
                <p>{reservationDetail?.customer_name ? reservationDetail.customer_name : t("label_not_entered")}</p>
              </div>

              <div className="col-md-12">
                <h4>{t("label_guest_email")}</h4>
                <p>{reservationDetail?.customer_email ? reservationDetail.customer_email : t("label_not_entered")}</p>
              </div>

              <div className="col-md-12">
                <h4>{t("label_phone_number")}</h4>
                <p>{reservationDetail?.customer_phone_number ? reservationDetail.customer_phone_number : t("label_not_entered")}</p>
              </div>

              <div className="col-md-12">
                <h4>{t("date_time_label")}</h4>
                <p>
                  {formateDate(reservationDetail.date)} {get24HoursFormatedTime(reservationDetail?.start_time)} <i class="ti ti-minus"></i> {get24HoursFormatedTime(reservationDetail?.end_time)}
                </p>
              </div>

              <div className="col-md-12">
                <h4>{t("reservation_status")}</h4>
                <p>{reservationDetail.status === "cancelled" ? t("label_cancelled") : new Date(reservationDetail.end_time) > new Date() ? t("label_upcoming") : t("label_passed")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
