import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "cashier/tables"
const options = {role: ROLE.CASHIER};

export const list = async (values ) => {
    return await axiosInstance(options).post(`${path}/list` , values);
}

export const detail = async (values) => {
    return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const categoryList = async (values ) => {
    return await axiosInstance(options).get(`${path}/categoriesList` , values);
}

export const categoryDetail = async (values) => {
    return await axiosInstance(options).get(`${path}/categoryDetail?o_id=${values.o_id}`, values);
}

export const menuList = async (values ) => {
    return await axiosInstance(options).post(`${path}/menuList` , values);
}

export const PaymentMethodList = async (values ) => {
    return await axiosInstance(options).post(`${path}/paymentMethodlist` , values);
}

export const getRestaurantSettings = async () => {
    return await axiosInstance(options).get(`${path}/restaurant-settings`)
}
  

export const payByCash = async (values) => {
    return await axiosInstance(options).post(`${path}/pay-by-cash` , values);
}

export const updateCustomerInfo = async (values ) => {
    return await axiosInstance(options).post(`${path}/update-customer-info` , values);
}


export const applyGiftCard = async (values ) => {
    return await axiosInstance(options).post(`${path}/apply-gift-card` , values);
}


export const removeGiftCard = async (values ) => {
    return await axiosInstance(options).get(`${path}/remove-gift-card?order_id=${values.order_id}` , values);
}

export const removeServiceCharge = async (values ) => {
    return await axiosInstance(options).get(`${path}/remove-service-charge?order_id=${values.order_id}`);
}


export const transactionDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/transaction-detail?o_id=${values.o_id}&type=${values.type}`, values);
}

export const orderDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/order-detail?o_id=${values.o_id}`, values);
}

export const receiptDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/receipt`, values);
}



export const applyCouponCode = async (values ) => {
    return await axiosInstance(options).post(`${path}/apply-coupon-code` , values);
}


export const removeCouponCode = async (values ) => {
    return await axiosInstance(options).get(`${path}/remove-coupon-code?order_id=${values.order_id}` , values);
}

export const getKitchenPrintHtml = async (values , option ) => {
    return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).get(`${path}/kitchen-print?table_id=${values.tableId}&type=${values.type}`);
}

export const updateServiceCharge = async (values ) => {
    return await axiosInstance(options).put(`${path}/update-service-charge?order_id=${values.order_id}`, values);
}


export const addNewMenuItem = async (values ) => {
    return await axiosInstance(options).post(`${path}/add-new-item` , values);
}

export const deleteMenuItem = async (values ) => {
    return await axiosInstance(options).post(`${path}/delete-item` , values);
}

export const updateMenuItem = async (values ) => {
    return await axiosInstance(options).post(`${path}/update-item` , values);
}

export const updateNetworkSetting = async (values ) => {
    return await axiosInstance(options).put(`${path}/update-network` , values);
}