import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/coupons";
const options = {role: ROLE.RESTAURANT};


export const couponList = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const add = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const edit = async (values) => {
  return await axiosInstance(options).put(`${path}/update`, values);
}

export const deleteCoupon = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const detail = async (values) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const changeStatus = async (values) => {
  return await axiosInstance(options).patch(`${path}/change-status`, values);
}