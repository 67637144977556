import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'; 



export default function Sidebar() {

  const {t} = useTranslation();

  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li>
            <NavLink end to={'/restaurant/table-management/table/1'}>
              <i className='ti ti-brand-airtable'></i>
              <span>{t("label_table1")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/table-management/reservations/list/1'}>
              <i className='ti ti-circle-check'></i>
              <span>{t("label_reservations")}</span>
            </NavLink>
          </li>
         
        
        </ul>
      </div>
    </div>
  )
}
