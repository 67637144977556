import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cashRegisterDetails, getMidDayReportPrint } from '../services/settlement.services';
import ReactToPrint from 'react-to-print';
import Swal from 'sweetalert2';
import { getPrinter, handleServerValidations } from '../../../utils/commonfunction';
import { LOADER_TIMEOUT_TIME, PRINTER_NAMES, SWAL_SETTINGS } from '../../../utils/Constants';
import { midDayReportPrint } from '../../../utils/printerfunction';


export default function OpenCashRegister() {
  const { t ,i18n } = useTranslation();
  const navigate = useNavigate();
  const [PrintHtml, setPrintHtml] = useState("");
  const printSlipTriggerRef = useRef();
  const SlipRef = useRef();
  const currentDate = new Date();

  const formattedDate = `${currentDate.getFullYear()}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}`;
  
  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.cashRegister);
  
  let cashier = JSON.parse(localStorage.getItem("cashier")) || {};

  let cashRegisterDetail =useSelector((state)=>state?.cashierDetails?.cashRegisterDetail); 
  const [cashierData, setCashierData] = useState({});


  // console.log(cashRegisterDetail)

  

  const handleMidDayReport = ()=>{

    getMidDayReportPrint({cash_register_id : cashierData?.cashRegisterDetail?._id}).then(async(response) => {
     
     
      if (response.success) {
        // setKitchenPrintHtml(response || "");
        // if (printSlipTriggerRef.current) {
        //   setTimeout(() => {
        //     printSlipTriggerRef.current.click();
        //   }, 100);
        // }
        // const printer = await getPrinter(PRINTER_NAMES.CASHIER_PRINTER);
        // midDayReportPrint(printer , response.data, t , i18n.language) 
      }
      else {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    
    });
   
  }
  useEffect(() => {
    try {
      if (isCashRegisterDone !== false) {
        navigate('/cashier/dashboard')
      }

    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [isCashRegisterDone]);


  useEffect(() => {
    cashRegisterDetails()
      .then((response) => {
        setCashierData(response.data);
     
       
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
        }, LOADER_TIMEOUT_TIME);
      });
  }, []);

  return (
    <div className='dashboard-cashier'>
      <div className='p-3 w-100'>
        <div className='cash-register-info text-center mt-5'>
          <h4>{t('input_placeholder_date')}: {formattedDate}</h4>
          <p>
  {cashierData?.cashRegisterDetail?.settlement_status === "pending" || 
   cashierData?.cashRegisterDetail?.settlement_status == null
    ? t('open_cash_register_description') 
    : t('label_settled_text')}
</p>
{cashierData?.cashRegisterDetail?.settlement_status === "pending" || 
 cashierData?.cashRegisterDetail?.settlement_status == null
  ? <button className='btn btn-primary' onClick={() => navigate('/cashier/penomination-details')}>
      {t('open_cash_register')}
    </button> 
  : <button className='btn btn-primary' onClick={() => { handleMidDayReport() }}>
      {t('label_end_day_report')}
    </button>}

        </div>
        <div className="d-none" >
        <ReactToPrint trigger={() =>
          <button className='keypad-action-btns' ref={printSlipTriggerRef}> </button>
        } content={() => SlipRef.current} />
        <div className="w-100" ref={SlipRef} dangerouslySetInnerHTML={{ __html: PrintHtml ? PrintHtml : "N/A" }}></div>
      </div>
      </div>
    </div>
  );
}
