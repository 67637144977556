import React from 'react'
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  // Determine if the link should be active
  const isActive = (path) => {
    const currentPath = location.pathname;
    return currentPath.startsWith(path);
  };

  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li>
          <NavLink
              to={'/restaurant/settings/staff/1'}
              className={isActive('/restaurant/settings/staff') ? 'active' : '' }
            >
              <i className='ti ti-users'></i>
              <span>{t('staff')}</span>
            </NavLink>

          </li>

          <li>
            <NavLink end to={'/restaurant/settings/coupon/1'}>
              <i className='ti ti-tag'></i>
              <span>{t('coupon')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/bulk-import'}>
              <i className='ti ti-upload'></i>
              <span>{t('bulk_import')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/receipt-invoice'}>
              <i className='ti ti-notes'></i>
              <span>{t('receipt_invoice')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/tax'}>
              <i className='ti ti-receipt-tax'></i>
              <span>{t('tax_label')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/service-charges'}>
              <i className='ti ti-moneybag'></i>
              <span>{t('service_charges')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/credit-card-payment'}>
              <i className='ti ti-credit-card'></i>
              <span>{t('credit_card_payment')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/settings/other-payment'}>
              <i className='ti ti-report-money'></i>
              <span>{t('other_payment_method')}</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink end to={'/restaurant/settings/download-report'}>
              <i className='ti ti-report-analytics'></i>
              <span>{t('download_report')}</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  )
}
