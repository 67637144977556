import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "cashier/sales";
const options = {role: ROLE.CASHIER};

export const todaySales = async (values) => {
    return await axiosInstance(options).get(`${path}/today-sales`, values);
}
  
export const transactionHistory = async (values) => {
  return await axiosInstance(options).post(`${path}/transaction-history`, values);
}

export const SalesHistoryData = async (values) => {
  return await axiosInstance(options).post(`${path}/sales-history`, values);
}

export const transactionDetail = async (values) => {
  return await axiosInstance(options).get(`${path}/transaction-detail?o_id=${values.o_id}&printReceipt=${values.printReceipt}`, values);
}


export const deleteTransactionSlip = async (values) => {
  return await axiosInstance(options).put(`${path}/transaction-slip-delete`,values );
}

export const editSalesSlipCashier = async (values) => {
  const cashierOptions = {role: ROLE.CASHIER,'active-user-email':values.email,'active-user-password':values.password};
  return await axiosInstance(cashierOptions).post(`${path}/edit-slip`, values);
}
