import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { deleteTransactionSlip, transactionDetail, transactionHistory } from "../../../services/tableOrder.service";
import { useTranslation } from "react-i18next";
import { CONSUMPTION_TAX_RATES, DISPLAY_METHOD_TAX_OPTIONS, LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, SORT_A, SORT_D, SWAL_SETTINGS } from "../../../../../utils/Constants";
import {
  capitalizeFirstLetter,
  formateDate,
  get24HoursFormatedTime,
  getDateInFormat,
  globalLoader,
  handleServerValidations,
  roundFigurePrice,
  showPrice,
  TrimText,
  writeMyExcel,
} from "../../../../../utils/commonfunction";
import { DatePicker, DateRangePicker } from "rsuite";
import moment from "moment";
import RecordNotFound from "../../../shared/recordNotFound";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { ErrorMessage, Field, FieldArray, useFormik } from "formik";
import { editSalesSlip } from "../../../services/sales-slip.service";
import { list as TableList } from "../../../services/table.service";
import { list as StaffList } from "../../../services/staff.service";
import CustomError from "../../../../../utils/customError";
import { useValidationSchemas } from "../../../validator/validation";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../../../admin/common/custompagination";
import { PaymentMethodList } from "../../../services/payment-method.services";

export default function SalesTransactionHistory() {
  const { t } = useTranslation();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [List, setList] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const componentRef = useRef();
  const [reportDate, setReportDate] = useState(new Date());
  const [transactionData, setTransactionData] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);
  const [sortConfig, setSortConfig] = useState({ column: "createdAt", order: SORT_D });
  const closeModalRef = useRef();
  const [refreshList, setRefreshList] = useState(false);
  const [isEditSaleSlip, setIsEditSaleSlip] = useState(false);
  const [tableListArray, setTableArrayList] = useState([]);
  const [staffArray, setStaffArray] = useState([]);
  const { editSlipValidationSchema } = useValidationSchemas();
  const slipRef = useRef();
  const [isDirty, setIsDirty] = useState(true);
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage] = useState(10);
  const [cardList , setCardList] = useState([]);
  const [otherPaymentList , setOtherPaymentList] = useState([]);

  // console.log(restSettingDetails)

  const today = new Date();

  // Dynamically format the date based on the Type
  const initialSelectedDate = today.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const lowerButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date());

  const handleUpperButtonClick = () => {
    if (lowerButtonRef.current) {
      if (isDatePickerOpen) {
        // Close the DatePicker
        lowerButtonRef.current.close(); // Close the DatePicker programmatically
      } else {
        // Open the DatePicker
        lowerButtonRef.current.open(); // Open the DatePicker programmatically
      }
      setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
    }
  };

  const handleDateChange = (value) => {
    setTodayDate(value);
    //   const formattedDate =value.toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //           month: 'short',
    //           year: 'numeric',
    //         });
    console.log(value);
    setSelectedDate(value); // Update the displayed value
    setIsDatePickerOpen(false); // Close the DatePicker after selection
  };

  const handleToggle = (open) => {
    setIsDatePickerOpen(open);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const getTransactionHistory = () => {
    setLoader(true);
    let data = {
      created: todayDate,
    };
    if (sortConfig) {
      data.sort = JSON.stringify(sortConfig);
    }
    let page = params.page;
    data.page = page;
    data.per_page = itemPerPage;
  
    transactionHistory(data)
      .then((response) => {
        setList(response?.data?.transactions ? response?.data?.transactions : []);
        setOffsetPage(response?.data?.offset);
        setTotalRecords(response?.data?.total_records || 0);
        setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log("error ====> ", error);
      });
  };
  useEffect(() => {
    getTransactionHistory();
  }, [todayDate, sortConfig, refreshList, params.page]);


  useEffect(()=>{
    PaymentMethodList({global_search : "credit_card"}).then((response)=>{
      //  console.log(response.data.list); 
      setCardList(response?.data?.list||[]) 
    }).catch((err)=>{
     console.log(err)
    });

    //qr_payments

    PaymentMethodList({global_search : "qr_payments"}).then((response)=>{
      // console.log(response.data.list);  
      setOtherPaymentList(response?.data?.list||[])
   }).catch((err)=>{
    console.log(err)
   });
  } , [])





  const handleExportToExcel = () => {
    // Prepare the data for export
    const headers = [
      t("date_time_label"),
      t("label_slip_number"),
      t("label_table_no"),
      t("title_amount"),
      t("label_choose_payment_method"),
      t("label_cashier_manager"),
      t("label_no_of_people"),
      t('label_type'),
      t('label_status'),
      t("lable_comments"),
   
    ];

    const rows = List.map((item) => [
      getDateInFormat(item?.createdAt),
      String(item?.slip_number)?.padStart(4, "0") || "",
      TrimText(item?.tableDetail?.table_number, 20),
      `¥${showPrice(item?.amount_after_discount || 0, 2)}`,
      capitalizeFirstLetter(item?.payment_method),
      item?.cashierDetail?.name,
      item?.number_of_persons,
      item.type==="restaurant_order"?t('label_sales'): t('label_cancelled') ,
      item.is_edited===true?item.type === "restaurant_order" ? t("label_edited") : t("label_cancelled"): t("label_success") ,
      item.comment && item.comment.length > 0 ? item?.comment : t("label_success"),
    ]);
    console.log("List data", List);
    writeMyExcel([headers, ...rows], "transaction_history_report.xlsx");
  };

  const getTransactionDetail = (o_id , printReceipt=false) => {
    transactionDetail({ o_id: o_id , printReceipt : printReceipt})
      .then((response) => {
        setTransactionData(response && response.data ? response.data : []);
       let ordered_items = handelOrderCalculations(response.data);
       setTransactionData(response && response.data ? {...response.data , ordered_items} : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };

  const handelOrderCalculations = (transactionData) => {
    const combinedOrderedItems = transactionData.orderDetail.ordered_items.map(item => ({
    ...item,
    order_id: transactionData.orderDetail._id
}));


    let modifiedItems = [];
    transactionData.subOrders.forEach((subOrder) => {
      combinedOrderedItems.push(
        ...subOrder.ordered_items.map(item => ({
          ...item,
          order_id: subOrder._id
        }))
      );
    });
    
   modifiedItems = [...combinedOrderedItems];
     
    // combinedOrderedItems.forEach((item) => {
    //   if (item.type === "set_of_items") {
    //     const existingIndex = modifiedItems.findIndex((record) => record._id === item._id);
    //     if (existingIndex === -1) {
    //       modifiedItems.push({
    //         ...item,
    //         quantity: item.quantity,
    //         updatedAddOns: [],
    //       });
    //     } else {
    //       modifiedItems[existingIndex].quantity += item.quantity;
    //     }
    //     const itemIndex = existingIndex === -1 ? modifiedItems?.length - 1 : existingIndex;
    //     if (item.addons.length > 0) {
    //       item.addons.forEach((addon) => {
    //         const addOnIndex = modifiedItems[itemIndex]?.updatedAddOns.findIndex((a) => a.addon_id === addon.addon_id && a.spice_level === addon.spice_level);
    //         if (addOnIndex === -1 || addOnIndex === undefined) {
    //           modifiedItems[itemIndex].updatedAddOns.push({
    //             ...addon,
    //             spice_level: addon.spice_level,
    //             count: 1,
    //           });
    //         } else {
    //           const existingAddon = modifiedItems[itemIndex]?.updatedAddOns[addOnIndex];
    //           existingAddon["count"] += 1;
    //         }
    //       });
    //     }
    //   } else {
    //     const existingIndex = modifiedItems.findIndex((record) => record._id === item._id && record.spice_level === item.spice_level);
    //     if (existingIndex === -1) {
    //       modifiedItems.push({
    //         ...item,
    //         quantity: item.quantity,
    //       });
    //     } else {
    //       modifiedItems[existingIndex].quantity += item.quantity;
    //     }
    //   }
    // });
    setOrderItems(modifiedItems);
  return modifiedItems
  };

  // console.log("orderItems ====> ", orderItems);

  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };

  const handleDelete = (_id) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteTransactionSlip({ _id: _id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              closeModalRef.current.click();
              setRefreshList(!refreshList);
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch(() => {
            globalLoader(false);
          });
      }
    });
  };

  const addSlipChanges = useFormik({
    initialValues: {
      transaction_id: transactionData?._id || "",
      men: transactionData?.men || 0,
      women: transactionData?.women || 0,
      payment_method: transactionData?.payment_method || "",
      type: transactionData?.type || "",
      cashier_id: transactionData?.cashierDetail?._id || "",
      table_id: transactionData?.tableDetail?._id || "",
      comment: transactionData?.comment || "",
      number_of_persons: transactionData?.number_of_persons || 0,
      ordered_items: transactionData?.ordered_items ||[],
      great_grand_total: transactionData?.great_grand_total || 0,
      subtotal: transactionData?.subtotal || 0,
      gift_card_applied: transactionData?.gift_card_applied || false,
      coupon_applied: transactionData?.coupon_applied || false,
      tax_price: transactionData?.tax_price || 0,
      service_charge: transactionData?.service_charge || 0,
      amount_after_discount: transactionData?.amount_after_discount || 0,
      total_tax_8: transactionData?.total_tax_8 || 0,
      total_tax_10: transactionData?.total_tax_10 || 0,
      cashierDetail : transactionData?.cashierDetail|| {} , 
      tableDetail : transactionData?.tableDetail || {} , 
      createdAt : transactionData?.createdAt || "",
      receiptHTML : transactionData?.receiptHTML,
      method_name : transactionData?.method_name||"-", 
      payment_method_id : transactionData?.payment_method_id ||""
      
    },
    validationSchema: editSlipValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => formData.append(key, value));
      // console.log(values.ordered_items)

      try {
        let data = {
          transaction_id: values?.transaction_id ,
          men: values?.men ,
          women: values?.women ,
          payment_method: values?.payment_method ,
          type: values?.type ,
          cashier_id: values?.cashier_id ,
          table_id: values?.table_id ,
          comment: values?.comment ,
          number_of_persons: values?.number_of_persons ,
          method_name : values?.payment_method!=='cash' ? values?.method_name : "", 
          payment_method_id :"",
          ordered_items: values?.ordered_items
          .map((item, index) => {
              if (JSON.stringify(item) !== JSON.stringify(transactionData?.ordered_items[index])) {
                  // Calculate tax and total price

                  // console.log("check for changed value" ,item.price , transactionData?.ordered_items[index].price)
                  let tax = item.price===transactionData?.ordered_items[index].price ?item.tax_price :roundFigurePrice(
                      (Number(item.price) * Number(item.consumption_tax_rate)) / 100,
                      restSettingDetails.tax_when_register_product
                  );
                  let totalPrice = Number(item.price) + tax;
  
                  // Return the updated item
                  return {
                      ...item,
                      tax_price: tax,
                      total_price: totalPrice,
                  };
              }
              return null; // Mark unchanged items as null
          })
          .filter((item) => item !== null),
          
        }
        // console.log(data.ordered_items)

        if(values?.payment_method!=='cash'){
  
          if(values?.payment_method==='card'){
           
            cardList.forEach((item)=>{
              console.log(item ,data.method_name )
              if(item.payment_type===data.method_name){
                data.payment_method_id =item._id 
              }
               
            })
            
          }
          if(values?.payment_method==='other'){
           
            otherPaymentList.forEach((item)=>{
              if(item.payment_type===data.method_name){
                data.payment_method_id =item._id 
              }
               
            })
          }
        }
        const response = await editSalesSlip(data);
        setIsEditSaleSlip(false);

        if (response.success) {
          closeModalRef.current.click();
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          resetForm();
          setRefreshList(!refreshList);
        } else {
          closeModalRef.current.click();
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      } catch (error) {
        closeModalRef.current.click();
        setIsEditSaleSlip(false);
        console.error("Error adding table", error);
      }
    },
  });

  useEffect(() => {
    TableList()
      .then((response) => {
        setTableArrayList(response.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
    StaffList()
      .then((response) => {
        setStaffArray(response.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAsterisk = (item) => {
    let result = null;
    if (
      item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT &&
      restSettingDetails?.icon_display_eligible_for_reduced_tax_rate
    ) {
      result = <span className="fw-bold">*</span>;
    }
    return result;
  };

  const reprintReceipt =(id)=>{
    getTransactionDetail(id , true)
  }

  return (
    <main id="main-content">
      <div className="menu-level-3">
        <ul className="nav nav-pills gap-3">
          <li className="nav-item">
            <Link className="nav-link active" to={`/restaurant/sales/sales-transaction-history/${1}`}>
              {t("label_sales_slip")}{" "}
            </Link>{" "}
          </li>{" "}
          <li className="nav-item">
            <Link className="nav-link " to={`/restaurant/sales/sales-slip-changes/${1}`}>
              {t("label_list_of_changes")}
            </Link>{" "}
          </li>{" "}
        </ul>{" "}
      </div>{" "}
      <div className="inner-container">
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          {/* <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i
                            className='ti ti-chevron-down'></i></button> */}

          <div style={{ position: "relative", display: "inline-block" }}>
            {/* Upper Button */}
            <button className="btn btn-date" onClick={handleUpperButtonClick}>
              <i className="ti ti-calendar" style={{ color: "#757575" }}></i>{" "}
              <span style={{ color: "#757575" }}>
                {todayDate.length > 0
                  ? `${new Date(selectedDate[0]).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })} - ${new Date(selectedDate[1]).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}`
                  : t("placeholder_date1")}
              </span>{" "}
              <i className={`ti ${isDatePickerOpen ? "ti-chevron-up" : "ti-chevron-down"}`} style={{ color: "#757575" }}></i>
            </button>

            {/* Hidden DatePicker */}
            <div
              style={{
                visibility: "hidden",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <DateRangePicker
                ref={lowerButtonRef}
                classPrefix="restaurant"
                placement="bottomStart"
                container={() => document.body}
                format={"dd/MM/yyyy"}
                cleanable={false}
                editable={false}
                value={todayDate}
                onChange={handleDateChange}
                onToggle={handleToggle} // Handle open/close state
                disabledDate={(date) => date > new Date()}
                onClose={handleDatePickerClose}
              />
            </div>
          </div>
          {/* 
                    <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_date1")}
                        placement="autoVertical" shouldDisableDate={(currentDate) => currentDate && currentDate >
                            moment().endOf('day')} format="dd MMMM yyyy" cleanable={false} editable={false}
                        name="reservation_date" onChange={(e) => { setReportDate(e) }} value={reportDate} /> */}

          <div className="card-head-group d-flex align-items-center gap-2">
            <button
              className="btn btn-black"
              onClick={() => {
                List?.length && handleExportToExcel();
              }}
              disabled={!List?.length}
            >
              <i className="ti ti-download"></i> {t("label_download")}
            </button>
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-black" disabled={!List?.length}>
                  <i className="ti ti-printer"></i> {t("label_print")}
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        {loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ) : List && List.length ? (
          <div className="card">
            <div className="card-body">
              <div className="table-responsive" ref={componentRef}>
                <table className="table table-printable ">
                  <thead>
                    <tr>
                      <th>{t("date_time_label")}</th>
                      <th>
                        {t("label_slip_number")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("_id");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "_id" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "_id" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>
                        {t("label_table_no")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("table_number");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "table_number" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "table_number" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>
                        {t("title_amount")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("great_grand_total");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "great_grand_total" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "great_grand_total" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>
                        {t("label_choose_payment_method")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("payment_method");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "payment_method" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "payment_method" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>
                        {t("label_cashier_manager")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("name");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "name" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "name" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>
                        {t("label_no_of_people")}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleSort("number_of_persons");
                          }}
                        >
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th>{t("label_type")}</th>
                      <th>{t("label_status")}</th>
                      <th>{t("lable_comments")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {List && List.length
                      ? List.map((item, index) => {
                          return (
                            <tr key={index} className={item.is_edited === true ? "total-tr" : ""}>
                              {/* <td>5/1 (Wed)</td> */}
                              <td>
                                <span className="text-nowrap">{getDateInFormat(item?.createdAt)}</span>
                              </td>
                              <td className="text-center">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#open-modal"
                                  className="link-text cp slip_number"
                                  onClick={() => {
                                    getTransactionDetail(item?._id);
                                  }}
                                >
                                  {String(item?.slip_number)?.padStart(4, "0") || ""}
                                </a>
                              </td>
                              <td>{TrimText(capitalizeFirstLetter(item?.tableDetail?.table_number), 20) || t("label_not_entered")}</td>
                              <td>¥{showPrice(item?.amount_after_discount || 0, 2)}</td>
                              <td>{capitalizeFirstLetter(item?.payment_method)}</td>
                              <td>{TrimText(capitalizeFirstLetter(item?.cashierDetail?.name), 15)}</td>
                              <td>{item?.number_of_persons}</td>
                              <td>{item.type === "restaurant_order" ? t("label_sales") : t("label_cancelled")}</td>
                              <td>{item.is_edited === true ? (item.type === "restaurant_order" ? t("label_edited") : t("label_cancelled")) : t("label_success")}</td>
                              <td>{item.comment && item.comment.length > 0 ? TrimText(capitalizeFirstLetter(item?.comment), 10) : t("label_success")}</td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="table-status-info d-flex justify-content-between align-items-center">
                <span>
                  {t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}
                </span>
                <nav aria-label="Page navigation example" title={page}>
                  {totalRecords && totalRecords > 0 ? (
                    <CustomPagination
                      datalength={totalRecords}
                      itemperpage={itemPerPage}
                      currentPage={page}
                      custompath="page"
                      setPage={setPage}
                      pageRoute={[{ name: "settings", path: "/restaurant/sales/sales-transaction-history" }]}
                    />
                  ) : (
                    ""
                  )}
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <RecordNotFound heading="lbl_record_not_found" />
        )}
      </div>
      <div className="modal fade modal-lg" data-bs-backdrop="static" id="open-modal" tabIndex="-1" aria-labelledby="sort-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ marginBottom: "100px", marginTop: "100px" }}>
          {isEditSaleSlip === false ? (
            <div className="modal-content">
              <div className="modal-header" style={{ paddingRight: "20px" }}>
                <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                  {t("label_sales_slip_details")}
                </h1>

                <button
                  type="button"
                  className="btn-close btn-icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                  onClick={() => {
                    addSlipChanges.resetForm();
                  }}
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>

              <div className="modal-body p-0">
                <div className="sales-slip-details-box">
                  <div className="list-info">
                    <ul>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_person_in_charge")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{addSlipChanges?.values?.cashierDetail?.name}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_table_no")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{addSlipChanges?.values?.tableDetail?.table_number}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_no_of_people")}</span>
                        </div>
                        <div className="list-info-right">
                          <span style={{ color: "black" }}>
                            {t("label_all")} : {addSlipChanges?.values?.number_of_persons || 0}
                          </span>
                          <br></br>
                          <span style={{ color: "black" }}>
                            {t("label_men")} : {addSlipChanges?.values?.men || 0}
                          </span>
                          <br></br>
                          <span style={{ color: "black" }}>
                            {t("label_woman")}: {addSlipChanges?.values?.women || 0}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_choose_payment_method")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{capitalizeFirstLetter(addSlipChanges?.values?.payment_method)}</span>
                        </div>
                      </li>
                      {addSlipChanges?.values?.payment_method !== "cash" && (
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_method_name")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>{capitalizeFirstLetter(addSlipChanges?.values?.method_name)}</span>
                          </div>
                        </li>
                      )}

                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_date_time")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{formateDate(addSlipChanges?.values?.createdAt, "DD/MM/YYYY", true, false)}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_type")}</span>
                        </div>
                        <div className="list-info-right">
                          <span className="text-capitalize">
                            {addSlipChanges?.values?.type ? (addSlipChanges?.values.type === "restaurant_order" ? t("nav_link_sales") : addSlipChanges?.values.type) : t("nav_link_sales")}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_sales")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(addSlipChanges?.values?.great_grand_total)} </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_sub_total")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(addSlipChanges?.values?.subtotal)} </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_discount_if_applicable")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>
                            {addSlipChanges?.values?.gift_card_applied || addSlipChanges?.values?.coupon_applied
                              ? `¥ ${showPrice(addSlipChanges?.values?.great_grand_total - addSlipChanges?.values?.amount_after_discount)}`
                              : 0}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_consumption_tax")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(addSlipChanges?.values?.tax_price || 0)}</span>
                        </div>
                      </li>
                      {addSlipChanges.values?.service_charge > 0 && (
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("service_charge")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(addSlipChanges?.values?.service_charge || 0)}</span>
                          </div>
                        </li>
                      )}

                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_total_capitalize")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>
                            <b>¥ {showPrice(addSlipChanges?.values?.amount_after_discount || 0, 3)}</b>
                          </span>
                          <br></br>
                          <span>
                            (8% {t("tax_on_text")} ¥ {showPrice(addSlipChanges?.values?.total_tax_8)})
                          </span>
                          <br></br>
                          <span>
                            (10% {t("tax_on_text")} ¥ {showPrice(addSlipChanges?.values?.total_tax_10)})
                          </span>
                          {addSlipChanges?.values?.total_tax_8 && addSlipChanges?.values?.total_tax_8 > 0 && restSettingDetails.icon_display_eligible_for_reduced_tax_rate ? (
                            <span className="d-block ">
                              ★ {t("tax_on_other_text")} <br />
                              {t("tax_rate_8")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="table-box">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ color: "black" }}>{t("label_menu_item1")}</th>
                            <th style={{ color: "black" }}>{t("label_quantity")}</th>
                            <th style={{ color: "black" }}>{t("label_unit_price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addSlipChanges.values.ordered_items && addSlipChanges.values.ordered_items.length
                            ? addSlipChanges.values.ordered_items.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {TrimText(item?.item_name, 25)} {handleAsterisk(item)}
                                    </td>
                                    <td>{item?.quantity}</td>
                                    <td>
                                      <span className="text-nowrap">¥ {showPrice(item?.price || 0, 2)}</span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <h4 style={{ color: "black" }} className="mx-0">
                    Comment
                  </h4>
                  <p className="m-2 fs-14 mb-0" style={{ wordBreak: "break-all" }}>
                    {addSlipChanges.values?.comment}
                  </p>
                </div>
              </div>

              <div className="modal-footer justify-content-center mb-2">
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(transactionData._id);
                  }}
                >
                  {t("btn_delete")}
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setIsEditSaleSlip(true);
                  }}
                  style={{ width: "110px" }}
                >
                  {t("btn_edit")}
                </button>

                {/* <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-primary" style={{ width: "110px" }}>
                      {t("label_reprint")}
                    </button>
                  )}
                  content={() => slipRef.current}
                /> */}

                <button type="button" className="btn btn-primary" style={{ width: "110px" }} onClick={()=>{reprintReceipt(addSlipChanges.values?.transaction_id)}}>
                  {t("label_reprint")}
                </button>

                <div style={{ display: "none" }}>
                  <div ref={slipRef} className="container d-flex justify-content-center align-items-center ">
                    {/* <OrderReceipt orderId={params.orderId} ref={componentRef}/> */}
                    <div dangerouslySetInnerHTML={{ __html: addSlipChanges.values?.receiptHTML ? addSlipChanges.values?.receiptHTML : "N/A" }}></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={addSlipChanges.handleSubmit}>
              <div className="modal-content">
                <div className="modal-header" style={{ paddingRight: "20px" }}>
                  <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                    {t("label_edit_sales_slip")}
                  </h1>

                  <button
                    type="button"
                    className="btn-close btn-icon "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeModalRef}
                    onClick={() => {
                      setIsEditSaleSlip(false);
                      addSlipChanges.resetForm();
                      setIsDirty(true);
                    }}
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>

                <div className="modal-body p-0">
                  <div className="sales-slip-details-box">
                    <div className="list-info">
                      <ul>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_person_in_charge")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="form-group">
                              <select
                                className="form-control cp"
                                name="cashier_id"
                                value={addSlipChanges.values.cashier_id}
                                onChange={(e) => {
                                  addSlipChanges.handleChange(e);
                                  setIsDirty(false);
                                }}
                                onBlur={addSlipChanges.handleBlur}
                              >
                                {staffArray.map((item, index) => {
                                  return (
                                    <option value={item._id} key={index} selected={addSlipChanges.values.cashierDetail._id === item._id ? true : false}>
                                      {TrimText(item.name, 25)}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_table_no")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="form-group">
                              <select
                                className="form-control cp"
                                name="table_id"
                                value={addSlipChanges.values.table_id}
                                onChange={(e) => {
                                  addSlipChanges.handleChange(e);
                                  setIsDirty(false);
                                }}
                                onBlur={addSlipChanges.handleBlur}
                              >
                                {tableListArray.map((item, index) => {
                                  return (
                                    <option value={item._id} key={index} selected={addSlipChanges.values.tableDetail._id === item._id ? true : false}>
                                      {item.table_number}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_no_of_people")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="d-flex flex-column gap-2 men-women-input-edit">
                              <div className="form-group d-flex align-items-center gap-2">
                                <label style={{ color: "black" }}>{t("label_all")} :</label>
                                <span style={{ color: "black" }}>{addSlipChanges.values?.number_of_persons || 0}</span>
                              </div>

                              <div className="form-group d-flex align-items-center gap-2">
                                <label style={{ color: "black" }}>{t("label_all")}</label>
                                <input
                                  type="number"
                                  className="form-control no_spring_input"
                                  name="number_of_persons"
                                  value={addSlipChanges?.values?.number_of_persons}
                                  onChange={(e) => {
                                    addSlipChanges.handleChange(e);
                                    setIsDirty(false);
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                />
                              </div>

                              <div className="form-group d-flex align-items-center gap-2">
                                <label style={{ color: "black" }}>Men</label>
                                <input
                                  type="number"
                                  className="form-control no_spring_input"
                                  name="men"
                                  value={addSlipChanges?.values?.men}
                                  onChange={(e) => {
                                    addSlipChanges.handleChange(e);
                                    if (e.target.value && !isNaN(e.target.value)) {
                                      const value = Number(e.target.value);
                                      const totalValue = addSlipChanges?.values?.number_of_persons;
                                      const finalValue = totalValue - value;
                                      setIsDirty(false);
                                      if (finalValue >= 0) {
                                        addSlipChanges.setFieldValue("women", totalValue - value);
                                      } else {
                                        addSlipChanges.setFieldValue("women", 0);
                                      }
                                    }
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                />

                                {/* <input type='text' className='form-control'/> */}
                              </div>
                              <div className="form-group d-flex align-items-center gap-2">
                                <label style={{ color: "black" }}>Women</label>
                                <input
                                  type="number"
                                  className="form-control no_spring_input"
                                  name="women"
                                  value={addSlipChanges?.values?.women}
                                  onChange={(e) => {
                                    addSlipChanges.handleChange(e);
                                    setIsDirty(false);
                                    if (e.target.value && !isNaN(e.target.value)) {
                                      const value = Number(e.target.value);
                                      const totalValue = addSlipChanges?.values?.number_of_persons;
                                      const finalValue = totalValue - value;
                                      if (finalValue >= 0) {
                                        addSlipChanges.setFieldValue("men", totalValue - value);
                                      } else {
                                        addSlipChanges.setFieldValue("men", 0);
                                      }
                                    }
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                />
                              </div>
                            </div>
                            <CustomError name="men" form={addSlipChanges} className="text-danger" />
                            <CustomError name="women" form={addSlipChanges} className="text-danger" />
                            <CustomError name="number_of_persons" form={addSlipChanges} className="text-danger" />
                          </div>
                        </li>

                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_choose_payment_method")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="form-group">
                              <select
                                className="form-control cp"
                                name="payment_method"
                                value={addSlipChanges.values.payment_method}
                                onChange={(e) => {
                                  addSlipChanges.handleChange(e);
                                  setIsDirty(false);
                                }}
                                onBlur={addSlipChanges.handleBlur}
                              >
                                {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => {
                                  return (
                                    <option value={item.value} key={index} selected={addSlipChanges.values.payment_method === item.value ? true : false}>
                                      {t(item.label)}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </li>

                        {addSlipChanges?.values?.payment_method !== "cash" && (
                          <li>
                            <div className="list-info-left">
                              <span style={{ color: "black" }}>{t("label_method_name")}</span>
                            </div>
                            <div className="list-info-right">
                              <div className="form-group">
                                <select
                                  className="form-control cp"
                                  name="method_name"
                                  value={addSlipChanges.values.method_name}
                                  onChange={(e) => {
                                    addSlipChanges.handleChange(e);
                                    setIsDirty(false);
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                >
                                  {addSlipChanges?.values?.payment_method === "card"
                                    ? cardList.map((item, index) => {
                                        return (
                                          <option value={item.payment_type} key={index} selected={addSlipChanges.values.payment_method === item.value ? true : false}>
                                            {t(item.payment_type)}
                                          </option>
                                        );
                                      })
                                    : otherPaymentList.map((item, index) => {
                                        return (
                                          <option value={item.payment_type} key={index} selected={addSlipChanges.values.payment_method === item.value ? true : false}>
                                            {t(item.payment_type)}
                                          </option>
                                        );
                                      })}
                                </select>
                              </div>
                            </div>
                          </li>
                        )}

                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_date_time")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>{formateDate(addSlipChanges.values?.createdAt, "DD/MM/YYYY", true, false)}</span>
                          </div>
                        </li>

                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_type")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="form-group">
                              <select
                                className="form-control"
                                name="type"
                                value={addSlipChanges.values.type}
                                onChange={(e) => {
                                  addSlipChanges.handleChange(e);
                                  setIsDirty(false);
                                }}
                                onBlur={addSlipChanges.handleBlur}
                              >
                                <option value="restaurant_order" selected={addSlipChanges.values.type === "restaurant_order" ? true : false}>
                                  {t("label_sales")}
                                </option>
                                <option value="cancelled" selected={addSlipChanges.values.type === "cancelled" ? true : false}>
                                  {t("label_cancelled")}
                                </option>
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_sales")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(addSlipChanges.values?.great_grand_total)} </span>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_sub_total")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(addSlipChanges.values?.subtotal)} </span>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_discount_if_applicable")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>
                              {addSlipChanges.values?.gift_card_applied || addSlipChanges.values?.coupon_applied
                                ? `¥ ${showPrice(addSlipChanges.values?.great_grand_total - addSlipChanges.values?.amount_after_discount)}`
                                : 0}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_consumption_tax")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(addSlipChanges.values?.tax_price || 0)}</span>
                          </div>
                        </li>
                        {addSlipChanges.values?.service_charge > 0 && (
                          <li>
                            <div className="list-info-left">
                              <span style={{ color: "black" }}>{t("service_charge")}</span>
                            </div>
                            <div className="list-info-right">
                              <span>¥ {showPrice(addSlipChanges.values?.service_charge || 0)}</span>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_total_capitalize")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>
                              <b>¥ {showPrice(addSlipChanges.values?.amount_after_discount || 0, 3)}</b>
                            </span>
                            <br></br>
                            <span>
                              (8% {t("tax_on_text")} ¥ {showPrice(addSlipChanges.values?.total_tax_8)})
                            </span>
                            <br></br>
                            <span>
                              (10% {t("tax_on_text")} ¥ {showPrice(addSlipChanges.values?.total_tax_10)})
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="table-box">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ color: "black" }}>{t("label_menu_item1")}</th>
                              <th style={{ color: "black" }}>{t("label_quantity")}</th>
                              <th style={{ color: "black" }}>{t("label_unit_price")}</th>
                            </tr>
                          </thead>

                          <tbody>
                            {addSlipChanges.values.ordered_items.map((item, index) => (
                              <tr key={index}>
                                <td className="text-nowrap">
                                  {TrimText(item.item_name, 25)} {handleAsterisk(item)}
                                </td>
                                <td>
                                  <div className="fix-error-in-bottom">
                                    <input
                                      type="number"
                                      name={`ordered_items[${index}].quantity`}
                                      value={item.quantity || ""}
                                      onChange={(e) => {
                                        addSlipChanges.handleChange(e);
                                        setIsDirty(false);
                                      }}
                                      onBlur={addSlipChanges.handleBlur}
                                      className="form-control no_spring_input"
                                    />
                                    {addSlipChanges.touched.ordered_items?.[index]?.quantity && addSlipChanges.errors.ordered_items?.[index]?.quantity && (
                                      <div className="text-danger fixed-error">{addSlipChanges.errors.ordered_items[index].quantity}</div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="fix-error-in-bottom">
                                    <div className="m-2 input-group p-2">
                                      <span className="input-group-text" id="basic-addon1">
                                        {" "}
                                        ¥{" "}
                                      </span>
                                      <input
                                        type="number"
                                        name={`ordered_items[${index}].price`}
                                        value={item.price || ""}
                                        onChange={(e) => {
                                          addSlipChanges.handleChange(e);
                                          setIsDirty(false);
                                        }}
                                        onBlur={addSlipChanges.handleBlur}
                                        className="form-control no_spring_input"
                                      />
                                    </div>

                                    {addSlipChanges.touched.ordered_items?.[index]?.price && addSlipChanges.errors.ordered_items?.[index]?.price && (
                                      <div className="text-danger fixed-error">{addSlipChanges.errors.ordered_items[index].price}</div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="p-3">
                    <textarea
                      className="form-control"
                      style={{ height: "100px" }}
                      placeholder="Comments"
                      name="comment"
                      id="comment"
                      onChange={(e) => {
                        addSlipChanges.handleChange(e);
                        if (e.target.value.trim().length === 0 && e.target.value.length > 0) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      onBlur={addSlipChanges.handleBlur}
                      value={addSlipChanges.values.comment}
                    ></textarea>
                    <CustomError name="comment" form={addSlipChanges} className="text-danger" />
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="submit" className="btn btn-primary " style={{ width: "95%" }} disabled={isDirty}>
                    {t("btn_save_changes")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </main>
  );
}
