import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'


export default function Sidebar() {
  // const location = useLocation();

  const {t}=useTranslation();
  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li>
            <NavLink end to={'/restaurant/sales'}>
              <i className='ti ti-report'></i>
              <span>{t('label_sales_report')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/sales/abc-analysis'}>
              <i className='ti ti-device-analytics'></i>
              <span>{t('label_abc_analysis')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/sales/sales-transaction-history'}>
              <i className='ti ti-history'></i>
              <span>{t('label_transaction_history')}</span>
            </NavLink>
          </li>
          
          
        </ul>
      </div>
    </div>
  )
}
