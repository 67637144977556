import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCategoryBasisReport } from '../../../services/abc-analysis.service';
import { DatePicker } from 'rsuite';
import logo from '../../../../../assets/restaurant/images/calenderLogo.png'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, SORT_A, SORT_D } from '../../../../../utils/Constants';
import { useTranslation } from 'react-i18next';
import RecordNotFound from '../../../shared/recordNotFound';
import Skeleton from 'react-loading-skeleton';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import { globalLoader, TrimText, writeMyExcel } from '../../../../../utils/commonfunction';



export default function AbcAnalysisCategoryBasis() {


  // in this component need to add skeleton and no data found component , prevent download and print in case of no data found . 

  const [List, setList] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [Type, setType] = useState("day");
  const [totalValues, setTotalValues] = useState({});
  const [payment_method, setPaymentMethod] = useState("");
  const { t } = useTranslation();
  const [langChanged, setLangChanged] = useState(false);
  const [Loader, setLoader] = useState(true);
  const [sortConfig, setSortConfig] = useState({ column: 'total_quantity', order: SORT_D });
  const componentRef = useRef();


  const today = new Date();
  
  // Dynamically format the date based on the Type
  const initialSelectedDate =
    Type === "day"
      ? today.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
      : today.toLocaleDateString('en-GB', {
          month: 'short',
          year: 'numeric',
        });
  const lowerButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);


  const handleUpperButtonClick = () => {
    if (lowerButtonRef.current) {
      if (isDatePickerOpen) {
        // Close the DatePicker
        lowerButtonRef.current.close(); // Close the DatePicker programmatically
      } else {
        // Open the DatePicker
        lowerButtonRef.current.open(); // Open the DatePicker programmatically
      }
      setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
    }
  };
  

  const handleDateChange = (value) => {
    setTodayDate(value);
    const formattedDate =
      Type === "day"
        ? value.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })
        : value.toLocaleDateString('en-GB', {
            month: 'short',
            year: 'numeric',
          });
    setSelectedDate(formattedDate); // Update the displayed value
    setIsDatePickerOpen(false); // Close the DatePicker after selection
  };

  const handleToggle = (open) => {
    setIsDatePickerOpen(open);
  };


 

  // Recalculate the selected date whenever Type or todayDate changes
  useEffect(() => {
    const formattedDate =
      Type === "day"
        ? todayDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })
        : todayDate.toLocaleDateString('en-GB', {
            month: 'short',
            year: 'numeric',
          });
    setSelectedDate(formattedDate);
  }, [Type, todayDate]);

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false); // Close the DatePicker and change the icon state
  };






  const CustomBase64Caret = () => (
    <img
      src={logo}
      alt="Custom Caret"
      style={{ width: 20, height: 20 }}
    />
  );


  function calculateTotal(data) {
    // Initialize totals
    let totalQuantity = 0;
    let totalAmount = 0;
    let totalCostPrice = 0;
    let totalProfit = 0;
    let totalProfitPercentage = 0;

    // Check if the data array exists and has a length greater than 0
    if (Array.isArray(data) && data.length > 0) {
      // Loop through the data and accumulate values
      data.forEach(item => {

        totalQuantity += item.total_quantity;
        totalAmount += item.total_amount;
        totalCostPrice += item.total_cost_price;
        totalProfit += item.total_profit;
        totalProfitPercentage += Number(item.total_profit_percentage);
      });

      // Calculate average profit percentage
      const totalProfitPercentageSum =
        totalCostPrice === 0 ? "0.00" : ((totalProfit / totalAmount) * 100).toFixed(2);

      // Return the result as an object
      return {
        total_quantity: totalQuantity,
        amount: totalAmount,
        cost_price: totalCostPrice,
        profit: totalProfit,
        profit_percentage: totalProfitPercentageSum
      };
    } else {
      // Return 0 for all values if data is empty or not an array
      return {
        total_quantity: 0,
        amount: 0,
        cost_price: 0,
        profit: 0,
        profit_percentage: "0.00"
      };
    }
  };



  const handleDownload = () => {
    globalLoader(true);
    downloadPdf();
  }

  const downloadPdf = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        const canvas = await html2canvas(html, { scale: 2 });
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let yOffset = 10;
        let pageHeight = 297;

        if (imgHeight > pageHeight) {
          pdf.addImage(imgData, 'PNG', 10, yOffset, imgWidth, imgHeight);
          pdf.addPage();
          yOffset = 0;
        } else {
          pdf.addImage(imgData, 'PNG', 10, yOffset, imgWidth, imgHeight);
        }
        pdf.save('report_on_category_basis.pdf');
        globalLoader(false);
      }
    },
  });



  useEffect(
    () => {
      setLoader(true);
      const data = {
        type: Type,
        payment_method: payment_method
      }
      if (Type === "day") {
        data.date = todayDate.toISOString();
      }
      if (Type === "month") {
        data.month = todayDate.toISOString().slice(0, 7);
      }
      if (sortConfig) {
        data.sort = JSON.stringify(sortConfig);
      }
      getCategoryBasisReport(data).then((response) => {
        setList(response.data);
        setTotalValues(calculateTotal(response.data));
        setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);


      })
        .catch(err => { setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME); });
    }, [todayDate, Type, payment_method, langChanged, sortConfig])


  useMemo(() => {
    document.addEventListener("language_change", function () {
      setLangChanged(!langChanged);
    });
  });


  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };


  const handleExportToExcel = () => {
    // Prepare the data for export
    const headers = [
      t('label_category_name'),
      t('label_quantity'),
      t('label_amount_sale'),
      t('label_cost_price'),
      t('label_profit'),
      t('label_profit_rate'),
      t('label__composition_ratio'),
    ];
  
    const rows = List.map((item) => [
      TrimText(item.category_name, 25),
      Number(item.total_quantity),
      `¥${Number(item.total_amount).toFixed(2)}`,
      `¥${Number(item.total_cost_price).toFixed(2)}`,
      `¥${Number(item.total_profit).toFixed(2)}`,
      `${Number(item.total_profit_percentage).toFixed(2)}%`,
      `${((item.total_amount / totalValues.amount) * 100).toFixed(2)}`,
    ]);
  
    if (List) {
      rows.push([
        t('total_label'),
        totalValues.total_quantity,
        `¥${Number(totalValues.amount).toFixed(2)}`,
        `¥${Number(totalValues.cost_price).toFixed(2)}`,
        `¥${Number(totalValues.profit).toFixed(2)}`,
        `${Number(totalValues.profit_percentage).toFixed(2)}%`,
        totalValues.amount > 0 ? '100' : '0',
      ]);
    }
  
    writeMyExcel([headers, ...rows], "report_on_category_basis.xlsx");
  };
  
  return (
    <main id="main-content">
      <div className="menu-level-3">
        <ul className="nav nav-pills gap-3">
          <li className="nav-item">
            <Link className="nav-link " to={"/restaurant/sales/abc-analysis"}>
              {t('label_product_basis')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to={"/restaurant/sales/abc-analysis-category-basis"}>
              {t('label_category_basis')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/restaurant/sales/abc-analysis-day-basis"}>
              {t('label_day_basis')}
            </Link>
          </li>
        </ul>
      </div>
      <div className="inner-container">
        <div className="d-flex tabs-with-etc justify-content-between align-items-center mb-4">
          <ul className="nav nav-pills gap-3">
            <li
              className="nav-item"
              onClick={() => {
                setType("day");
                setTodayDate(new Date());
                  setSelectedDate(initialSelectedDate);
                  setIsDatePickerOpen(false);
              }}
            >
              <Link className={Type === "day" ? "nav-link active" : "nav-link"}>{t('label_day')}</Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setType("month");
                setTodayDate(new Date());
                  setSelectedDate(initialSelectedDate);
                  setIsDatePickerOpen(false);
              }}
            >
              <Link className={Type === "month" ? "nav-link active" : "nav-link"}>{t('label_month')}</Link>
            </li>
          </ul>
          <div className="tabls-dowpdown">
            <select className="form-control cp" onChange={(e) => setPaymentMethod(e.target.value)}>
              <option value="">
                {t('text_choose_payment_method')}
              </option>
              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => {
                return (
                  <option key={index} value={item.value}>{t(`${item.label}`)}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">


        <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Upper Button */}
      <button className="btn btn-date" onClick={handleUpperButtonClick}>
        <i className="ti ti-calendar" style={{color: "#757575"}}></i> <span style={{color: "#757575"}}>{selectedDate}</span>{' '}
        <i className={`ti ${isDatePickerOpen ? 'ti-chevron-up' : 'ti-chevron-down'}`} style={{color: "#757575"}}></i>
      </button>

      {/* Hidden DatePicker */}
      <div
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <DatePicker
          ref={lowerButtonRef}
          oneTap={true}
          classPrefix="restaurant"
          placement="bottomStart" // Opens directly beneath the button
          container={() => document.body} // Prevents clipping by parent containers
          format={Type === "day" ? "dd/MM/yyyy" : "MMM yyyy"}
          cleanable={false}
          editable={false}
          value={todayDate}
          onChange={handleDateChange}
          onToggle={handleToggle} // Handle open/close state
          disabledDate={(date) => date > new Date()}
          onClose={ handleDatePickerClose}
        />
      </div>
    </div>


          {/* <button className="">
            {Type === "day" ? <DatePicker
              oneTap={true}
              classPrefix="restaurant"
              placement="autoVertical"
              format="dd/MM/yyyy"
              cleanable={false}
              editable={false}
              value={todayDate}
              caretAs={CustomBase64Caret}
              onChange={(value) => {
                setTodayDate(value);
              }}
              disabledDate={(date) => date > new Date()}
            /> :
              <DatePicker
                oneTap={true}
                classPrefix="restaurant"
                placement="autoVertical"
                cleanable={false}
                editable={false}
                value={todayDate}
                format="MMM yyyy"
                caretAs={CustomBase64Caret}
                onChange={(value) => {

                  setTodayDate(value);
                }}
                disabledDate={(date) => date > new Date()}
              />

            }
          </button> */}
          <div className="card-head-group d-flex align-items-center gap-2">
            <button className="btn btn-black" onClick={() => List?.length && handleExportToExcel()} disabled={!List?.length}>
              <i className="ti ti-download"></i> {t('label_download')}
            </button>
            <ReactToPrint trigger={() =>
              <button className="btn btn-black" disabled={!List?.length}>
                <i className="ti ti-printer"></i> {t('label_print')}
              </button>
            } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}"/>
            {/* <button className="btn btn-black" onClick={()=>{List?.length &&printPDF();}}>
              <i className="ti ti-printer"></i> {t('label_print')}
            </button> */}
          </div>
        </div>
        {Loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ) :
          <div className="card">
            <div className="card-body" ref={componentRef}>
              {List && List.length ?
                <div className="table-responsive">
                  <table className="table table-printable ">
                    <thead>
                      <tr>
                        <th>{t('label_category_name')}</th>
                        <th>{t('label_quantity')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_quantity')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_quantity' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_quantity' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>

                        </th>
                        <th>{t('label_amount_sale')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_amount')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_amount' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_amount' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>
                        </th>
                        <th>{t('label_cost_price')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_cost_price')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_cost_price' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_cost_price' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>
                        </th>
                        <th>{t('label_profit')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_profit')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_profit' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_profit' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>
                        </th>
                        <th>{t('label_profit_rate')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_profit_percentage')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_profit_percentage' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_profit_percentage' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>
                        </th>
                        <th>{t('label__composition_ratio')}
                          <span style={{ cursor: 'pointer' }} onClick={() => handleSort('total_amount')}>

                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'total_amount' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '5px' }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'total_amount' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                              style={{ marginLeft: '-8px' }} />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {List && List.length
                        ? List.map((item, index) => { 
                          return (
                            <tr key={index}>
                              <td>{TrimText(item.category_name,25)}</td>
                              <td>{Number(item.total_quantity)}</td>
                              <td>¥ {Number(item.total_amount).toFixed(2)}</td>
                              <td>¥ {Number(item.total_cost_price).toFixed(2)}</td>
                              <td>¥ {Number(item.total_profit).toFixed(2)}</td>
                              <td>{Number(item.total_profit_percentage).toFixed(2)}%</td>
                              <td>{((item.total_amount / totalValues.amount) * 100).toFixed(2)}%</td>
                            </tr>
                          );
                        })
                        : ""}

                      <tr className="total-tr">
                        <td>{t('total_label')}</td>
                        <td>{totalValues.total_quantity}</td>
                        <td>¥ {Number(totalValues.amount).toFixed(2)}</td>
                        <td>¥ {Number(totalValues.cost_price).toFixed(2)}</td>
                        <td>¥ {Number(totalValues.profit).toFixed(2)}</td>
                        <td>{Number(totalValues.profit_percentage).toFixed(2)}%</td>
                        <td>{totalValues.amount > 0 ? 100 : 0}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div> : <RecordNotFound heading='lbl_record_not_found' />}
            </div>
          </div>}
      </div>
    </main>
  );
}
