import React, { useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import { useDispatch, useSelector } from "react-redux";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import Header from "./header";
import { getLocalKey, getUser, handleServerValidations, setLocalKey } from "../../../utils/commonfunction";
import {
    SWAL_SETTINGS,
    ATLEAST_ONE_CAPITAL_REGEX,
    ATLEAST_ONE_NUMBER_REGEX,
    ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH,
    ATLEAST_ONE_SMALL_REGEX, ROLE,
    SOCKET_EVENTS
} from "../../../utils/Constants";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import CustomError from "../../../utils/customError";
import { getRestaurantSettings, updateInitialPassword } from "../services/profile.services";
import { SOCKET_CLIENT } from "../../../utils/socket";
import { addRestData } from "../../../redux/slices/restSettings";
import { GlobalSettingsDetails } from '../../website/services/website.services';

const RestaurantLayout = () => {
    const passwordModalBtn = useRef();
    const passwordModalCloseBtn = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const navigate = useNavigate();
    window.navigate_ = navigate;
    const [show, setShow] = useState(true);
    const [restaurantDetailChanged, setRestaurantDetailChanged] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);

    useEffect(() => {
        const userData = getUser(ROLE.RESTAURANT);
        setTimeout(() => {
            if (userData.initial_password_changed === "true" || userData.initial_password_changed === true) {
                /** User changed his/her password already */
            } else {
                passwordModalBtn?.current?.click();
            }
        }, 100);
    }, [location, passwordModalBtn]);
    const handleShow = () => {
        setShow(!show);
    };
    const validationSchema = Yup.object().shape({
        new_password: Yup.string()
            .required("label_new_password_error")
            .matches(
                ATLEAST_ONE_SMALL_REGEX,
                "password_validation_lowercase"
            )
            .matches(
                ATLEAST_ONE_CAPITAL_REGEX,
                "Password_Validation_uppercase"
            )
            .matches(
                ATLEAST_ONE_NUMBER_REGEX,
                "Password_Validation_number"
            )
            .matches(
                ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
                "Password_Validation_special_char"
            )
            .min(PASSWORD_MIN_LENGTH, "Password_Validation_minimum_length")
            .max(
                PASSWORD_MAX_LENGTH,
                "Password_Validation_maximum_length"
            ),
        confirm_password: Yup.string()
            .required("label_confirm_password_error")
            .oneOf([Yup.ref("new_password"), null], "password_validation_does_not_match"),
    });
    const changePasswordForm = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            updateInitialPassword(values).then((response) => {
                if (response.success) {
                    let userData = getUser(ROLE.RESTAURANT);
                    userData.initial_password_changed = true;
                    setLocalKey(ROLE.RESTAURANT, JSON.stringify(userData));
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    passwordModalCloseBtn?.current?.click();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                console.log("error===>", error)
            });
        }
    });

    /** Joining user to socket for realtime notifications >>>>> */
    useMemo(() => {
        const user = getUser(ROLE.RESTAURANT);
        if (user) {
            let joinRequest = {
                roomID: user._id
            };
            SOCKET_CLIENT.emit('JOIN-USER', joinRequest, (error) => {
                if (error) {
                    console.log("*****Socket connection error on JOIN-USER", error);
                }
            });
        }
    }, [location]);
    /** Joining user to socket for realtime notifications <<<<< */
    useMemo(() => {
        SOCKET_CLIENT.on('GET-DATA', async (response) => {
            if (response) {
                if (response?.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
                    console.log("socket event listened")
                    setRestaurantDetailChanged(!restaurantDetailChanged)
                }
            }
        });
    }, [restaurantDetailChanged]);



    useMemo(() => {
        getRestaurantSettings().then((globalDetails) => {
            dispatch(addRestData(globalDetails?.data));
            setRestaurantDetailChanged(false)
        });
    }, [restaurantDetailChanged]);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div>
                    <Header />
                    <Outlet />
                    {/* Initial password change modal >>>>> */}
                    <div className="modal fade" data-bs-backdrop="static" id="changePasswordModal" aria-hidden="true" aria-labelledby="changePasswordModalLabel" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="changePasswordModalLabel">{t("change_password")}</h1>
                                    <button type="button" className="btn-close d-none" ref={passwordModalCloseBtn} data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {t("restaurant_force_change_password_note")}
                                    <form onSubmit={changePasswordForm.handleSubmit}>
                                        <div className="my-3">
                                            <label htmlFor="new_password" className="form-label">{t("label_password")}<span>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type={!show ? "text" : "password"}
                                                    className="form-control border-end-0"
                                                    id="new_password"
                                                    placeholder={t("placeholder_password")}
                                                    value={changePasswordForm?.values?.new_password}
                                                    name="new_password"
                                                    onChange={changePasswordForm.handleChange}
                                                    onBlur={changePasswordForm.handleBlur}
                                                />
                                                <span
                                                    className="input-group-text cp restaurant-password-toggle"
                                                    onClick={handleShow}
                                                >
                                                    {!show === true ? (
                                                        <i className="ti ti-eye"></i>
                                                    ) : (
                                                        <i className="ti ti-eye-off"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="new_password" form={changePasswordForm} />
                                            </span>
                                        </div>
                                        <div className="my-3">
                                            <label htmlFor="confirm_password" className="form-label">{t("label_confirm_password")}<span>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type={!show ? "text" : "password"}
                                                    className="form-control border-end-0"
                                                    id="confirm_password"
                                                    placeholder={t("placeholder_confirm_password")}
                                                    value={changePasswordForm?.values?.confirm_password}
                                                    name="confirm_password"
                                                    onChange={changePasswordForm.handleChange}
                                                    onBlur={changePasswordForm.handleBlur}
                                                />
                                                <span
                                                    className="input-group-text cp restaurant-password-toggle"
                                                    onClick={handleShow}
                                                >
                                                    {!show === true ? (
                                                        <i className="ti ti-eye-off"></i>
                                                    ) : (
                                                        <i className="ti ti-eye"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="confirm_password" form={changePasswordForm} />
                                            </span>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-primary w-100" type="submit">{t("btn_submit")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="d-none" ref={passwordModalBtn} data-bs-toggle="modal" href="#changePasswordModal" role="button">Open first modal</a>
                    {/* Initial password change modal <<<<< */}
                </div>

            )}
        </>
    );
};
export default RestaurantLayout;
