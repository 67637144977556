import React, { useEffect, useMemo, useRef, useState } from 'react';
import card1 from '../../../assets/cashier/images/paymentsuccessicon.png';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import HomeIcon from '../../../assets/cashier/images/home-img.svg';
import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
import AmericanCard from '../../../assets/cashier/images/american-card.png';
import amex from '../../../assets/cashier/images/amex.png';
import diners from '../../../assets/cashier/images/diners.png';
import JcbCard from '../../../assets/cashier/images/jcb-card.png';
import visa from '../../../assets/cashier/images/visa.png';
import Mastercard from '../../../assets/cashier/images/mastercard.png';
import aupay from '../../../assets/cashier/images/au-pay.png';
import linepay from '../../../assets/cashier/images/line-pay.png';
import merupay from '../../../assets/cashier/images/meru-pay.png';
import paypay from '../../../assets/cashier/images/pay-pay.png';
import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
import dbarai from '../../../assets/cashier/images/d-barai.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { showPrice } from '../../../utils/commonfunction';
import ReactToPrint from 'react-to-print';
import { detail, receiptDetail, transactionDetail } from '../services/table.services';
import OrderReceipt from '../shared/orderReceipt';

export default function PaymentSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [orderTransactionDetail, setOrderTransactionDetail] = useState({});
  const componentRef = useRef();
  const reprintRef = useRef();
  const [langChanged, setLangChanged] = useState(false);


  const getOrderDetail = (orderId) => {
    transactionDetail({ o_id: orderId })
      .then((response) => {
        setOrderTransactionDetail(response?.data);
        console.log(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    if (params.orderId) {
      getOrderDetail(params.orderId);
    }
    else{
      navigate("/cashier/dashboard")
    }
  }, [params.orderId,langChanged]);


  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });

  return (
    <div className='dashboard-cashier-wrap'>
      {/* <div className='loader-box'>
        <div className='loader-in'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
          <span>Waiting for payment received </span>
        </div>
      </div> */}
      <div className="cashier-head text-center">
        <h4>{t('payment_label')}</h4>
        <div className="btns-head-in-left"  onClick={()=>navigate(-1)}>
          <button className="btn btn-icon"><i className="ti ti-arrow-left"></i></button>
        </div>
      </div>
      <div className='payment-wraper'>
        <div className='payment-wraper-in'>
          <div className='paymnet-typing shadow-box'>
            <span>{t('label_change_to_return')}</span>
            <h4>¥ {showPrice(orderTransactionDetail?.returned_amount)}</h4>
          </div>
          <div className='payment-keypad-box shadow-box'>
            <div className='paymnet-success-msg'>
              <figure>
                <img src={card1}/>
              </figure>
              <h4>{t("payment_success_msg")}</h4>
            </div>
          </div>
          <div className='keypad-action-btn-left gap-3'>
            <ReactToPrint trigger={() => 
                <button className='keypad-action-btns' >
                <img src={InvoiceIcon} />
                <span>{t('label_print_invoice')}</span>
              </button>
            } content={() => componentRef.current} />
            
            <ReactToPrint trigger={() => 
            <button className='keypad-action-btns mt-5' >
              <img src={ReprintIcon} />
              <span>{t('label_reprint')}</span>
            </button>
            } content={() => reprintRef.current} />
          </div>
          <div className='keypad-action-btn-right gap-3'>
            <button className='keypad-action-btns' onClick={()=>navigate('/cashier/dashboard')}>
              <img src={HomeIcon} />
              <span>{t('nav_link_home')}</span>
            </button>
          </div>
        </div>
      </div>
      {/* style={{ display: 'none' }} */}
      <div  style={{ display: 'none' }} >
          <div ref={componentRef}  className="container d-flex justify-content-center align-items-center vh-100">
              {/* <OrderReceipt orderId={params.orderId} ref={componentRef}/> */}
              <div dangerouslySetInnerHTML={{ __html: orderTransactionDetail?.html ? orderTransactionDetail?.html : "N/A" }}></div>
          </div>
      </div>

      <div  style={{ display: 'none' }} >
          <div ref={reprintRef}  className="container d-flex justify-content-center align-items-center vh-100">

              <div dangerouslySetInnerHTML={{ __html: orderTransactionDetail?.reprint_html ? orderTransactionDetail?.reprint_html : "N/A" }}></div>
          </div>
      </div>
    </div>






  )
}
