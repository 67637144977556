import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getProductBasisReport } from '../../../services/abc-analysis.service';
import { DatePicker } from 'rsuite';
import logo from '../../../../../assets/restaurant/images/calenderLogo.png'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION } from '../../../../../utils/Constants';  
import { useTranslation } from 'react-i18next';
import RecordNotFound from '../../../shared/recordNotFound';
import Skeleton from 'react-loading-skeleton';



export default function AbcAnalysis() {


  // in this component need to add skeleton and no data found component , prevent download and print in case of no data found . 

    const [List , setList] = useState([]);
    const [todayDate , setTodayDate] = useState(new Date());
    const [Type , setType] = useState("day");
    const [totalValues , setTotalValues] = useState({}); 
    const [payment_method , setPaymentMethod] = useState(""); 
    const {t}=useTranslation();
    const [langChanged, setLangChanged] = useState(false);
    const [Loader , setLoader]= useState(true);

    const CustomBase64Caret = () => (
      <img 
        src={logo}
        alt="Custom Caret" 
        style={{ width: 20, height: 20 }} 
      />
    );


    function calculateTotal(data) {
      // Initialize totals
      let totalQuantity = 0;
      let totalAmount = 0;
      let totalCostPrice = 0;
      let totalProfit = 0;
      let totalProfitPercentage = 0;
  
      // Check if the data array exists and has a length greater than 0
      if (Array.isArray(data) && data.length > 0) {
          // Loop through the data and accumulate values
          data.forEach(item => {
              console.log(item.profit_percentage);
              totalQuantity += item.total_quantity;
              totalAmount += item.amount;
              totalCostPrice += item.cost_price;
              totalProfit += item.profit;
              totalProfitPercentage += Number(item.profit_percentage);
          });
  
          // Calculate average profit percentage
          const totalProfitPercentageSum = 
              totalCostPrice === 0 ? "0.00" : ((totalProfit / totalCostPrice) * 100).toFixed(2);
  
          // Return the result as an object
          return {
              total_quantity: totalQuantity,
              amount: totalAmount,
              cost_price: totalCostPrice,
              profit: totalProfit,
              profit_percentage: totalProfitPercentageSum
          };
      } else {
          // Return 0 for all values if data is empty or not an array
          return {
              total_quantity: 0,
              amount: 0,
              cost_price: 0,
              profit: 0,
              profit_percentage: "0.00"
          };
      }
  };
  


  // Function to download the table data as PDF
  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // Add title
    doc.text('Report on Product Basis', 20, 10);
  
    // Prepare table data and debug
    const tableData = List.map(item => {
      const compositionRatio = ((item.amount / totalValues.amount) * 100).toFixed(2);
      console.log('Item:', item.item_name, 'Composition Ratio:', compositionRatio); // Debugging output
      return [
        item.item_name,
        item.total_quantity,
        `¥${item.amount}`,
        `¥${item.cost_price}`,
        `¥${item.profit}`,
        `${item.profit_percentage}%`,
        `${compositionRatio}`
      ];
    });
  
    console.log('Table Data:', tableData); // Check if tableData is generated correctly
  
    // Add table to PDF using autoTable
    autoTable(doc, {
      head: [['Product name', 'Quantity', 'Amount of sales', 'Cost price', 'Profit', 'Profit rate', 'Composition Ratio']],
      body: tableData,
      theme: 'grid', // Optional: add a grid for better visualization
      columnStyles: {
        6: { halign: 'right' } // Align the last column to the right
      }
    });
  
    // Save the PDF
    doc.save('report_on_product_basis.pdf');
  };
  



  const printPDF = () => {
    const doc = new jsPDF();
  
    // Add title
    doc.text('Report on Product Basis', 20, 10);
  
    // Prepare table data with debugging
    const tableData = List.map(item => {
      const compositionRatio = ((item.amount / totalValues.amount) * 100).toFixed(2);
      console.log('Item:', item.item_name, 'Composition Ratio:', compositionRatio); // Debugging output
      return [
        item.item_name,
        item.total_quantity,
        `¥${item.amount}`,
        `¥${item.cost_price}`,
        `¥${item.profit}`,
        `${item.profit_percentage}%`,
        `${compositionRatio}`
      ];
    });
  
   
  
    // Add table to PDF using autoTable
    autoTable(doc, {
      head: [['Product name', 'Quantity', 'Amount of sales', 'Cost price', 'Profit', 'Profit rate', 'Composition Ratio']],
      body: tableData,
      theme: 'grid', // Add a grid theme for better visualization
      columnStyles: {
        6: { halign: 'right' } // Align the last column to the right
      }
    });
  
    // Open the print dialog directly
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const printWindow = window.open(pdfUrl);
  
    // Ensure the print window opens and then triggers the print dialog
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        URL.revokeObjectURL(pdfUrl); // Clean up the URL object
      };
    } else {
      console.error('Failed to open print window');
    }
  };
  
  



    useEffect(
        ()=>{
            
            const data = {
                type : Type ,
                payment_method : payment_method
            }
            if(Type==="day"){
              data.date = todayDate.toISOString();
            }
            if(Type==="month"){
              data.month = todayDate.toISOString().slice(0, 7);
            }
            getProductBasisReport(data).then((response)=>{
                setList(response.data);
                setTotalValues(calculateTotal(response.data));
                setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
               
               
            })
            .catch(err=>{  setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);});
        } , [todayDate , Type , payment_method ,langChanged])


        useMemo(() => {
          document.addEventListener("language_change", function () {
            setLangChanged(!langChanged);
          });
        });
  return (
    <main id="main-content">
      <div className="menu-level-3">
        <ul className="nav nav-pills gap-3">
          <li className="nav-item">
            <Link className="nav-link active" to={"/restaurant/sales/abc-analysis"}>
              {t('label_product_basis')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to={"/restaurant/sales/abc-analysis-category-basis"}>
              {t('label_category_basis')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/restaurant/sales/abc-analysis-day-basis"}>
              {t('label_day_basis')}
            </Link>
          </li>
        </ul>
      </div>
      <div className="inner-container">
        <div className="d-flex tabs-with-etc justify-content-between align-items-center mb-4">
          <ul className="nav nav-pills gap-3">
            <li
              className="nav-item"
              onClick={() => {
                setType("day");
                setTodayDate(new Date());
              }}
            >
              <Link className={Type === "day" ? "nav-link active" : "nav-link"}>{t('label_day')}</Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setType("month");
                setTodayDate(new Date());
              }}
            >
              <Link className={Type === "month" ? "nav-link active" : "nav-link"}>{t('label_month')}</Link>
            </li>
          </ul>
          <div className="tabls-dowpdown">
            <select className="form-control cp"onChange={(e) => setPaymentMethod(e.target.value)}>
              <option selected disabled>
                {t('text_choose_payment_method')}
              </option>
            {PAYMENT_METHOD_TYPES_TRANSACTION.map((item , index)=>{
               return(
                <option key={index} value={item.value}>{t(`${item.label}`)}</option>
               )
            })}
            </select>
          </div>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <button className="">
            {Type==="day" ?<DatePicker
              oneTap={true}
              classPrefix="restaurant"
              placement="autoVertical"
              format="dd/MM/yyyy"
              cleanable={false}
              editable={false}
              value={todayDate}
              caretAs={CustomBase64Caret}
              onChange={(value) => {
                setTodayDate(value); 
              }}
            />:  
            <DatePicker
              oneTap={true}
              classPrefix="restaurant"
              placement="autoVertical"
              cleanable={false}
              editable={false}
              value={todayDate}
             format="MMM yyyy"
             caretAs={CustomBase64Caret}
             onChange={(value) => {
              
              setTodayDate(value); 
            }}
             />
             
              }
          </button>
          <div className="card-head-group d-flex align-items-center gap-2">
            <button className="btn btn-black" onClick={()=>{List?.length &&downloadPDF();}}>
              <i className="ti ti-download"></i> {t('label_download')}
            </button>
            <button className="btn btn-black" onClick={()=>{List?.length &&printPDF();}}>
              <i className="ti ti-printer"></i> {t('label_print')}
            </button>
          </div>
        </div>
        {Loader ? (
              <div className="w-100">
                <Skeleton className="mb-2" height={50} count={5} />
              </div>
            ) :
        <div className="card">
          <div className="card-body">
            {List && List.length ?
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t('label_product_name')}</th>
                    <th>{t('label_quantity')}</th>
                    <th>{t('label_amount_sale')}</th>
                    <th>{t('label_cost_price')}</th>
                    <th>{t('label_profit')}</th>
                    <th>{t('label_profit_rate')}</th>
                    <th>{t('label__composition_ratio')}</th>
                  </tr>
                </thead>
                <tbody>
                  {List && List.length
                    ? List.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.item_name}</td>
                            <td>{item.total_quantity}</td>
                            <td>¥{item.amount}</td>
                            <td>¥{item.cost_price}</td>
                            <td>¥{item.profit}</td>
                            <td>{item.profit_percentage}%</td>
                            <td>{((item.amount/totalValues.amount)*100).toFixed(2)}</td>
                          </tr>
                        );
                      })
                    : ""}

                  <tr className="total-tr">
                    <td>Total</td>
                    <td>{totalValues.total_quantity}</td>
                    <td>¥{totalValues.amount}</td>
                    <td>¥{totalValues.cost_price}</td>
                    <td>¥{totalValues.profit}</td>
                    <td>{totalValues.profit_percentage}%</td>
                    <td>{totalValues.amount>0?100:0}</td>
                  </tr>
                </tbody>
              </table>
            </div> : <RecordNotFound heading='lbl_record_not_found'/>}
          </div>
        </div>}
      </div>
    </main>
  );
}
