import React from 'react'
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { globalLoader, logOutUser } from '../../../../../utils/commonfunction';
import { LOADER_TIMEOUT_TIME, ROLE, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { LogoutUser } from '../../../../../utils/common.service';
import { useDispatch } from 'react-redux';
import {  setCashierDetail } from '../../../../../redux/slices/cashierDetail';

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Determine if the link should be active
  const isActive = (path) => {
    const currentPath = location.pathname;
    return currentPath.startsWith(path);
  };

  const logOutCashier = () => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("text_you_want_to_logout"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#6c757d",
      cancelButtonColor: "#dc3545",
      confirmButtonText: t("btn_yes"),
      cancelButtonText: t("btn_cancel"),
      customClass: {
        popup: 'swal-restaurant',
        confirmButton: 'btn btn-secondary col',
        cancelButton: 'btn btn-danger col'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        LogoutUser({ role: ROLE.CASHIER }).then(() => {
          setTimeout(() => {
            globalLoader(false);
            logOutUser(ROLE.CASHIER);
            dispatch(setCashierDetail(null));
            Swal.fire({
              icon: "success",
              text: t("msg_logout_successfully"),
              ...SWAL_SETTINGS,
            });
          }, LOADER_TIMEOUT_TIME);
        }).catch((err) => {
          console.error("Error while logging out", err);
        });
      }
    });
  };

  return (
    <div className='cashier-left-part d-flex flex-column justify-content-between'>
      <ul className='cashier-menus'>
        <li>
          <NavLink
            to={'/cashier/settings/today-sales'}
            className={isActive('/cashier/settings/today-sales') ? 'active' : ''}
          >
            <i className='ti ti-chart-dots'></i>
            <span>{t('label_today_sales')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={'/cashier/settings/sales-history/1'} className={isActive('/cashier/settings/sales-history') ? 'active' : ''}>
            <i className='ti ti-chart-dots-3'></i>
            <span>{t('label_sales_history')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={'/cashier/settings/check-network'}>
            <i className='ti ti-brand-netbeans'></i>
            <span>{t('label_check_network')}</span>
          </NavLink>
        </li>
        <li>

          <NavLink to={'/cashier/settings/settlement/1'}>
            <i className='ti ti-sun'></i>
            <span>{t('mail_settlement')}</span>
          </NavLink>
        </li>
      </ul>

      <button className='btn btn-text log-out-cashier' onClick={()=>{logOutCashier()}}>
      <i className='ti ti-logout'></i>
            <span>{t("label_logout")}</span>
      </button>

      
    </div>
  )
}
