import React, { useEffect, useMemo, useState } from "react";
import english from "../../../assets/restaurant/images/english.png";
import japanese from "../../../assets/restaurant/images/japanese.png";
import Skeleton from "react-loading-skeleton";
import { LOADER_TIMEOUT_TIME, ORDER_PLATFORM, ORDER_STATUS } from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { tableDetail } from "../services/table.service";
import { getLocalKey, setLocalKey, TrimText, getSessionKey, QRHeaderStatus, formatDateToHHMM, DT } from "../../../utils/commonfunction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { addTableDetails } from "../../../redux/slices/qrcodeDetails";
import Swal from "sweetalert2";

function Header() {
  const CartLengthData = useSelector((state) => state.QrOrderToken.CartLength);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tableDetailsLoader, setTableDetailsLoader] = useState(true);
  const [tableData, setTableData] = useState({});
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const [langChanged, setLangChanged] = useState(false);
  const [disableCart, setDisableCart] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const table_id = queryParams.get("table_id");
  const { t } = useTranslation();
  const lang = queryParams.get("language");
  const base = queryParams.get("base");
  const [showWebHeder, setShowWebHeder] = useState(false);

  useEffect(() => {
    if (queryParams.get("order_id") && window.location.pathname !== "/qr-order/place") {
      setDisableCart(true);
    } else {
      setDisableCart(false);
    }
  }, [location]);


  const newToken = useSelector((state) => {
    return state.QrOrderToken.qrPanelToken;
  });
  useEffect(() => {
    setToken(newToken);
  }, [newToken]);

  const [flag, setFlag] = useState(getLocalKey("system_language_flag") || english);

  useEffect(() => {
    if (token && token.length) {
      setTableDetailsLoader(true);
      tableDetail({ o_id: table_id }, { token: token })
        .then((response) => {
          setTableData(response.data);
          
          if(response?.data?.upcoming_reservations && window.location.pathname==='/qr-order'){
            const reservationData =response?.data?.upcoming_reservations_data;
            const reservationTime = formatDateToHHMM(new Date(reservationData.start_time));
            let qr_popup_text = DT(t('qr_popup_text'),[reservationTime]);
            Swal.fire({
              title: t("msg_are_you_sure"),
              text: qr_popup_text,
              icon : "question" , 
              showCancelButton: true,
              confirmButtonColor: "#f8821b",
              cancelButtonColor: "#6c757d",
              confirmButtonText: t("btn_No"),
              cancelButtonText: t("btn_yes"),
              reverseButtons: true,
              customClass: {
                  popup: 'swal-restaurant',
                  confirmButton: 'btn btn-danger col',
                  cancelButton: 'btn btn-secondary col'
              },
              allowOutsideClick: false
          }).then((result) => {
              if (result.isConfirmed && base === ORDER_PLATFORM.WEB) {
                window.location.replace("https://google.com");
              }
              if(result.isConfirmed && base === ORDER_PLATFORM.APP){
                const appData = { action: "go-to", data: "TakeOrderSelectTable" };
                window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
              }
          });
          }
      


          dispatch(addTableDetails(response.data));
          if (response.data.activeOrder) {
            const mySearchParams = new URLSearchParams(window.location.search);
            mySearchParams.set("restaurant_id", response.data.restaurant_id);
            mySearchParams.set("table_id", response.data.tableOrders?.table_id);
            mySearchParams.set("type", "table");
            if (response.data.activeOrder.order_status === ORDER_STATUS.PENDING || response.data.activeOrder.order_status === ORDER_STATUS.CHECKED_OUT) {
              mySearchParams.set("order_id", response.data.activeOrder?._id);
              navigate(
                {
                  pathname: "/qr-order/place",
                  search: mySearchParams.toString(),
                },
                {
                  state: {
                    customerDetails: response?.data.tableOrders?.customerDetails,
                  },
                }
              );
            }
          }
          setTimeout(() => {
            setTableDetailsLoader(false);
          }, LOADER_TIMEOUT_TIME);
        })
        .catch((err) => {
          setTimeout(() => {
            setTableDetailsLoader(false);
          }, LOADER_TIMEOUT_TIME);
          console.log(err);
        });
    }
  }, [token]);

  const changeLanguage = (lang, flag, triggerEvent=true) => {
    i18next.changeLanguage(lang);
    setLocalKey("system_language", lang);
    setLocalKey("system_language_flag", flag);
    setFlag(flag);
    if(triggerEvent){
      const evt = new CustomEvent("language_change");
      document.dispatchEvent(evt);
      if(base === ORDER_PLATFORM.APP){
        const appData = { action: 'language-changed', data: lang };
        window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
      }
    }
  };
  useMemo(() => {
    document.addEventListener("language_change", function () {
      setLangChanged(!langChanged);
    });
  });

  useMemo(() => {
    if (base === ORDER_PLATFORM.APP) {
      let flag = lang === "ja" ? japanese: english;
      changeLanguage(lang, flag, false);
    }
  }, []);

  useEffect(() => {
    setShowWebHeder(QRHeaderStatus(base));
  }, [location]);

  const WebHeader = () => {
    return (
      <div className="qr-header d-flex align-items-center justify-content-between">
        <h4 className="m-0">{TrimText(queryParams.get("user_name") || t("label_guest"), 15)}</h4>
        <div className="qr-head-right d-flex align-items-center gap-2">
          {
            base === ORDER_PLATFORM.WEB ? 
              <button
                className="btn btn-icon position-relative"
                onClick={() => {
                  if (disableCart) {
                    const mySearchParams = new URLSearchParams(window.location.search);
                    navigate({
                      pathname: "/qr-order/place",
                      search: mySearchParams.toString(),
                    });
                  }
                }}
              >
                <i className="ti ti-shopping-cart"></i>
                {CartLengthData ? <span className="cart-badge">{CartLengthData > 99 ? "99+" : CartLengthData}</span> : null}
              </button>
            :null
          }
          <div className="qr-table-number">
            {t("label_table1")} <span>{TrimText(tableData?.table_number, 4)}</span>
          </div>
          <div className="dropdown profile-dropdown lang-dropdown">
            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="avatar avatar-xs">
                <img src={flag} alt="Japanese-flag" />
              </div>
            </button>
            <ul className="dropdown-menu">
              <li
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage("ja", japanese);
                }}
              >
                <button className="dropdown-item">
                  <figure className="avatar avatar-xxs">
                    <img src={japanese} alt="Japanese-flag" />
                  </figure>
                  <span>Japanese</span>
                </button>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage("en", english);
                }}
              >
                <button className="dropdown-item">
                  <figure className="avatar avatar-xxs">
                    <img src={english} alt="UK-flag" />
                  </figure>
                  <span>English</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  };
  const AppHeader = () => {
    return (
      <div className="qr-header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-icon position-relative bg-transparent"
            onClick={() => {
              if(window?.location?.pathname === '/qr-order' || window?.location?.pathname === '/qr-order/success'){
                const appData = { action: "go-to", data: "TakeOrderSelectTable" };
                window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
              }else{
                window?.history?.back();
              }
            }}
          >
            <i className="ti ti-arrow-left"></i>
          </button>
        </div>
        {
          window?.location?.pathname !== '/qr-order/success' ?
            <>
              <div className="dropdown profile-dropdown lang-dropdown">
                <span className='d-block text-center' style={{ fontSize: "18px", fontWeight: 500 }}>{t("heading_take_order")}</span>
                {
                  window?.location?.pathname === '/qr-order' ?
                    <span className='d-block text-center text-uppercase' style={{ fontSize: "16px", fontWeight: 500 }}>{t("heading_order_items")}</span>
                    :
                    <span className='d-block text-center text-uppercase' style={{ fontSize: "16px", fontWeight: 500 }}>{t("heading_order_details")}</span>
                }
              </div>
              <div className="qr-table-number">
                {t("label_table1")} <span>{TrimText(tableData?.table_number, 4)}</span>
              </div>
            </>
          :null
        }
      </div>
    )
  };
  return (
    <>
      {!tableDetailsLoader ? (
        <>
          {
            showWebHeder ?
              <WebHeader/>
              : 
              <AppHeader/>
          }          
        </>
      ) : (
        <div className="qr-header d-flex align-items-center justify-content-between">
          <Skeleton containerClassName="flex-fill me-3" style={{ height: "37px" }} />
          <div className="qr-head-right d-flex align-items-center gap-2">
            <Skeleton style={{ height: "37px", width: "37px" }} />
            <Skeleton style={{ height: "37px", width: "86px" }} />
            <Skeleton style={{ height: "37px", width: "37px" }} className="rounded-circle" />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
