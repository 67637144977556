import React, { useEffect, useMemo, useState, useTransition } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { applyGiftCard, detail, getRestaurantSettings, removeGiftCard } from "../../../services/table.services";
import RecordNotFound from "../../../../restaurant/shared/recordNotFound";
import { getSubTotalQRApp, getTranslatedKey, pluralText, roundFigurePrice, showPrice, TrimText } from "../../../../../utils/commonfunction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addRestData } from "../../../../../redux/slices/restSettings";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { DISPLAY_METHOD_TAX_OPTIONS,SOCKET_EVENTS,SWAL_SETTINGS } from "../../../../../utils/Constants";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { SOCKET_CLIENT } from "../../../../../utils/socket";

const CashierHomeLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tableId } = useParams();
  const [tableDetail, setTableDetail] = useState({});
  const [combinedOrderItems, setCombinedOrderItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const params = useParams();
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);
  const [orderPriceDetail, setOrderPriceDetail] = useState(null);
  const [giftCardApplied, setGiftCardApplied] = useState(false);


  const formik = useFormik({
    initialValues: {
      couponCode: "",
      gift_card_amount: "",
      isCouponChecked: false,
      isGiftCardChecked: false,
    },
    validationSchema: Yup.object({
      couponCode: Yup.string().test("couponCode", t("error_coupon_required"), function (value) {
        const { isCouponChecked } = this.parent;
        return !isCouponChecked || (isCouponChecked && value);
      }),
      gift_card_amount: Yup.number().test("gift_card_amount", t("error_gift_card_required"), function (value) {
        const { isGiftCardChecked } = this.parent;
        return !isGiftCardChecked || (isGiftCardChecked && value != null);
      }).min(1, t("number_err_msg")),
    }),
    onSubmit: (values) => {
      console.log("Form Values:", values);
      navigate(`/cashier/payment-via-cash/${tableId}`);
    },
  });


  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        console.log("response?.data", response?.data);
        setTableDetail(response?.data);
        if (response?.data?.is_available === false) {
          const combinedOrderedItems = [...response?.data?.activeOrder?.ordered_items];
          response?.data?.activeOrder?.subOrders.forEach((subOrder) => {
            combinedOrderedItems.push(...subOrder.ordered_items);
          });
          setCombinedOrderItems(combinedOrderedItems);
          const itemCounts = combinedOrderedItems.reduce((acc, item) => {
            // Create a unique key based on both item name and spice level
            const key = `${item.item_name}-${item.spice_level}`;
        
            // Initialize the accumulator for this key if it doesn't exist
            acc[key] = acc[key] || {
                quantity: 0,  // Start quantity at 0 to accumulate values
                consumption_tax_rate: item.consumption_tax_rate,
                price: item.price,
                tax_price: item.tax_price,
                total_price: item.total_price,
                item_name_ja: item.item_name_ja,
                spice_level: item.spice_level, // Include spice level in the result
            };
        
            // Add the item's quantity to the existing quantity for this key
            acc[key].quantity += item.quantity;
        
            return acc;
        }, {});
        
          
          // Convert the counts into an array of objects
          const result = Object.keys(itemCounts).map((key) => ({
            item_name: key.split('-')[0], // Extract the item name from the key
            spice_level: itemCounts[key].spice_level, // Extract spice level
            quantity: itemCounts[key].quantity,
            consumption_tax_rate: itemCounts[key].consumption_tax_rate,
            price: itemCounts[key].price,
            tax_price: itemCounts[key].tax_price,
            total_price: itemCounts[key].total_price,
            item_name_ja: itemCounts[key].item_name_ja,
          }));
          

          setOrderItems(result);

          handelOrderCalculations(response?.data?.activeOrder);

          if(response?.data?.activeOrder?.gift_card_applied){
            setGiftCardApplied(true);
            formik.setFieldValue('isGiftCardChecked',true);
            formik.setFieldValue('gift_card_amount',response?.data?.activeOrder?.gift_card_amount)
          }
          else{ 
            setGiftCardApplied(false);
            formik.setFieldValue('isGiftCardChecked',false);
            formik.setFieldValue('gift_card_amount','')
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelOrderCalculations = (orderDetail) => {
    let data = {
      subtotal: 0,
      tax_price: 0,
      grand_total: 0,
      total_tax_8: 0,
      total_tax_10: 0,
      service_charge: 0,
      great_grand_total: 0,
      amount_after_discount:0
    };
    const subOrders = orderDetail?.subOrders;
    // console.log(orderDetail , "check")
    if (orderDetail) {
      data.subtotal += orderDetail?.subtotal;
      data.tax_price += orderDetail?.tax_price;
      // data.total_tax_8 += orderDetail?.total_tax_8;
      // data.total_tax_10 += orderDetail?.total_tax_10;
      orderDetail.ordered_items.forEach((item)=>{
        if (item.consumption_tax_rate === 8) {
          data.total_tax_8 += ((item?.tax_price+ item?.price )* item.quantity);
        } else if (item.consumption_tax_rate === 10) {
          data.total_tax_10 += ((item?.tax_price+ item?.price )* item.quantity);
        }
      })
      data.grand_total += orderDetail?.grand_total;
      data.service_charge += orderDetail?.service_charge;
      data.great_grand_total += orderDetail?.great_grand_total;
    }
    if (subOrders && subOrders.length) {
      subOrders.forEach((order) => {
        if (order) {
          // console.log(order)
          data.subtotal += order?.subtotal;
          data.tax_price += order?.tax_price;
          // data.total_tax_8 += order?.total_tax_8;
          // data.total_tax_10 += order?.total_tax_10;
          data.grand_total += order?.grand_total;
          data.service_charge += order?.service_charge;

          order.ordered_items.forEach((item)=>{
            if (item.consumption_tax_rate === 8) {
              data.total_tax_8 += ((item?.tax_price+ item?.price )* item.quantity);
            } else if (item.consumption_tax_rate === 10) {
              data.total_tax_10 += ((item?.tax_price+ item?.price )* item.quantity);
            }
          })
         
      
        }
      });
    }
    if(orderDetail?.gift_card_applied){
      data.amount_after_discount = data?.great_grand_total - orderDetail?.gift_card_amount
    }
    setOrderPriceDetail(data);
  };

  useEffect(() => {
    if (params.tableId) {
      getTableDetail(params.tableId);
    }
  }, [params.tableId]);


  const applyGiftCardAmount = () => {
    applyGiftCard({ order_id: tableDetail?.activeOrder?._id,gift_card_amount:formik?.values?.gift_card_amount })
      .then((response) => {
        if(response?.success){
          setGiftCardApplied(true);
          getTableDetail(tableDetail?._id);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else{
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
       
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelGiftCardChange = (val) =>{
    if (!val && giftCardApplied) {
      return removeGiftCardSwal();
      // formik.setFieldValue("gift_card_amount", ""); 
      // removeGiftCardAmount();
    }
    formik.setFieldValue("isGiftCardChecked", true);
  }

  const removeGiftCardSwal = () => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_remove_gift_card"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeGiftCardAmount();
        formik.setFieldValue("isGiftCardChecked", false);
        formik.setFieldValue("gift_card_amount", "")
      } else {
      }
    });
  };

  const removeGiftCardAmount = () => {
    removeGiftCard({ order_id: tableDetail?.activeOrder?._id })
      .then((response) => {
        if(response?.success){
          setGiftCardApplied(false);
          Swal.fire({
            icon: "success",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
        }
        else{
          Swal.fire({
            icon: "error",
            text: t(response.message),
            ...SWAL_SETTINGS,
          });
       
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useMemo(() => {
    SOCKET_CLIENT.on('GET-DATA', (response) => {
      if (response.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
        if (params.tableId) {
          getTableDetail(params.tableId);
        }
      }
    });
  }, []);

  return (
    <>
      <div className="dashboard-cashier">
        <Outlet />
        <div className="dashboard-cashier-right">
          {!tableId ? (
            <div className="table-not-found">
              <RecordNotFound heading="no_table_selected" />
            </div>
          ) : (
            <div className="dashboard-cashier-right-in">
              <div className="menu-qty d-flex flex-column gap-2 p-3">
                <div className="category-list-grid">
                  {orderItems.length ? (
                    <>
                      {orderItems.map((item, index) => {
                        return (
                          <div className="menu-qty-row" key={index}>
                            <div>
                            <h4>{TrimText(getTranslatedKey("item_name", item), 15)}</h4>
                            {console.log(item)}
                           {
                            item.spice_level &&
                            <p className="mb-0 fs-12">{t('spice_level_text')} - <strong className="text-capitalize">{item?.spice_level}</strong></p>
                           } 
                            </div>
                           
                            <div className="menu-qty-right">
                              <span>{item?.quantity}</span>
                              <h4>
                                ¥{" "}
                                {restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ? showPrice(item?.price* item?.quantity) : showPrice(item?.total_price* item?.quantity) }
                              </h4>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <RecordNotFound heading="no_category_found_text" text="no_category_found_text_para" />
                  )}
                </div>
              </div>
              <div className="buttom-bill-info">
                <form onSubmit={formik.handleSubmit}>
                  <div className="coupon-enter d-flex flex-column gap-3 p-3">
                    <div className="coupon-enter-in">
                      <div className="form-check">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          id="coupon_code"
                          checked={formik.values.isCouponChecked}
                          onChange={() => {
                            formik.setFieldValue("isCouponChecked", !formik.values.isCouponChecked);
                            if (!formik.values.isCouponChecked) {
                              formik.setFieldValue("couponCode", ""); // Reset coupon code if unchecked
                            }
                          }}
                        />
                        <label className="form-check-label cp" htmlFor="coupon_code">
                          {t("label_coupon_code")}
                        </label>
                      </div>
                      {formik.values.isCouponChecked && (
                        <div className="coupon-input">
                          <label className="form-label">{t("label_enter_code")}</label>
                          <input type="text" className="form-control no_spring_input pe-73px" placeholder={t("label_enter_coupon")} {...formik.getFieldProps("couponCode")} />
                          {formik.touched.couponCode && formik.errors.couponCode ? <div className="text-danger">{formik.errors.couponCode}</div> : null}
                          <button type="button" className="btn btn-primary"  disabled={!formik?.values?.couponCode }>
                            {t("label_apply")}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="coupon-enter-in">
                      <div className="form-check">
                        <input
                          className="form-check-input cp "
                          type="checkbox"
                          id="gift_card"
                          checked={formik.values.isGiftCardChecked}
                          onChange={(e) => {
                            handelGiftCardChange(e?.target?.checked)
                          }}
                        />
                        <label className="form-check-label cp" htmlFor="gift_card">
                          {t("label_gift_card")}
                        </label>
                      </div>
                      {formik.values.isGiftCardChecked && (
                        <div className="coupon-input">
                          <label className="form-label">{t("enter_gift_card_amount")}</label>
                          <div className="input-group">
                          <span className="input-group-text" id="basic-addon1"> ¥ </span>
                          <input type="number" className="form-control no_spring_input pe-73px" placeholder={t("enter_gift_card_amount")} {...formik.getFieldProps("gift_card_amount")}  />
                          <button type="button" className="btn btn-primary" disabled={!formik?.values?.gift_card_amount} onClick={applyGiftCardAmount} style={{position: 'static'}}>
                            {t("label_apply")}
                          </button>
                          </div>

                          {formik.touched.gift_card_amount && formik.errors.gift_card_amount ? <div className="text-danger">{formik.errors.gift_card_amount}</div> : null}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="subtotal-info p-3">
                    <ul className=" d-flex flex-column gap-3">
                      <li>
                        <h4>
                          {t("sub_total_label")} ({orderItems?.length} {pluralText(orderItems?.length, t("label_item"), t("label_items"))})
                        </h4>
                        <h4>
                          ¥{" "}
                         
                            {showPrice(orderPriceDetail?.subtotal)}
                        </h4>
                      </li>
                      {/* {restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED && (
                        <span style={{ fontSize: "12px", marginTop: "-12px", color: "#424242" , padding:"0px 5px" , fontWeight:"500"}}>
                           ( {t('tax_included_label')})</span>
                      )} */}

                      {orderPriceDetail?.service_charge ? (
                        <li>
                          <h4>{t("service_charge")}</h4>
                          <h4>¥ {showPrice(orderPriceDetail?.service_charge)}</h4>
                        </li>
                      ) : null}
                      <li>
                        <h4>{t("label_consumption_tax")}</h4>
                        <h4>¥ {showPrice(orderPriceDetail?.tax_price)}</h4>
                      </li>
                    </ul>
                  
                      <p>
                        8% {t("label_take_out_tax")} ¥{showPrice(orderPriceDetail?.total_tax_8)}
                      </p>
                   
                  
                      <p>
                        10% {t("label_dine_in_tax")} ¥{showPrice(orderPriceDetail?.total_tax_10)}
                      </p>
                  
                  </div>
                  {/* onClick={() => navigate(`/cashier/payment-via-cash/${tableId}`)} */}
                  {/* <button className="btn btn-primary d-flex justify-content-between w-100 btn-total" type="submit">
                    <span>{t("label_total_capitalize")}</span>
                    <span>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                  </button> */}
                  <button className="btn btn-primary d-flex justify-content-between w-100 btn-total" type="submit">
                    <span>{t("label_total_capitalize")}</span>
                    <div className="text-end">
                      {giftCardApplied ? (
                        <>
                          <span className="text-decoration-line-through"style={{ fontSize: 'medium' }}>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                          <span className="d-block"> ¥ {showPrice(orderPriceDetail?.amount_after_discount)}</span>
                        </>
                      ) : (
                        <span>¥ {showPrice(orderPriceDetail?.great_grand_total)}</span>
                      )}
                    </div>
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CashierHomeLayout;
