
import Swal from "sweetalert2";
import { PERMISSIONS } from "./permissions";
import { t } from "i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { LogoutUser } from "./common.service";
import moment from "moment";

const {
  DYNAMIC_VARIABLES,
  STAFF_ROLE,
  FILE_UPLOAD,
  ROLE,
  SWAL_SETTINGS,
  TAX_ROUND_OFF_FRACTIONS,
  LOADER_TIMEOUT_TIME,
  SERVICE_CHARGED_CONDITION,
  DEFAULT_ITEM_LANGUAGE,
  ORDER_PLATFORM,
  FULLMONTHS,
} = require("../utils/Constants");
// const store = require("../redux/store");
const production = process.env.REACT_APP_PRODUCTION;
const { getData } = require("country-list");
export const encodeValue = (value) => {
  return btoa(value);
};

export const decodeValue = (value) => {
  return atob(value);
};

export const formateDateWithMonth = (date) => {
  try {
    let newStrDate = new Date(date);
    const language = localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en";
    const options = { month: "long", year: "numeric", day: "2-digit" };
    const formatter = language === "en"
      ? new Intl.DateTimeFormat("en-US", options)
      : new Intl.DateTimeFormat('ja-JP', options);
    newStrDate = formatter.format(newStrDate);
    return newStrDate;
  } catch (err) { return ""; }

};

export const formateDate = (dateString, formate = "DD/MM/YYYY", showTime = false , showSecond=true) => {
  let newStrDate = "";
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "N/A";
  }
  const language = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en";
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formatter = new Intl.DateTimeFormat(language, options);
  const parts = formatter.formatToParts(date);

  // Extract the individual components
  let day, month, year;
  parts.forEach(part => {
    if (part.type === 'day') day = part.value;
    if (part.type === 'month') month = part.value;
    if (part.type === 'year') year = part.value;
  });
  newStrDate = formate.replace("DD", day).replace("MM", month).replace("YYYY", year);
  if (showTime) {
    const hour =
      date.getHours() === 0
        ? 12
        : date.getHours() > 12
          ? date.getHours() - 12
          : date.getHours();
    const min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const secs =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    const ampm = date.getHours() < 12 ? "AM" : "PM";
    newStrDate += showSecond 
    ? ` ${hour}:${min}:${secs} ${ampm}` 
    : ` ${hour}:${min} ${ampm}`;
  }
  return newStrDate;
};

export const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "NA";
  }
  const now = new Date();
  const diffInMilliseconds = now - date;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);
  if (diffInSeconds < 60) {
    return "Just now";
  } else if (diffInMinutes === 1) {
    return "1 min ago";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} mins ago`;
  } else if (diffInHours === 1) {
    return "1 hour ago";
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else if (diffInDays === 1) {
    return "1 day ago";
  } else if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  } else if (diffInMonths === 1) {
    return "1 month ago";
  } else if (diffInMonths < 12) {
    return `${diffInMonths} months ago`;
  } else if (diffInYears === 1) {
    return "1 year ago";
  } else {
    return `${diffInYears} years ago`;
  }
};

export const formatDateFromTime = (createdAt) => {
  const isAdminPath = window.location.pathname.includes("/admin");
  const language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  if (createdAt) {
    const date = new Date(createdAt);
    if (!isNaN(date.getTime())) {
      const options = { month: "long", year: "numeric", day: "2-digit" };
      const formatter =
        isAdminPath || language === "en"
          ? new Intl.DateTimeFormat("en-US", options)
          : new Intl.DateTimeFormat("de-DE", options);
      return formatter.format(date);
    }
  }
  return "Invalid Date";
};

export const getFormatedTime = (dateString) => {
  let date = new Date(dateString);

  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");

  let amOrPm;
  if (hours >= 12) {
    amOrPm = "PM";
  } else {
    amOrPm = "AM";
  }

  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes} ${amOrPm}`;
};

export const setTitle = (value) => {
  let title = document.getElementById("dynamicTitle");
  if (title) {
    title.innerText = value;
  }
};

export const createMeta = (data) => {
  let keywordMeta = document.getElementById("keyword_meta");
  let desctiptiondMeta = document.getElementById("desctiption_meta");
  let slugFlag = sessionStorage.getItem("meta_tag_flag");
  let head = document.querySelector("head");
  if (data?.meta_keyword) {
    if (keywordMeta) {
      keywordMeta.name = "keywords";
      keywordMeta.content = data?.meta_keyword;
    } else {
      let newMeta = document.createElement("meta");
      newMeta.id = "keyword_meta";
      newMeta.name = "keywords";
      newMeta.content = data?.meta_keyword;
      head.appendChild(newMeta);
    }
  }
  if (data?.metadescription) {
    if (desctiptiondMeta) {
      desctiptiondMeta.name = "description";
      desctiptiondMeta.content = data?.metadescription;
    } else {
      let newMeta = document.createElement("meta");
      newMeta.id = "desctiption_meta";
      newMeta.name = "description";
      newMeta.content = data?.metadescription;
      head.appendChild(newMeta);
    }
  }
  if (data?.meta_tags) {
    if (slugFlag && slugFlag === "active") {
      /** Aready set */
    } else {
      head.innerHTML += data?.meta_tags;
      sessionStorage.setItem("meta_tag_flag", "active");
    }
  }
  setTimeout(() => {
    sessionStorage.removeItem("meta_tag_flag");
  }, 2000);
};

export const formatetime = (dateString) => {
  let showTime = "";
  const date = new Date(dateString);
  const hour =
    date.getHours() === 0
      ? 12
      : date.getHours() > 12
        ? date.getHours() - 12
        : date.getHours();
  const min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const secs =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  const ampm = date.getHours() < 12 ? "AM" : "PM";
  return (showTime += ` ${hour}:${min}:${secs} ${ampm}`);
};

export const formatePickerDate = (dateString) => {
  const date = new Date(dateString);
  return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
};

export const Editorconvertercsv = (paragraph) => {
  let text = "";
  text = paragraph
    ? paragraph
      .toString()
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/(?:\\[rn]|[\r\n]+)+/g, "")
    : "-";
  return text;
};

export const getDayBetweenDates = (from, to) => {
  const start_date = new Date(from);
  const end_date = new Date(to);
  const dateRange = [];
  let currentDate = start_date;
  while (currentDate <= end_date) {
    dateRange.push(new Date(currentDate).toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateRange;
};

export const calculatePercentage = (part, total) => {
  return (total * part) / 100;
};

export const myToFixed = (price) => {
  let number = Number(price);
  if (number) {
    return number.toFixed(2);
  } else {
    return 0;
  }
};

export function getDate(
  dateString,
  format = "DD-MM-YYYY",
  showTime = true,
  showDefaultDate = false
) {
  let createDateFrom = null;
  let newStrDate = "";
  if (isNaN(dateString)) {
    createDateFrom = dateString;
  } else {
    createDateFrom = Number(dateString);
  }
  if (showDefaultDate && !dateString) {
    createDateFrom = new Date();
  }
  if (createDateFrom) {
    const date = new Date(createDateFrom);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    newStrDate = format.replace("DD", day);
    newStrDate = newStrDate.replace("MM", month);
    newStrDate = newStrDate.replace("YYYY", year);
    /**Configuring time view */
    if (showTime) {
      const hour = String(
        date.getHours() === 0
          ? 12
          : date.getHours() > 12
            ? date.getHours() - 12
            : date.getHours()
      ).padStart(2, "0");
      const min = String(date.getMinutes()).padStart(2, "0");
      const secs = String(date.getSeconds()).padStart(2, "0");
      const ampm = date.getHours() < 12 ? "AM" : "PM";
      newStrDate += ` ${hour}:${min}:${secs} ${ampm}`;
    }
  } else {
    newStrDate = "N/A";
  }

  return newStrDate;
}

export const formateDataMultiSelect = (data, labelField, valueField) => {
  let newData = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      let newItem = {
        label: item[labelField],
        value: item[valueField],
      };
      newData.push(newItem);
    });
  }
  return newData;
};

export const formateIdArray = (data, fromField) => {
  let newData = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      newData.push(item[fromField]);
    });
  }
  return newData;
};

export const TrimText = (value, size = 40) => {
  return value && value !== undefined && value.length > size
    ? value.slice(0, size) + "..."
    : value;
};

export const getUser = function (role) {
  const keyData = getLocalKey(role);
  if (keyData) {
    try {
      const userData = JSON.parse(keyData);
      return userData;
    } catch (err) {
      console.log("Invalid user data", err);
      return {};
    }
  } else {
    return {};
  }
};

export const getRestaurantUser = function () {
  if (getLocalKey("restaurant")) {
    return JSON.parse(getLocalKey("restaurant"));
  } else {
    return {};
  }
};

export const copyText = async function (text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.log("error", error);
  }
};

export const logOutUser = (role, navigate = null) => {
  removeLocalKey(role);
  let loginUrl = "";
  if (role === ROLE.RESTAURANT) {
    loginUrl = "/restaurant/login"
  } else if (role === ROLE.SUPER_ADMIN) {
    loginUrl = "/admin/login";
  }
  else if (role === ROLE.CASHIER) {
    loginUrl = "/cashier/login";
    removeLocalKey('cashierDetail');
    removeLocalKey('cashRegisterEntry');
  }
  if (window.navigate_) {
    window.navigate_(loginUrl);
  } else if (navigate) {
    navigate(loginUrl);
  } else {
    window.location.href = loginUrl;
  }
};
export const logOutRestaurant = () => {
  Swal.fire({
    title: t("msg_are_you_sure"),
    text: t("text_you_want_to_logout"),
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#6c757d",
    cancelButtonColor: "#dc3545",
    confirmButtonText: t("btn_yes"),
    cancelButtonText: t("btn_cancel"),
    customClass: {
      popup: 'swal-restaurant',
      confirmButton: 'btn btn-secondary col',
      cancelButton: 'btn btn-danger col'
    },
  }).then((result) => {
    if (result.isConfirmed) {
      globalLoader(true);
      LogoutUser({ role: ROLE.RESTAURANT }).then(() => {
        setTimeout(() => {
          globalLoader(false);
          logOutUser(ROLE.RESTAURANT);
          Swal.fire({
            icon: "success",
            text: t("msg_logout_successfully"),
            ...SWAL_SETTINGS,
          });
        }, LOADER_TIMEOUT_TIME);
      }).catch((err) => {
        console.error("Error while logging out", err);
      });
    }
  });
};

export const setLocalKey = (key, value) => {
  if (production === "true") {
    localStorage.setItem(encodeValue(key), encodeValue(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const getLocalKey = (key) => {
  if (production === "true") {
    if (localStorage.getItem(encodeValue(key))) {
      return decodeValue(localStorage.getItem(encodeValue(key)));
    }
  } else {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
  }
};

export const removeLocalKey = (key) => {
  if (production === "true") {
    localStorage.removeItem(encodeValue(key));
  } else {
    localStorage.removeItem(key);
  }
};

export const DT = (text, dynamicOptions = []) => {
  let str = text;
  if (Array.isArray(dynamicOptions)) {
    dynamicOptions.forEach((string) => {
      str = str.replace(/{{(.*?)}}/, string);
    });
  } else if (typeof dynamicOptions === "object") {
    Object.keys(dynamicOptions).forEach((key) => {
      let regex = new RegExp(`{{${key}}}`, 'g');
      str = str.replace(regex, dynamicOptions[key]);
    });
  }
  return str;
};

export const setSessionKey = (key, value) => {
  if (production === "true") {
    sessionStorage.setItem(encodeValue(key), encodeValue(value));
  } else {
    sessionStorage.setItem(key, value);
  }
};

export const getSessionKey = (key) => {
  if (production === "true") {
    if (sessionStorage.getItem(encodeValue(key))) {
      return decodeValue(sessionStorage.getItem(encodeValue(key)));
    }
  } else {
    if (sessionStorage.getItem(key)) {
      return sessionStorage.getItem(key);
    }
  }
};

export const removeSessionKey = (key) => {
  if (production === "true") {
    sessionStorage.removeItem(encodeValue(key));
  } else {
    sessionStorage.removeItem(key);
  }
};

export const blockInvalidChar = (e) => {
  const str =
    "eE+-.,;:/|\\!@#$%^&*()_=~`abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ";
  return str.split("").includes(e.key) && e.preventDefault();
};
export const blockSpecialChar = (e) => {
  const str =
    "eE+-.,;:/|\\!@#$%^&*()_=~` ";
  return str.split("").includes(e.key) && e.preventDefault();
};

export const blockInvalidCharWithNumber = (e) => {
  const str =
    "eE+-.,;:/|\\!@#$%^&*()_=~`abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ 1234567890'";
  return str.split("").includes(e.key) && e.preventDefault();
};

export const handleServerValidations = (response) => {
  let message = response.message;
  if (Array.isArray(response.error)) {
    if (response.error[0] && response.error[0].msg) {
      message = response.error[0].msg;
    } else if (response.error[0] && response.error[0].message) {
      message = response.error[0].message;
    }
  }
  return message;
};

export const showStatus = (status) => {
  switch (status) {
    case 0:
      return `<span className="badge badge-secondary" href="#">Inactive</span>`;
    case 1:
      return `<span className="badge badge-primary" href="#">Active</span>`;
    default:
      return ``;
  }
};

export const capitalizeFirstLetter = (str) => {
  if (str && str.length && typeof str === "string") {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

export const getExtension = (fileName) => {
  const fileNameArr = fileName.split(".");
  return fileNameArr[fileNameArr.length - 1];
};

export const getIcons = (fileName) => {
  if (!fileName) return null;
  let fileExt = getExtension(fileName);
  let imageExts = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  if (imageExts.indexOf(fileExt.toLowerCase()) >= 0) {
    return `image`;
    // return `<img className="" src=${fileName} alt="Thumb-1" />`
  } else {
    if (fileExt === "pdf") {
      return `<i className="fa fa-file-pdf-o" aria-hidden="true"></i>`;
    } else if (fileExt === "zip" || fileExt === "tar" || fileExt === "rar") {
      return `<i className="fa fa-file-archive-o" aria-hidden="true"></i>`;
    } else if (fileExt === "docx") {
      return `<i className="fa fa-file-word-o" aria-hidden="true"></i>`;
    } else if (fileExt === "xls" || fileExt === "xlsx" || fileExt === "csv") {
      return `<i className="fa fa-file-excel-o" aria-hidden="true"></i>`;
    } else if (fileExt === "mp3" || fileExt === "wav") {
      // return `<i className="fa fa-file-audio-o" aria-hidden="true"></i>`;
      return `<audio controls><source src=${fileName} type="audio/${fileExt}">Your browser does not support the audio element.</audio>`;
    } else if (fileExt === "mp4" || fileExt === "mov" || fileExt === "avi") {
      // return `<i className="fa fa-file-video-o" aria-hidden="true"></i>`;
      return `<video controls width="250"><source src=${fileName} type="video/${fileExt}">Your browser does not support the video element.</video>`;
    } else {
      return `<i className="fa fa-file" aria-hidden="true"></i>`;
    }
  }
};

export const removeSpecialCharacter = (value) => {
  let text = "";
  try {
    text = String(value);
  } catch (err) {
    console.log(err);
  }
  if (text) {
    return text.replaceAll("-", " ").replaceAll("_", " ");
  }
  return "";
};

export const addSpecialCharacter = (text) => {
  return text.replace(" ", "-");
};

export const isUser = (role) => {
  const user = getUser();
  if (user && user.token) {
    return true;
  }
  return false;
};
export const diffrenceBetweenTwoDates = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  console.log("date2 - date1", date2 - date1);
  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};
export const getDiffrenceInDays = (startDate, endDate) => {
  if (typeof startDate === "object") {
    startDate = startDate.toISOString().split("T")[0];
  }
  if (typeof endDate === "object") {
    endDate = endDate.toISOString().split("T")[0];
  }
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const getDiffrenceInTime = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = date2 - date1;
  return diffTime;
};

export const globalLoader = (action) => {
  const loader = document.getElementById("main-loader");
  if (loader) {
    let customStyle = action ? "flex" : "none";
    loader.style.display = customStyle;
  }
};

export const getImageFileFromUrl = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(url);
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };

      const file = new File([data], "image1.jpg", metadata);
      // console.log("file", file);
      resolve(file);
    } catch (err) {
      console.log("err", err);
      reject(err);
    }
  });
};

export const getStringfromPTag = (text) => {
  // const text = 'This is a <p>paragraph</p> of text with <p>multiple</p> paragraphs.';
  const regex = /<p>(.*?)<\/p>/g;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1]);
  }

  // console.log(matches);
  return matches;
};

export const removeSpacesAndCharacters = (inputString) => {
  const pattern = /[^0-9]+/g;
  const result = inputString?.replace(pattern, "");

  console.log("res", result);
  return result;
};
// };
// module.exports = myfunctions;

export const attachDynamicStrings = (data) => {
  let response = data;
  Object.keys(DYNAMIC_VARIABLES).forEach((item) => {
    response = response.replace(
      RegExp("{" + item + "}", "g"),
      DYNAMIC_VARIABLES[item]
    );
  });
  return response;
};

export const getNameFromList = (codeName) => {
  const data = getData();
  data.push({ code: "AC", name: "Ascension" });

  var name;
  for (let i = 0; i < data.length; i++) {
    if (data[i].code === codeName) {
      name = data[i].name;
    }
  }
  return name;
};

export const formatFileSize = (bytes) => {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

export const getLocalizedDate = (date) => {
  return new Date(date).toLocaleString()?.split(",")[1];
};

export const hasPermission = (route) => {
  let permissionQuota = null;
  let dataLocal = null;
  try {
    dataLocal = JSON.parse(getLocalKey("GlobalDetails"));
  } catch (e) { }

  const userData = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {};
  let role = userData.role;
  if (!userData.role) {
    return false;
  }
  switch (role) {
    case STAFF_ROLE.SUPER_ADMIN:
      permissionQuota = "superAdmin";
      break;
    case STAFF_ROLE.SUB_ADMIN:
      permissionQuota = "subAdmin";
      break;
    case STAFF_ROLE.STAFF:
      permissionQuota = "staff";
      break;
    default:
      permissionQuota = "";
  }
  let allPermissions = [
    ...PERMISSIONS[permissionQuota],
    ...(dataLocal && dataLocal.surveys === true
      ? [
        "/admin/question-settings/sets/add",
        "/admin/question-settings/sets/*/edit/*",
        "/admin/question-settings/sets/status",
        "/admin/question-settings/sets/delete",
        "/admin/question-settings/sets/more",
        "/admin/question-settings/sets/reorder",
        "/admin/question-settings/sets/newQuestions",
        "/admin/question-settings/sets/duplicate",
        "/admin/question-settings/sets/multi_select",
      ]
      : []),
  ];

  return allPermissions.indexOf(route) !== -1;
};
// console.log("Hello", getDayBetweenDates("2024-01-11T00:00:00.000Z","2024-01-12T00:00:00.000Z"));

export const addQueryParam = (url, key, value) => {
  const separator = url.includes("?") ? "&" : "?";
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);
  console.log(`${url}${separator}${queryParams.toString()}`);
  return `${url}${separator}${queryParams.toString()}`;
};

export const decideRole = (role) => {
  // console.log(role);
  if (role === STAFF_ROLE.ADMIN) {
    return <span className="badge badge-success">Super Admin</span>;
  } else if (role === STAFF_ROLE.SUB_ADMIN) {
    return <span className="badge badge-info">Sub Admin</span>;
  } else if (role === STAFF_ROLE.USER) {
    return <span className="badge badge-dark">User</span>;
  } else if (role === STAFF_ROLE.STAFF) {
    return <span className="badge badge-primary">Staff</span>;
  } else {
    return <span className="badge badge-light">Unknown</span>;
  }
};

export const removeCommas = (inputString) => {
  if (typeof inputString !== "string") {
    return inputString;
  }
  return inputString.replace(/,/g, ";");
};

export const isImage = (fileName) => {
  if (!fileName) {
    return false;
  }
  const imageExts = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const fileExt = fileName.split(".").pop().toLowerCase();
  return imageExts.includes(fileExt);
};

export const getFileIcons = (fileName) => {
  if (!fileName) return null;

  const fileExt = fileName.split(".").pop().toLowerCase();
  const imageExts = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

  if (imageExts.includes(fileExt)) {
    return <img src={fileName} alt="Thumbnail" />;
  } else {
    switch (fileExt) {
      case "pdf":
        return (
          <i
            className="fa fa-file-pdf-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      case "zip":
      case "tar":
      case "rar":
        return (
          <i
            className="fa fa-file-archive-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      case "docx":
        return (
          <i
            className="fa fa-file-word-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      case "xls":
      case "xlsx":
      case "csv":
        return (
          <i
            className="fa fa-file-excel-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      case "mp3":
      case "wav":
        return (
          <i
            className="fa fa-file-audio-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      case "mov":
      case "avi":
      case "mp4":
        return (
          <i
            className="fa fa-file-video-o"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
          ></i>
        );
      default:
        return <i className="fa fa-file" aria-hidden="true"></i>;
    }
  }
};

export const writeMyExcel = (data, fileName) => {
  return new Promise((resolve, reject)=>{
    const XLSX = document.XLSX;
    if(XLSX){
      try{
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Calculate column widths based on data length
        const headings = data[0];
        const dynamicWidths = headings.map((heading, i) => {
          const lengths = data.map((row, j) => {
            return (row[i].length + 1) || 10;
          });
          return { wch: Math.max(...lengths) };
        });
        ws["!cols"] = dynamicWidths;

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, fileName);
        resolve();
      }catch(err){
        reject(err);
      }
    }else{
      reject(new Error("XLSX module not found."));
    }
  });
};

export const SpecialCharacter = (text) => {
  return text.replace(/_/g, " ").replace("-", " ");
};

export const capitalizeFirstLetterOfEachWord = (str) => {
  if (str && str.length) {
    return str.replace(/(?:^|\s|-|_)\S/g, (char) => char.toUpperCase());
  } else {
    return "";
  }
};

export const timeStamp = (data, fileName) => {
  return new Date().getTime();
};
export const pluralString = (length = 0, plural = "", singular = "") => {
  return length > 1 ? plural : singular;
};
export const getFileNameFromURL = (url) => {
  if (url) {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const segments = pathname.split("/");
      const fileName = segments[segments.length - 1];
      return fileName;
    } catch (err) {
      console.log("Provide url is not valid", err, url);
      return "";
    }
  } else {
    return "";
  }
};
export const getChunks = (file) => {
  let chunks = [];
  const size = file.size;
  const totalChunks = Math.ceil(size / FILE_UPLOAD.CHUNK_SIZE);
  return new Promise((resolve) => {
    let i = 0;
    for (i; i < totalChunks; i++) {
      chunks.push(
        file.slice(i * FILE_UPLOAD.CHUNK_SIZE, FILE_UPLOAD.CHUNK_SIZE * (i + 1))
      );
    }
    resolve(chunks);
  });
};
export const prepareQuestionsFormData = (
  result,
  formData,
  fileUploadPath,
  formId = null
) => {
  if (result) {
    let ans = "";
    let keySuffix = "";
    try {
      ans = JSON.stringify(result?.normalQuestions);
    } catch (err) {
      console.log("Not a valid data to stringify", err);
    }
    if (formId) {
      keySuffix = `__FORM_ID__${formId}`;
    }
    formData?.append("answered_questions" + keySuffix, ans);
    formData?.append("questions_file_upload_path" + keySuffix, fileUploadPath);
    if (Object.keys(result?.files).length) {
      Object.keys(result?.files).forEach((item) => {
        if (Array.isArray(result?.files[item])) {
          result?.files[item].forEach((file) => {
            formData?.append(item + keySuffix, file);
          });
        } else {
          formData?.append(item + keySuffix, result?.files[item]);
        }
      });
    }
  }
  return formData;
};
export const convertTo12HourFormat = (time24) => {
  const [hours, minutes] = time24.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const time12 = `${hours12}:${String(minutes).padStart(2, "0")} ${period}`;
  return time12;
};
export const getArrayOf = (range = 0) => {
  const arr = [];
  for (let i = 0; i < range; i++) {
    arr.push(i);
  }
  return arr;
};

export const isApp = () => {
  const key = getSessionKey("running_in_app");
  return Boolean(key && key === "true");
};

export const breakWord = (str) => {
  let words = str.split(" ");
  let newStr = words.map(word =>
    word.length > 50 ? word.slice(0, 50) + "...." : word
  ).join(" ");
  return newStr;
}

export const handleOffcanvasScroll = (e, id, type = "bootstrap") => {
  const targetElement = type === "bootstrap" ? document.getElementById(id) : e.target.parentElement;
  if (e.target.scrollTop > 0) {
    if (targetElement) {
      targetElement.classList.add("offcanvas_scrolled");
    }
  } else {
    if (targetElement) {
      targetElement.classList.remove("offcanvas_scrolled");
    }
  }
};

export const createSlider = (selector, settings = null) => {
  setTimeout(() => {
    const evt = new CustomEvent("createOWLSlider", { detail: { selector, settings } });
    document.dispatchEvent(evt);
  }, 100);
};

export const setReturnURL = (role) => {
  const URL = window.location.href.replace(window.location.origin, '');
  if (URL) {
    setSessionKey('return_url', URL);
    setSessionKey('return_url_role', role);
  }
  return true;
};

export const handleNavigation = ({ successURL, role }) => {
  const returnURL = getSessionKey('return_url');
  const returnRole = getSessionKey('return_url_role');
  removeSessionKey('return_url');
  removeSessionKey('return_url_role');
  if (window.location.pathname === returnURL) {
    return window.navigate_ ? window.navigate_(successURL) : null;
  } else {
    if (returnURL && returnRole && returnRole === role) {
      return window.navigate_ ? window.navigate_(returnURL) : null;
    } else {
      return window.navigate_ ? window.navigate_(successURL) : null;
    }
  }
};
export const useDebouncing = (func, delay = 300) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
};
export const booleanMask = (value, options = {
  trueLabel: "true_label_yes", falseLabel: "false_label_no"
}) => {
  if (Boolean(value)) {
    return options.trueLabel;
  } else {
    return options.falseLabel;
  }
};
export const showPrice = (value,toFixedValue=2) => {
  const number = isNaN(value) ? 0 : Number(value);
  return number.toFixed(toFixedValue);
};

export const handlePicker = (event) => {
  if (event && event.target && typeof event.target.showPicker !== "undefined") {
    // event.target.showPicker();
    event.target.focus();
  }
};

export const getColorType = (hexColor) => {
  hexColor = hexColor.replace(/^#/, '');
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);
  if (r > 240 && g > 240 && b > 240) {
    return 'white';
  }
  r /= 255;
  g /= 255;
  b /= 255;
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 0.5 ? 'light' : 'dark';
};

export const getTaxFromValue = (value, taxRate) => {
  /** Convert taxRate from percentage (e.g., 8) to decimal (e.g., 0.08) */
  let data = value - value / (1 + taxRate / 100);
  return Number(data.toFixed(2));  /** Returns the tax value rounded to 2 decimal places */
};



export const getImageRatio = (base64Image) => {
  return new Promise((resolve) => {
    var byteCharacters = atob(base64Image.split(',')[1]);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: 'image/jpeg' });

    var img = new Image();

    img.src = URL.createObjectURL(blob);

    img.onload = function () {
      var width = this.width;
      var height = this.height;
      resolve({ width: width * (25.4 / 96), height: height * (25.4 / 96) });
    };
  })
}

export const generateTablePdf = async (name) => {
  try {
    const svgElement = document.getElementById('qr-image');
    const svgData = new XMLSerializer().serializeToString(svgElement);

    // Create a canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Create an image from the SVG data
    const img = new Image();
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      // Set canvas dimensions
      canvas.width = 140;
      canvas.height = 140;

      // Draw the image on the canvas
      context.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert canvas to image data URL
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add heading
      // doc.setFontSize(22);
      // doc.text(name, 105, 20, null, null, 'center');

      // Center the image
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addImage(imgData, 'PNG', (pageWidth - 140) / 2, 30, 140, 140);

      // Save the PDF
      doc.save(`table-${name}.pdf`);

      // Cleanup
      URL.revokeObjectURL(url);
    };

    img.src = url; // Start loading the image
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export const getPdfByHtml = (html, fileName = "qrCode.pdf") => {
  return new Promise((resolve) => {
    console.log("html", html)
    const doc = new jsPDF();

    html2canvas(html, {
      useCORS: true, // enable if dealing with cross-origin resources
      backgroundColor: null // set to 'null' to preserve the background color
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Get the image dimensions
      let img = new Image();
      img.src = imgData;

      img.onload = () => {
        const imageWidth = img.width;
        const imageHeight = img.height;

        // Get PDF dimensions
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Calculate scaling factor to fit the image within the PDF page
        const scaleFactor = Math.min(pageWidth / imageWidth, pageHeight / imageHeight);
        const scaledImageWidth = imageWidth * scaleFactor;
        const scaledImageHeight = imageHeight * scaleFactor;

        // Add image to PDF
        doc.addImage(imgData, 'PNG', 70, 10, scaledImageWidth, scaledImageHeight);
        doc.save(fileName);
        resolve({});
      };
    });
  });
}





export const logOutCashier = () => {
  Swal.fire({
    title: t("msg_are_you_sure"),
    text: t("text_you_want_to_logout"),
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#6c757d",
    cancelButtonColor: "#dc3545",
    confirmButtonText: t("btn_yes"),
    cancelButtonText: t("btn_cancel"),
    customClass: {
      popup: 'swal-restaurant',
      confirmButton: 'btn btn-secondary col',
      cancelButton: 'btn btn-danger col'
    },
  }).then((result) => {
    if (result.isConfirmed) {
      globalLoader(true);
      LogoutUser({ role: ROLE.CASHIER }).then(() => {
        setTimeout(() => {
          globalLoader(false);
          logOutUser(ROLE.CASHIER);
          // dispatch(setCashierDetail(null));
          Swal.fire({
            icon: "success",
            text: t("msg_logout_successfully"),
            ...SWAL_SETTINGS,
          });
        }, LOADER_TIMEOUT_TIME);
      }).catch((err) => {
        console.error("Error while logging out", err);
      });
    }
  });
};
export const roundFigurePrice = (value, type) => {
  if (type === TAX_ROUND_OFF_FRACTIONS.TRUNCATE) {
    return Math.trunc(value);
  } else if (type === TAX_ROUND_OFF_FRACTIONS.ROUND_UP) {
    return Math.ceil(value);
  } else if (type === TAX_ROUND_OFF_FRACTIONS.ROUND_OFF) {
    return Math.round(value);
  } else {
    return Math.round(value);
  }
}

export const pluralText = (value, singularText, pluralText) => {
  return value > 1 ? pluralText : singularText;
}

export const getDayDiff = (start, end) => {
  let result = "";
  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);
  const finalStartDate = moment(startDate);
  const finalEndDate = moment(endDate);
  const diffInDays = finalEndDate.diff(finalStartDate, 'days');
  if (diffInDays) {
    result = ` (+${diffInDays} ${t(pluralText(diffInDays, "text_day", "text_days"))})`;
  }
  return result;
};

export const isValidJSON = (data) => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  return true
};

export const getSubTotalQRApp = (data = [], restSettings, options = {
  handleTaxSettings: false
}) => {
  let result = {
    subtotal: 0,
    tax_price: 0,
    grand_total: 0,
    total_tax_8: 0,
    total_tax_10: 0,
    service_charge: 0,
    grand_cost_price: 0
  };
  data.forEach((item) => {
    const itemQuantity = Number(item?.quantity) || 1;
    result.subtotal += (item?.price * itemQuantity);
    result.tax_price += (item?.tax_price * itemQuantity);
    // result.grand_total += item?.total_price;
    if (item.consumption_tax_rate === 8) {
      result.total_tax_8 += ((item?.tax_price + item?.price) * itemQuantity);
    } else if (item.consumption_tax_rate === 10) {
      result.total_tax_10 += ((item?.tax_price + item?.price) * itemQuantity);
    }

    result.grand_cost_price += item?.cost_price;
  });

  result.grand_total = result?.subtotal + result?.tax_price;
  if (restSettings?.is_service_charge_enabled && restSettings.service_charge && restSettings?.service_charge_applicable_condition === SERVICE_CHARGED_CONDITION.ALWAYS) {
    result.service_charge = (result.subtotal * restSettings.service_charge) / 100;
  }
  result.grand_total += result?.service_charge;

  console.log("result", result)
  return result;
};

export const getTranslatedKey = (key, data = null) => {
  const language = getLocalKey('system_language');

  if (!language || language == 'en') {
    return data[key]
  }
  else {
    let updatedKey = `${key}_${DEFAULT_ITEM_LANGUAGE}`
    return data[updatedKey] ? data[updatedKey] : data[key]
    // if(default_language){
    //   let updatedKey= `${key}_${default_language}`
    //   return data[updatedKey] ? data[updatedKey] : data[key]
    // }
    // else{
    //   let updatedKey= `${key}_${language}`
    //   return data[updatedKey] ? data[updatedKey] : data[key]
    // }
  }
}

export const isSameArray = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every(item => arr2.includes(item));
};
export const getUniqueID = (prefix = "") => {
  const date = new Date();
  return `${prefix}${date.getTime()}`;
}

export const getReciptDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = daysOfWeek[now.getDay()];

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} (${dayOfWeek}) ${hours}:${minutes}:${seconds}`;
}
export const QRHeaderStatus = (base) => {
  if (base === ORDER_PLATFORM.WEB) {
    return true;
  } else {
    const notAllowedPaths = ['/qr-order', '/qr-order/review', '/qr-order/success'];
    if (notAllowedPaths.indexOf(window?.location?.pathname) >= 0) {
      return false;
    } else {
      return true;
    }
  }
}


export const get24HoursFormatedTime = (dateString) => {
  let date = new Date(dateString);

  let hours = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, "0");

  let amOrPm = hours >= 12 ? "PM" : "AM";
  let formattedHours = hours % 12 || 12;  // Convert 24-hour to 12-hour format; 0 should be shown as 12

  return `${formattedHours.toString().padStart(2, "0")}:${minutes} ${amOrPm}`;
};

export const formatDateToHHMM = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const getDatesOfMonth = (date) => {
  const today = new Date(date);
  const currentDate = new Date(); // Current date to compare with

  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const dates = [];
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // If the provided date is from the current month, only create dates up to today
  const endDate = (today.getMonth() === currentDate.getMonth() && today.getFullYear() === currentDate.getFullYear()) ? currentDate.getDate() : lastDay.getDate();

  for (let day = 1; day <= endDate; day++) {
    // const month = monthNames[today.getMonth()];
    const month = today.getMonth() + 1;
    dates.push(`${month}/${day}`);
  }

  return dates;
};

export const getTotalSalesData = (date, transactions, type = 'sales') => {
  const dates = getDatesOfMonth(date);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const transactionMap = transactions ? transactions.reduce((map, transaction) => {
    const dateObj = new Date(transaction.date);

    const month = dateObj.getMonth() + 1;
    const formattedDate = `${month}/${dateObj.getDate()}`;
    map[formattedDate] = transaction;
    return map;
  }, {}) : {};

  const totalSalesData = dates.map(date => {
    const transaction = transactionMap[date];
    return transaction ? (type === 'sales' ? transaction.totalSales : transaction.totalProfit) : 0;
  });

  return totalSalesData;
};


export const formatDateToDaySalesFormat = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[date.getDay()];
  return `${month}/${day} (${dayOfWeek})`;
}

export const getMonthName = (date, index = null) => {
  const months = FULLMONTHS
  const d = new Date(date);
  const monthIndex = d.getMonth();
  return index ? months[index - 1] : months[monthIndex];
}

export const getDateWithYearFromString = (yearString) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const newDate = new Date(yearString, currentMonth, currentDay);
  return newDate;
};


export const formateDateWithMonthForGraph = (date) => {
  try {
    let newStrDate = new Date(date);
    const language = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en";
    const options = { month: '2-digit', day: '2-digit', weekday: 'short' };
    if (language === "en") {
      const formatter = new Intl.DateTimeFormat("en-US", options);
      newStrDate = formatter.format(newStrDate);
      let date1 = ''
      const [monthWithDay, day] = newStrDate.split('/');
      const [dayOfWeek, month] = monthWithDay.split(', ');
      date1 = `${month}/${day} (${dayOfWeek})`;
      return date1;
    }
    else {
      const formatter = new Intl.DateTimeFormat('ja-JP', options);
      newStrDate = formatter.format(newStrDate);
      return newStrDate;
    }


  } catch (err) { return ""; }

};

export const getDateInFormat = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
}


export const countSpecificDayUpToToday =(year, day)=>{
  // Define a mapping of days to their respective indices
  const daysOfWeek = {
      sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6
  };

  // Convert the input day to lowercase and get the corresponding index
  const targetDayIndex = daysOfWeek[day.toLowerCase()];
  if (targetDayIndex === undefined) {
      throw new Error("Invalid day provided. Please use: sun, mon, tue, wed, thu, fri, sat");
  }

  // Get today's date
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // 0-indexed
  const currentDate = today.getDate();

  // Ensure the provided year is not in the future
  if (year > currentYear) {
      throw new Error("The provided year cannot be in the future.");
  }

  // Initialize a counter for the target day
  let count = 0;

  // Loop through all months up to the current month
  for (let month = 0; month <= currentMonth; month++) {
      // Determine the number of days in the current month
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      // Loop through all days of the current month
      for (let date = 1; date <= daysInMonth; date++) {
          // Stop at today's date if it's the current month
          if (year === currentYear && month === currentMonth && date > currentDate) {
              break;
          }

          const dayIndex = new Date(year, month, date).getDay(); // Get day of the week
          if (dayIndex === targetDayIndex) {
              count++;
          }
      }
  }

  return count;
}

// Example usage:
// const year = 2025;
// const day = "mon";
// const count = countSpecificDayUpToToday(year, day);
// console.log(`The year ${year} has had ${count} Mondays up to today's date.`);
