import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/table-orders";
const options = {role: ROLE.RESTAURANT};


export const list = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const todaySales = async (values) => {
    return await axiosInstance(options).get(`${path}/today-sales`, values);
  }
  
export const daySales = async (values) => {
  return await axiosInstance(options).post(`${path}/day-sales`, values);
}

export const transactionHistory = async (values) => {
  return await axiosInstance(options).post(`${path}/transaction-history`, values);
}


