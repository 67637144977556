import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login } from "../../services/auth.services";
import { Link } from "react-router-dom";
import {
    getSessionKey,
    handleNavigation,
    handleServerValidations,
    removeSessionKey,
    setLocalKey,
    setSessionKey,
} from "../../../../utils/commonfunction";
import { SWAL_SETTINGS, COOKIES_EXPIRATION, ROLE, REST_LOGIN_ERROR_TYPES } from "../../../../utils/Constants";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import CustomError from "../../../../utils/customError";
import { useValidationSchemas } from "../../validator/validation";
import logo from '../../../../assets/restaurant/images/logo.svg';
import { useSelector } from "react-redux";
import { setCashRegister } from "../../../../redux/slices/cashierDetail";
import { useDispatch } from "react-redux";


const CashierLogin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { loginValidationSchema } = useValidationSchemas();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const myGlobalData = useSelector((state) => state.globalData?.data);
    const [getGlobalData, setGlobalData] = useState({});

    useEffect(() => {
        if (myGlobalData) {
            const settingsData = JSON.parse(myGlobalData);
            setGlobalData(settingsData);
        }
    }, [myGlobalData]);

    /** Remember last logged-in user <<<< */

    const handleshow = () => {
        setShow(!show);
    };


    const formik = useFormik({
        initialValues: {
            restaurant_id: "",
            password: ""
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();
            formData.append("restaurant_id", values.restaurant_id);
            formData.append("password", values.password)
            login(formData)
                .then((response) => {
                    if (response.success) {
                        setLocalKey(ROLE.CASHIER, JSON.stringify(response.data));
                        setLocalKey('cashRegisterEntry', response.data.cashRegisterEntry);
                        dispatch(setCashRegister(response.data.cashRegisterEntry));
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                            handleNavigation({
                                successURL: "/cashier/dashboard",
                                role: ROLE.CASHIER
                            });

                    } else {
                        let message = handleServerValidations(response);
                        /** Handling different types of server errors */
                        const supportUrlConditions = [REST_LOGIN_ERROR_TYPES.ACCOUNT_IS_BLOCKED, REST_LOGIN_ERROR_TYPES.SUBSCRIPTION_EXPIRED, REST_LOGIN_ERROR_TYPES.ACCOUNT_NOT_ENROLLED, REST_LOGIN_ERROR_TYPES.PASSWORD_NOT_SET_YET];
                        if (supportUrlConditions.indexOf(response.error_type) !== -1) {
                            message += `<a className="d-block mt-2 no-decoration link-text" href="/support/${response.error_type}">${t('btn_text_contact_us')}</a>`;
                        }
                        Swal.fire({
                            icon: "error",
                            html: message,
                            ...SWAL_SETTINGS
                        });
                    }
                })
                .catch((error) => {
                    console.log("error===>", error)
                })
        }
    });



    return (
        <div className="login-wrap cashier">
            <div className="login-wrap-in">
                <div className="login-logo">
                    <img src={logo} alt="" />
                </div>
                <div className="login-form-wrap">
                    <div className="login-form-header">
                        <h4>{t('login_text')}</h4>
                        <p>{t('login_para_text')} </p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-form">
                            <div className="mb-3">
                            <label htmlFor="restaurant_id" className="form-label">{t('label_restaurant_id')}<span>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="restaurant_id"
                                    name="restaurant_id"
                                    placeholder={t('placeholder_id_restaurant')}
                                    value={formik?.values?.restaurant_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                />
                                <span className="text-danger d-flex text-left">
                                    <CustomError name="restaurant_id" form={formik} />
                                </span>


                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">{t('label_password')}<span>*</span></label>
                                <div className="input-group">
                                    <input
                                        type={!show ? "text" : "password"}
                                        className="form-control border-end-0"
                                        id="password"
                                        placeholder={t('placeholder_password')}
                                        value={formik?.values?.password}
                                        name="password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span
                                        className="input-group-text cp restaurant-password-toggle"
                                        onClick={handleshow}
                                    >
                                        {!show === true ? (
                                            <i className="ti ti-eye"></i>
                                        ) : (
                                            <i className="ti ti-eye-off"></i>
                                        )}
                                    </span>
                                </div>

                                <span className="text-danger d-flex text-left">
                                    <CustomError name="password" form={formik} />
                                </span>

                            </div>
                            <button className="btn btn-primary w-100 mt-4 justify-content-center">{t('link_login')}</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CashierLogin;