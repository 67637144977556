import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/reservation";
const options = {role: ROLE.RESTAURANT};


export const ReservationsList = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const add = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const edit = async (values) => {
  return await axiosInstance(options).put(`${path}/update`, values);
}

export const deleteReservation = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const reservationDetails = async (values) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}


export const cancelReservation = async (values) => {
  return await axiosInstance(options).get(`${path}/cancel?o_id=${values.o_id}`);
}
