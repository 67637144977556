import { Link } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react';
import { deleteTransactionSlip, transactionDetail, transactionHistory } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { DISPLAY_METHOD_TAX_OPTIONS, LOADER_TIMEOUT_TIME, SORT_A, SORT_D, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { capitalizeFirstLetter, formateDate, get24HoursFormatedTime, getDateInFormat, globalLoader, handleServerValidations, showPrice, TrimText, writeMyExcel } from
    '../../../../../utils/commonfunction';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import RecordNotFound from '../../../shared/recordNotFound';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import trash from '../../../../../assets/restaurant/images/trash.svg';

export default function SalesTransactionHistory() {

    const { t } = useTranslation();
    const [loader, setLoader] = useState(true);
    const [List, setList] = useState([]);
    const [saleData, setSaleData] = useState(null);
    const componentRef = useRef();
    const [reportDate, setReportDate] = useState(new Date());
    const [transactionData, setTransactionData] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const restSettingDetails = useSelector((state) => state?.restSettings?.data);
    const [sortConfig, setSortConfig] = useState({ column: "createdAt", order: SORT_D });
    const closeModalRef = useRef();
    const [refreshList , setRefreshList] = useState(false);

    // console.log(restSettingDetails)

    const today = new Date();
  
    // Dynamically format the date based on the Type
    const initialSelectedDate =today.toLocaleDateString('en-GB', {
        day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
    const lowerButtonRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [todayDate, setTodayDate] = useState(new Date());
  
  
    const handleUpperButtonClick = () => {
      if (lowerButtonRef.current) {
        if (isDatePickerOpen) {
          // Close the DatePicker
          lowerButtonRef.current.close(); // Close the DatePicker programmatically
        } else {
          // Open the DatePicker
          lowerButtonRef.current.open(); // Open the DatePicker programmatically
        }
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
      }
    };
    
  
    const handleDateChange = (value) => {
      setTodayDate(value);
      const formattedDate =value.toLocaleDateString('en-GB', {
        day: '2-digit',
              month: 'short',
              year: 'numeric',
            });
      setSelectedDate(formattedDate); // Update the displayed value
      setIsDatePickerOpen(false); // Close the DatePicker after selection
    };
  
    const handleToggle = (open) => {
      setIsDatePickerOpen(open);
    };
  
  
   
  
  
  
    const handleDatePickerClose = () => {
        
            setIsDatePickerOpen(false); 
        
     
    };
  
  




    const getTransactionHistory = () => {
        setLoader(true);
        let obj = {
            created: todayDate
        }
        if (sortConfig) {
            obj.sort = JSON.stringify(sortConfig);
          }
        transactionHistory(obj).then(response => {
            setList(response.data);
        }).catch(error => {
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
            console.log("error ====> ", error);
        });
    }
    useEffect(() => {
        getTransactionHistory();
    }, [todayDate , sortConfig , refreshList]);

    const handleDownload = () => {
        globalLoader(true);
        downloadPdf();
    }

    const downloadPdf = useReactToPrint({
        onPrintError: (error) => console.log(error),
        content: () => componentRef.current,
        removeAfterPrint: true,
        print: async (printIframe) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName("html")[0];
                const canvas = await html2canvas(html, { scale: 2 });
                const pdf = new jsPDF();
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 250;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                let yOffset = 10;
                let pageHeight = 297;

                if (imgHeight > pageHeight) {
                    pdf.addImage(imgData, 'PNG', 0, yOffset, imgWidth, imgHeight);
                    pdf.addPage();
                    yOffset = 0;
                } else {
                    pdf.addImage(imgData, 'PNG', 0, yOffset, imgWidth, imgHeight);
                }
                pdf.save('transaction_history.pdf');
                globalLoader(false);
            }
        },
    });


    const handleExportToExcel = () => {
        // Prepare the data for export
        const headers = [
          t('date_time_label'),
          t('label_slip_number'),
          t('label_table_no'),
          t('title_amount'),
          t('label_choose_payment_method'),
          t('label_cashier_manager'),
          t('label_no_of_people'),
          t('lable_comments'),
        ];



      
        const rows = List.map((item) => [
            getDateInFormat(item?.createdAt),
            (String(item?.slip_number)?.padStart(4, '0')) || '',
            TrimText(item?.tableDetail?.table_number, 20),
          `¥${showPrice(item?.great_grand_total || 0,3)}`,
          capitalizeFirstLetter(item?.payment_method),
          item?.cashier_id?.name ,
          item?.number_of_persons,
          t('label_success'),
        
        ]);
      
     
      
        writeMyExcel([headers, ...rows], "transaction_history_report.xlsx");
      };

    const getTransactionDetail = (o_id) => {
        transactionDetail({ o_id: o_id })
            .then((response) => {
                setTransactionData(response && response.data ? response.data : []);
                handelOrderCalculations(response.data);
            })
            .catch((error) => {
                console.log("error=====>", error);
            });
    }



    const handelOrderCalculations = (transactionData) => {
        const combinedOrderedItems = [...transactionData.orderDetail.ordered_items];
        let modifiedItems = [];
        transactionData.subOrders.forEach((subOrder) => {
            combinedOrderedItems.push(...subOrder.ordered_items);
        });

        combinedOrderedItems.forEach((item) => {
            if (item.type === "set_of_items") {
                const existingIndex = modifiedItems.findIndex(
                    (record) => record._id === item._id
                );
                if (existingIndex === -1) {
                    modifiedItems.push({
                        ...item,
                        quantity: item.quantity,
                        updatedAddOns: [],
                    });
                } else {
                    modifiedItems[existingIndex].quantity += item.quantity;
                }
                const itemIndex =
                    existingIndex === -1 ? modifiedItems?.length - 1 : existingIndex;
                if (item.addons.length > 0) {
                    item.addons.forEach((addon) => {
                        const addOnIndex = modifiedItems[
                            itemIndex
                        ]?.updatedAddOns.findIndex(
                            (a) =>
                                a.addon_id === addon.addon_id &&
                                a.spice_level === addon.spice_level
                        );
                        if (addOnIndex === -1 || addOnIndex === undefined) {
                            modifiedItems[itemIndex].updatedAddOns.push({
                                ...addon,
                                spice_level: addon.spice_level,
                                count: 1,
                            });
                        } else {
                            const existingAddon =
                                modifiedItems[itemIndex]?.updatedAddOns[addOnIndex];
                            existingAddon["count"] += 1;
                        }
                    });
                }
            } else {
                const existingIndex = modifiedItems.findIndex(
                    (record) =>
                        record._id === item._id && record.spice_level === item.spice_level
                );
                if (existingIndex === -1) {
                    modifiedItems.push({
                        ...item,
                        quantity: item.quantity,
                    });
                } else {
                    modifiedItems[existingIndex].quantity += item.quantity;
                }
            }
        });
        setOrderItems(modifiedItems);

    };

    // console.log("orderItems ====> ", orderItems);

    const handleSort = (key) => {
        let direction = SORT_A;
        if (sortConfig.column === key && sortConfig.order === SORT_A) {
          direction = SORT_D;
        }
        setSortConfig({ column: key, order: direction });
      };


      const handleDelete = (_id) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: 'swal-restaurant',
                confirmButton: 'btn btn-danger col',
                cancelButton: 'btn btn-secondary col'
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                deleteTransactionSlip({ _id: _id }).then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        closeModalRef.current.click();
                        setRefreshList(!refreshList)
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                     
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                }).catch(() => {
                    globalLoader(false);
                });
            }
        });
    };


    
    return (
        <main id='main-content'>
            <div className='menu-level-3'>
                <ul className="nav nav-pills gap-3">
                    <li className="nav-item">
                        <Link className="nav-link active"
                            to={'/restaurant/sales/sales-transaction-history'}>{t('label_sales_slip')} </Link> </li> <li
                                className="nav-item">
                        <Link className="nav-link " to={'/restaurant/sales/sales-slip-changes'}>{t('label_list_of_changes')}
                        </Link> </li> </ul> </div> <div className='inner-container'>
                <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                    {/* <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i
                            className='ti ti-chevron-down'></i></button> */}


<div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Upper Button */}
      <button className="btn btn-date" onClick={handleUpperButtonClick}>
        <i className="ti ti-calendar" style={{color: "#757575"}}></i> <span style={{color: "#757575"}}>{selectedDate}</span>{' '}
        <i className={`ti ${isDatePickerOpen ? 'ti-chevron-up' : 'ti-chevron-down'}`} style={{color: "#757575"}}></i>
      </button>

      {/* Hidden DatePicker */}
      <div
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <DatePicker
          ref={lowerButtonRef}
          oneTap={true}
          classPrefix="restaurant"
          placement="bottomStart" // Opens directly beneath the button
          container={() => document.body} // Prevents clipping by parent containers
          format={"dd/MM/yyyy"}
          cleanable={false}
          editable={false}
          value={todayDate}
          onChange={handleDateChange}
          onToggle={handleToggle} // Handle open/close state
          disabledDate={(date) => date > new Date()}
          onClose={ handleDatePickerClose}
        />
      </div>
    </div>      
{/* 
                    <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_date1")}
                        placement="autoVertical" shouldDisableDate={(currentDate) => currentDate && currentDate >
                            moment().endOf('day')} format="dd MMMM yyyy" cleanable={false} editable={false}
                        name="reservation_date" onChange={(e) => { setReportDate(e) }} value={reportDate} /> */}

                    <div className='card-head-group d-flex align-items-center gap-2'>
                        <button className="btn btn-black" onClick={() => { List?.length && handleExportToExcel(); }} disabled={!List?.length}>
                            <i className="ti ti-download"></i> {t("label_download")}
                        </button>
                        <ReactToPrint trigger={() =>
                            <button className="btn btn-black" disabled={!List?.length}>
                                <i className="ti ti-printer"></i> {t('label_print')}
                            </button>
                        } content={() => componentRef.current} />
                    </div>

                </div>
                <div className='card'>
                    <div className='card-body'>
                        {List && List.length ?
                            <div className='table-responsive' ref={componentRef}>
                                <table className='table table-printable '>
                                    <thead>
                                        <tr>
                                            <th>{t('date_time_label')}</th>
                                            <th>{t("label_slip_number")}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("_id")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "_id" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "_id" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            </th>
                                            <th>{t("label_table_no")}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("table_number")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "table_number" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "table_number" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            </th>
                                            <th>{t("title_amount")}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("great_grand_total")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "great_grand_total" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "great_grand_total" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            </th>
                                            <th>{t("label_choose_payment_method")}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("payment_method")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "payment_method" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "payment_method" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            </th>
                                            <th>{t("label_cashier_manager")}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("name")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "name" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "name" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            </th>
                                            <th>{t('label_no_of_people')}
                                            <span style={{ cursor: "pointer" }} onClick={() => {handleSort("number_of_persons")}}>
                            <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                            <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                          </span>
                                            
                                            </th>
                                            <th>{t("lable_comments")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            List && List.length ? List.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {/* <td>5/1 (Wed)</td> */}
                                                        <td>{getDateInFormat(item?.createdAt)}</td>
                                                        {/* {console.log(item.is_sales_slip_deleted)} */}
                                                        <td>{!item.is_sales_slip_deleted ?
                                                        <a data-bs-toggle="modal" data-bs-target="#open-modal" className='link-text cp' onClick={() => { getTransactionDetail(item?._id) }}>
                                                            {(String(item?.slip_number)?.padStart(4, '0')) || ''}</a>: t("label_deleted")}
                                                            
                                                            </td>
                                                        <td>{TrimText(item?.tableDetail?.table_number, 20) || t('label_not_entered')}
                                                        </td>
                                                        <td>¥{showPrice(item?.great_grand_total || 0,3)}</td>
                                                        <td>{capitalizeFirstLetter(item?.payment_method)}</td>
                                                        <td>{item?.cashier_id?.name}</td>
                                                        <td>{item?.number_of_persons}</td>
                                                        <td>{t('label_success')}</td>
                                                    </tr>
                                                )
                                            }) : ""
                                        }

                                    </tbody>

                                </table>
                            </div> :
                            <RecordNotFound heading='lbl_record_not_found' />}
                    </div>
                </div>
            </div>


            <div className="modal fade modal-lg" data-bs-backdrop="static" id="open-modal" tabIndex="-1"
                aria-labelledby="sort-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                                {t('label_sales_slip_details')}
                            </h1>

                            <button type="button" className="btn-close btn-icon" data-bs-dismiss="modal" aria-label="Close" ref={closeModalRef}>
                                <i className="ti ti-x"></i>
                            </button>
                        </div>

                        <div className="modal-body p-0">
                            <div className='sales-slip-details-box'>
                                <div className='list-info'>
                                    <ul>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_person_in_charge')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{transactionData?.cashier_id?.name}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_table_no')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{transactionData?.tableDetail?.table_number}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_no_of_people')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{t('label_all')} : {transactionData?.number_of_persons || 0}</span><br></br>
                                                <span>{t('label_men')} : {transactionData?.man || 0}</span><br></br>
                                                <span>{t('label_woman')}: {transactionData?.woman || 0}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_choose_payment_method')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{capitalizeFirstLetter(transactionData?.payment_method)}</span>
                                            </div>
                                        </li>

                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_date_time')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{formateDate(transactionData?.createdAt, "DD/MM/YYYY", true , false)}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_sales')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>¥ {showPrice(transactionData?.great_grand_total)} </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_type')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                            <span className='text-capitalize'>{transactionData?.type ? transactionData.type==="restaurant_order"? t('nav_link_sales'): transactionData.type: t('nav_link_sales')}</span>

                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_sub_total')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{capitalizeFirstLetter(transactionData?.payment_method)} ¥ {showPrice(transactionData?.subtotal)} </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_discount_if_applicable')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{transactionData?.gift_card_applied ?  `¥ ${showPrice(transactionData?.gift_card_amount)}`: 0}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_consumption_tax')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>¥{" "}{showPrice(transactionData?.tax_price || 0)}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('service_charge')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>¥{" "}{showPrice(transactionData?.service_charge || 0)}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t('label_total_capitalize')}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span><b>¥ {showPrice(transactionData?.great_grand_total || 0,3)}</b></span><br></br>
                                                <span>
                                                    (8% {t("tax_on_text")} ¥{" "}
                                                    {showPrice(transactionData?.total_tax_8)})
                                                </span><br></br>
                                                <span>(10% {t("tax_on_text")} ¥{" "}
                                                {showPrice(transactionData?.total_tax_10)})</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='table-box'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>{t('label_menu_item1')}</th>
                                                    <th>{t('label_quantity')}</th>
                                                    <th>{t('label_unit_price')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderItems && orderItems.length ? orderItems.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{TrimText(item?.item_name,25)}</td>
                                                                <td>{item?.quantity}</td>
                                                                <td>¥ {restSettingDetails.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED?showPrice(item?.price || 0,3) : showPrice(item?.total_price || 0,3)}</td>
                                                               
                                                            </tr>
                                                        )
                                                    }) : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='p-3'>
                                <textarea className='form-control' style={{ height: '100px' }} placeholder='Comments'></textarea>
                            </div> */}
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-primary" onClick={()=>{handleDelete(transactionData._id)}}>
                                {t('btn_delete')}
                            </button>
                            <button type="button" className="btn btn-primary">
                                {t('btn_edit')}
                            </button>
                            <button type="button" className="btn btn-primary">
                                {t('label_reprint')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}