import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { staffList, authenticateAdmin } from "../services/auth.services";
import { setCashRegister, setCashierDetail } from '../../../redux/slices/cashierDetail';
import { getLocalKey, globalLoader, handleServerValidations, logOutUser, setLocalKey, TrimText } from '../../../utils/commonfunction';
import i18next from 'i18next';
import logo from '../../../assets/cashier/images/logo.svg';
import thai from '../../../assets/restaurant/images/thai.png';
import chinese from '../../../assets/restaurant/images/chinese.png';
import english from '../../../assets/restaurant/images/english.png';
import hindi from '../../../assets/restaurant/images/hindi.png';
import japanese from '../../../assets/restaurant/images/japanese.png';
import nepalese from '../../../assets/restaurant/images/nepalese.png';
import { Link, NavLink } from 'react-router-dom';
import DefaultProfileImg from '../../../assets/restaurant/images/default_user_profile.png';
import { ROLE, SWAL_SETTINGS, LOADER_TIMEOUT_TIME, SOCKET_EVENTS } from '../../../utils/Constants';
import Swal from "sweetalert2";
import { LogoutUser } from '../../../utils/common.service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useValidationSchemas } from '../validator/validation';
import { SOCKET_CLIENT } from '../../../utils/socket';

export default function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [flag, setFlag] = useState(getLocalKey("system_language_flag") || english);
  const [list, setList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [password, setPassword] = useState('');
  // const myCashierData = getLocalKey('cashierDetail');
  const restaurantData = getLocalKey(ROLE.CASHIER) ? JSON.parse(getLocalKey(ROLE.CASHIER)) : {};
  const cashierDetails = useSelector((state) => state?.cashierDetails?.data);
  const [getCashierData, setCashierData] = useState(getLocalKey('cashierDetail'));
  const { authenticateAdminValidationSchema } = useValidationSchemas();
  const [show, setShow] = useState("false");

  useEffect(() => {
    try {
      dispatch(setCashRegister(restaurantData?.cashRegisterEntry));
      let data = typeof getCashierData === 'string' ? JSON.parse(getCashierData) : null;
      if (data && !cashierDetails) {
        dispatch(setCashierDetail(data));
        setCashierData(data);
        let joinRequest = {
          roomID: data._id
        };
        SOCKET_CLIENT.emit('JOIN-USER', joinRequest, (error) => {
          if (error) {
            console.error("*****Socket connection error on JOIN-USER", error);
          }
        });
      }

      if (cashierDetails && !data) {
        setCashierData(cashierDetails);
        let joinRequest = {
          roomID: cashierDetails._id
        };
        SOCKET_CLIENT.emit('JOIN-USER', joinRequest, (error) => {
          if (error) {
            console.error("*****Socket connection error on JOIN-USER", error);
          }
        });
      }


    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [getCashierData, cashierDetails, dispatch]);



  useMemo(() => {
    SOCKET_CLIENT.on('GET-DATA', async (response) => {
      if (response) {
        if (response?.event === SOCKET_EVENTS.STAFF_PASSWORD_CHANGED) {
          logOutUser(ROLE.CASHIER);
          dispatch(setCashierDetail(null));
          Swal.fire({
            icon: "success",
            text: response.message ? response.message : t("msg_logout_successfully"),
            ...SWAL_SETTINGS,
          });
        }
      }
    });
  }, []);

  const changeLanguage = (lang, flag) => {
    i18next.changeLanguage(lang);
    setLocalKey("system_language", lang);
    setLocalKey("system_language_flag", flag);
    setFlag(flag);
    const evt = new CustomEvent("language_change");
    document.dispatchEvent(evt);
  }

  const isActive = (url = "/") => {
    return window?.location?.pathname?.includes(url);
  }

  useEffect(() => {
    staffList()
      .then((data) => {
        if (data && data.data) {
          setList(data.data || []);
          if (!getCashierData) {
            const elem = document.getElementById('staff-button');
            elem.click();
          }
        } else {
          setList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching staff list:", error);
      });
  }, [getCashierData, dispatch]);


  const handleStaffSelect = (staff) => {
    setSelectedStaff(staff)
    if (staff.role === ROLE.RESTAURANT) {
      let elem = document.getElementById('staff-button-single');
      elem.click();
    } else {
      setLocalKey('cashierDetail', JSON.stringify(staff));
      dispatch(setCashierDetail(staff));
    }
  };

  const handleshow = (event) => {
    setShow(!show);
  };


  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validationSchema: authenticateAdminValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let obj = {
        email: selectedStaff.email,
        password: values.password
      }
      authenticateAdmin(obj)
        .then((response) => {
          if (response.success) {
            setLocalKey('cashierDetail', JSON.stringify(selectedStaff));
            dispatch(setCashierDetail(selectedStaff));
            document.getElementById('close_modal').click();
            resetForm();
          } else {
            let message = handleServerValidations(response);
            Swal.fire({
              icon: "error",
              html: message,
              ...SWAL_SETTINGS
            });
          }
        })
        .catch((error) => {
          console.error("error===>", error)
        })
    }
  });


  const logOutCashier = () => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("text_you_want_to_logout"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#6c757d",
      cancelButtonColor: "#dc3545",
      confirmButtonText: t("btn_yes"),
      cancelButtonText: t("btn_cancel"),
      customClass: {
        popup: 'swal-restaurant',
        confirmButton: 'btn btn-secondary col',
        cancelButton: 'btn btn-danger col'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        LogoutUser({ role: ROLE.CASHIER }).then(() => {
          setTimeout(() => {
            globalLoader(false);
            logOutUser(ROLE.CASHIER);
            dispatch(setCashierDetail(null));
            Swal.fire({
              icon: "success",
              text: t("msg_logout_successfully"),
              ...SWAL_SETTINGS,
            });
          }, LOADER_TIMEOUT_TIME);
        }).catch((err) => {
          console.error("Error while logging out", err);
        });
      }
    });
  };

  return (
    <div className='header-cashier'>
      <div className='header-cashier-in'>
        <div className='logo-cashier'>
          <a href='#'><img src={logo} /></a>
        </div>
        <div className='cashier-nav'>
          <ul>
            <li className={isActive('/cashier/dashboard') ? " active" : ""}>
              <NavLink to='/cashier/dashboard'>
                <i className='ti ti-grid-dots'></i>
                <span>{t('nav_link_home')}</span>
              </NavLink>
            </li>
            <li className={isActive('/cashier/profile') ? " active" : ""}>
              {/* <NavLink to='/cashier/profile'> */}
              <a href='#' data-bs-toggle="modal" data-bs-target="#modal-users">
                <i className='ti ti-user'></i>
                <span>{cashierDetails ? TrimText(cashierDetails?.name, 10):TrimText(restaurantData?.restaurant_name, 10)}</span>
              </a>
              {/* </NavLink> */}
            </li>
            <li className={isActive('/cashier/settings') ? " active" : ""}>
              <NavLink to='/cashier/settings'>
                <i className='ti ti-settings'></i>
                <span>{t("nav_link_settings")}</span>
              </NavLink>
            </li>
            <li>
              <Link onClick={(e) => { e.preventDefault(); logOutCashier() }}>
                <i className='ti ti-logout'></i>
                <span>{t("label_logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="dropdown profile-dropdown lang-dropdown">
          <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div className='avatar avatar-xxs'>
              <img src={flag} alt='Flag' />
            </div>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item cp" onClick={(e) => { e.preventDefault(); changeLanguage("ja", japanese) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={japanese} alt='Japanese-flag' />
                </figure>
                <span>Japanese</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); changeLanguage("en", english) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={english} alt='UK-flag' />
                </figure>
                <span>English</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); changeLanguage("ne", nepalese) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={nepalese} alt='Nepalese-flag' />
                </figure>
                <span>Nepalese</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); changeLanguage("zh", chinese) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={chinese} alt='Chinese-flag' />
                </figure>
                <span>Chinese</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); changeLanguage("hi", hindi) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={hindi} alt='India-flag' />
                </figure>
                <span>Hindi</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); changeLanguage("th", thai) }}>
                <figure className='avatar avatar-xxs'>
                  <img src={thai} alt='Thai-flag' />
                </figure>
                <span>Thai</span>
              </a>
            </li>
            <button data-bs-target="#modal-users-single" data-bs-toggle="modal" id="staff-button-single"></button>
            <button data-bs-target="#modal-users" data-bs-toggle="modal" id="staff-button"></button>

          </ul>
        </div>
      </div>
      <div className="modal fade modal-cashier modal-cashier-lg" data-bs-backdrop="static" id="modal-users" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h1 className="modal-title fs-5 text-center w-100" id="exampleModalLabel">{t("label_operators")}</h1>
              {getCashierData ?
                <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" >
                  <i className='ti ti-x'></i>
                </button> : null
              }
            </div>
            <div className="modal-body">
              <div className='list-users'>
                {list?.map((staff) => (
                  <div key={staff._id} className='list-user-box' onClick={() => handleStaffSelect(staff)} data-bs-dismiss="modal">
                    <figure className='avatar avatar-md'>
                      <img src={staff.profile_image ? staff?.profile_image : DefaultProfileImg} />
                    </figure>
                    <h4>{staff.role && staff.role == ROLE.RESTAURANT ? t('role_admin') : t('role_employee')}</h4>
                    <h4 style={{wordBreak:'break-all'}}>{TrimText(staff.name)}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modal-cashier" id="modal-users-single" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" data-bs-target="#modal-users" data-bs-toggle="modal">
                <i className='ti ti-arrow-left'></i>
              </button>
              <h1 className="modal-title fs-5 text-center w-100" id="exampleModalLabel">{t("label_authenticate_user")}</h1>
              {/* <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close"  id="close_modal">
                  <i className='ti ti-x'></i>
              </button> */}
            </div>
            <div className="modal-body">
              <div className='user-enter-password px-4'>
                <div className='list-user-box'>
                  <figure className='avatar avatar-md'>
                    <img src={selectedStaff && selectedStaff.profile_image ? selectedStaff?.profile_image : DefaultProfileImg} alt='Admin' />
                  </figure>
                  <h4>{selectedStaff && selectedStaff?.first_name}</h4>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">{t("label_password")}<span>*</span></label>
                    <div className="input-group">
                      <input
                        type={!show === true ? "text" : "password"}
                        className="form-control border-end-0"
                        id="password"
                        placeholder={t('placeholder_password')}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span
                        className="input-group-text cp restaurant-password-toggle"
                        onClick={() => {
                          handleshow();
                        }}
                      >
                        {!show === true ? (
                          <i className="ti ti-eye"></i>
                        ) : (
                          <i className="ti ti-eye-off"></i>
                        )}
                      </span>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger">{formik.errors.password}</div>
                    ) : null}
                  </div>
                  {formik.status?.success && <div className="alert alert-success">{formik.status.success}</div>}
                  <button type="submit" className='btn btn-primary w-100'>{t("label_change_operator")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
