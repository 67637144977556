import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { updateUserPassword } from './../../../services/profile.services'
import { handleServerValidations } from './../../../../../utils/commonfunction'
import Swal from 'sweetalert2';
import CustomError from '../../../../../utils/customError';
import {
    SWAL_SETTINGS,
    ATLEAST_ONE_CAPITAL_REGEX,
    ATLEAST_ONE_NUMBER_REGEX,
    ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH,
    ATLEAST_ONE_SMALL_REGEX,
} from "./../../../../../utils/Constants";

export default function ProfileChangePassword() {
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState("false");
    const [newshow, setNewShow] = useState("false");
    const [conformshow, setConformShow] = useState("false");
    const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);

    const validationSchema = Yup.object().shape({
        current_password: Yup.string().required("label_old_password_error"),
        new_password: Yup.string()
            .required("label_new_password_error")
            .matches(
                ATLEAST_ONE_SMALL_REGEX,
                "password_validation_lowercase"
            )
            .matches(
                ATLEAST_ONE_CAPITAL_REGEX,
                "Password_Validation_uppercase"
            )
            .matches(
                ATLEAST_ONE_NUMBER_REGEX,
                "Password_Validation_number"
            )
            .matches(
                ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
                "Password_Validation_special_char"
            )
            .min(PASSWORD_MIN_LENGTH, "Password_Validation_minimum_length")
            .max(
                PASSWORD_MAX_LENGTH,
                "Password_Validation_maximum_length"
            ),
        confirmPassword: Yup.string()
            .required("label_confirm_password_error")
            .oneOf([Yup.ref("new_password"), null], "password_validation_does_not_match"),
    });

    const formik = useFormik({
        initialValues: { current_password: "", new_password: "", confirmPassword: "" },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitted(true);
            let formData = new FormData();
            formData.append("current_password", values.current_password);
            formData.append("new_password", values.new_password);
            formData.append("confirm_newpassword", values.confirmPassword);
            setAddSubmissionLoader(true);
            updateUserPassword(formData)
                .then((response) => {
                    setAddSubmissionLoader(false);
                    setSubmitting(false);
                    setSubmitted(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm({ values: "" });
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    setAddSubmissionLoader(false);
                    setSubmitted(false);
                    console.log("error ====> ", error);
                });


        }
    })

    const handleshow = (event) => {
        if (event === "oldshw") {
            setShow(!show);
        }
        if (event === "newpwd") {
            setNewShow(!newshow);
        }
        if (event === "confirepwd") {
            setConformShow(!conformshow);
        }
    };


    return (
        <main id='main-content'>
            <div className='inner-container'>
                <ul className="nav nav-pills gap-3 mb-3">
                    <li className="nav-item">
                        <Link className="nav-link" to={'/restaurant/profile'}>{t("link_my_profile")}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to={'/restaurant/profile/change-password'}>{t("change_password")}</Link>
                    </li>
                </ul>
                <form onSubmit={formik.handleSubmit}>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-6'>

                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label htmlFor='current_password' className="form-label">{t("label_old_password")}<span>*</span></label>
                                            <div className='input-group'>
                                                <input
                                                    type={!show ? "text" : "password"}
                                                    className="form-control border-end-0"
                                                    placeholder={t("placeholder_old_password")}
                                                    id='current_password'
                                                    name='current_password'
                                                    value={formik.values.current_password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span
                                                    className="input-group-text cp restaurant-password-toggle"
                                                    onClick={() => {
                                                        handleshow("oldshw");
                                                    }}
                                                >
                                                    {!show === true ? (
                                                        <i className="ti ti-eye"></i>
                                                    ) : (
                                                        <i className="ti ti-eye-off"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="current_password" form={formik} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label htmlFor='new_password' className="form-label">{t("label_new_password")}<span>*</span></label>
                                            <div className='input-group'>
                                                <input
                                                    type={!newshow ? "text" : "password"}
                                                    className="form-control border-end-0"
                                                    placeholder={t("placeholder_new_password")}
                                                    id='new_password'
                                                    name='new_password'
                                                    value={formik.values.new_password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span
                                                    className="input-group-text cp restaurant-password-toggle"
                                                    onClick={() => {
                                                        handleshow("newpwd");
                                                    }}
                                                >
                                                    {!newshow === true ? (
                                                        <i className="ti ti-eye"></i>
                                                    ) : (
                                                        <i className="ti ti-eye-off"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="new_password" form={formik} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label htmlFor='confirmPassword' className="form-label">{t("placeholder_re_enter_new_password")}<span>*</span></label>
                                            <div className='input-group'>
                                                <input
                                                    type={!conformshow ? "text" : "password"}
                                                    className="form-control border-end-0"
                                                    placeholder={t("placeholder_re_enter_new_password")}
                                                    id='confirmPassword'
                                                    name='confirmPassword'
                                                    value={formik.values.confirmPassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span
                                                    className="input-group-text cp restaurant-password-toggle"
                                                    onClick={() => {
                                                        handleshow("confirepwd");
                                                    }}
                                                >
                                                    {!conformshow === true ? (
                                                        <i className="ti ti-eye"></i>
                                                    ) : (
                                                        <i className="ti ti-eye-off"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <span className='text-danger d-flex text-left'>
                                                <CustomError name="confirmPassword" form={formik} />
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='button-form'>
                                <button disabled={submitted ? true : null} type='submit' className={"btn btn-primary"+(addSubmissionLoader?" btn-loader":"")}>Update Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    )
}
