import React, { useEffect, useMemo, useRef, useState } from 'react'
import trash from '../../../../../assets/restaurant/images/trash.svg';
import { useTranslation } from 'react-i18next';
import { ITEM_TYPES, LOADER_TIMEOUT_TIME, SWAL_SETTINGS } from '../../../../../utils/Constants';
import Swal from 'sweetalert2';
import * as addonService from '../../../services/addons.service';
import * as menuItemsService from '../../../services/menuItem.services';
import { getTranslatedKey, globalLoader, handleOffcanvasScroll, handleServerValidations, useDebouncing } from '../../../../../utils/commonfunction';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import RecordNotFound from '../../../shared/recordNotFound';

export default function OptionsListing() {
    const navigate = useNavigate();
    const params = useParams();
    const addFormCloseBtn = useRef();
    const editFormCloseBtn = useRef();
    const assignFormCloseBtn = useRef();
    const { t } = useTranslation();
    const [search] = useState({});
    const [globalSearch, setGlobalSearch] = useState('');
    const [refreshList, setRefreshList] = useState(false);
    const [refreshItemsList, setRefreshItemsList] = useState(null);
    const [loader, setLoader] = useState(true);
    const [itemsLoader, setItemsLoader] = useState(true);
    const [List, setList] = useState([]);
    const [editableId, setEditableId] = useState(null);
    const [activeOption, setActiveOption] = useState(null);
    const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
    const [editSubmissionLoader, setEditSubmissionLoader] = useState(false);
    const [assignSubmissionLoader, setAssignSubmissionLoader] = useState(false);

    /** Add record form */
    const addForm = useFormik({
        initialValues: {
            addon_name: "",
            addon_name_ja: ""
        },
        validationSchema: Yup.object().shape({
            addon_name: Yup.string().trim().required(t("validation_addon_name_required")),
            addon_name_ja: Yup.string().trim().required(t("validation_addon_name_ja_required"))
        }),
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            setAddSubmissionLoader(true);
            addonService.add(values).then((response) => {
                setAddSubmissionLoader(false);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    resetForm();
                    addFormCloseBtn?.current?.click();
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                setAddSubmissionLoader(false);
                console.error("Error updating profile", error);
            });
        },
    });
    /** Edit record form */
    const editForm = useFormik({
        initialValues: {
            o_id: "",
            addon_name: "",
            addon_name_ja: "",
        },
        validationSchema: Yup.object().shape({
            addon_name: Yup.string().trim().required(t("validation_addon_name_required")),
            addon_name_ja: Yup.string().trim().required(t("validation_addon_name_ja_required"))
        }),
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            setEditSubmissionLoader(true);
            addonService.edit(values).then((response) => {
                setEditSubmissionLoader(false);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    resetForm();
                    editFormCloseBtn?.current?.click();
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                setEditSubmissionLoader(false);
                console.error("Error updating profile", error);
            });
        },
    });
    /** Assign items form */
    const assignForm = useFormik({
        initialValues: {
            o_id: "",
            assigned_items: [],
        },
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            setAssignSubmissionLoader(true);
            const formData = {
                o_id: values.o_id,
                assigned_items: values?.assigned_items?.filter(item => item.checked).map(item => item._id)
            };
            addonService.assignItems(formData).then((response) => {
                setAssignSubmissionLoader(false);
                if (response.success) {
                    Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                    });
                    resetForm();
                    assignFormCloseBtn?.current?.click();
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((error) => {
                setAssignSubmissionLoader(false);
                console.error("Error updating profile", error);
            });
        },
    });

    const prepareGlobalSearch = (value) => {
        setGlobalSearch(value);
    };
    const searchDebounce = useDebouncing(prepareGlobalSearch);

    const handleActiveOption = (isDefault, data) => {
        if (isDefault) {
            if (params.active_option) {
                const activeRecord = data.filter(item => item?._id === params.active_option)[0];
                if (activeRecord) {
                    setActiveOption(activeRecord);
                } else {
                    setActiveOption(data[0] || null);
                }
            } else {
                setActiveOption(data[0] || null);
            }
        } else {
            setActiveOption(data || null);
        }
    };

    const isSelectedItem = (record) => {
        if (activeOption?.assigned_items?.length) {
            return Boolean(activeOption.assigned_items.filter(item => item?._id === record?._id)[0]);
        }
        return false;
    };

    /** Getting list */
    useEffect(() => {
        setLoader(true);
        const formData = new FormData();
        if (Object.keys(search).length) {
            formData.append("search", JSON.stringify(search));
        }
        formData.append("global_search", globalSearch);
        addonService.list(formData).then(response => {
            setTimeout(()=>{setLoader(false);} , LOADER_TIMEOUT_TIME) ;
            if (response.success) {
                setList(response && response.data && response.data.list ? response.data.list : []);
                handleActiveOption(true, response.data.list);
            } else {
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                });
            }
        }).catch(error => {
            setTimeout(()=>{setLoader(false);} , LOADER_TIMEOUT_TIME) ;
            console.log("error ====> ", error);
        });
    }, [search, refreshList, globalSearch]);

    /** Getting record details for edit */
    useEffect(() => {
        if (editableId) {
            globalLoader(true);
            addonService.details(editableId).then(response => {
                globalLoader(false);
                if (response.success) {
                    editForm.setValues({
                        o_id: response?.data?._id,
                        addon_name: response?.data?.addon_name,
                        addon_name_ja: response?.data?.addon_name_ja
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch(error => {
                globalLoader(false);
            });
        }
    }, [editableId]);

    /** Getting items list */
    useEffect(() => {
        if (refreshItemsList !== null) {
            setItemsLoader(true);
            menuItemsService.Options(ITEM_TYPES.ITEM).then(response => {
                setItemsLoader(false);
                if (response.success) {
                    const assignedItem = (response && response.data ? response.data : []).map(item => {
                        return {
                            ...item,
                            checked: isSelectedItem(item)
                        }
                    });
                    assignForm.setValues({
                        o_id: activeOption?._id,
                        assigned_items: assignedItem
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch(error => {
                setItemsLoader(false);
                console.log("error ====> ", error);
            });
        }
    }, [refreshItemsList]);

    /** Handling the language change */
    useMemo(() => {
        document.addEventListener('language_change', function () {
            setRefreshList(!refreshList);
        });
    });

    /** Handling the delete functionality */
    const handleDelete = (record) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: 'swal-restaurant',
                confirmButton: 'btn btn-danger col',
                cancelButton: 'btn btn-secondary col'
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                addonService.deleteRecord({ o_id: record._id }).then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                }).catch(() => {
                    globalLoader(false);
                });
            }
        });
    };
    /** Handling the delete functionality */
    const handleDeleteFromAssigned = (record) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: 'swal-restaurant',
                confirmButton: 'btn btn-danger col',
                cancelButton: 'btn btn-secondary col'
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                addonService.deleteAssignedItem({ option_id: activeOption?._id, item_id: record?._id }).then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                }).catch(() => {
                    globalLoader(false);
                });
            }
        });
    };

    const selectItemsToAssign = (e, i) => {
        let allValues = [...assignForm.values?.assigned_items];
        allValues[i].checked = e.target.checked
        assignForm.setFieldValue(`assigned_items`, allValues);
    };
    const changeActiveOption = (e, record) => {
        if (e.target.classList.contains("active_option_handler")) {
            navigate('/restaurant/menu/options/list/' + record?._id);
            handleActiveOption(false, record);
            setRefreshItemsList(!refreshItemsList);
        }
    };


    return (
        <main id='main-content'>
            <div className='inner-container'>
                <div className='page-header d-flex align-items-center'>
                    <h4 className='heading-title-text'>{t('label_options')} ({List.length})</h4>
                </div>
                <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                    <div className='search-box'>
                        <input type='text' className='form-control search-input' placeholder={t("input_placeholder_search")} onInput={(e) => {
                            searchDebounce(e.target.value);
                        }} />
                    </div>
                    <div className='card-head-group d-flex align-items-center gap-2'>
                        <button className='btn btn-primary' data-bs-toggle="offcanvas" data-bs-target="#add-form-canvas"><i className='ti ti-plus'></i> {t('label_add_addon_option')}</button>
                    </div>
                </div>
                <div className='option-item-wrap d-flex'>
                    <div className='option-item-list'>
                        <div className='card'>
                            <div className='card-body'>
                                {
                                    loader ?
                                        <div className='w-100'>
                                            <Skeleton className='mb-2' height={50} count={5} />
                                        </div>
                                        :
                                        List.length ?
                                            <ul className='option-list-ul'>
                                                {List.map((item, index) => {
                                                    return (<li key={index} className={'active_option_handler cp' + (activeOption?._id === item?._id ? " active" : "")} onClick={(e) => { changeActiveOption(e, item) }}>
                                                        {console.log("item",item)}
                                                        <span className='active_option_handler'>{getTranslatedKey('addon_name',item)}</span>
                                                        {/* {item?.addon_name} */}
                                                        <div className='option-btns d-flex gap-2'>
                                                            <button className='btn btn-icon' data-bs-toggle="offcanvas" data-bs-target="#edit-form-canvas" onClick={() => { setEditableId(item?._id) }}>
                                                                <i className='ti ti-edit'></i>
                                                            </button>
                                                            <button className='btn btn-icon' onClick={() => { handleDelete(item) }}>
                                                                <i className='ti ti-trash'></i>
                                                            </button>
                                                        </div>
                                                    </li>)
                                                })}
                                            </ul>
                                            :
                                            <RecordNotFound heading="no_addons_found_text" />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='option-item-info'>
                        <div className='card'>
                            <div className='card-body'>
                                {
                                    loader ?
                                        <div className='w-100'>
                                            <Skeleton className='mb-2' height={50} count={5} />
                                        </div>
                                        :
                                        activeOption?.assigned_items?.length ?
                                            <div className='table-responsive' style={{minHeight:'auto'}}>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('product_name')}</th>
                                                            <th style={{width : "150px"}}>{t('label_action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {activeOption?.assigned_items.map((item, index) => {
                                                            return (<tr key={index}>
                                                                <td>{getTranslatedKey('item_name',item)}</td>
                                                                <td>
                                                                    <button className='btn btn-icon' onClick={() => { handleDeleteFromAssigned(item) }}><i className="ti ti-trash"></i></button>
                                                                </td>
                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <RecordNotFound heading='no_assigned_item_text' />
                                }
                                {
                                    List.length && !loader?
                                        <div className='text-center'>
                                            <button className='btn btn-primary' data-bs-toggle="offcanvas" data-bs-target="#assign-items-canvas"
                                                onClick={() => { setRefreshItemsList(!refreshItemsList) }}>{t('label_assign_items')}</button>
                                        </div>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add form  */}
            <div className="offcanvas offcanvas-end right-modal-w" id="add-form-canvas" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("label_add_addon_option")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" ref={addFormCloseBtn} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { addForm.resetForm() }}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={addForm.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">{t("label_addon_option_name")} (English)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_addon_option_name")}
                                name="addon_name"
                                value={addForm.values.addon_name}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.addon_name && addForm.errors.addon_name ? (
                                <div className="text-danger">{t(addForm.errors.addon_name)}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t("label_addon_option_name")} (Japanese)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_addon_option_name")}
                                name="addon_name_ja"
                                value={addForm.values.addon_name_ja}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.addon_name_ja && addForm.errors.addon_name_ja ? (
                                <div className="text-danger">{t(addForm.errors.addon_name_ja)}</div>
                            ) : null}
                        </div>

                        <div className="button-form">
                            <button type="submit" className={"btn btn-primary w-100"+(addSubmissionLoader?" btn-loader":"")}>
                                {t("btn_submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Edit form  */}
            <div className="offcanvas offcanvas-end right-modal-w" id="edit-form-canvas" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("label_edit_addon_option")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" ref={editFormCloseBtn} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { editForm.resetForm(); setEditableId(null); }}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={editForm.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">{t("label_addon_option_name")} (English)<span>*</span></label>
                            <input type="hidden" name="o_id" value={editForm?.values?.o_id} onChange={editForm.handleChange} />
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_addon_option_name")}
                                name="addon_name"
                                value={editForm.values.addon_name}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.addon_name && editForm.errors.addon_name ? (
                                <div className="text-danger">{t(editForm.errors.addon_name)}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t("label_addon_option_name")} (Japanese)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("placeholder_enter_addon_option_name")}
                                name="addon_name_ja"
                                value={editForm.values.addon_name_ja}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.addon_name_ja && editForm.errors.addon_name_ja ? (
                                <div className="text-danger">{t(editForm.errors.addon_name_ja)}</div>
                            ) : null}
                        </div>

                        <div className="button-form">
                            <button type="submit" className={"btn btn-primary w-100"+(editSubmissionLoader?" btn-loader":"")}>
                                {t("btn_update")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Assign form  */}
            <div className="offcanvas offcanvas-end right-modal-w" id="assign-items-canvas" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("label_assign_items")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" ref={assignFormCloseBtn} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { assignForm.resetForm(); }}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body" onScroll={(e) => {
                    handleOffcanvasScroll(e, "assign-items-canvas");
                }}>
                    {
                        itemsLoader?
                            <div className='w-100'>
                                <Skeleton className='mb-2' height={50} count={5} />
                            </div>
                        :
                    <form onSubmit={assignForm.handleSubmit}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>{t('label_category')}</th>
                                        <th>{t('label_items')}</th>
                                        <th>{t('label_price')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        assignForm?.values?.assigned_items?.map((record, i) => (
                                            <tr key={i}>
                                                <td className='py-2 border-0'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input cp"
                                                            type="checkbox"
                                                            id={"menu-item" + i}
                                                            value={record?._id}
                                                            checked={record?.checked}
                                                            onChange={(e) => { selectItemsToAssign(e, i) }}
                                                        />
                                                        <label className="form-check-label cp" htmlFor={"menu-item" + i}>
                                                        {getTranslatedKey('name',record?.category_data)} 
                                                        {/* {record?.category_data?.name} */}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td className='py-2 border-0'>{getTranslatedKey('item_name',record)}</td>
                                                {/* {record?.item_name} */}
                                                <td className='py-2 border-0'>¥ {record?.total_price}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="button-form">
                            <button type="submit" className={"btn btn-primary w-100"+(assignSubmissionLoader?" btn-loader":"")}>
                                {t("btn_submit")}
                            </button>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </main>
    )
}
