import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import { generateToken } from '../services/order.service';
import { getSessionKey, QRHeaderStatus, setSessionKey } from "../../../utils/commonfunction";
import { addQrPanelToken , ChargeSettingsDetail} from "../../../redux/slices/qrcodeDetails";
import { getRestaurantSettings } from "../../restaurant/services/profile.services";
import { ORDER_PLATFORM, SOCKET_EVENTS } from "../../../utils/Constants";
import { SOCKET_CLIENT } from "../../../utils/socket";

const QrOrderLayout = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const loaderState = useSelector((state) => state.globalLoader);
    const queryParams = new URLSearchParams(window.location.search);
    const restaurant_id = queryParams.get('restaurant_id');
    const table_id = queryParams.get('table_id');
    const base = queryParams.get('base');
    const [token, setToken] = useState(null);
    const [layoutHeight, setLayoutHeight] = useState("100vh");
    const [restaurantDetailChanged, setRestaurantDetailChanged] = useState(false);

    const newToken = useSelector((state)=>{return state.QrOrderToken.qrPanelToken});
    useEffect(()=>{
      setToken(newToken);
    },[newToken]);

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useEffect(() => {
        if(getSessionKey("qr_user_token")){
            dispatch(addQrPanelToken(getSessionKey("qr_user_token")));
        }else{
            if (base === "web") {
                generateToken({ restaurant_id: restaurant_id, table_id: table_id })
                    .then((response) => {
                        setToken(response?.data?.token);
                        setSessionKey("qr_user_token", response?.data?.token);
                        dispatch(addQrPanelToken(response?.data?.token));
                    })
                    .catch((err) => { console.log(err) });
            }
            else {
                setSessionKey("qr_user_token", queryParams.get('token'));
                dispatch(addQrPanelToken(queryParams.get('token')));
            }
        }

    }, []);
    

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/qrOrder/css/style.css"),
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);
    useEffect(()=>{
        setLayoutHeight(`${window.innerHeight}px`);
    },[location]);

    useMemo(() => {
 
      
            let joinRequest = {
                roomID: restaurant_id
            };
            SOCKET_CLIENT.emit('JOIN-USER', joinRequest, (error) => {
                if (error) {
                    console.log("*****Socket connection error on JOIN-USER", error);
                }
            });
        
    }, [location]);

    useMemo(() => {
        SOCKET_CLIENT.on('GET-DATA', async (response) => {
            if (response) {
                if (response?.event === SOCKET_EVENTS.RESTAURANT_DETAILS_CHANGED) {
                    console.log("socket event listened")
                    setRestaurantDetailChanged(!restaurantDetailChanged)
                }
            }
        });
    }, [restaurantDetailChanged]);

    useEffect(() => {
        if(token && token.length>30){
            getRestaurantSettings({
                extraHeaders:{Authorization:`Bearer ${token}`}
            })
              .then((response) => {
                // console.log(response.data);
                dispatch(ChargeSettingsDetail(response.data));
              })
              .catch((error) => {
                console.log("Error===>", error);
              });
        }
       
      }, [token , restaurantDetailChanged]);

    return (
        <>
            {loaderState && token? (
                <GlobalLoader />
            ) : (
                <div className='mobile-container'>
                    <div className='mobile-container-wraper' style={{height:layoutHeight}}>
                        <Header /> 
                        <Outlet />
                    </div>
                </div>
            )}
        </>
    );
};
export default QrOrderLayout;
