import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { transactionHistory } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { showPrice, TrimText } from '../../../../../utils/commonfunction';


export default function SalesTransactionHistory() {

const { t } = useTranslation();
const [loader, setLoader] = useState(true);
const [List, setList] = useState([]);
const [saleData, setSaleData] = useState(null);

const getTransactionHistory = () => {
    setLoader(true);
    transactionHistory().then(response => {
        setList(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }
  useEffect(() => {
    getTransactionHistory();
  }, []);

  return (
    <main id='main-content'>
        <div className='menu-level-3'>
            <ul className="nav nav-pills gap-3">
                <li className="nav-item">
                    <Link className="nav-link active" to={'/restaurant/sales/sales-transaction-history'}>Sales Slip</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to={'/restaurant/sales/sales-slip-changes'}>List of changes</Link>
                </li>
            </ul>
        </div>
        <div className='inner-container'>
            <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i className='ti ti-chevron-down'></i></button>
                <div className='card-head-group d-flex align-items-center gap-2'>
                    <button className='btn btn-black'><i className='ti ti-download'></i> Download</button>
                    <button className='btn btn-black'><i className='ti ti-printer'></i> Print</button>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Date & time</th>
                                    <th>Slip number</th>
                                    <th>Table no.</th>
                                    <th>Amount</th>
                                    <th>Payment method</th>
                                    <th>Cashier/manager</th>
                                    <th>No. of people</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        List && List.length ? List.map((item , index)=>{
                                            return(
                                                <tr key={index}>
                                                {/* <td>5/1 (Wed)</td> */}
                                                <td>{item?.createdAt}</td>
                                                <td><a href='#' className='link-text'>{TrimText(item?.slip_number,20)}</a></td>
                                                <td>{TrimText(item?.tableDetail?.table_number,20) || t('label_not_entered')}</td>
                                                <td>¥{showPrice(item?.great_grand_total)}</td>
                                                <td>item?.payment_method</td>
                                                <td>item?.cashier_id?.name</td>
                                                <td>item?.number_of_persons</td>
                                                <td>Success</td>
                                            </tr>
                                            )
                                        }) : ""
                                    }
                                
                                </tbody>
                            {/* <tbody>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                                <tr>
                                    <td>19/06/2024 11:30 AM</td>
                                    <td><a href='#' className='link-text'>02515444145387</a></td>
                                    <td>3</td>
                                    <td>¥60,719</td>
                                    <td>Cash</td>
                                    <td>Administrator </td>
                                    <td>2</td>
                                    <td>Success</td>
                                </tr>
                            </tbody> */}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
  )
}
