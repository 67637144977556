import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import CustomError from '../../../../../utils/customError';
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { TestPrinterConnection, updatePrinterSettings } from '../../../services/profile.services';
import Swal from 'sweetalert2';
import { connectToPrinter, handleServerValidations } from '../../../../../utils/commonfunction';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';

function PrinterSettings() {
  const {t} = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [addSubmissionLoader, setAddSubmissionLoader] = useState(false);
  const [connections, setConnections] = useState({});
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);

  const settingsForm = useFormik({
    initialValues: {
      kitchen_printer_ip:restSettingDetails?restSettingDetails?.kitchen_printer_ip : "",
      cashier_printer_ip:restSettingDetails?restSettingDetails?.cashier_printer_ip : ""
    },
    validateOnBlur:true,
    enableReinitialize: true,
    validationSchema : Yup.object().shape({
      kitchen_printer_ip: Yup.string()
        .matches(
          /^(?:\d{1,3}\.){3}\d{1,3}$/,
          "Invalid IP address. Use a valid IPv4 format (e.g., 192.168.1.1)"
        )
        .required("IP address is required"),
        cashier_printer_ip: Yup.string()
        .matches(
          /^(?:\d{1,3}\.){3}\d{1,3}$/,
          "Invalid IP address. Use a valid IPv4 format (e.g., 192.168.1.1)"
        )
        .required("IP address is required"),
    }),
    onSubmit: (values) => {
      setAddSubmissionLoader(true);
      updatePrinterSettings(values).then((response)=>{
        setAddSubmissionLoader(false);
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      }).catch((error) => {
        setAddSubmissionLoader(false);
        console.error("Error updating charges", error);
      });
    },
  });
  useEffect(()=>{
    if (restSettingDetails && typeof restSettingDetails === "object" && Object.keys(restSettingDetails).length) {
      setDataLoaded(true);
    }
  },[restSettingDetails]);


  const checkPrinterConnection = (key) => {
    const IP = settingsForm?.values[key + "_ip"];
    console.log(IP);

    TestPrinterConnection({ printerIp: IP })
        .then((response) => {
          if(response.success){
            let tempConnections = { ...connections };
            tempConnections[key] = {
                status: true,
                message: "Connected",
            };
            setConnections(tempConnections); // ✅ Ensure state update here
          }
          else{
            let tempConnections = { ...connections };
            tempConnections[key] = {
                status: false,
                message: "failed",
            };
            setConnections(tempConnections);
          }
            
        })
        .catch((err) => {
            let tempConnections = { ...connections };
            tempConnections[key] = {
                status: false,
                message: err?.message,
            };
            setConnections(tempConnections);
        });
};


  // const triggerConnection = (key)=>{
  //   const IP = settingsForm?.values[key+"_ip"];
  //   if(IP){
  //     connectToPrinter(key, IP).then(()=>{
  //       let tempConnections = {...connections};
  //       tempConnections[key]={
  //         status:true,
  //         message:"Connected"
  //       }
  //       setConnections(tempConnections);
  //     }).catch((err)=>{
  //       let tempConnections = {...connections};
  //       tempConnections[key]={
  //         status:false,
  //         message:err?.message
  //       }
  //       setConnections(tempConnections);
  //     });
  //   }
  // }

  const RenderConnectionStatus = ({connectionKey})=>{
    return <>
    <button className='btn btn-primary mx-3' type='button' onClick={()=>{checkPrinterConnection(connectionKey)}}>Test Connection</button>
      {
        connections && connections[connectionKey] ?
          <>
            {
              connections[connectionKey]?.status ?
                <div className='text-success d-flex align-items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                  <span className='ms-2' style={{ fontWeight: 600 }}>Connected</span>
                </div>
                :
                <div className='text-danger d-flex align-items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-square-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19 2h-14a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3 -3v-14a3 3 0 0 0 -3 -3zm-9.387 6.21l.094 .083l2.293 2.292l2.293 -2.292a1 1 0 0 1 1.497 1.32l-.083 .094l-2.292 2.293l2.292 2.293a1 1 0 0 1 -1.32 1.497l-.094 -.083l-2.293 -2.292l-2.293 2.292a1 1 0 0 1 -1.497 -1.32l.083 -.094l2.292 -2.293l-2.292 -2.293a1 1 0 0 1 1.32 -1.497z" /></svg>
                  <span className='ms-2' style={{ fontWeight: 600 }}>Error</span>
                </div>
            }
          </>
          : null
      }
    </>
  }

  return (
    <main id='main-content'>
      <div className='inner-container'>
        <div className='page-header d-flex align-items-center'>
          <h4 className='heading-title-text'>{t("heading_printer_settings")}</h4>
        </div>
        {
          !dataLoaded ? (<div className="service-charges-box">
            <div className="card p-2">
              <Skeleton className="m-0" height={50} count={2} />
            </div>
          </div>) : (
            <div className="service-charges-box">
              <form onSubmit={settingsForm.handleSubmit}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-3 w-100">
                        <div className="form-group w-100">
                          <label className='form-label' htmlFor='kitchen_printer_ip'>Kitchen Printer IP </label>
                            <div className='d-flex w-100'>
                              <div>
                              <input type="text" className="form-control" style={{minWidth:"400px"}} id='kitchen_printer_ip' name='kitchen_printer_ip' 
                              value={settingsForm?.values?.kitchen_printer_ip} onChange={settingsForm.handleChange} onBlur={settingsForm.handleBlur} placeholder={"192.XXX.XXX.XXX"} />
                              <CustomError className='text-danger' name="kitchen_printer_ip" form={settingsForm} />
                              </div>
                              <RenderConnectionStatus connectionKey="kitchen_printer"/>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 mt-4">
                      <div className="d-flex gap-3 w-100">
                        <div className="form-group w-100">
                          <label className='form-label' htmlFor='cashier_printer_ip'>Cashier Printer IP </label>
                            <div className='d-flex w-100'>
                          <div>
                          <input type="text" id='cashier_printer_ip' className="form-control" style={{minWidth:"400px"}} name='cashier_printer_ip' 
                              value={settingsForm?.values?.cashier_printer_ip} onChange={settingsForm.handleChange} onBlur={settingsForm.handleBlur} placeholder={"192.XXX.XXX.XXX"} />
                              <CustomError className='text-danger' name="cashier_printer_ip" form={settingsForm} />
                          </div>
                          <RenderConnectionStatus connectionKey="cashier_printer"/>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className={"btn btn-primary mt-4" + (addSubmissionLoader ? " btn-loader" : "")}>
                  {t('btn_update')}
                </button>
              </form>
            </div>
          )
        }
      </div>
    </main>
  );
}

export default PrinterSettings;