import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "cashier/tables"
const options = {role: ROLE.CASHIER};

export const list = async (values ) => {
    return await axiosInstance(options).post(`${path}/list` , values);
}

export const detail = async (values) => {
    return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const categoryList = async (values ) => {
    return await axiosInstance(options).get(`${path}/categoriesList` , values);
}

export const categoryDetail = async (values) => {
    return await axiosInstance(options).get(`${path}/categoryDetail?o_id=${values.o_id}`, values);
}

export const menuList = async (values ) => {
    return await axiosInstance(options).post(`${path}/menuList` , values);
}

export const PaymentMethodList = async (values ) => {
    return await axiosInstance(options).post(`${path}/paymentMethodlist` , values);
}

export const getRestaurantSettings = async () => {
    return await axiosInstance(options).get(`${path}/restaurant-settings`)
}
  

export const payByCash = async (values) => {
    return await axiosInstance(options).post(`${path}/pay-by-cash` , values);
}

export const updateCustomerInfo = async (values ) => {
    return await axiosInstance(options).post(`${path}/update-customer-info` , values);
}


export const applyGiftCard = async (values ) => {
    return await axiosInstance(options).post(`${path}/apply-gift-card` , values);
}


export const removeGiftCard = async (values ) => {
    return await axiosInstance(options).get(`${path}/remove-gift-card?order_id=${values.order_id}` , values);
}


export const transactionDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/transaction-detail?o_id=${values.o_id}`, values);
}

export const orderDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/order-detail?o_id=${values.o_id}`, values);
}

export const receiptDetail = async (values ) => {
    return await axiosInstance(options).get(`${path}/receipt`, values);
}


