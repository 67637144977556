import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/bulk-import";
const options = {role: ROLE.RESTAURANT};


export const getSampleFiles = async (values) => {
  return await axiosInstance(options).post(`${path}/sample-file`, values);
}

export const handleImports = async (values) => {
  return await axiosInstance(options).post(`${path}/handle-imports`, values);
}

