

export const EDITOR_TOOLBAR = [
  {
    name: "clipboard",
    groups: [
      "Cut",
      "Copy",
      "Paste",
      "PasteText",
      "PasteFromWord",
      "-",
      "Undo",
      "Redo",
    ],
  },
  {
    name: "basicStyles",
    groups: [
      "Bold",
      "Italic",
      "Underline",
      "Strike",
      "Subscript",
      "Superscript",
    ],
  },
  {
    name: "paragraph",
    groups: ["NumberedList", "BulletedList", "Outdent", "Indent", "Blockquote"],
  },
  {
    name: "alignment",
    groups: [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "BidiLtr",
      "BidiRtl",
    ],
  },
  { name: "links", groups: ["Link", "Unlink", "Image"] },
  { name: "table", groups: ["Table", "TableToolbar"] },
  {
    name: "styles",
    groups: ["Font", "FontSize", "Format", "TextColor", "BGColor"],
  },
  { name: "miscellaneous", groups: ["Find", "Replace", "Source"] },
  { name: "insert", groups: ["cstButton2"] },
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const SWAL_SETTINGS = {
  customClass: "swal-wide",
  position: "top-right",
  showConfirmButton: false,
  timer: 7000,
  toast: true,
  showClass: {
    popup: "animate__animated animate__bounceInRight",
  },
  hideClass: {
    popup: "animate__animated animate__bounceOutRight",
  },
  showCloseButton: true,
  // timerProgressBar: true,
};
export const showFilterlist = [
  { name: "list_heading_status", status__id: "" },
  { name: "btn_active", status__id: "1" },
  { name: "btn_inactive", status__id: "0" },
];
export const ROLE = {
  SUPER_ADMIN: "super_admin",
  RESTAURANT: "restaurant",
  STAFF: "staff",
  CASHIER: "cashier"
};

export const EMAIL_TEMPLATE_TYPES = {
  DEFAULT: "default",
};

export const TRANSACTION_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const TRANSACTION_TYPES = {
  PAYMENT: "payment",
  REFUND: "refund",
};

export const DEFAULT_EMAIL_OPTIONS = [
  "web_url",
  "name",
  "title",
  "first_name",
  "middle_name",
  "last_name",
  "email",
  "role",
  "phone_number"
];

export const DYNAMIC_VARIABLES = {
  SERVER_URL: process.env.REACT_APP_API_URL.replace("/webservice/api/v1", ""),
};

export const OTP_LENGTH = 6;
export const NUMBER_CHARACTER_ONLY = /^\d+$/;
export const MOBILE_NUMBER_REGEX = /^[0-9]+$/;
// export const EMAIL_REGEX = /^[a-zA-Z0-9._'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._'-]{1,100}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
export const ATLEAST_ONE_SMALL_REGEX = /[a-z]/g;
export const ATLEAST_ONE_CAPITAL_REGEX = /[A-Z]/g;
export const ATLEAST_ONE_NUMBER_REGEX = /[0-9]/g;
export const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /[^\w\s]/;
export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;
export const INPUT_LENGTH_15 = 15
export const INPUT_MAX_25 = 25;
export const INPUT_LENGTH_50 = 50;
export const TEXTAREA_MAX_LENGTH = 150;
export const CKEDITOR_CONFIG = {
  filebrowserUploadUrl: `${process.env.REACT_APP_API_URL}/admin/gallery/upload-ck-image`,
  allowedContent: true,
  fileTools_requestHeaders: {
    Authorization: `Bearer ${localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : ""}`,
  },
  extraAllowedContent: '*[*]',
  toolbar: [
    { name: 'source', items: ['Source'] },
    { name: 'functions', items: ['NewPage', 'ExportPdf', 'Print', 'Preview',] },
    { name: 'clipboard', items: ["Cut", "Copy", "Paste", "CopyFormatting"] },
    { name: 'miscellaneous', items: ["Undo", "Redo"] },
    { name: 'text-corrections', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
    { name: 'basic-styles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote'] },
    { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl'] },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor', 'Image'] },
    { name: 'table', items: ['Table', 'TableToolbar'] },
    { name: 'styles', items: ['Font', 'FontSize', 'Format', 'TextColor', 'BGColor', 'PageBreak'] },
    { name: 'forms', items: ['Form', "Radio", "Checkbox", "TextField", "Textarea", "Select", 'MediaButton'] },
    { name: 'configs', items: ['Smiley', 'SpecialChar', 'Iframe', 'Maximize'] }
  ]
};

export const COOKIES_EXPIRATION = 7; /** In Days */
export const MAX_VOICE_MEMO_SECONDS = 30; /** In Seconds */
export const FILE_UPLOAD = {
  CHUNK_SIZE: 1048576 /** 1 MB */,
};

export const PHONE_NO_LENGTH = {
  min: 7,
  max: 15
} 

export const MENU_LOCATION = {
  menu_of_page_header: "header",
  menu_of_page_footer: "footer",
};

export const amountRegex = /^\d{1,9}(\.\d{1,2})?$/;
export const phoneRegExp = /^(\+?\d{1,4}[\s-]?)?(?!0+\s+,?$)\d{10,14}$/;

export const cuisineOptions = [
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Nepali', value: 'Nepali' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Malaysian', value: 'Malaysian' },
  { label: 'Philippine', value: 'Philippine' },
  { label: 'Bruneian', value: 'Bruneian' },

];

export const CATEGORIES_TAX_TYPES = [
  { label: 'label_dine_option', value: 'dine' },
  { label: 'label_take_out_option', value: 'take_out' }
];

export const CONSUMPTION_TAX_RATES = {
  DINE:10, /** In percent */
  TAKE_OUT:8, /** In percent */
};


export const PLAN_TYPES = {
  DEMO: "demo",
  PREMIUM_PLAN: "premium_plan"
};
export const PLAN_DURATION_TYPES = {
  MONTHLY: "monthly",
  YEARLY: "yearly"
};
export const ITEM_TYPES = {
  ITEM: "item",
  SET_OF_ITEMS: "set_of_items"
};
export const ITEM_TAX_OPTIONS = {
  INCLUSIVE: "inclusive",
  EXCLUSIVE: "exclusive"
};
export const SOCKET_EVENTS = {
  STAFF_PASSWORD_CHANGED:"staff_password_changed",
  RESTAURANT_DETAILS_CHANGED:"restaurant_details_changed",
  ORDER_PLACED:"order_placed",
  ORDER_BILLED:"order_billed"
};

export const LOADER_TIMEOUT_TIME = 500; /* TIME FOR LOADER   */

export const ACTIVE = 1;
export const DEACTIVE = 0;

export const RECEIPT_TYPES = {
  TYPE_1: "receipt_style_1",
  TYPE_2: "receipt_style_2",
  TYPE_3: "receipt_style_3"
}


export const TAX_ROUND_OFF_FRACTIONS = {
  TRUNCATE: "truncate",
  ROUND_UP: "round_up",
  ROUND_OFF: "round_off"
};

export const DISPLAY_METHOD_TAX_OPTIONS = {
  TAX_INCLUDED: "tax_included",
  TAX_EXCLUDED: "tax_excluded"
}
export const REST_LOGIN_ERROR_TYPES = {
  INVALID_CREDENTIALS: "invalid_credentials",
  ACCOUNT_IS_BLOCKED: "account_is_blocked",
  ACCOUNT_NOT_ENROLLED: "account_not_enrolled",
  SUBSCRIPTION_EXPIRED: "subscription_expired",
  PASSWORD_NOT_SET_YET: "password_not_set_yet",
};

export const PAYMENT_METHOD_TYPES = {
  CREDIT_CARD: "credit_card",
  QR_PAYMENTS: "qr_payments",
};
export const USER_ENQUIRY_TYPES = {
  SIMPLE_INQUIRY: "simple_inquiry",
  NEED_SUPPORT: "need_support"
};


export const TABLE_SHAPE_TYPES = [
  { label: 'label_round', value: 'round' },
  { label: 'label_square', value: 'square' } , 
  { label: 'label_rectangle', value: 'rectangle' }
];

export const NOTES = [
  { value: 10000, label: '¥10000' },
  { value: 5000, label: '¥5000' },
  { value: 2000, label: '¥2000' },
  { value: 1000, label: '¥1000' },
];


export const COINS = [
  { value: 500, label: '¥500' },
  { value: 100, label: '¥100' },
  { value: 50, label: '¥50' },
  { value: 10, label: '¥10' },
  { value: 5, label: '¥5' },
  { value: 1, label: '¥1' }]

export const QR_CODE_TYPES={
  TABLE:'table'
}
export const SPICE_LEVELS={
  LOW:'low',
  MEDIUM:'medium',
  HIGHT:'high',
};


export const CUSTOMER_TAGS = {
  VIP : 'vip' ,
  REGULAR : 'regular'
};

export const ORDER_TYPES = {
  BASE_ORDER : "base_order" , 
  SUB_ORDER : "sub_order"
};

export const ORDER_STATUS = {
  PENDING : "pending" , 
  CHECKED_OUT : "checked_out" , 
  BILLED : "billed"
};
export const TITLE_MAX_LIMIT = 500 ;

export const SERVICE_CHARGED_CONDITION = {
  ALWAYS : 'always' , 
  AT_CHECKOUT : 'at_checkout'
}

export const DEFAULT_ITEM_LANGUAGE='ja';


export const ORDER_PLATFORM = {
  WEB : "web" , 
  APP : "waiter-app"
}

export const RESERVATION_STATUS = [
  { label: 'label_upcoming', value: 'reserved' },
  { label: 'label_cancelled', value: 'cancelled' } , 
  { label: 'label_passed', value: 'passed' }
]


export const SORT_A = 1;
export const SORT_D = -1;

export const COUPON_TYPES = {
  FLAT: "flat",
  PERCENTAGE: "percentage"
};


export const COUPON_CATEGORY_TYPES = [
  { label: 'label_general', value: 'General' },
  { label: 'label_special', value: 'Special' },
];

export const FILE_SLUG = {
  CATEGORY : "category" , 
  MENU_ITEMS : "menu_items" , 
  OPTIONS : "options"

}

export const PAYMENT_METHOD_TYPES_TRANSACTION = [
  { label: 'label_cash', value: 'cash' },
  { label: 'label_card', value: 'card' },
  { label: 'label_other', value: 'other' },
]


export const REPORT_TAX_TYPES = [
  { label: 'label_with_tax', value: 'With Tax' },
  { label: 'label_without_tax', value: 'Without Tax' },
];